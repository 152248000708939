<div id="userDataForm" class="container content" [formGroup]="formGroup">
    <!-- Datos personales -->
    <h5 class="title-step" align="center">
        <img src="./assets/img/users.svg" class="img-fluid ico-title" width="20"> Datos personales
    </h5>
    <div class="card">
        <ul class="list-group list-group-flush">
            <li class="list-group-item cuerpo-pack">
                <div class="form-row">
                    <div class="col-lg-2 mt-1">
                        <mat-form-field>
                            <mat-label>Tipo documento</mat-label>
                            <select matNativeControl required formControlName="doctype"
                                    (change)="changeTypeDocument($event.target.value); setMaxLengthByDoc($event.target.value)">
                                <option *ngFor="let documentType of documentTypes" [value]="documentType.document_type_id" [hidden]="!permSv.hasPermFromV2(allPermissions.simyo.permite_ventas_cif) && documentType?.document_type_id === 4">
                                    {{ documentType.value }}
                                </option>
                            </select>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-2 mt-1" [ngClass]="{'custom-error': checkErrorFieldForm('doc', ['required', 'doc'])}">
                        <mat-form-field>
                            <mat-label>Nº de documento</mat-label>
                            <input matInput type="text" name="doc" formControlName="doc" [maxLength]="maxLengthByDoc" required (keypress)="alphanumericOnly($event)">
                            <!-- error -->
                            <mat-error *ngIf="checkErrorFieldForm('doc', ['required'])">
                                Campo obligatorio.
                            </mat-error>
                            <mat-error *ngIf="checkErrorFieldForm('doc', ['required']) == false && checkErrorFieldForm('doc', ['doc', 'pattern'])">
                                Número de documento erróneo
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-2 mt-1" [ngClass]="{'custom-error': checkErrorFieldForm('country', ['required'])}">
                        <mat-form-field>
                            <mat-label>Nacionalidad</mat-label>
                            <select matNativeControl required formControlName="country">
                                <ng-container *ngFor="let country of countries;">
                                    <option [value]="null" hidden></option>
                                    <option [value]="country.country_id">
                                        {{ country.value }}
                                    </option>
                                </ng-container>
                            </select>
                            <mat-error *ngIf="checkErrorFieldForm('country', ['required'])">
                                Campo obligatorio.
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-2 mt-1" [ngClass]="{'custom-error': checkErrorFieldForm('firstName', ['required','blankSpace'])}">
                        <mat-form-field>
                            <mat-label>{{ requireCif ? 'Razón social' : 'Nombre' }}</mat-label>
                            <input matInput type="text" oninvalid="" required formControlName="firstName" maxlength="32">
                            <mat-error *ngIf="checkErrorFieldForm('firstName', ['required', 'blankSpace'])">
                                Campo obligatorio.
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-2 mt-1" [ngClass]="{'custom-error': checkErrorFieldForm('surname1', ['required','blankSpace'])}">
                        <mat-form-field>
                            <mat-label>{{ requireCif ? 'Tipo de sociedad' : 'Apellido1' }}</mat-label>
                            <input matInput type="text" oninvalid="" required formControlName="surname1" maxlength="64">
                            <mat-error *ngIf="checkErrorFieldForm('surname1', ['required','blankSpace'])">
                                Campo obligatorio.
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-2 mt-1" *ngIf="!requireCif">
                        <mat-form-field>
                            <mat-label>Apellido2</mat-label>
                            <input matInput type="text" oninvalid="" formControlName="surname2" maxlength="64">
                        </mat-form-field>
                    </div>

                    <div class="col-lg-2 mt-1" *ngIf="!requireCif"  [ngClass]="{'custom-error': checkErrorFieldForm('birthday', ['required','date_minimum', 'date_maximum'])}">
                        <app-datepicker-custom
                        title="Fecha de nacimiento"
                        [form]="formGroup"
                        name="birthday"
                        [maxDate]="maxDate"
                        [minDate]="minDate"
                        ></app-datepicker-custom>
                    </div>

                    <div class="col-lg-2 mt-1" *ngIf="!requireCif">
                        <mat-form-field>
                            <mat-label>Sexo</mat-label>
                            <select matNativeControl formControlName="sex">
                                <option *ngFor="let sex of sexTypes;" [value]="sex.gender_id">
                                    {{ sex.value }}
                                </option>
                            </select>
                        </mat-form-field>
                    </div>
                </div>
            </li>
        </ul>
    </div>

    <!-- Datos de contacto -->
    <h5 class="title-step mt-4" align="center">
        <img src="./assets/img/users.svg" class="img-fluid ico-title" width="20"> Datos de contacto <ng-container *ngIf="isPortabilityChange">del nuevo titular en Simyo  </ng-container>
    </h5>
    <div class="card">
        <ul class="list-group list-group-flush">
            <li class="list-group-item cuerpo-pack">
                <div class="form-row">
                    <div class="col-lg-7 mb-1" [ngClass]="{'custom-error': checkErrorFieldForm('nombreVia', ['required','blankSpace'])}">
                        <mat-form-field>
                            <mat-label>Nombre de la vía</mat-label>
                            <input matInput matGoogleMapsAutocomplete required type="text" name="nombreVia" formControlName="nombreVia"
                                   [types]="['address']"
                                   [country]="country"
                                   (input)="sendEvent($event)"
                                   (onAutocompleteSelected)="onAutocompleteSelected($event)">
                            <!-- error -->
                            <mat-error *ngIf="checkErrorFieldForm('nombreVia', ['required','blankSpace'])">
                                Campo obligatorio.
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-1 mb-1" [ngClass]="{'custom-error': checkErrorFieldForm('numero', ['required','blankSpace'])}">
                        <mat-form-field>
                            <mat-label>Número</mat-label>
                            <input matInput type="text" name="numero" formControlName="numero" required min="0" (input)="restrictInputToNumbers($event)">
                            <!-- error -->
                            <mat-error *ngIf="checkErrorFieldForm('numero', ['required','blankSpace'])">
                                Campo obligatorio.
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-1 mb-1">
                        <mat-form-field>
                            <mat-label>Planta</mat-label>
                            <input type="text" matInput formControlName="planta">
                        </mat-form-field>
                    </div>

                    <div class="col-lg-1 mb-1">
                        <mat-form-field>
                            <mat-label>Puerta</mat-label>
                            <input type="text" matInput formControlName="puerta">
                        </mat-form-field>
                    </div>

                    <div class="col-lg-1 mb-1">
                        <mat-form-field>
                            <mat-label>Escalera</mat-label>
                            <input type="text" matInput formControlName="escalera">
                        </mat-form-field>
                    </div>

                    <div class="col-lg-1 mb-1">
                        <mat-form-field>
                            <mat-label>Bloque</mat-label>
                            <input type="text" matInput formControlName="bloque">
                        </mat-form-field>
                    </div>
                </div>

                <!--SEGUNDA FILA-->
                <div class="form-row">
                    <div class="col-lg-3 mb-1" [ngClass]="{'custom-error': checkErrorFieldForm('codigoPostal', ['required', 'zipCodeInvalid','blankSpace'])}">
                        <mat-form-field>
                            <mat-label>Código Postal</mat-label>
                            <input matInput type="text" autocomplete="off"  name="codigoPostal" formControlName="codigoPostal" required (input)="onChangeZip($event.target.value)" (keydown)="onlyPositiveDigits($event)">
                            <!-- error -->
                            <mat-error *ngIf="checkErrorFieldForm('codigoPostal', ['required','blankSpace'])">
                                Campo obligatorio.
                            </mat-error>
                            <mat-error *ngIf="checkErrorFieldForm('codigoPostal', ['zipCodeInvalid'])">
                                Formato de CP incorrecto.
                            </mat-error>
                            <mat-error *ngIf="checkErrorFieldForm('codigoPostal', ['sameTax'])">
                                CP incorrecto.
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-5 mb-1" [ngClass]="{'custom-error': checkErrorFieldForm('localidad', ['required','blankSpace'])}">
                        <mat-form-field>
                            <mat-label>Localidad</mat-label>
                            <input matInput type="text" name="localidad" formControlName="localidad" required>
                            <!-- error -->
                            <mat-error *ngIf="checkErrorFieldForm('localidad', ['required','blankSpace'])">
                                Campo obligatorio.
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4 mb-1" [ngClass]="{'custom-error': checkErrorFieldForm('provincia', ['required'])}">
                        <mat-form-field>
                            <mat-label>Provincia</mat-label>
                            <select matNativeControl formControlName="provincia" required>
                                <option *ngFor="let province of provinces" [value]="province.code">
                                    {{province.code|titlecase }}
                                </option>
                            </select>
                            <!-- error -->
                            <mat-error *ngIf="checkErrorFieldForm('provincia', ['required'])">
                                Campo obligatorio.
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="form-row">
                    <div class="col-lg-2 mt-4" style="position: relative;" [ngClass]="{'custom-error': checkErrorFieldForm('phone', ['required', 'pattern', 'minlength'])}">
                        <mat-form-field>
                            <mat-label>Teléfono de contacto</mat-label>
                            <input matInput type="text" oninvalid="" required formControlName="phone" minlength="9" maxlength="9">
                            <mat-error *ngIf="formGroup.get('phone')?.errors?.pattern && !formGroup.get('phone')?.errors?.minlength && this.formGroup.get('phone')?.touched">
                                El número de teléfono es incorrecto
                            </mat-error>
                            <mat-error *ngIf="checkErrorFieldForm('phone', ['required']) == false && checkErrorFieldForm('phone', ['minlength'])" >
                                El número de teléfono debe tener 9 dígitos
                            </mat-error>
                            <mat-error *ngIf="checkErrorFieldForm('phone', ['required'])">
                                Campo obligatorio.
                            </mat-error>
                            <mat-error *ngIf="errorHandling('phone', 'duplicate') && !errorHandling('phone', 'required')">
                                Número igual al antiguo titular
                            </mat-error>
                        </mat-form-field>
                        <a class="cursor-pointer custom-buttom-modal" [ngbTooltip]="infoSignMobile" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_digital_signature)">
                            <img src="./assets/img/info.svg" class="img-fluid ico-title icon-white" width="15">
                        </a>
                    </div>
                    <ng-template #infoSignMobile>
                        Este número de teléfono es el que se utilizará para el envío del SMS en el proceso de firma digital en el caso de seleccionar número de contacto en lugar de alguno de los números que se soliciten portar
                    </ng-template>

                    <div class="col-lg-4 mt-4" style="position: relative;" [ngClass]="{'custom-error': checkErrorFieldForm('email', ['required', 'pattern'])}">
                        <mat-form-field>
                            <mat-label>Correo electrónico</mat-label>
                            <input matInput type="text" oninvalid="" required formControlName="email" (input)="convertToLowerCase('email')">
                            <mat-error *ngIf="checkErrorFieldForm('email', ['required']) == true">
                                Campo obligatorio.
                            </mat-error>
                            <mat-error *ngIf="checkErrorFieldForm('email', ['pattern']) == true">
                                Correo electrónico no válido
                            </mat-error>
                            <mat-error *ngIf="errorHandling('email', 'duplicate') && !errorHandling('email', 'required')">
                                Email igual al antiguo titular
                            </mat-error>
                        </mat-form-field>
                        <a class="cursor-pointer custom-buttom-modal" [ngbTooltip]="infoSignEmail" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_digital_signature)">
                            <img src="./assets/img/info.svg" class="img-fluid ico-title icon-white" width="15">
                        </a>
                    </div>
                    <ng-template #infoSignEmail>
                        Este email es el que se utilizará para el envío del email en el proceso de firma digital
                    </ng-template>

                    <div class="col-lg-4 mt-4" [ngClass]="{'custom-error': checkErrorFieldForm('email_confirm', ['required', 'mustMatch', 'pattern'])}">
                        <mat-form-field>
                            <mat-label>Confirmar correo electrónico</mat-label>
                            <input appBlockPaste matInput type="text" autocomplete="off" oninvalid="" required formControlName="email_confirm" (input)="convertToLowerCase('email_confirm')">
                            <mat-error *ngIf="checkErrorFieldForm('email_confirm', ['required']) == false && checkErrorFieldForm('email_confirm', ['mustMatch'])" >
                                El correo electrónico no coincide
                            </mat-error>
                            <mat-error *ngIf="checkErrorFieldForm('email_confirm', ['required']) == true">
                                Campo obligatorio.
                            </mat-error>
                            <mat-error *ngIf="checkErrorFieldForm('email_confirm', ['pattern']) == true">
                                Correo electrónico no válido
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </li>
        </ul>
    </div>



    <!-- Datos de contacto origen portabilidad -->
    <h5 class="title-step mt-4" align="center" *ngIf="isPortabilityChange">
        <img src="./assets/img/users.svg" class="img-fluid ico-title" width="20"> Datos del antiguo titular de la línea
    </h5>
    <div class="card" *ngIf="isPortabilityChange">
        <ul class="list-group list-group-flush">
            <li class="list-group-item cuerpo-pack">
                <div class="form-row">
                    <div class="col-lg-2 mt-1" [ngClass]="{'custom-error': checkErrorFieldForm('portability_origen_doc_type', ['required'])}">
                        <mat-form-field>
                            <mat-label>Tipo documento</mat-label>
                            <select matNativeControl required formControlName="portability_origen_doc_type"
                                    (change)="changeTypeDocument($event.target.value,'portability_origen_doc'); setMaxLengthByDoc($event.target.value)">
                                <option *ngFor="let documentType of documentTypesDonor" [value]="documentType.document_type_id" [hidden]="!permSv.hasPermFromV2(allPermissions.simyo.permite_ventas_cif) && documentType?.document_type_id === 4">
                                    {{ documentType.value }}
                                </option>
                            </select>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-2 mt-1" [ngClass]="{'custom-error': checkErrorFieldForm('portability_origen_doc', ['required', 'pattern', 'duplicate'])}">
                        <mat-form-field>
                            <mat-label>Nº de documento</mat-label>
                            <input matInput type="text" name="portability_origen_doc" formControlName="portability_origen_doc" [maxLength]="maxLengthByDoc" required (keypress)="alphanumericOnly($event)" (input)="convertToUpperCase('portability_origen_doc')">
                            <!-- error -->
                            <mat-error *ngIf="checkErrorFieldForm('portability_origen_doc', ['required'])">
                                Campo obligatorio.
                            </mat-error>
                            <mat-error *ngIf="checkErrorFieldForm('portability_origen_doc', ['required']) == false && checkErrorFieldForm('portability_origen_doc', ['doc', 'pattern'])">
                                Número de documento erróneo
                            </mat-error>
                            <mat-error *ngIf="errorHandling('portability_origen_doc', 'duplicate') && !errorHandling('portability_origen_doc', 'required')">
                                Documento igual al nuevo titular
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-2 mt-1" [ngClass]="{'custom-error': checkErrorFieldForm('portability_origen_nationality_id', ['required'])}">
                        <mat-form-field>
                            <mat-label>Nacionalidad</mat-label>
                            <select matNativeControl required formControlName="portability_origen_nationality_id">
                                <ng-container *ngFor="let country of countries;">
                                    <option [value]="null" hidden></option>
                                    <option [value]="country.country_id">
                                        {{ country.value }}
                                    </option>
                                </ng-container>
                            </select>
                            <mat-error *ngIf="checkErrorFieldForm('portability_origen_nationality_id', ['required'])">
                                Campo obligatorio.
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-2 mt-1" [ngClass]="{'custom-error': checkErrorFieldForm('portability_origen_name', ['required','blankSpace'])}">
                        <mat-form-field>
                            <mat-label>Nombre</mat-label>
                            <input matInput type="text" oninvalid="" required formControlName="portability_origen_name" maxlength="32">
                            <mat-error *ngIf="checkErrorFieldForm('portability_origen_name', ['required', 'blankSpace'])">
                                Campo obligatorio.
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-2 mt-1" [ngClass]="{'custom-error': checkErrorFieldForm('portability_origen_lastname', ['required','blankSpace'])}">
                        <mat-form-field>
                            <mat-label>Apellido1</mat-label>
                            <input matInput type="text" oninvalid="" required formControlName="portability_origen_lastname" maxlength="64">
                            <mat-error *ngIf="checkErrorFieldForm('portability_origen_lastname', ['required','blankSpace'])">
                                Campo obligatorio.
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-2 mt-1" *ngIf="!requireCif">
                        <mat-form-field>
                            <mat-label>Apellido2</mat-label>
                            <input matInput type="text" oninvalid="" formControlName="portability_origen_lastname2" maxlength="64">
                        </mat-form-field>
                    </div>
                </div>

                <div class="form-row">

                    <div class="col-lg-2 mt-4" [ngClass]="{'custom-error': checkErrorFieldForm('portability_origen_birth_date', ['required','date_minimum', 'date_maximum'])}">
                        <app-datepicker-custom
                        title="Fecha de nacimiento"
                        [form]="formGroup"
                        name="portability_origen_birth_date"
                        [maxDate]="maxDate"
                        [minDate]="minDate"
                        ></app-datepicker-custom>
                    </div>

                    <div class="col-lg-2 mt-4" style="position: relative;" [ngClass]="{'custom-error': checkErrorFieldForm('portability_origen_phone', ['required', 'pattern', 'minlength'])}">
                        <mat-form-field>
                            <mat-label>Teléfono de contacto</mat-label>
                            <input matInput type="text" oninvalid="" required formControlName="portability_origen_phone" minlength="9" maxlength="9">
                            <mat-error *ngIf="formGroup.get('portability_origen_phone')?.errors?.pattern && !formGroup.get('portability_origen_phone')?.errors?.minlength && this.formGroup.get('portability_origen_phone')?.touched">
                                El número de teléfono es incorrecto
                            </mat-error>
                            <mat-error *ngIf="checkErrorFieldForm('portability_origen_phone', ['required']) == false && checkErrorFieldForm('portability_origen_phone', ['minlength'])" >
                                El número de teléfono debe tener 9 dígitos
                            </mat-error>
                            <mat-error *ngIf="checkErrorFieldForm('portability_origen_phone', ['required'])">
                                Campo obligatorio.
                            </mat-error>
                            <mat-error *ngIf="errorHandling('portability_origen_phone', 'duplicate') && !errorHandling('portability_origen_phone', 'required')">
                                Número igual al nuevo titular
                            </mat-error>
                        </mat-form-field>
                        <a class="cursor-pointer custom-buttom-modal" [ngbTooltip]="infoSignMobile" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_digital_signature)">
                            <img src="./assets/img/info.svg" class="img-fluid ico-title icon-white" width="15">
                        </a>
                    </div>
                   <!--  <ng-template #infoSignMobile>
                        Este número de teléfono es el que se utilizará para el envío del SMS en el proceso de firma digital en el caso de seleccionar número de contacto en lugar de alguno de los números que se soliciten portar
                    </ng-template> -->

                    <div class="col-lg-4 mt-4" style="position: relative;" [ngClass]="{'custom-error': checkErrorFieldForm('portability_origen_email', ['required', 'pattern'])}">
                        <mat-form-field>
                            <mat-label>Correo electrónico</mat-label>
                            <input matInput type="text" oninvalid="" required formControlName="portability_origen_email" (input)="convertToLowerCase('portability_origen_email')">
                            <mat-error *ngIf="checkErrorFieldForm('portability_origen_email', ['required']) == true">
                                Campo obligatorio.
                            </mat-error>
                            <mat-error *ngIf="checkErrorFieldForm('portability_origen_email', ['pattern']) == true">
                                Correo electrónico no válido
                            </mat-error>
                            <mat-error *ngIf="errorHandling('portability_origen_email', 'duplicate') && !errorHandling('portability_origen_email', 'required')">
                                Email igual al nuevo titular
                            </mat-error>
                        </mat-form-field>
                        <!--<a class="cursor-pointer custom-buttom-modal" [ngbTooltip]="infoSignEmail" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_digital_signature)">
                            <img src="./assets/img/info.svg" class="img-fluid ico-title icon-white" width="15">
                        </a>-->
                    </div>
                  <!--   <ng-template #infoSignEmail>
                        Este email es el que se utilizará para el envío del email en el proceso de firma digital
                    </ng-template> -->

                    <div class="col-lg-4 mt-4" [ngClass]="{'custom-error': checkErrorFieldForm('portability_origen_email_confirm', ['required', 'mustMatch', 'pattern'])}">
                        <mat-form-field>
                            <mat-label>Confirmar correo electrónico</mat-label>
                            <input appBlockPaste matInput type="text" autocomplete="off" oninvalid="" required formControlName="portability_origen_email_confirm" (input)="convertToLowerCase('portability_origen_email_confirm')">
                            <mat-error *ngIf="checkErrorFieldForm('portability_origen_email_confirm', ['required']) == false && checkErrorFieldForm('portability_origen_email_confirm', ['mustMatch'])" >
                                El correo electrónico no coincide
                            </mat-error>
                            <mat-error *ngIf="checkErrorFieldForm('portability_origen_email_confirm', ['required']) == true">
                                Campo obligatorio.
                            </mat-error>
                            <mat-error *ngIf="checkErrorFieldForm('portability_origen_email_confirm', ['pattern']) == true">
                                Correo electrónico no válido
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </li>
        </ul>
    </div>






    <!-- Método de entrega -->
    <div *ngIf="showDelivery">
        <h5 class="title-step mt-4" align="center">
            <img src="./assets/img/send-user.svg" class="img-fluid ico-title" width="20"> Método de entrega
        </h5>
        <div class="card">
            <ul class="list-group list-group-flush">
                <li class="list-group-item cuerpo-pack">
                    <div class="form-row">
                        <div class="col-lg-7 mb-0">
                            <app-simyo-terminal-delivery #terminalDelivery [formGroup]="formGroup"></app-simyo-terminal-delivery>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>

    <!-- Datos bancarios -->
    <div *ngIf="showBankingData">
        <h5 class="title-step mt-4" align="center">
            <img src="./assets/img/credit-card.svg" class="img-fluid ico-title" width="20"> Datos bancarios
        </h5>
        <div class="card">
            <ul class="list-group list-group-flush">
                <li class="list-group-item cuerpo-pack">
                    <div *ngIf="dataCustomer?.accounts && dataCustomer.accounts.length">
                        <h6 class="mt-3 mb-2">
                            Seleccione o añada un método de pago
                        </h6>
                        <div *ngFor="let acc of dataCustomer.accounts, index as i">
                            <div class="form-row mt-3 account" [ngClass]="{ 'active' : accountSelected === i }" *ngIf="acc.account_type_id === 2" (click)="selectBankData(acc, i)">
                                <div class="col-md-6 pl-5 align-self-center">
                                    <div class="col-12 text-muted">
                                        Número de cuenta bancaria:
                                    </div>
                                    <div class="col-12">
                                        {{ acc.account_number }}
                                    </div>
                                </div>
                                <div class="col-md-6 mb-0 pl-5">
                                    <div class="jumbotron-form2 mb-0">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <div>
                                                <span class="label-ad-text2">Titular de la cuenta (debe ser el mismo que el titular del contrato)</span>
                                                <br>
                                                <!-- Nombre titular operador -->
                                                <span class="label-ad-text">{{ fullNameBanking }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                        </div>
                    </div>
                    <div class="form-row mt-3" [ngClass]="{'account' : thereIsMoreBankData, 'active' : accountSelected === '-1' }" (click)="selectNewBank()">
                        <div class="col-md-6" [ngClass]="{'pl-5' : thereIsMoreBankData, 'custom-error': checkErrorFieldForm('new_bank_account', ['required', 'iban']) }">
                            <mat-form-field *ngIf="formGroup.get('doctype').value !== '3'">
                                <mat-label>IBAN (ESXX XXXX XXXX XX XXXXXXXXXX)</mat-label>
                                <input matInput type="text" formControlName="new_bank_account"
                                    mask="AA00 0000 0000 00 0000000000" (input)="onIbanChange($event)">
                                <mat-error *ngIf="checkErrorFieldForm('new_bank_account', ['required'])">
                                    Campo obligatorio.
                                </mat-error>
                                <mat-error *ngIf="checkErrorFieldForm('new_bank_account', ['required']) == false && checkErrorFieldForm('new_bank_account', ['iban'])">
                                    IBAN no válido.
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field *ngIf="formGroup.get('doctype').value === '3'">
                                <mat-label>IBAN </mat-label>
                                <input matInput type="text" formControlName="new_bank_account"
                                    maxlength="32" (input)="onIbanChange($event)">
                            </mat-form-field>
                        </div>

                        <div class="col-md-6 mb-0 pl-5">
                            <div class="jumbotron-form2 mb-0">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div>
                                        <span class="label-ad-text2">Titular de la cuenta (debe ser el mismo que el titular del contrato)</span>
                                        <br>
                                        <!-- Nombre titular operador -->
                                        <span class="label-ad-text">{{ fullNameBanking }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>

    <!--  -->
    <div *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_new_signature)">
        <h5 class="title-step mt-4" align="center">
            <img src="./assets/img/check-user.svg" class="img-fluid ico-title" width="20"> Protección de datos
        </h5>
        <div class="card cuerpo-pack list-group-item">
            <app-simyo-agreements></app-simyo-agreements>
        </div>
    </div>

    <ng-template #showCreditCard>
        <h5 class="title-step mt-4" align="center">
            <img src="./assets/img/users.svg" class="img-fluid ico-title" width="20"> Datos tarjeta de crédito
        </h5>
        <div class="card">
            <ul class="list-group list-group-flush">
                <li class="list-group-item cuerpo-pack">
                    <div class="form-row mt-3">
                        <div class="col-lg-2">
                            <div class="radio-btn-group mt-3">
                                <div class="radio" *ngFor="let creditCardType of creditCardTypes">
                                    <input id="{{creditCardType.credit_card_type_id}}" type="radio" name="credit_card_type" [value]="creditCardType.credit_card_type_id" formControlName="credit_card_type">
                                    <label for="{{creditCardType.credit_card_type_id}}" class="font-weight-bold">{{ creditCardType.value }}</label>
                                </div>
                            </div>
                            <mat-error *ngIf="errorHandling('credit_card_type', 'required')">
                                Campo obligatorio.
                            </mat-error>
                        </div>
                        <div class="col-lg-6">
                            <mat-form-field>
                                <mat-label>Nombre en la tarjeta</mat-label>
                                <input matInput type="text" required formControlName="credit_card_name">
                            </mat-form-field>
                        </div>
                        <div class="col-lg-2">
                            <mat-form-field>
                                <mat-label>Fecha de vencimiento</mat-label>
                                <input matInput type="text" required formControlName="credit_card_date_of_expiry" mask="00/00" placeholder="MM/YY">
                            </mat-form-field>
                        </div>
                        <div class="col-lg-2">
                            <mat-form-field>
                                <mat-label>CVV</mat-label>
                                <input matInput type="text" required formControlName="credit_card_cvv" maxlength="3">
                            </mat-form-field>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </ng-template>

    <!-- Continuar proceso -->
    <div align="center">
        <br>
        <button class="btn btn-simyo-o2 btn-block mb-1" (click)="next()">
            Continuar <img src="./assets/img/arrow-right-white.svg" class="img-fluid ico-title" width="15">
        </button>

        <button class="btn btn-simyo-o btn-block mb-5" (click)="prev()">Volver</button>
        <br><br>
    </div>
</div>
