import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OrangeBonosHiringDetailComponent } from './components/orange-bonos-hiring/orange-bonos-hiring-detail/orange-bonos-hiring-detail.component';
import { OrangeBonosHiringListComponent } from './components/orange-bonos-hiring/orange-bonos-hiring-list/orange-bonos-hiring-list.component';
import { OrangeBonosHiringComponent } from './components/orange-bonos-hiring/orange-bonos-hiring.component';
import { OrangePrepaidDetailComponent } from './components/orange-prepaid-hiring/orange-prepaid-detail/orange-prepaid-detail.component';
import { OrangePrepaidHiringComponent } from './components/orange-prepaid-hiring/orange-prepaid-hiring.component';
import { OrangePrepaidListComponent } from './components/orange-prepaid-hiring/orange-prepaid-list/orange-prepaid-list.component';
import { OrangeBonosAdicionalHiringGuard } from './guards/orange-bonos-adicional-hiring.guard';
import { OrangePrepaidHiringGuard } from './guards/orange-prepaid-hiring.guard';

const routes: Routes = [
    { path: 'bonos-adicionales', component: OrangeBonosHiringComponent, canActivate: [OrangeBonosAdicionalHiringGuard] },
    { path: 'bonos-adicionales-order/:order_bono_id', component: OrangeBonosHiringDetailComponent, canActivate: [OrangeBonosAdicionalHiringGuard] },
    { path: 'bonos-adicionales-listado', component: OrangeBonosHiringListComponent, canActivate: [OrangeBonosAdicionalHiringGuard] },
    { path: 'prepago/alta', component: OrangePrepaidHiringComponent, canActivate: [OrangePrepaidHiringGuard] },
    { path: 'prepago/portabilidad', component: OrangePrepaidHiringComponent, canActivate: [OrangePrepaidHiringGuard] },
    { path: 'prepago/esim', component: OrangePrepaidHiringComponent, canActivate: [OrangePrepaidHiringGuard] },
    { path: 'prepago-order/:order_prepaid_id', component: OrangePrepaidDetailComponent, canActivate: [OrangePrepaidHiringGuard] },
    { path: 'prepago-listado', component: OrangePrepaidListComponent, canActivate: [OrangePrepaidHiringGuard] }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class OrangeRoutingModule { }
