<div class="resumen d-flex justify-content-between align-items-center mt-2" *ngIf="title && title !== ''">
    <h6 class="resumen-color">
        <b>{{title}}</b>
    </h6>

    <span class="d-flex justify-content-end align-items-center">
        <small class="text-muted trash-carrito" *ngIf="showEditButton">
            <a (click)="goToEdit()" class="cursor-pointer px-2 mr-2">
                <img src="./assets/img/edit.svg" class="img-fluid" width="13">
            </a>
        </small>

        <small class="text-muted trash-carrito" *ngIf="showDeleteButton">
            <a (click)="removeProduct()" *ngIf="productId > 0" class="cursor-pointer px-2 mr-2">
                <img src="./assets/img/trash.svg" class="img-fluid" width="13">
            </a>
        </small>
    </span>
</div>
<div class="resumen d-flex justify-content-between align-items-center" *ngIf="titleLine && titleLine !== ''">
    <span class="sidebar-info5">
        <img src="./assets/img/phone.svg" class="img-fluid mr-1" width="13">
        <span>{{ titleLine }}</span>
    </span>
</div>
<div class="resumen d-flex justify-content-between align-items-center" *ngIf="rateName && rateName !== ''">
    <span class="sidebar-info5 sidebar-flex">
        <img src="./assets/img/package2.svg" class="img-fluid mt-1 mr-1" width="13">
        <span [innerHTML]="rateName"></span>
    </span>
    <small class="text-muted">
        <!-- Precio tarifa -->
        <span class="sidebar-info3">
            <b>{{ price }} €</b>
        </span>
    </small>
</div>