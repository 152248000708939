<h5 class="title-step mt-0 mb-3 text-center">{{ title }}</h5>

<div>
    <p [innerHTML]="message"></p>
</div>

<div class="row">
    <div class="col-sm-6">
        <button class="btn btn-block btn-grey" (click)="closeDialog()">No</button>
    </div>
    <div class="col-sm-6">
        <button class="btn btn-block btn-green" (click)="confirm()">Sí</button>
    </div>
</div>
