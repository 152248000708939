import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {HomeRoutingModule} from './home-routing.module';
import {MatTabsModule} from '@angular/material/tabs';
import {HomeComponent} from './components/home/home.component';
import {HmCoverageComponent} from './components/hm-coverage/hm-coverage.component';
import {HmHiringComponent} from './components/hm-hiring/hm-hiring.component';
import {HmRatesComponent} from './components/hm-rates/hm-rates.component';
import { HmHeaderComponent } from './components/hm-header/hm-header.component';
import { HmPromoComponent } from './components/hm-promo/hm-promo.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import { HmSupportComponent } from './components/hm-support/hm-support.component';
import { HmNotificationsComponent } from './components/hm-notifications/hm-notifications.component';
import { HmGpvComponent } from './components/hm-gpv/hm-gpv.component';
import { HmCoberturaComponent } from './components/hm-cobertura/hm-cobertura.component';
import { HmManualsComponent } from './components/hm-manuals/hm-manuals.component';
import { HmModalSimyoContratosComponent } from './components/hm-modal-simyo-contratos/hm-modal-simyo-contratos.component';
import { HmSalesComponent } from './components/hm-sales/hm-sales.component';
import { HmOperationsComponent } from './components/hm-operations/hm-operations.component';
import { MatTableModule } from '@angular/material/table';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { HmCommissionsComponent } from './components/hm-commissions/hm-commissions.component';
import { HmModalMrComponent } from './components/hm-modal-mr/hm-modal-mr.component';
import { SharedModule } from '../shared/shared.module';
import { HmModalJazztelContratosComponent } from './components/hm-modal-jazztel-contratos/hm-modal-jazztel-contratos.component';
import { HmOperationsPosventaComponent } from './components/hm-operations-posventa/hm-operations-posventa.component';
import { HmModalContactPdvComponent } from './components/hm-modal-contact-pdv/hm-modal-contact-pdv.component';
import { HmModalSimyoImageComponent } from './components/hm-modal-simyo-image/hm-modal-simyo-image.component';
import { MovementsComponent } from './components/hm-points/movements/movements.component';
import { HomePointsComponent } from './components/hm-points/home-points/home-points.component';
import { AcceptedPointsProgramComponent } from './components/hm-points/modals/accepted-points-program/accepted-points-program.component';
import { OrdersComponent } from './components/hm-points/orders/orders.component';
import { PointOrderComponent } from './components/hm-points/order/order.component';
import { BannerComponent } from './components/hm-points/modals/banner/banner.component';
import { HmBrandComponent } from './components/hm-hiring/components/hm-brand/hm-brand.component';

@NgModule({
    declarations: [
        HomeComponent,
        HmCoverageComponent,
        HmHiringComponent,
        HmRatesComponent,
        HmHeaderComponent,
        HmPromoComponent,
        HmSupportComponent,
        HmNotificationsComponent,
        HmGpvComponent,
        HmCoberturaComponent,
        HmManualsComponent,
        HmModalSimyoContratosComponent,
        HmSalesComponent,
        HmOperationsComponent,
        HmCommissionsComponent,
        HmModalMrComponent,
        HmModalJazztelContratosComponent,
        HmOperationsPosventaComponent,
        HmModalContactPdvComponent,
        HmModalSimyoImageComponent,
        HomePointsComponent,
        MovementsComponent,
        AcceptedPointsProgramComponent,
        OrdersComponent,
        PointOrderComponent,
        BannerComponent,
        HmBrandComponent
    ],
    exports: [
        HmHeaderComponent
    ],
    imports: [
        CommonModule,
        HomeRoutingModule,
        MatTabsModule,
        MatDialogModule,
        MatButtonModule,
        MatTableModule,
        MatSlideToggleModule,
        SharedModule
    ]
})

export class HomeModule {
}
