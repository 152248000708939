import {Component, OnInit, Input, ViewChild, ElementRef, OnDestroy} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { RmErrorStateMatcher } from 'src/app/utils/validators/rm-error-state-matcher';
import { SimyoService } from '../../services/simyo.service';
import { SimyoNormalizadorService } from '../../services/simyo-normalizador.service';
import { getNameStreet, onChangeZip, searchRoadType } from 'src/app/utils/normalizerFunctions';
import { SimyoGlobals } from '../../classes/simyo-globals';
import { of, Subscription } from 'rxjs';
import { HomeService } from 'src/app/home/services/home.service';
import { masterAllSimyo, trackTypeSimyo } from '../../models/masterAllSimyo';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

@Component({
    selector: 'app-simyo-normaliza-direccion',
    templateUrl: './simyo-normaliza-direccion.component.html',
    styleUrls: ['./simyo-normaliza-direccion.component.css', '../../../../assets/css/simyo-theme.css']
})
export class SimyoNormalizaDireccionComponent implements OnInit, OnDestroy {

    @Input() formGroup: FormGroup;
    @ViewChild('manualAddress') manualAddress;
    @ViewChild('manualAddressText') manualAddressText;
    @ViewChild('formNumber') formNumber: ElementRef;

    public address: string;
    public isLoading = false;
    public muestraBoton = true;
    public textDefault = 'Normaliza dirección';
    public provinces: any[];
    public msgErrorPeticion: string;
    public muestraErrorPeticion = false;
    public country = environment.googleMapsCountry;
    public matcher = new RmErrorStateMatcher();
    public autocomplete;

    // Master
    public roadTypes: trackTypeSimyo[];

    private allSubscription: Subscription[] = [];

    constructor(
        private simyoService: SimyoService,
        private normalizadorService: SimyoNormalizadorService,
        private simyoGlobals: SimyoGlobals,
        private homeService: HomeService
    ) { }

    ngOnInit(): void {
        this.enableForm();

        this.allSubscription.push(
            this.simyoService.getProvincesCommunFromService().pipe(
                map((data:any) => {
                    if(data && data.msg) {
                        this.simyoService.setProvincesCommun(data.msg);
                        return data.msg;
                    }
                    return null;
                }),
                tap((data: any) => {
                    if (data) {
                        this.provinces = data;
                        this.onChangeZip(this.formGroup.get('codigoPostalNormaliza').value);
                    }
                })
            ).subscribe()
        );
        this.allSubscription.push(
            this.simyoService.getRoadTypesCommun().pipe(
                switchMap((data:any) => {
                    if(data != null) {
                        return of(data);
                    }
                    return this.simyoService.getRoadTypeCommunFromService().pipe(
                        catchError(error => {
                            return of(null);
                        }),
                        map((data:any) => {
                            if(data && data.msg) {
                                this.simyoService.setRoadTypesCommun(data.msg);
                                return of(data.msg);
                            }
                            return of(null);
                        })
                    )
                }),
                tap((data: any) => {
                    if (data) {
                        this.roadTypes = data;
                    }
                })
            ).subscribe());

        this.allSubscription.push(this.normalizadorService.getMuestraBotonNormalizacion()
            .subscribe(data => {
                if (data) {
                    this.enableForm();
                }
                this.muestraBoton = data;
            })
        );

        if (this.formGroup.get('codigoPostalNormaliza').value && this.formGroup.get('codigoPostalNormaliza').value !== '') {
            this.onChangeZip(this.formGroup.get('codigoPostalNormaliza').value);
        }
    }

    onAutocompleteSelected($event) {
        const addressComponents: any[] = $event.address_components;

        if (addressComponents != null && addressComponents.length) {
            this.formGroup.get('tipoViaNormaliza').patchValue('');
            this.formGroup.get('nombreViaNormaliza').patchValue('');
            this.formGroup.get('numeroNormaliza').patchValue('');
            this.formGroup.get('codigoPostalNormaliza').patchValue('');
            this.formGroup.get('localidadNormaliza').patchValue('');
            this.formGroup.get('provinciaNormaliza').patchValue('');

            for (const addressComponent of addressComponents) {
                let field = '';
                const types: string[] = addressComponent.types;
                let value: string = addressComponent.long_name;

                if (types.includes('route')) {
                    field = 'nombreViaNormaliza';
                    value = searchRoadType(value, this.roadTypes, 'value', this.formGroup.get('tipoViaNormaliza'));
                } else if (types.includes('street_number')) {
                    field = 'numeroNormaliza';
                } else if (types.includes('postal_code')) {
                    field = 'codigoPostalNormaliza';
                    this.onChangeZip(value);
                } else if (types.includes('locality')) {
                    field = 'localidadNormaliza';
                } else if (types.includes('administrative_area_level_2')) {
                    field = 'provinciaNormaliza';
                    value = value.toUpperCase();
                }

                if (field !== '') {
                    this.formGroup.get(field).patchValue(value);
                }
            }
        }
        if (this.formGroup.get('numeroNormaliza').value === '') {
            this.formNumber.nativeElement.focus();
        }
    }

    onChangeZip(value) {
        if (value && +value > 99999) {
            value = value.substring(0,5);
            this.formGroup.get('codigoPostalNormaliza').patchValue(value);
        }
        onChangeZip(value, this.provinces, 'postal_code', this.formGroup.get('provinciaNormaliza'));
    }

    onNormalizarDireccion() {
        this.muestraErrorPeticion = false;
        this.msgErrorPeticion = null;
        this.formGroup.markAllAsTouched();
        this.formGroup.updateValueAndValidity();

        if (!this.formGroup.get('tipoViaNormaliza').value || this.formGroup.get('tipoViaNormaliza').value === '') {
            searchRoadType(this.formGroup.get('nombreViaNormaliza').value, this.roadTypes, 'value', this.formGroup.get('tipoViaNormaliza'));
        }

        if (this.formGroup.valid) {
            this.onChangeLoading(true);
            this.formGroup.disable();

            this.normalizadorService.normalizarHorizontal(this.getDataNormalizar())
                .subscribe((data: any) => {
                    if (data.msg) {
                        data = data.msg;
                    }
                    if (Array.isArray(data) && data.length) {
                        data = data.filter(address => address.vertical_addresses && address.vertical_addresses.length);
                    }
                    if (Array.isArray(data) && data.length) {
                        this.muestraErrorPeticion = false;
                        this.normalizadorService.setMuestraBotonNormalizacion(false);
                        this.normalizadorService.setMuestraCobertura(true);
                        this.normalizadorService.setDatosNormalizacion(data);
                        this.normalizadorService.setLocalidades(data);
                        this.normalizadorService.setFormularioNormalizacion(this.formGroup);
                        this.onChangeLoading(false);
                    } else {
                        this.muestraErrorPeticion = true;
                        this.msgErrorPeticion = data && data.msg ? data.msg : 'No existen resultados para los datos indicados.';
                        this.onChangeLoading(false);
                        this.enableForm();
                    }
                }, error => {
                    this.muestraErrorPeticion = true;
                    this.msgErrorPeticion = error.error.error.msg;
                    this.onChangeLoading(false);
                    this.enableForm();

                    console.error(error);
                });
        }
    }

    onChangeLoading(state) {
        if (state === true) {
            this.isLoading = true;
            this.textDefault = 'Normalizando ...';
        } else {
            this.isLoading = false;
            this.textDefault = 'Normalizar dirección';
        }
    }

    // Formateamos los datos para enviarlos al servicio
    getDataNormalizar() {
        return {
            streetNr: `${this.formGroup.value.numeroNormaliza}`,
            streetName: getNameStreet(this.formGroup.value.nombreViaNormaliza, this.roadTypes, 'value'),
            city: this.formGroup.value.localidadNormaliza,
            stateOrProvince: this.formGroup.value.provinciaNormaliza,
            streetType: this.simyoGlobals.getTypeStreet(this.formGroup.value.tipoViaNormaliza, this.roadTypes),
            postCode: `${this.formGroup.value.codigoPostalNormaliza}`
        };
    }

    onAddressChange(event) {
        setTimeout(() => {
            const text = event.target.value;
            const autocompleteBoxes = document.getElementsByClassName('pac-container pac-logo');
            let autocompleteBox = autocompleteBoxes[autocompleteBoxes.length - 1];

            for (let i = 0; i < autocompleteBoxes.length; i++) {
                if (autocompleteBoxes.item(i).clientWidth > 0 && autocompleteBoxes.item(i).innerHTML) {
                    autocompleteBox = autocompleteBoxes.item(i);
                    break;
                }
            }

            if (text && text !== '') {
                this.manualAddress.nativeElement.style.display = 'flex';
                this.manualAddress.nativeElement.style.top = 'calc(100% + ' + (autocompleteBox.clientHeight - 20) + 'px)';
                this.manualAddressText.nativeElement.innerText = text;
            } else {
                this.onAddressFocusOut();
            }
        }, 300)
    }

    sendEvent(event) {
        setTimeout(() => {
            const text = event.target.value;
            if (text && text !== '') {
                this.homeService.sendIdSessionToService('SN');
            }
        }, 300)
    }

    onAddressFocusOut() {
        setTimeout(() => {
            this.manualAddress.nativeElement.style.display = 'none';
            this.manualAddress.nativeElement.style.top = '100%';
            this.manualAddressText.nativeElement.innerText = '';
        }, 100)
    }

    private enableForm() {
        this.formGroup.enable();
        this.formGroup.get('provinciaNormaliza').disable();
    }

    ngOnDestroy() {
        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map(subs => subs.unsubscribe());
        }
    }
}
