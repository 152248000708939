import { AbstractControl, ValidatorFn } from '@angular/forms';

export function iuaValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const value = control.value;

        if (value == null || value === '' || validateIUA(value)) {
            return null;
        }

        return { iua: { value } };
    };
}

function validateIUA(value: string): boolean {
    console.log('validateIUA')
    if (typeof value !== 'string' || value.length !== 12) {
        return false;
    }

    const iuaArray = value.split('');
    if (!iuaArray.every(char => /^\d$/.test(char))) {
        return false;
    }

    let x = 0;

    for (let key = 1; key < iuaArray.length; key++) {
        x += key * parseInt(iuaArray[key], 10);
    }

    const rest = x % 9;
    return (rest + 1) === parseInt(iuaArray[0], 10);
}