import { Injectable } from '@angular/core';
import { DigoService } from 'src/app/services/digo.service';
import { environment } from 'src/environments/environment';
import { Ipdv } from '../models/pdvResponse';
import { Permission } from '../permissions';

@Injectable({
    providedIn: 'root',
})
export class PermissionService {
    public pdv: Ipdv;

    constructor(private digoService: DigoService) {
        this.digoService.getPDV().subscribe((data: Ipdv) => {
            if (data) {
                this.pdv = data;
            }
        });
    }

    private buildCondiction(permiso, operaciones, operator) {
        const operadoresLogicos = ['|', '&'];
        const reemplazarOperaciones = (part) => {
            if (operadoresLogicos.includes(part)) {
                return ` ${part}${part} `;
            } else {
                return `'${permiso}' ${operator} '${part}'`;
            }
        };
        const partes = operaciones.split(/([\(\)|&])/).map((part) => part.trim()).filter((part) => part !== '');

        const construirCadena = (partes) => {
            let resultado = '';
            for (let i = 0; i < partes.length; i++) {
                const parte = partes[i];

                if (parte === '(') {
                    let subExpresion = [];
                    i++;
                    while (i < partes.length && partes[i] !== ')') {
                        subExpresion.push(partes[i]);
                        i++;
                    }
                    const subCadena = construirCadena(subExpresion);
                    resultado += `(${subCadena})`;
                } else {
                    resultado += reemplazarOperaciones(parte);
                }
            }

            return resultado;
        };
        return eval(construirCadena(partes));
    }

    private checkPermission(permissions, operator) {
        if (Object.keys(permissions).length === 0) return true;
        return Object.entries(permissions).some(([clave, valor]) => {
            return (
                this.pdv.permissions.hasOwnProperty(clave)
                && (valor === ''
                    || (typeof valor === 'boolean' && this.pdv.permissions[clave] === valor)
                    || this.buildCondiction(this.pdv.permissions[clave], valor, operator ))
            );
        });
    }

    checkIfExistPermission(permissions) {
        return this.checkPermission(permissions, '===');
    }
    checkIfNotExistPermission(permissions) {
        return this.checkPermission(permissions, '!==');
    }

    public hasPermFromV2(permission: Permission): boolean {
        if (!this.pdv || !this.pdv.permissions) {
            return false;
        }
        return (
            this.checkIfExistPermission(permission.include) &&
            this.checkIfNotExistPermission(permission.notInclude)
        );
    }

    public getPermissionSimyoRates() {
        if (!this.pdv || !this.pdv.simyo_tarifas) {
            return null;
        }
        return this.pdv.simyo_tarifas;
    }
}
