import {Component, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {SimyoService} from '../../services/simyo.service';
import {SimyoNormalizadorService} from '../../services/simyo-normalizador.service';
import {SimyoOrderService} from '../../services/simyo-order.service';
import {cifValidator, dniValidator, getMaxLengthByDoc, nieValidator} from 'src/app/utils/validators/spanish-id-validator';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {EMPTY, Observable, Subscription, of} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {setTotal} from '../redux/simyo.actions';
import {SimyoRateService} from '../../services/simyo-rate.service';
import {environment} from 'src/environments/environment';
import {brands, countriesAllowedWithPassport, maxDate, minDate, mobileContractType, orderType, rateAvailableIn, technologyTypes} from 'src/app/shared/constantes';
import {PermissionService} from 'src/app/shared/services/permission.service';
import { allPermissions } from 'src/app/shared/permissions';
import {SimyoGlobals} from '../../classes/simyo-globals';
import {countrySimyo, documentTypeSimyo, masterAllSimyo} from '../../models/masterAllSimyo';
import {Ipdv} from 'src/app/shared/models/pdvResponse';
import * as moment from 'moment';
import {SimyoTemporaryClosureComponent} from '../simyo-temporary-closure/simyo-temporary-closure.component';
import {isAdult} from '../../../utils/validators/any-form-valid';
import {AlertDialogComponent} from '../../../shared/components/alert-dialog/alert-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import { blankSpaceValidator } from 'src/app/utils/validators/blank-space-validator';
import { catchError, skipWhile, take, tap } from 'rxjs/operators';
import { DigoService } from 'src/app/services/digo.service';
import { formatDate } from '@angular/common';

@Component({
    selector: 'app-simyo-customer',
    templateUrl: './simyo-customer.component.html',
    styleUrls: ['./simyo-customer.component.css', '../../../../assets/css/simyo-theme.css'],
    encapsulation: ViewEncapsulation.None,
})
export class SimyoCustomerComponent implements OnInit {
    @ViewChild('infoCustomer') infoCustomer;
    @Input() formGroup: FormGroup;
    @Input() pdv: Ipdv;

    @Input() set resultadoCobertura(val: FormGroup) {
        this.isNebaTechnology = val?.get('resultadoConsultaCobertura')?.value?.technology && val?.get('resultadoConsultaCobertura')?.value?.technology.length && val?.get('resultadoConsultaCobertura')?.value?.technology[0] === technologyTypes.ind_neba && val?.get('resultadoConsultaCobertura')?.value?.commercial_deal === null;
    }

    public documentTypes: documentTypeSimyo[] = [];
    public typeOrder;
    public orderType = orderType;
    public isCustomer: boolean = false;
    public showNotCustomer: boolean = false;
    public showErrorCustomer: string = '';
    public loaded: boolean = true;
    public customerInfo: any;
    public simyo$: Observable<number>;
    public maxLengthByDoc = 9;
    public environment = environment;
    public showInfoCustomer = false;
    public allPermissions = allPermissions;
    public maxDate: Date = maxDate;
    public minDate: Date = minDate;
    public today = moment();
    private rateFtth: any;
    private allAdditionalRates: any;
    private ratesMobiles: any;
    private isNebaTechnology: boolean = false;
    private allSubscription: Subscription[] = [];
    public countries: countrySimyo[] = [];
    public showCountries = false;
    public onlyPrepaid = false;
    public lockSearch = false;
    private allRates;

    constructor(
        private simyoService: SimyoService,
        private normalizadorService: SimyoNormalizadorService,
        private orderService: SimyoOrderService,
        private modalService: NgbModal,
        private rateService: SimyoRateService,
        private store: Store<{ simyo: number }>,
        public permSv: PermissionService,
        private simyoGlobals: SimyoGlobals,
        private matDialog: MatDialog,
        private digoService: DigoService
    ) {
        this.simyo$ = store.pipe(select('simyo'));
    }

    ngOnInit(): void {
        this.formGroup.get('customer_doctype').disable();
        this.allSubscription.push(this.simyoService.getTypeOrder()
            .subscribe((data: any) => {
                if (data != null) {
                    this.typeOrder = data;
                }
            })
        );

        this.allSubscription.push(this.simyoService.getMasterAll()
            .subscribe((data: masterAllSimyo) => {
                if (data && this.documentTypes && !this.documentTypes.length) {
                    this.documentTypes = data.document_types;
                }
                if (data && this.countries && !this.countries?.length) {
                    this.countries = data.countries.filter(country => !this.checkIfCountryShouldBeHidden(country.country_id));
                }
                this.formGroup.get('customer_doctype').enable();
            })
        );

        this.allSubscription.push(this.simyoService.getDataFromNotCustomer()
            .subscribe((data: any) => {
                if (data) {
                    this.setDataFromNotCustomer(data);
                }
            })
        );

        this.allSubscription.push(this.rateService.getRates()
            .subscribe((data: any) => {
                this.allRates = data?.RATES;
                this.allAdditionalRates = data?.RATES?.ADDITIONALS;
            })
        );

        this.allSubscription.push(this.simyoService.getRatesMobile()
            .subscribe((data: any) => {
                if (data != null) {
                    this.ratesMobiles = data;
                }
            })
        );

        this.allSubscription.push(this.simyoService.getRateFtth()
            .subscribe((data: any) => {
                this.rateFtth = data;
            })
        );
        if (this.formGroup.get('country').value !== null) {
            this.changeCountry(this.formGroup.get('country').value);
        }

        this.showInfoCustomer = sessionStorage.getItem('showInfoCustomer') ? (sessionStorage.getItem('showInfoCustomer') === '1' ? true : false) : false;
    }

    public setNotCustomer() {
        this.isCustomer = false;
        this.orderService.setCustomerId(null);
        this.simyoService.setCustomerPromotion(null);
        this.simyoService.setDataFromNotCustomer(this.formGroup.getRawValue());
        this.next();
    }

    public async next(content?) {
        this.formGroup.markAllAsTouched();
        if (this.formGroup.valid) {
            if (this.isCustomer) {
                const body = {
                    document_type_id: this.formGroup.value.customer_doctype,
                    document_number: this.formGroup.value.customer_doc,
                    birth_date: this.formGroup.value.customer_birthday && this.formGroup.value.customer_birthday !== '' ? moment(this.formGroup.value.customer_birthday).format('YYYY-MM-DD') : undefined,
                    zip_code: this.formGroup.value.zip_code
                };
                let clientData = this.formGroup.value;
                if((this.typeOrder === orderType.BROADBAND || this.typeOrder === orderType.CONVERGENT)
                    && clientData.customer_doc !== null
                    && clientData.customer_doc !== ''
                    && clientData.customer_doctype !== null
                    && clientData.customer_doctype !== '') {
                    this.simyoService.setVirtualInstallationData({
                        documentType: clientData.customer_doctype,
                        documentNumber: clientData.customer_doc,
                    })
                }

                this.loaded = false;
                this.showErrorCustomer = '';
                await this.getRates();
                this.simyoService.getCustomerInfoFromService(body).pipe(
                    take(1),
                    catchError((error: any) => {
                        if (error?.error?.error?.errorCode && error.error.error.errorCode === 581) {
                            this.showErrorCustomer = error.error.error.msg_complete;
                        } else {
                            this.showErrorCustomer = 'No se ha podido realizar la comprobación del cliente';
                        }
                        this.loaded = true;
                        return of({error: true});
                    }),
                    skipWhile((response: any) => response.error === true),
                    tap((response:any) => {
                        if (response.error === true) {
                            return;
                        }

                        if (response) {
                            if (response.msg) {
                                if (this.typeOrder !== orderType.MOBILE && parseInt(response.msg?.customer?.document_type_id) === 3 && !countriesAllowedWithPassport.includes(parseInt(response.msg.customer.nationality_id))) {
                                    this.showNotAllowedPassportDialog()
                                    return
                                }

                                this.showNotCustomer = false;
                                this.customerInfo = response.msg;
                                this.customerInfo.customer.zip_code = this.formGroup.value.zip_code;
                                this.open(content);
                                this.orderService.set_existing_client(true);
                                if(this.typeOrder === orderType.CONVERGENT || this.typeOrder === orderType.BROADBAND) {
                                    let customer = response.msg.customer;
                                    this.simyoService.setVirtualInstallationData({
                                        customerName: `${customer.name} ${customer.last_name} ${customer.second_last_name}`
                                    })
                                }
                            }
                            if (response.error && response.error.errorCode && response.error.errorCode === 's631') {
                                this.showNotCustomer = true;
                                this.orderService.set_existing_client(false);
                            }
                            if (response.error && response.error.errorCode && response.error.errorCode !== 's631') {
                                this.showErrorCustomer = 'No se ha podido realizar la comprobación del cliente';
                            }
                        } else {
                            this.showErrorCustomer = 'No se ha podido realizar la comprobación del cliente';
                        }
                    }),
                    tap(() => this.loaded = true)
                )
                .subscribe()
            } else {
                this.normalizadorService.setClientIsCover(true);
            }
        } else {
            const elements: any = document.getElementsByClassName('mat-input-element ng-invalid');
            if (elements.length > 0) {
                elements[0].focus();
            }
        }
    }

    async getRates(){
        await this.rateService.getRatesFromService(this.formGroup.value.zip_code)
            .toPromise()
            .then((data: any) => {
                if (data && data.msg) {
                    this.rateService.setRates(data.msg);
                }
            });
    }

    public changeTypeDocument(documentTypeId): void {
        const docValidators: ValidatorFn[] = [Validators.required, blankSpaceValidator(), Validators.pattern('^[a-zA-Z0-9]+$')];

        if (typeof documentTypeId === 'number') {
            documentTypeId = documentTypeId.toString();
        }

        this.lockSearch = false;
        this.showCountries = false;
        this.formGroup.get('country').clearValidators();

        switch (documentTypeId) {
            case '2':
                docValidators.push(dniValidator());
                this.formGroup.get('customer_birthday').setValidators([Validators.required, isAdult()]);
                break;
            case '1':
                docValidators.push(nieValidator());
                this.formGroup.get('customer_birthday').setValidators([Validators.required, isAdult()]);
                break;
            case '3':
                this.showCountries = true;
                this.formGroup.get('country').setValidators([Validators.required]);
                this.formGroup.get('customer_birthday').setValidators([Validators.required, isAdult()]);
                break;
            case '4':
                docValidators.push(cifValidator());
                this.formGroup.get('customer_birthday').clearValidators();
                this.formGroup.get('customer_birthday').patchValue(null);
                break;
            default:
                break;
        }

        this.formGroup.get('customer_doc').setValidators(docValidators);
        this.formGroup.get('customer_doc').updateValueAndValidity();
        this.formGroup.get('country').updateValueAndValidity();
        this.formGroup.get('customer_birthday').updateValueAndValidity();
    }

    public changeCountry(country_id) {
        if (parseInt(country_id) === -1 || this.pdv?.info?.isstand && !countriesAllowedWithPassport.includes(parseInt(country_id))) {
            this.showNotAllowedPassportDialog()
            return
        }

        this.lockSearch = false;
        this.onlyPrepaid = !countriesAllowedWithPassport.includes(parseInt(country_id));
    }

    public showNotAllowedPassportDialog() {
        this.lockSearch = true;

        const alert = this.matDialog.open(AlertDialogComponent, {
            data: {message: 'Upss con este Pasaporte no está permitido hacer el alta'}
        });

        alert.afterClosed().subscribe(() => {
            this.formGroup.reset();
            this.changeTypeDocument('');
        })
    }

    public checkIfCountryShouldBeHidden(country_id) {
        if (this.typeOrder !== orderType.MOBILE) {
            return !countriesAllowedWithPassport.includes(parseInt(country_id));
        }

        return false;
    }

    private open(content) {
        this.modalService.open(content, {size: 'lg', centered: true});
    }

    public setCustomer() {
        if (this.customerInfo) {
            this.validateFirstRate();
            this.evaluateRateAndPassport();
            this.orderService.setCustomerId(this.customerInfo);
            this.simyoService.setDataFromCustomer(this.customerInfo);
            this.simyoService.setCustomerPromotion(this.customerInfo);
            this.normalizadorService.setClientIsCover(true);
        }
    }

    private validateFirstRate() {
        // Si el flujo lo comienza desde el selector de tarifas general se puede setear una tarifa antes de meter los datos de cliente.
        // En este caso, miramos si le corresponde una tarifa adicional en oferta por lo contratado y buscamos una tarifa similar pero más barata
        if (this.rateService.isAdditional(0, this.customerInfo, this.typeOrder) && this.typeOrder !== orderType.BROADBAND && this.ratesMobiles && this.ratesMobiles.length && this.ratesMobiles[0].type_pay === mobileContractType.postpaid && this.allAdditionalRates) {
            let type: string;
            if (this.typeOrder === orderType.MOBILE) {
                type = rateAvailableIn.mobile;
            }
            if (this.typeOrder === orderType.CONVERGENT && this.rateFtth) {
                type = this.simyoGlobals.getAvailableIn(this.rateFtth.rate_info.speed);
            }
            this.allAdditionalRates = this.allAdditionalRates.filter(rate => rate.rate_info.available_in.includes(type) && rate.rate_ids[mobileContractType.postpaid] !== '');
            this.searchSameRateInOffer(this.allAdditionalRates, this.ratesMobiles);
        }
    }

    private searchSameRateInOffer(compatibleAdditionalRates, actualRates) {
        if (compatibleAdditionalRates?.length && actualRates?.length) {
            const found = compatibleAdditionalRates.find(rate => rate.data === actualRates[0]?.data && rate.voice === actualRates[0]?.voice && rate.is_additional);
            if (found) {
                found.type_pay = mobileContractType.postpaid;
                // this.simyoService.setRatesMobile(0, {movil: found, bonos: actualRates[0]?.bonos});
                this.simyoService.setRatesMobile(0, {...found});
            }
        }
    }
    public parseYear(a: any){
        if (a != null && typeof a._i === 'string') { //Los datos son validos y han sido introducidos a mano, sin el datepicker
            //Comprobamos si el año introducido tiene 2 digitos o 4
            //(si el string de 4 caracteres tiene una letra o un separador como '/' o '-'
            //significa que ha introducido solo 2 digitos para el año)
            if (!/^[0-9]*$/.test(a._i.substring(a._i.length - 4)) && a._d.getFullYear() > this.today.year()) {
                a._d.setFullYear(a._d.getFullYear() - 100);
                this.formGroup.get('customer_birthday').patchValue(moment(a));
            }
        }
    }
    public invalidDate(){
        return this.formGroup.get('customer_birthday')?.errors?.date_minimum;
    }
    public adultAgeDate(){
        return this.formGroup.get('customer_birthday')?.errors?.date_maximum;
    }
    private setDataFromNotCustomer(dataNotCustomer) {
        // Datos personales
        if (dataNotCustomer.customer_document_type_id && dataNotCustomer.customer_document_type_id !== '') {
            this.formGroup.get('customer_doctype').patchValue(dataNotCustomer.customer_document_type_id);

            if (dataNotCustomer.customer_document_type_id === 3 && dataNotCustomer.customer_nationality_id) {
                this.formGroup.get('country').patchValue(dataNotCustomer.customer_nationality_id);
                this.changeTypeDocument(dataNotCustomer.customer_document_type_id);
            }
        }

        if (dataNotCustomer.customer_document && dataNotCustomer.customer_document !== '') {
            this.formGroup.get('customer_doc').patchValue(dataNotCustomer.customer_document);
        }

        if (dataNotCustomer.customer_birth_date && dataNotCustomer.customer_birth_date !== '') {
            this.formGroup.get('customer_birthday').patchValue(dataNotCustomer.customer_birth_date);
        }

        if (dataNotCustomer.tax_type_cp && dataNotCustomer.tax_type_cp !== '') {
            this.formGroup.get('zip_code').patchValue(dataNotCustomer.tax_type_cp);
        }

        this.evaluateRateAndPassport();
    }

    // Si el flujo lo comienza desde el selector de tarifas general se puede setear una tarifa antes de meter los datos de cliente.
    // Si se elige un pasaporte como tipo de documento, hay que evaluar la tarifa. Si es pospago se debe eliminar
    private evaluateRateAndPassport() {
        if (this.typeOrder === orderType.MOBILE && +this.formGroup.get('customer_doctype')?.value === 3 && this.ratesMobiles?.length) {
            if (this.ratesMobiles[0]?.type_pay === mobileContractType.postpaid) {
                this.simyoService.removeFirstRatesMobile();
                this.store.dispatch(setTotal({total: 0}));
            }
        }

        // Con fibra neba
        if (this.isNebaTechnology) {
            // Sólo fibra: no se permite nada
            if (this.typeOrder === orderType.BROADBAND) {
                // Cliente existente: Solo permitimos fibra 500
                // if (this.customerInfo?.num_subscriptions && (this.rateFtth?.rate_info?.speed === '100' || this.rateFtth?.rate_info?.speed === '300')) {
                //     this.simyoService.setRateFtth(null);
                //     this.store.dispatch(setTotal({total: 0}));
                // }
                // Cliente nuevo: No se permite nada
                // if (!this.customerInfo || (this.customerInfo && this.customerInfo.num_subscriptions === 0)) {
                this.simyoService.setRateFtth(null);
                this.store.dispatch(setTotal({total: 0}));
                // }
            }

            if (this.typeOrder === orderType.CONVERGENT && this.rateFtth?.rate_info?.speed === '300') {
                this.simyoService.setRateFtth(null);
                this.store.dispatch(setTotal({total: 0}));
            }
        }

        // Retomamos borrador, cambiamos de cp en el cliente y tenemos que actualizar la tarifa
        if (this.typeOrder === orderType.MOBILE && this.ratesMobiles?.length) {
            this.evaluateRateMobile();
        }
        if (this.typeOrder === orderType.CONVERGENT && this.ratesMobiles?.length && this.rateFtth) {
            this.evaluateRateMobile();

            // Fibra
            this.evaluateRateFtth();
        }

        if (this.typeOrder === orderType.BROADBAND && this.rateFtth) {
            this.evaluateRateFtth();
        }
    }

    private evaluateRateMobile() {
        this.ratesMobiles.forEach(actualRate => {
            const foundMobile = this.allRates.MOBILE.find(rateMobile => (rateMobile?.contract_type === mobileContractType.postpaid ? rateMobile.rate_ids.postpaid : rateMobile.rate_ids.prepaid) === (actualRate?.type_pay === mobileContractType.postpaid ? actualRate?.rate_ids?.postpaid : actualRate?.rate_ids?.prepaid) && +actualRate?.rate_info?.real_price !== +rateMobile?.rate_info?.real_price);
            const foundAdditional = this.allRates.ADDITIONALS.find(rateMobile => (rateMobile?.contract_type === mobileContractType.postpaid ? rateMobile.rate_ids.postpaid : rateMobile.rate_ids.prepaid) === (actualRate?.type_pay === mobileContractType.postpaid ? actualRate?.rate_ids?.postpaid : actualRate?.rate_ids?.prepaid) && +actualRate?.rate_info?.real_price !== +rateMobile?.rate_info?.real_price);
            // Tenemos una tarifa cuyo real price es diferente al seleccionado
            if (foundMobile || foundAdditional) {
                if (foundMobile) {
                    actualRate.rate_info.real_price = foundMobile.rate_info?.real_price;
                    actualRate.rate_info.contracted_price = foundMobile.rate_info?.real_price;

                    actualRate.rate_info.ftth_1_price = foundMobile.rate_info?.ftth_1_price;
                    actualRate.rate_info.ftth_1_price_igic = foundMobile.rate_info?.prices?.igic?.ftth_1_price;
                    actualRate.rate_info.ftth_1_price_ipsi_8 = foundMobile.rate_info?.prices?.ipsi_8?.ftth_1_price;
                    actualRate.rate_info.ftth_1_price_ipsi_10 = foundMobile.rate_info?.prices?.ipsi_10?.price;
                    actualRate.rate_info.ftth_1_price_iva = foundMobile.rate_info?.prices?.iva?.price;

                    actualRate.rate_info.ftth_300_price = foundMobile.rate_info?.ftth_300_price;
                    actualRate.rate_info.ftth_300_price_igic = foundMobile.rate_info?.prices?.igic?.ftth_300_price;
                    actualRate.rate_info.ftth_300_price_ipsi_8 = foundMobile.rate_info?.prices?.ipsi_8?.ftth_300_price;
                    actualRate.rate_info.ftth_300_price_ipsi_10 = foundMobile.rate_info?.prices?.ipsi_10?.price;
                    actualRate.rate_info.ftth_300_price_iva = foundMobile.rate_info?.prices?.iva?.price;

                    actualRate.rate_info.ftth_600_price = foundMobile.rate_info?.ftth_600_price;
                    actualRate.rate_info.ftth_600_price_igic = foundMobile.rate_info?.prices?.igic?.ftth_600_price;
                    actualRate.rate_info.ftth_600_price_ipsi_8 = foundMobile.rate_info?.prices?.ipsi_8?.ftth_600_price;
                    actualRate.rate_info.ftth_600_price_ipsi_10 = foundMobile.rate_info?.prices?.ipsi_10?.price;
                    actualRate.rate_info.ftth_600_price_iva = foundMobile.rate_info?.prices?.iva?.price;

                    actualRate.rate_info.price = foundMobile.rate_info?.real_price;
                    actualRate.rate_info.price_igic = foundMobile.rate_info?.prices?.igic?.price;
                    actualRate.rate_info.price_ipsi_8 = foundMobile.rate_info?.prices?.ipsi_8?.price;
                    actualRate.rate_info.price_ipsi_10 = foundMobile.rate_info?.prices?.ipsi_10?.price;
                    actualRate.rate_info.price_iva = foundMobile.rate_info?.prices?.iva?.price;
                }
                if (foundAdditional) {
                    actualRate.rate_info.real_price = foundAdditional.rate_info?.real_price;
                    actualRate.rate_info.contracted_price = foundAdditional.rate_info?.real_price;

                    actualRate.rate_info.ftth_1_price = foundAdditional.rate_info?.ftth_1_price;
                    actualRate.rate_info.ftth_1_price_igic = foundAdditional.rate_info?.prices?.igic?.ftth_1_price;
                    actualRate.rate_info.ftth_1_price_ipsi_8 = foundAdditional.rate_info?.prices?.ipsi_8?.ftth_1_price;
                    actualRate.rate_info.ftth_1_price_ipsi_10 = foundAdditional.rate_info?.prices?.ipsi_10?.price;
                    actualRate.rate_info.ftth_1_price_iva = foundAdditional.rate_info?.prices?.iva?.price;

                    actualRate.rate_info.ftth_300_price = foundAdditional.rate_info?.ftth_300_price;
                    actualRate.rate_info.ftth_300_price_igic = foundAdditional.rate_info?.prices?.igic?.ftth_300_price;
                    actualRate.rate_info.ftth_300_price_ipsi_8 = foundAdditional.rate_info?.prices?.ipsi_8?.ftth_300_price;
                    actualRate.rate_info.ftth_300_price_ipsi_10 = foundAdditional.rate_info?.prices?.ipsi_10?.price;
                    actualRate.rate_info.ftth_300_price_iva = foundAdditional.rate_info?.prices?.iva?.price;

                    actualRate.rate_info.ftth_600_price = foundAdditional.rate_info?.ftth_600_price;
                    actualRate.rate_info.ftth_600_price_igic = foundAdditional.rate_info?.prices?.igic?.ftth_600_price;
                    actualRate.rate_info.ftth_600_price_ipsi_8 = foundAdditional.rate_info?.prices?.ipsi_8?.ftth_600_price;
                    actualRate.rate_info.ftth_600_price_ipsi_10 = foundAdditional.rate_info?.prices?.ipsi_10?.price;
                    actualRate.rate_info.ftth_600_price_iva = foundAdditional.rate_info?.prices?.iva?.price;

                    actualRate.rate_info.price = foundAdditional.rate_info?.real_price;
                    actualRate.rate_info.price_igic = foundAdditional.rate_info?.prices?.igic?.price;
                    actualRate.rate_info.price_ipsi_8 = foundAdditional.rate_info?.prices?.ipsi_8?.price;
                    actualRate.rate_info.price_ipsi_10 = foundAdditional.rate_info?.prices?.ipsi_10?.price;
                    actualRate.rate_info.price_iva = foundAdditional.rate_info?.prices?.iva?.price;
                }
            }
        });
    }

    private evaluateRateFtth() {
        const foundFtth = this.allRates.FTTH_NEW.find(ftth => ((ftth?.type_discount?.rate_id === this.rateFtth?.rate_id) || (ftth?.type_normal?.rate_id === this.rateFtth?.rate_id)) && this.rateFtth?.rate_info?.contracted_price !== ftth?.rate_info?.real_price);
        if (foundFtth) {
            if (foundFtth.type_discount?.rate_id === this.rateFtth?.rate_id) {
                this.rateFtth.rate_info.contracted_price = foundFtth.type_discount.price;
                this.rateFtth.rate_info.price = foundFtth.type_discount.price;
                this.rateFtth.rate_info.price_igic = foundFtth.type_discount.prices.igic.price;
                this.rateFtth.rate_info.price_ipsi_8 = foundFtth.type_discount.prices.ipsi_8.price;
                this.rateFtth.rate_info.price_ipsi_10 = foundFtth.type_discount.prices.ipsi_10.price;
                this.rateFtth.rate_info.price_iva = foundFtth.type_discount.prices.iva.price;
            }
            if (foundFtth.type_normal?.rate_id === this.rateFtth?.rate_id) {
                this.rateFtth.rate_info.contracted_price = foundFtth.type_normal.price;
                this.rateFtth.rate_info.price = foundFtth.type_normal.price;
                this.rateFtth.rate_info.price_igic = foundFtth.type_normal.prices.igic.price;
                this.rateFtth.rate_info.price_ipsi_8 = foundFtth.type_normal.prices.ipsi_8.price;
                this.rateFtth.rate_info.price_ipsi_10 = foundFtth.type_normal.prices.ipsi_10.price;
                this.rateFtth.rate_info.price_iva = foundFtth.type_normal.prices.iva.price;
            }
            this.orderService.setBroadbandRate(this.rateFtth);
        }
    }

    public setMaxLengthByDoc(type) {
        this.maxLengthByDoc = getMaxLengthByDoc(brands.simyo, type);
    }

    public getDocumentLabel(id): string {
        if (this.documentTypes && this.documentTypes.length && id) {
            const found = this.documentTypes.find(x => x.document_type_id === +id);
            return found ? found.value : '';
        }
    }

    public checkSimyoTemporaryClosure() {
        this.simyoService.setTaxType(this.formGroup.get('zip_code').value);
        if (this.permSv.hasPermFromV2(this.allPermissions.simyo.nbss_block)) {
            this.modalService.open(SimyoTemporaryClosureComponent, {size: 'md', centered: true, backdrop: 'static'});
            return;
        }
        this.isCustomer = true;
        this.next(this.infoCustomer);
    }

    public isSameTaxZone(){
        var zip_code = (this.customerInfo?.customer?.zip_code + '').slice(0,2);
        var user_post_code = (this.customerInfo?.customer?.postal_address?.post_code + '').slice(0,2);
        if (zip_code == '' || user_post_code == '') {
            return true;
        }

        if (zip_code !== user_post_code) {
            if (zip_code === '51' || zip_code === '52') {
                return false;
            }
            if ((zip_code === '35' || zip_code === '38') && (user_post_code !== '35' && user_post_code !== '38')) {
                return false;
            }
            if (user_post_code === '51' || user_post_code === '52' || user_post_code === '35' || user_post_code === '38') {
                return false;
            }
        }
        return true;

    }

    public setNewFakeCustomer(): object {
        return {
            'error': {
                'errorCode': 's631',
                'msg': 'Error interno, int\u00e9ntelo de nuevo m\u00e1s tarde',
                'msg_complete': 'The customer ID does not exist in the system.',
                'request_id': 'c603a34e68a081dc1dfd72fa32299ff5',
                'simyo_code': 777631
            }
        };
    }

    public setCustomFakeCustomer(): object {
        return {
            'msg': {
                'customer_id': '1001036132543',
                'customer': {
                    "customer_id": "1010000014205",
                    "document_type_id": 3,
                    "document": "12345678",
                    "name": "Isaac",
                    "second_last_name": "Caycho",
                    "last_name": "Pérez",
                    "birth_date": "2000-01-01",
                    "nationality_id": 76,
                    "email": "isaac.perez@tecalis.com",
                    "phone_number": "655555555",
                    "tax_type": "18",
                    "allow_data_navigation": "Y",
                    "receive_third_communications": "Y",
                    "give_data_orange_group": "Y",
                    "allow_create_commercial_profile": "Y",
                    "allow_commercial_communications": "Y",
                    "postal_address": {
                        "address_type": "CUSTOMER",
                        "post_code": "28003",
                        "street_number": "30",
                        "street_type": "CL",
                        "street_name": "CRISTOBAL BORDIU",
                        "province": "28",
                        "city": "MADRID",
                        "country": "1724",
                        "floor_number": "Planta 7",
                        "apartment_number": "F"
                    }
                },
                'accounts': [{'acct_code': '38455608', 'account_number': '********************2643', 'account_type_id': 2, 'create_date': '2022-05-20'}],
                /*has_ftth : false,
                has_mobile_promotion: false,
                num_ftth: 0,
                num_mobile: 3,
                num_subscriptions : 3*/
                'num_subscriptions': 1,
                'has_ftth': {
                    ftth_100: false,
                    ftth_300: false,
                    ftth_500: true,
                    ftth_600: false,
                    ftth_1: false,
                },
                'num_ftth': {
                    ftth_100: 0,
                    ftth_300: 0,
                    ftth_500: 1,
                    ftth_600: 0,
                    ftth_1: 0,
                    ftth_100_discount: 0,
                    ftth_300_discount: 0,
                    ftth_500_discount: 0,
                    ftth_600_discount: 0,
                    ftth_1_discount: 0,
                },
                'has_mobile_promotion': {
                    gt7: false,
                    gt10: false
                },
                'num_mobile': {
                    gt7: 0,
                    gt10: 0
                }
            }
        };
    }

    onChangeZip(value) {
        if (value.length > 5) {
            this.formGroup.get('zip_code').patchValue(value.slice(0, 5));
        }
    }

    onlyPositiveDigits(event){
        if (event.key.length > 1) {
            return true;
        }
        if (+event.key >= 0 && +event.key <= 9 ) {
            return true;
        }

        return false;
    }

    ngOnDestroy() {
        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map(subs => subs.unsubscribe());
        }
    }

    alphanumericOnly(event) {
        let pattern: RegExp = /^[a-zA-Z0-9]+$/;
        const inputChar = event.key;
        if (!pattern.test(inputChar)) {
            event.preventDefault();
        }
    }

    protected readonly countriesAllowedWithPassport = countriesAllowedWithPassport;

    setUser() {
        this.formGroup.patchValue({
            customer_doctype: '2',
            customer_doc: '12345678Z',
            customer_birthday: formatDate('2000-02-02', 'yyyy-MM-dd', 'en'),
            zip_code: '13700'
        })
    }
}
