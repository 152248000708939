import {HttpErrorResponse} from '@angular/common/http';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MatStepper} from '@angular/material/stepper';
import {select, Store} from '@ngrx/store';
import {interval, Observable, Subscription} from 'rxjs';
import {startWith, take} from 'rxjs/operators';
import {CreatePrepaidResponse} from 'src/app/orange/models/createPrepaid';
import {PrepaidLine, PrepaidOrderResponse} from 'src/app/orange/models/prepaidInfoResponse';
import {AppState} from 'src/app/orange/redux';
import {resetState, setPrepaidOrderInfo, showShoppingCart} from 'src/app/orange/redux/orange.actions';
import {IOrangePrepaidState} from 'src/app/orange/redux/orange.state';
import {OrangePrepaidHiringService} from 'src/app/orange/services/orange-prepaid-hiring.service';
import {orangePrepaidTypes, orangeWelcomePackCodes, superStatus} from 'src/app/shared/constantes';
import {Ipdv} from 'src/app/shared/models/pdvResponse';
import {OrangeRatesWP} from '../../../models/masterAllResponse';
import {Router} from '@angular/router';
import {OrangeShoppingCartService} from '../../../services/orange-shopping-cart.service';
import {downloadFile} from 'src/app/utils/downloadFile';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from '../../../../shared/components/alert-dialog/alert-dialog.component';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { environment } from 'src/environments/environment';

import { OrangeOrdersService } from 'src/app/orange/services/orange-orders.service';
import { eSimConstants } from 'src/app/orange/models/eSimConstants';
import moment from 'moment';
import { EContratoService } from 'src/app/orange/services/e-contrato.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalKycErrorComponent } from 'src/app/orange/modals/modal-kyc-error/modal-kyc-error.component';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { ModalEContratoComponent } from 'src/app/orange/modals/e-contrato/modal-e-contrato.component';
import { generateLetterDNI } from 'src/app/utils/validators/spanish-id-validator';
import { allPermissions } from 'src/app/shared/permissions';


@Component({
    selector: 'app-orange-prepaid-confirmation',
    templateUrl: './orange-prepaid-confirmation.component.html',
    styleUrls: ['./orange-prepaid-confirmation.component.css', '../../../../../assets/css/orange-theme.css']
})
export class OrangePrepaidConfirmationComponent implements OnInit, OnDestroy {
    @Input() configurationFormGroup: FormGroup;
    @Input() userDataFormGroup: FormGroup;
    @Input() agreementsFormGroup: FormGroup;
    @Input() stepper: MatStepper;
    @Input() pdv: Ipdv;
    @Output() isEditable = new EventEmitter<boolean>();
    @Input() type: string;

    public showProgressBar: boolean = false;
    public showAllProgressBar: boolean = true;
    public showErrorOrder: boolean = false;
    public showCreateButton: boolean = true;
    public isCreateLoading: boolean = false;
    public showErrorOrderMsg: string = 'Error interno. Por favor, inténtelo de nuevo más tarde.';
    public orange$: Observable<IOrangePrepaidState>;
    public checkStatusSubscription: Subscription;
    public superStatus = superStatus;
    public progressBarTimer;
    public orangeWelcomePackCodes = orangeWelcomePackCodes;
    public msisdns: string[];
    public orderData: any = {lines: []}; // Variable para modificar los loaders sin refrescar la animación
    public showRecharge: boolean = false;
    public loadingRecharge: boolean = false;
    public confirmRecharge: any = null;
    public errorRecharge: string = '';
    public precontract: boolean = false;
    public hash: string = '';
    public loadingPrecontract: boolean = false;
    public loadingDownload: boolean = false;
    public errorDownload: boolean = false;
    public only_send_email: boolean = false;
    public sendEmailTries: number = 3;
    public downloaded: boolean = false;
    public sended: boolean = false;
    public stepperAqui: boolean = false;
    public modalShowed: boolean = false;
    private numConsecutiveErrorRequest = 0;
    private MAX_CONSECUTIVE_ERROR_REQUEST = 3;
    private PROGRESS_BAR_TIME = 60;
    private TIME_BETWEEN_REQUEST = 2500;
    private CHECK_STATUS_TIME = 30;   
    public orderDraftId: string = null;
    public messageAlertRemember: string = null;
    /* KYC */
    public resultKYC: any = null;
    public showSkipKYC: boolean = environment.showSkipKYC;
    public confirmSkipKYC: boolean = false;
    private notAllowedCodes = ['10','12','13'];
    public allPermissions = allPermissions;

    public userSkipKyC = ['demo_pdv', 'demo_ent1', 'demo_ent2', 'demo_int', 'demo_ase'];
    
    constructor(
        private router: Router,
        private orangePrepaidHiringService: OrangePrepaidHiringService,
        private orangeStore: Store<AppState>,
        private shoppingCartService: OrangeShoppingCartService,
        private orangeOrderService: OrangeOrdersService,
        public matDialog: MatDialog,
        private eContratoService: EContratoService,
        private modalService: NgbModal,
        public permSv: PermissionService
    ) {
        this.orange$ = orangeStore.pipe(select('orangePrepaid'));
        this.eContratoService.overrideXMLHttpRequest();
    }

    ngOnInit(): void {
        this.orange$.subscribe(v => {
            this.msisdns = v.configuration_form_values.msisdns.filter(m => m !== '')
            this.orderDraftId = v.orderDraftId;
        });

        this.userDataFormGroup.get('email').valueChanges.subscribe(()=>{this.resetPrecontract()});
        this.userDataFormGroup.get('birth_date').valueChanges.subscribe(()=>{this.resetKyc()});
        this.userDataFormGroup.get('document_type').valueChanges.subscribe(()=>{this.resetKyc()});
        this.userDataFormGroup.get('document_number').valueChanges.subscribe(()=>{this.resetKyc()});
        this.userDataFormGroup.get('first_surname').valueChanges.subscribe(()=>{this.resetKyc()});
        this.userDataFormGroup.get('nacionality').valueChanges.subscribe(()=>{this.resetKyc()});
        this.userDataFormGroup.get('name').valueChanges.subscribe(()=>{this.resetKyc()});
        this.userDataFormGroup.get('second_surname').valueChanges.subscribe(()=>{this.resetKyc()});
        this.userDataFormGroup.get('sex').valueChanges.subscribe(()=>{this.resetKyc()});
        this.configurationFormGroup.get('rate').valueChanges.subscribe(()=>{this.resetPrecontract()});
        this.configurationFormGroup.get('msisdns').valueChanges.subscribe(()=>{this.resetPrecontract()});
        this.configurationFormGroup.get('esim_number_lines').valueChanges.subscribe(()=>{this.resetPrecontract()});

        this.configurationFormGroup.valueChanges.subscribe((configurationFormValue) => {
            if (this.configurationFormGroup.valid) {
                this.showErrorOrder = false;
                this.showCreateButton = true;
                this.isCreateLoading = false;
            }
        });

        this.userDataFormGroup.valueChanges.subscribe((configurationFormValue) => {
            if (this.userDataFormGroup.valid) {
                this.showErrorOrder = false;
                this.showCreateButton = true;
                this.isCreateLoading = false;
            }
        });

        this.stepper.selectionChange
            .subscribe((res: StepperSelectionEvent) => {
                if (this.stepper.steps.last === res.selectedStep) {
                    window.scrollTo({
                        top: document.body.scrollHeight,
                        left: 0 ,
                        behavior: 'smooth'
                    });
                    this.stepperAqui = true;
                    if (!this.modalShowed && !this.sended && !this.downloaded && this.sendEmailTries === 0) {
                        this.openModal('No es posible enviar el correo al cliente. Imprime ahora el documento y entrégaselo al cliente. Recuerda que es obligatorio que el cliente disponga de este documento.');
                    }
                }else{
                    this.stepperAqui = false;
                }
            });
    }

    public create(precontract: boolean) {
        if (this.configurationFormGroup.valid && this.userDataFormGroup.valid) {
            this.showErrorOrder = false;
            
            
            const configurationData = this.configurationFormGroup.value;
            let userData = {
                ...this.userDataFormGroup.value,
                province: this.userDataFormGroup.get('province').value
            };
            const msisdns = configurationData.msisdns?.filter(msisdn => msisdn !== '');
            if (this.type !== orangePrepaidTypes.esim 
                || (this.type === orangePrepaidTypes.esim && configurationData.type_operation === eSimConstants.portabilityTypeOperation)) {
                    this.createOrderDataLines(msisdns);
            } else {
                this.createOrderDataLines(['']);
            }
            if (!precontract) {
                this.isCreateLoading = true;    
                this.clearTimerProgressBar();
                this.initTimerProgressBar();
                return this.orangeOrderService.createOrder(
                    userData,
                    configurationData,
                    this.agreementsFormGroup.value,
                    this.type,
                    this.hash,
                    this.only_send_email,
                    this.pdv?.sfids?.orange,
                    this.orderDraftId)
                    .subscribe(r => this.onCreateSuccess(r), e => this.onCreateError(e), () => this.onCreateComplete());
            }


            return this.orangeOrderService.createPreOrder(
                userData,
                configurationData,
                this.agreementsFormGroup.value,
                this.type,
                this.hash,
                this.only_send_email,
                this.pdv?.sfids?.orange,
                this.orderDraftId)
                .subscribe(r => this.onCreatePrecontractSuccess(r), e => this.onCreatePrecontractError(e), () => this.onCreateComplete());
        
        }
    }

    private onCreatePrecontractSuccess(response: any){
        this.loadingPrecontract = false;
        this.showErrorOrder = false;
        if (response?.errorCode === 's107') {
            this.hash = response.hash;
            this.sendEmailTries--;
            this.sended = false;
            if (this.sendEmailTries > 0) {
                this.only_send_email = true;
                this.create(true);
            }else{// se ha intentado ya 2 veces y no se ha enviado el mail
                //mostrar modal si no le ha dado ya a descargar, si no se ha mostrado ningun modal ya, y si el usuario está en este paso del stepper
                if (!this.downloaded && !this.errorDownload && !this.modalShowed && this.stepperAqui) {
                    this.openModal('No es posible enviar el correo al cliente. Imprime ahora el documento y entrégaselo al cliente. Recuerda que es obligatorio que el cliente disponga de este documento.');
                }
            }
        }
        else if (response?.hash) {
            this.hash = response.hash;
            this.sended = true;
            this.only_send_email = false;
            this.sendEmailTries = 3;
            this.goToBottom();
        }
    }

    private onCreateSuccess(response: CreatePrepaidResponse) {
        if (response?.msg) {
            if (response?.msg?.order_id && response?.msg?.order_id !== '') {
                this.showProgressBar = true;
                this.isEditable.emit(false);
                this.orangeStore.dispatch(showShoppingCart({show: false}));
                this.shoppingCartService.hiddeShoppingCart.emit(true);
                this.checkStatus(response?.msg?.order_id);
                return;
            }
        }

        if (response?.error?.error_code.toString().includes('E')) {
            this.showErrorOrderMsg = response.error.msg + ' (' + response?.error?.error_code.toString() + ')';
        }

        this.showErrorOrder = true;
        this.showCreateButton = false;
        this.isEditable.emit(true);
        this.notShowProgressBar();
        this.goToTop();
    }

    private onCreateError(error: HttpErrorResponse) {
        this.showErrorOrder = true;
        this.showCreateButton = false;
        this.isEditable.emit(true);
        this.notShowProgressBar();
        this.goToTop();
    }

    private onCreatePrecontractError(error: HttpErrorResponse){
        this.loadingPrecontract = false;
        this.hash = '';
    }

    private onCreateComplete() {
        this.isCreateLoading = false;
        /*this.showProgressBar = true;
        this.isEditable.emit(false);*/
    }

    private goToTop() {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    private goToBottom() {
        setTimeout(() => {
            window.scrollTo({ 
                top: document.body.scrollHeight, 
                left: 0 ,
                behavior: 'smooth'
            });
        }, 300);
    }

    public normalizedMsisdns(msisdns) {
        return msisdns ? msisdns.filter(msisdn => msisdn !== '' && msisdn.length === 9 && (msisdn[0] === '6' || msisdn[0] === '7')) : [];
    }

    public getTotalPrice(): number {
        const msisdns = this.normalizedMsisdns(this.configurationFormGroup.get('msisdns')?.value);
        if (msisdns && msisdns.length && this.configurationFormGroup.get('rate').value) {
            return msisdns.length * +this.configurationFormGroup.get('rate').value.price;
        }

        const esimNumberLines = this.configurationFormGroup.get('esim_number_lines')?.value;
        if(esimNumberLines && esimNumberLines > 0 && this.configurationFormGroup.get('rate').value) {
            return esimNumberLines * +this.configurationFormGroup.get('rate').value?.price;
        }
        return 0;
    }

    private checkStatus(orderId) {
        this.checkStatusSubscription = interval(this.TIME_BETWEEN_REQUEST)
            .pipe(startWith(0))
            .pipe(take((this.PROGRESS_BAR_TIME / (this.TIME_BETWEEN_REQUEST / 1000)) + (this.CHECK_STATUS_TIME / (this.TIME_BETWEEN_REQUEST / 1000))))
            .subscribe(val => {
                if (orderId && orderId !== '') {
                    this.orangePrepaidHiringService.getOrderPrepaid(orderId, this.type !== orangePrepaidTypes.esim, false, 0)
                        .pipe(take(1))
                        .subscribe((data: PrepaidOrderResponse) => {
                            if (data && data.msg) {
                                this.orangeStore.dispatch(setPrepaidOrderInfo({order: data.msg}));

                                data.msg.lines.map((l, i) => {
                                    if (!this.orderData.lines[i]) {
                                        this.orderData.lines[i] = {};
                                    }

                                    this.orderData.lines[i].msisdn = l.msisdn;
                                    this.orderData.lines[i].status = l.status;
                                    this.orderData.lines[i].status_description = l.status_description;
                                    this.orderData.lines[i].exit_progress_bar = l.exit_progress_bar;
                                    this.orderData.lines[i].super_status = l.super_status;
                                });

                                /*if (data.msg.exit_progress_bar) {
                                    this.notShowProgressBar();
                                }*/

                                if (this.checkSuperStatusOnLines(data.msg.lines)) {
                                    this.unsubscribeCheckStatus();
                                    this.showAllProgressBar = false;
                                    if (+this.configurationFormGroup.get('balance').value && this.allLineActive(data.msg.lines)) {
                                        this.rechargeOrderPrepaid(orderId);
                                    }
                                }
                            }
                        }, (error: HttpErrorResponse) => {
                            this.evaluateStatusError();
                        });
                }
            });
    }

    private allLineActive(lines: PrepaidLine[]): boolean {
        if (lines && lines.length) {
            const a = lines.filter(line => {
                return line.super_status && line.super_status === superStatus.active;
            });

            return lines.length === a.length;
        }
        return false;
    }

    private rechargeOrderPrepaid(orderId: any) {
        this.showRecharge = true;
        this.loadingRecharge = true;
        this.orangePrepaidHiringService.rechargeOrderPrepaid(orderId)
            .subscribe((data: any) => {
                if (data) {
                    if (data.recharge && data.recharge === 'ok') {
                        this.confirmRecharge = data;
                        this.errorRecharge = '';
                    }
                    if (data.recharge && data.recharge === 'ko') {
                        this.confirmRecharge = null;
                        this.errorRecharge = data?.error?.msg || 'Ha ocurrido un error al recargar. Inténtelo de nuevo más tarde';
                    }
                } else {
                    this.errorRecharge = 'Ha ocurrido un error al recargar. Inténtelo de nuevo más tarde';
                }
                this.loadingRecharge = false;
            }, (error: HttpErrorResponse) => {
                this.loadingRecharge = false;
                this.confirmRecharge = false;
                this.errorRecharge = 'Ha ocurrido un error al recargar. Inténtelo de nuevo más tarde';
            });
    }

    public getJustificante(url: any) {
        const urlArray = url.split(' ');
        const safeUrl = urlArray.join('%20');

        console.log(url, safeUrl);

        window.open(safeUrl, '_blank');
    }

    private createOrderDataLines(msisdns: string[]) {
        msisdns.map((l, i) => {
            if (!this.orderData.lines[i]) {
                this.orderData.lines[i] = {};
            }

            this.orderData.lines[i].msisdn = l;
            this.orderData.lines[i].status = '1';
            this.orderData.lines[i].status_description = 'Pendiente';
            this.orderData.lines[i].exit_progress_bar = false;
        });
    }

    private evaluateStatusError() {
        this.numConsecutiveErrorRequest++;
        if (this.numConsecutiveErrorRequest >= this.MAX_CONSECUTIVE_ERROR_REQUEST) {
            this.notShowProgressBar();
            this.showErrorOrder = true;
            this.showErrorOrderMsg = 'No se ha podido completar el proceso. Compruebe más tarde el estado de la activación.';
            this.unsubscribeCheckStatus();
        }
    }

    private initTimerProgressBar() {
        var timeleft = this.PROGRESS_BAR_TIME;
        let self = this;
        this.progressBarTimer = setInterval(function() {
            if (timeleft <= 0) {
                clearInterval(self.progressBarTimer);
                self.showAllProgressBar = false;
                //self.showProgressBar = false;
            }
            timeleft -= 1;
        }, 1000);
    }

    private clearTimerProgressBar() {
        if (this.progressBarTimer) {
            clearInterval(this.progressBarTimer);
        }
    }

    private notShowProgressBar() {
        this.clearTimerProgressBar();
        this.showProgressBar = false;
    }

    private unsubscribeCheckStatus() {
        if (this.checkStatusSubscription) {
            this.checkStatusSubscription.unsubscribe();
        }
    }

    private checkSuperStatusOnLines(lines: PrepaidLine[]): boolean {
        if (lines && lines.length) {
            const a = lines.filter(line => {
                return line.super_status && (line.super_status === superStatus.active || line.super_status === superStatus.error || line.super_status === superStatus.cancelled || line.super_status === superStatus.rejected);
            });

            return lines.length === a.length;
        }

        return false;
    }

    ngOnDestroy() {
        this.unsubscribeCheckStatus();
        this.orangeStore.dispatch(resetState());
        this.eContratoService.restoreXMLHttpRequest();
    }

    public getTitle() {
        switch (this.type) {
            case orangePrepaidTypes.esim:
                return 'Confirmación eSIM prepago';
            case orangePrepaidTypes.portabilidad:
                return 'Confirmación Portabilidad';
            case orangePrepaidTypes.terminal:
                return 'Confirmación Terminal + SIM';
            case orangePrepaidTypes.alta:
                return 'Confirmación alta prepago';
            default:
                return 'Confirmación alta prepago';
        }
    }

    public getAltaType() {
        switch (this.type) {
            case orangePrepaidTypes.esim:
                return 'eSIM prepago';
            case orangePrepaidTypes.portabilidad:
                return 'Portabilidad';
            case orangePrepaidTypes.terminal:
                return 'Terminal + SIM';
            case orangePrepaidTypes.alta:
                return 'Alta prepago';
            default:
                return 'Alta prepago';
        }
    }

    public getTextColor(status: any): any {
        switch (status) {
            case superStatus.cancelled:
            case superStatus.error:
            case superStatus.rejected:
                return { 'color': 'red' };
            case superStatus.active:
                return { 'color': 'green' };
            default:
                return {};
        }
    }

    public precontractSended() {
        this.precontract = true;
        this.loadingPrecontract = true;
        this.create(true);
        
    }

    public download(){
        if (this.sendEmailTries > 0 && !this.sended && !this.modalShowed) {
            this.openModal('Imprime ahora el documento y entregárselo al cliente. Recuerda que es obligatorio que el cliente disponga de este documento.');
        }
        this.loadingDownload = true;
        this.orangePrepaidHiringService.downloadPre(this.hash)
            .subscribe(data => {
                if (data) {
                    var reader = new FileReader();
                    let self = this;
                    reader.onloadend = function() {
                        try {
                            const file = JSON.parse(reader.result as string);
                            if (file && file.error) {
                                self.errorDownload = true;
                            }
                        } catch (e) {
                            downloadFile(data, 'Resumen_de_contrato.pdf');
                        }
                    };
                    reader.readAsText(data);
                    this.downloaded = true;
                }
                this.loadingDownload = false;
            }, (error: HttpErrorResponse) => {
                this.loadingDownload = false;
                this.errorDownload = true;
                this.downloaded = false;
            }, () => {
            });
    }

    public resetPrecontract(){
        this.precontract = false;
        this.sendEmailTries = 3;
        this.only_send_email = false;
        this.downloaded = false;
        this.sended = false;
        this.modalShowed = false;
    }
    public resetKyc(){
        this.resultKYC = null;
        this.confirmSkipKYC = false;
    }

    public openModal(msg: string){
        this.modalShowed = true;
        this.matDialog.open(AlertDialogComponent, {
            data: {message: msg}
        });
    }

    public goToHome() {
        this.router.navigate(['/']);
    }

    public getRecharge(recharge, decimal): number {
        return Number((recharge === null ? Number(0) : parseFloat(recharge)).toFixed(decimal));
    }

    getArrayMultiLine() {
        return Array(this.configurationFormGroup.controls['esim_number_lines'].value).fill(0);
    }

    openModalDocumentation() {
        const modalRef = this.modalService.open(ModalEContratoComponent, {backdrop: 'static', keyboard: false, size: 'lg', centered: true});
        const userData = this.userDataFormGroup.value;
        modalRef.componentInstance.docid = userData.document_number;
        modalRef.componentInstance.typedocid = userData.document_type.document_type_id;
        modalRef.componentInstance.idPdV = this.pdv.info.idpdv;

        modalRef.result.then((result) => {
            this.receiveOutput(result);
        });      
    }

    receiveOutput(result) {
        console.log('RESULT KYC - Obtenido por DIGO', result);
        
        if(result.code === 'KO') {
            // El componente ha dado error KO, no se continua y se muestra mensaje
            // mensaje -> 'Ha ocurrido un error en la subida de documentación'
            this.openModalErrorKyc(
                'Ha ocurrido un error en la subida de documentación',
                'highlight_off',
                false,
                result
            );
            return
        }
        if(result.code === '2') {
            // Se ha cerrado sin terminar el proceso, no se continua, sin mensaje
            // ¿Hacer algo? Enviar info al back?
            return
        }
        if(result.code === 'OK' && result.document?.authenticity == '00') {
            //El proceso de subida ha ido correctamente, validamos los datos que tenemos con los que llegan
            if(this.validateDocument(result.document) === false) {
                this.openModalErrorKyc(
                    'Los datos introducidos no coinciden con el documento escaneado.',
                    'highlight_off',
                    false,
                    result
                );
                return;
            }
            //documento ok y validacion ok 
            // mostrar icono de ok igual que en la descarga de firma
            this.resultKYC = result;
            this.resultKYC.canContinueProcess = true;
            this.goToBottom();
            return;
        }
        if(result.code === 'OK' && this.notAllowedCodes.includes(result.document?.authenticity) === true) {
            //codigos NO permitidos para continuar con el proceso 10, 12, 13
            //10 -> El documento identificativo no ha pasado la política de seguridad establecida por la compañía, no se puede continuar con el proceso
            //12 -> El documento identificativo está caducado, no se puede continuar con el proceso
            //13 -> Falta mensaje
            let message = null;
            message = message ?? (result.document?.authenticity == '10' ? 'El documento identificativo no ha pasado la política de seguridad establecida por la compañía, no se puede continuar con el proceso.': null);
            message = message ?? (result.document?.authenticity == '12' ? 'El documento identificativo está caducado, no se puede continuar con el proceso.' : null);
            message = message ?? (result.document?.authenticity == '13' ? 'No ha sido posible reconocer el documento de identidad, no se puede continuar con el proceso.' : null);
            this.openModalErrorKyc(
                message ?? '',
                'highlight_off',
                false,
                result
            );
            return;
        }

        if(result.code === 'OK' && this.notAllowedCodes.includes(result.document?.authenticity) === false) {
            //codigos error pero SI permiten continuar con el proceso
            //11 -> No ha sido posible reconocer el documento de identidad, realiza la validación de la identidad del cliente visualmente para decidir si se continúa o no con el proceso.
            //Mensaje generico de error para el resto de codigos
            let message = 'No ha sido posible reconocer el documento de identidad, realiza la validación de la identidad del cliente visualmente para decidir si se continúa o no con el proceso.'
            this.openModalErrorKyc(
                message,
                'error_outline',
                true,
                result
            );
            return;
        }
        //Default options
        this.openModalErrorKyc(
            'Ha ocurrido un error en la subida de documentación',
            'highlight_off',
            false,
            result
        );
    }

    openModalErrorKyc(messageError, iconError, canContinueProcess, _resultKyc) {
        this.orangePrepaidHiringService.sendErrorKYC(this.orderDraftId, _resultKyc).pipe(take(1)).subscribe();
        
        const modalRef = this.modalService.open(ModalKycErrorComponent, {backdrop: 'static', keyboard: false, size: 'lg', centered: true});
        modalRef.componentInstance.messageError = messageError;
        modalRef.componentInstance.iconError = iconError;
        modalRef.componentInstance.canContinueProcess = canContinueProcess;

        modalRef.result.then((result) => {
            this.resultKYC = _resultKyc;
            this.resultKYC.canContinueProcess = canContinueProcess;
            if (canContinueProcess) {
                this.goToBottom();
            } 
        });
    }

    checkIsEsimAndScanDocument(isEsim) {
        if (isEsim === false) {
            return true;
        }

        if (this.resultKYC?.canContinueProcess === true) {
            return true;
        }

        if (this.showSkipKYC && this.userSkipKyC.includes(this.pdv?.info?.username) && this.confirmSkipKYC) {
            return true;
        }

        if (this.permSv.hasPermFromV2(allPermissions.orange.permite_view_kyc_esim) === false) {
            return true;
        }

        if( this.permSv.hasPermFromV2(allPermissions.orange.permite_view_new_esim) === false) {
            return true;
        }

        return false;
    }

    skypKYC() {
        this.confirmSkipKYC = true;
        this.goToBottom();
    }

    showAlertRemember(data, msisdns, type) {
        if(!data.rate_selected?.recharge) {
            return false;
        }
        const recharge = this.getRecharge(data.rate_selected?.recharge, 0);
        if(msisdns.length > 1) {
            this.messageAlertRemember = `Recuerde al cliente que debe recargar <span>${ recharge }€</span> en cada línea para su activación`;
            return true;
        }

        if(data.welcome_pack_selected?.is_esim && data.configuration_form_values.esim_number_lines > 1) {
            this.messageAlertRemember = `Recuerde al cliente que debe recargar <span>${ (type !== 'portabilidad' ? (recharge - data?.configuration_form_values?.balance.toFixed(0)) : recharge - 10) }€</span> en cada línea para su activación`;
            return true;
        }
        if (msisdns.length <= 1 && (type !== 'portabilidad' ? (recharge - data?.configuration_form_values?.balance.toFixed(0)) > 0 : recharge - 10 > 0)) {
            this.messageAlertRemember = `Recuerde al cliente que debe recargar <span>${ (type !== 'portabilidad' ? (recharge - data?.configuration_form_values?.balance.toFixed(0)) : recharge - 10) }€</span> para su activación`;
            return true;
        }
        return false;
    }

    disableButtonSendDocumentation() {
        return false;
        return (this.resultKYC && this.resultKYC.canContinueProcess === true && this.notAllowedCodes.includes(this.resultKYC?.document?.authenticity) === false) || this.confirmSkipKYC;
    }
    validateDocument(document):boolean {
        const userData = this.userDataFormGroup.value;
        //if(this.validateDocumentType(userData.document_type.document_type_id, document.documentData.type) === false) return false;
        let dniBuilder = '-1';
        // Convertir pasaporte español en DNI ya que en el KyC se realiza esta conversion y nos devuelven el DNI
        if(userData.document_type.document_type_id == 4 && userData.nacionality.country_id == 64) {
            let numberDNI = userData.document_number.slice(1,9);
            let letterDNI = generateLetterDNI(numberDNI);
            dniBuilder = `${numberDNI}${letterDNI}`
        }
        if(userData.document_number.toUpperCase( ) !== document.documentData.idNumber.toUpperCase( ) 
        && userData.document_number.toUpperCase( ) !== document.documentData.docNumber.toUpperCase( )
        && dniBuilder !==  document.documentData.idNumber.toUpperCase( )
        && dniBuilder !==  document.documentData.docNumber.toUpperCase( )) {
            return false;
        } 
        //if(this.normalizeTextAndUpperCase(userData.name) !== this.normalizeTextAndUpperCase(document.documentHolder.name) ) return false;
        //if(this.normalizeTextAndUpperCase(userData.first_surname) !== this.normalizeTextAndUpperCase(document.documentHolder.surname1)) return false;
        //if(userData.second_surname !== '' && this.normalizeTextAndUpperCase(userData.second_surname) !== this.normalizeTextAndUpperCase(document.documentHolder.surname2)) return false;
        //if(userData.nacionality.toUpperCase( ) !== document.documentHolder.nationality.toUpperCase( ) ) return false;
        //if(userData.birth_date.isSame( moment(document.documentHolder.birthdate, "DD/MM/YYYY")) === false) return false;
        return true;
    }
    validateDocumentType(userDataDocumentType, documentDataType):boolean {
        const dictionaryEquivalences = {
            type_id_1: ['2', '6'],
            type_id_5: ['1'],
            type_id_4: ['4']
        }
        return dictionaryEquivalences[`type_id_${userDataDocumentType}`].includes(documentDataType);	
    }

    normalizeTextAndUpperCase(text):string {
        text = text.toUpperCase()
        text = text.replace(/[ÁÄÂÀ]/g, 'A');
        text = text.replace(/[ÉËÊÈ]/g, 'E');
        text = text.replace(/[ÍÏÎÌ]/g, 'I');
        text = text.replace(/[ÓÖÔÒ]/g, 'O');
        text = text.replace(/[ÚÜÛÙ]/g, 'U');
        
        text = text.replace(/-/g, ' '); 
        
        return text;
    }
}
