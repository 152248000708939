import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SimyoComponent } from './components/simyo/simyo.component';
import { SimyoIndexComponent } from './components/simyo-index/simyo-index.component';
import { SimyoGuardDraftGuard } from './services/simyo-guard-draft.guard';
import { SimyoListadoActivacionesComponent } from './components/simyo-listado-activaciones/simyo-listado-activaciones.component';
import { SimyoOrderDetailComponent } from './components/simyo-order-detail/simyo-order-detail.component';
import { SimyoRatesListComponent } from './components/simyo-rates-list/simyo-rates-list.component';
import { SimyoDuplicadosSimComponent } from './components/simyo-duplicados-sim/simyo-duplicados-sim.component';
import { SimyoDsListadoComponent } from './components/simyo-duplicados-sim/simyo-ds-listado/simyo-ds-listado.component';
import { SimyoDsDetailComponent } from './components/simyo-duplicados-sim/simyo-ds-detail/simyo-ds-detail.component';
import { SimyoGuardDuplicadosGuard } from './services/simyo-guard-duplicados.guard';
import { SimyoOrderDetailBetaComponent } from './components/simyo-order-detail-beta/simyo-order-detail-beta.component';
import { SimyoMyAddressComponent } from './components/simyo-my-address/simyo-my-address.component';


const routes: Routes = [
	{ path: '', component: SimyoIndexComponent },
	{ path: 'convergente', component: SimyoComponent, canDeactivate: [SimyoGuardDraftGuard] },
    { path: 'solo-movil', component: SimyoComponent, canDeactivate: [SimyoGuardDraftGuard] },
    { path: 'solo-fibra', component: SimyoComponent, canDeactivate: [SimyoGuardDraftGuard] },
    { path: 'listado', component: SimyoListadoActivacionesComponent },
    { path: 'order/:order_id', component: SimyoOrderDetailBetaComponent },
    //{ path: 'order-beta/:order_id', component: SimyoOrderDetailBetaComponent },
    { path: 'tarifas', component: SimyoRatesListComponent },
    { path: 'duplicados', component: SimyoDuplicadosSimComponent, canActivate: [SimyoGuardDuplicadosGuard] },
    { path: 'listado-duplicados', component: SimyoDsListadoComponent, canActivate: [SimyoGuardDuplicadosGuard] },
    { path: 'duplicados/:duplicados_id', component: SimyoDsDetailComponent, canActivate: [SimyoGuardDuplicadosGuard] },
    { path: 'myaddress', component: SimyoMyAddressComponent }

];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class SimyoRoutingModule { }
