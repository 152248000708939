import { Injectable } from '@angular/core';
import { CanActivate, CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SimyoModalSaveComponent } from '../components/simyo-modal-save/simyo-modal-save.component';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SimyoTemporaryClosureComponent } from '../components/simyo-temporary-closure/simyo-temporary-closure.component';
import { allPermissions } from 'src/app/shared/permissions';

export interface CanComponentDeactivate {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
    providedIn: 'root'
})
export class SimyoGuardDraftGuard extends SimyoModalSaveComponent implements CanDeactivate<CanComponentDeactivate> {
    private simyoUrlsConvergenteBlocked = ['/simyo/convergente', '/simyo/solo-fibra'];


    canDeactivate() {
        if (this.preventDestroy) {
            return true;
        }
        //permissions
        if (this.simyoUrlsConvergenteBlocked.includes(this.router.url) && this.permSv.hasPermFromV2(allPermissions.simyo.permite_ventas_convergente) === false) {
            return true
        }

        if (this.draftService.isPersistableOrder(this.order, this.ratesMobile)) {
            const options: NgbModalOptions = {
                backdrop: 'static',
                size: 'lg',
                centered: true,
                windowClass: 'modalAlertChange'
            };

            this.modalService.open(SimyoModalSaveComponent, options);
            return this.draftService.continueNavigation;
        }
        
        return true;
    }

}
