<div class="modal-header">
    <h5 class="modal-title font-weight-bold ml-3 mt-3" id="exampleModalLabel" [class.mb-3]="showAlert">
        {{ showAlert ? '¿Necesitas actualizar los datos de contacto para soporte?' : 'Contacto para soporte' }}
    </h5>

    <button type="button" class="close close-m2" mat-dialog-close="" *ngIf="!isLoading && !showAlert">
        <img src="./assets/img/x-black.svg" width="18" class="close-aviso--1">
    </button>
</div>

<div class="modal-body">
    <div class="container">
        <div class="row justify-content-between" *ngIf="showAlert">
            <div class="col-lg-4">
                <button class="btn btn-black-outline btn-block spinner" mat-dialog-close="">No</button>
            </div>

            <div class="col-lg-4">
                <button class="btn btn-black-outline btn-block spinner" (click)="showAlert = false">
                    <strong>Sí, editar</strong>
                </button>
            </div>
        </div>

        <div class="form-row" [formGroup]="formGroup" *ngIf="!showAlert">
            <div class="col-lg-12">
                <mat-form-field>
                    <mat-label>Teléfono</mat-label>
                    <input type="text" matInput name="supportphone" formControlName="supportphone" autocomplete="nope" placeholder="" maxlength="9" [readonly]="isLoading" (keypress)="numberOnly($event)" />
                    <!-- error -->
                    <mat-error *ngIf="errorHandling('supportphone', 'required')">
                        Campo obligatorio.
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-lg-12">
                <mat-form-field>
                    <mat-label>E-mail</mat-label>
                    <input type="text" matInput name="supportemail" formControlName="supportemail" autocomplete="nope" placeholder="" [readonly]="isLoading" />
                    <!-- error -->
                    <mat-error *ngIf="errorHandling('supportemail', 'required')">
                        Campo obligatorio.
                    </mat-error>
                    <mat-error *ngIf="errorHandling('supportemail', 'pattern')">
                        Formato de e-mail incorrecto.
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-lg-12">
                <mat-form-field>
                    <mat-label>Contacto</mat-label>
                    <input type="text" matInput name="supportcontact" formControlName="supportcontact" autocomplete="nope" placeholder="" [readonly]="isLoading" />
                    <!-- error -->
                    <mat-error *ngIf="errorHandling('supportcontact', 'required')">
                        Campo obligatorio.
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-lg-12" *ngIf="alertType">
                <ngb-alert [type]="alertType" (close)="alertType = null">{{ alertMessage }}</ngb-alert>
            </div>

            <div class="col-lg-12 mt-5 mb-3">
                <button class="btn btn-black-outline btn-block spinner" (click)="validateForm()" [disabled]="isLoading">
                    <span *ngIf="isLoading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    {{ isLoading ? 'Guardando...' : 'Guardar' }}
                </button>
            </div>
        </div>
    </div>
</div>
