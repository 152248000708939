<div>
    <div class="modal-header bg-simyo-grey">
        <h5 class="modal-title white" id="exampleModalLabel">Tipo de {{ showOptionClient ? 'tramitación' : 'retramitación' }}</h5>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">
                <img src="./assets/img/times2.svg" class="img-fluid ico-title" width="20">
            </span>
        </button>
    </div>

    <div class="modal-body" *ngIf="!loading && !errorOrder">
        <div class="form-row p-3 reprocess"
            [ngClass]="{ 'active' : selected.type === typeReProcess.full }" 
            (click)="selected.type = typeReProcess.full; anyRateInvalidOrder(this.order.type)"
            *ngIf="selected.countCanceledProduct && !showOptionClient">
            <div class="pl-5">
                Todos los productos cancelados del pedido{{ anyRateInvalid && selected.type === typeReProcess.full ? '*' : ''}}
            </div>
        </div>
        <div *ngIf="selected.countCanceledProduct > 1 && !showOptionClient">
            <div class="form-row p-3 reprocess" [ngClass]="{ 'active' : selected.type === typeReProcess.partial }" (click)="selected.type = typeReProcess.partial; changeOptions()">
                <div class="pl-5">
                    Algunos de los productos cancelados del pedido{{ anyRateInvalid && selected.type === typeReProcess.partial ? '*' : ''}}
                </div>
            </div>
            <div *ngIf="selected.type === typeReProcess.partial">
                <div class="form-row ml-5 p-3 pl-5 reprocess-square"
                    [ngClass]="{ 'active' : selected.elements.fibra === true }" 
                    *ngIf="order.broadband && order.broadband.rate"
                    (click)="selected.elements.fibra = !selected.elements.fibra; changeOptions()">
                    {{ (fiberIndirectTechnologies.includes(order?.broadband?.access_type) ? 'Fibra IND: ' : 'Fibra: ') + order.broadband.rate.name }}{{ order.broadband.isValidRate ? '' : '*' }}
                </div>
                <div *ngIf="order.mobiles && order.mobiles.length">
                    <div class="form-row ml-5 p-3 pl-5 reprocess-square" 
                        *ngFor="let mobile of order.mobiles; index as i"
                        [ngClass]="{ 'active' : selected.elements.movil[i] === true }" 
                        (click)="selected.elements.movil[i] = !selected.elements.movil[i]; changeOptions()">
                        {{ (i + 1) + 'ª línea móvil'}}: {{ 'Tarifa ' + mobile.rate?.data + ' + ' + mobile.rate?.voice }}{{ mobile?.isValidRate ? '' : '*' }}
                    </div>
                </div>
                <div class="form-row ml-5 p-3 pl-5 reprocess" 
                    [ngClass]="{ 'active' : selected.elements.cliente === true }"
                    [hidden]="true">
                    Cliente
                </div>
            </div>
        </div>
        <button class="btn btn-simyo-o2 btn-sm w-100 mt-2"
            *ngIf="selected.type === typeReProcess.full"
            (click)="copyOrderFull()">
            Iniciar nueva tramitación{{ anyRateInvalid ? '*' : ''}}
        </button>
        <ng-container *ngIf="selected.type === typeReProcess.partial">
            <button class="btn btn-simyo-o2 btn-sm w-100 mt-2"
                *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_vender_broadband) && showConvergente"
                (click)="copyOrderPartial(orderType.CONVERGENT)">
                Iniciar nueva tramitación{{ anyRateInvalid ? '*' : ''}}
            </button>
            <button class="btn btn-simyo-o2 btn-sm w-100 mt-2"
                *ngIf="showMovil"
                (click)="copyOrderPartial(orderType.MOBILE)">
                Iniciar nueva tramitación{{ anyRateInvalid ? '*' : ''}}
            </button>
            <button class="btn btn-simyo-o2 btn-sm w-100 mt-2"
                *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_vender_broadband) && showFibra"
                (click)="copyOrderPartial(orderType.BROADBAND)">
                Iniciar nueva tramitación{{ order.broadband.isValidRate ? '' : '*' }}
            </button>
            <button class="btn btn-simyo-o2 btn-sm w-100 mt-2"
                *ngIf="!showConvergente && !showMovil && !showFibra"
                [disabled]="true">
                Iniciar nueva tramitación
            </button>
        </ng-container>
        <ng-container *ngIf="showOptionClient">
            <div class="form-row p-3" (click)="selected.type = typeReProcess.onlyClient; showConvergente = true; showMovil = true; showFibra = true">
                <div>
                    Nueva tramitación copiando los datos de este cliente
                </div>
            </div>
            <div class="row mx-0 mt-3" style="justify-content: center;">
                <div class="col-lg-4" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_vender_broadband) && showConvergente">
                    <button class="btn-black-outline btn-block mb-1 pt-3 pb-3" (click)="copyOrderPartial(orderType.CONVERGENT)">
                        Fibra y móvil
                    </button>
                </div>
                <div class="col-lg-4" *ngIf="showMovil">
                    <button class="btn-black-outline btn-block mb-1 pt-3 pb-3" (click)="copyOrderPartial(orderType.MOBILE)">
                        Móvil
                    </button>
                </div>
                <div class="col-lg-4" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_vender_broadband) && showFibra">
                    <button class="btn-black-outline btn-block mb-1 pt-3 pb-3" (click)="copyOrderPartial(orderType.BROADBAND)">
                        Fibra
                    </button>
                </div>
            </div>
        </ng-container>
        <div class="mt-1" *ngIf="anyRateInvalid && (selected.type === typeReProcess.full || selected.type === typeReProcess.partial)">
            *La tarifa de algunos productos ya no está disponible
        </div>
    </div>
    <div *ngIf="loading && !errorOrder" class="mt-2 mb-5">
        <h5 align="center" class="tar-name-preload">{{ loadingText }}</h5>

        <div align="center">
            <img class="" src="./assets/img/spinner-simyo.svg" width="30" alt="">
        </div>
    </div>

    <div *ngIf="errorOrder" class="form-row p-3">
        Error al obtener los datos del cliente. Por favor, inténtalo de nuevo más tarde
    </div>
</div>
