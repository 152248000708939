<div class="card mb-3">
    <div class="card-header bg-rm">
        <div class="d-flex justify-content-between align-items-center">
            <div>
                <h6 class="subtitulo-pack2"><b>Cliente</b></h6>
            </div>
        </div>
    </div>

    <div class="card-body bg-white">
        <div class="row mt-4">
            <ng-container *ngIf="!isDetail">
                <div class="col-lg-8">
                    <!-- Nombre cliente -->
                    <h5 class="title-list ml-3">
                        <span class="text-list">
                            <b>{{ getName() }}</b>
                        </span>
                        <br><br>
                        <!-- Contacto -->
                        <span class="font-list">
                            <img src="./assets/img/phone.svg" class="img-fluid" width="13">
                            Nº de contacto: <b>{{ customer.phone_number }}</b>
                        </span>
                        <br>
                        <!-- Tipo de alta -->
                        <span class="font-list">
                            <img src="./assets/img/package2.svg" class="img-fluid" width="13">
                            Tipo de alta: <b>{{getTypeDischarge()}}
                            <b class="info-secundaria" *ngIf="mobiles.length > 1">
                                + {{ mobiles.length - 1 }} línea(s) adicional(es)
                            </b>
                        </b>
                        </span>
                        <!-- Linea movil -->
                        <ng-container *ngIf="mobiles && mobiles.length && mobiles[0].msisdn">
                            <br>
                            <!-- MSISDN -->
                            <span class="font-list">
                                <img src="./assets/img/file-black.svg" class="img-fluid" width="13">
                                Línea móvil: <b>{{ mobiles[0].msisdn }}</b>
                            </span>
                        </ng-container>
                        <br>
                        <!-- Fecha de solicitud -->
                        <span class="font-list">
                            <img src="./assets/img/clock.svg" class="img-fluid" width="13">
                            Fecha de solicitud: <b>{{date | date:'dd/MM/yyyy':'UTC+1'}}</b>
                        </span>
                        <br>
                        <!-- Documento de identidad -->
                        <span class="font-list">
                            <img src="./assets/img/file-black.svg" class="img-fluid" width="13">
                            {{ getDocumentLabel(customer.document_type_id) }}: <b>{{ customer.document }}</b>
                        </span>
                        <br>
                        <!-- Nº de contrato -->
                        <span class="font-list">
                            <img src="./assets/img/package2.svg" class="img-fluid" width="13"> Nº de contrato: <b>{{getContractNumber()}}</b>
                        </span>
                        <br>
                    </h5>
                </div>
            </ng-container>
            <ng-container *ngIf="isDetail">
                <div class="col-lg-8">
                    <!-- Nombre cliente -->
                    <h5 class="title-list ml-3">
                        <b *ngIf="is_contingency" ngbTooltip="Alta por soporte">
                            <img src="./assets/img/support.png" alt="Alta por soporte" class="img-fluid mr-2" width="24" style="height: auto; top: -1px; position: relative;">
                        </b>
                        <span class="text-list">
                            <b>{{ getName() }}</b>
                        </span>
                        <br><br>
                        <!-- Documento de identidad -->
                        <span class="font-list">
                            <img src="./assets/img/file-black.svg" class="img-fluid" width="13">
                            {{ getDocumentLabel(customer.document_type_id) }}: <b>{{ customer.document }}</b>
                        </span>
                        <br>
                        <!-- Fecha de solicitud -->
                        <span class="font-list">
                            <img src="./assets/img/clock.svg" class="img-fluid" width="13">
                            Fecha de solicitud: <b>{{date | date:'dd/MM/yyyy, HH:mm':'UTC+1'}}</b>
                        </span>
                        <br>
                        <!-- Tipo de alta -->
                        <span class="font-list">
                            <img src="./assets/img/package2.svg" class="img-fluid" width="13">
                            Tipo de alta: <b>{{getTypeDischarge()}}
                            <b class="info-secundaria" *ngIf="mobiles.length > 1">
                                + {{ mobiles.length - 1 }} línea(s) adicional(es)
                            </b>
                        </b>
                        </span>
                    </h5>
                </div>
            </ng-container>
            <div class="col-lg-4 text-right">
                <div class="col-lg-12 mb-2">
                    <span class="badge badge-pill badge-simyo" *ngIf="(typeOrder === orderType.CONVERGENT || typeOrder === orderType.BROADBAND) && rateFtth">
                        <span>
                            {{ (fiberIndirectTechnologies.includes(broadband?.access_type) ? 'Fibra IND ' : 'Fibra ') + rateFtth.name + ': ' + ((rateFtth.rate_info?.price ?? rateFtth.rate_info?.contracted_price)|toFixedDecimals) }} €
                        </span>
                    </span>
                </div>
                <div class="col-lg-12 mb-2" *ngFor="let mobile of mobiles; index as i">
                    <ng-container *ngIf="mobile.rate">
                        <ng-template [ngIf]="mobile.rate">
                            <span class="badge badge-pill badge-simyo">
                                {{ mobile.rate?.data + ' + ' + mobile.rate?.voice + ': ' + (simyoGlobals.getMobilePrice(mobile)|toFixedDecimals) }} €
                            </span>
                        </ng-template>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
