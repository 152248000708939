<div #filling></div>

<nav #navbar id="navbar" class="navbar-rm navbar navbar-expand-lg bg-dark">
    <div class="container">
        <div class="col-md-12">
            <div class="row">
                <a class="navbar-brand" routerLink="republica-movil">
                    <img src="./assets/img/rm.svg" class="img-fluid icon-nav2" width="110">
                </a>
                <div class="navbar-nav align-items-center mr-auto" [ngSwitch]="href">
                    <span class="navbar-text contratacion" *ngSwitchCase="'/republica-movil/listado'">
                        Listado de operaciones
                    </span>
                </div>

                <div class="my-2 my-lg-0 mr-0 d-flex align-items-center">
                    <div class="row">

                        <div class="col-auto d-flex align-items-center">
                            <div class="cursor-pointer mr-0 d-flex" (click)="openModalHelp(modalHelp)">
                                <img src="./assets/img/help.svg" class="img-fluid icon-nav2 icon-header-help" width="24">
                            </div>
                        </div>

                        <div class="col-auto d-flex align-items-center" *ngIf="!href.includes('/republica-movil/order')">
                            <a routerLink="/" class="btn btn-white btn-sm">Volver al inicio</a>
                        </div>

                        <div class="col-auto d-flex align-items-center" *ngIf="href.includes('/republica-movil/order')">
                            <a (click)="goBack()" class="btn btn-white btn-sm">Volver al listado</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</nav>


<ng-template #modalHelp let-modal>
    <div class="modal-header">
        <h5 class="modal-title white" id="exampleModalLabel">Soporte</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">
                <img src="./assets/img/times2.svg" class="img-fluid ico-title" width="20">
            </span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row p-2">
            <div class="col-lg-12">
                <span>Puede ponerse en contacto con soporte a través del siguiente número de teléfono: 900 900 537</span>
                <br>
                <span>Horario de atención:</span>
                <ul>
                    <li>Lunes a viernes: 8:30 - 22:30</li>
                    <li>Fines de semana y festivos: 10:00 - 20:00</li>
                </ul>
            </div>
        </div>
    </div>
</ng-template>
