<div #filling></div>

<nav #navbar id="navbar" class="navbar navbar-expand-lg bg-simyo">
    <div class="container">
        <!-- Marca -->
        <a class="navbar-brand" routerLink="/">
            <img src="./assets/img/or-login-c.svg" class="img-fluid icon-nav2" width="130" style="margin-left: -15px;">
        </a>
        <div class="navbar-nav align-items-center mr-auto" [ngSwitch]="href">
            <!-- Proceso de contratación -->
            <span class="navbar-text contratacion" *ngSwitchDefault>
                Prepago
            </span>
            <span class="navbar-text contratacion" *ngSwitchCase="'/orange/prepago/alta'">
                Prepago - Alta
            </span>
            <span class="navbar-text contratacion" *ngSwitchCase="'/orange/prepago/portabilidad'">
                Prepago - Portabilidad
            </span>
            <span class="navbar-text contratacion" *ngSwitchCase="'/orange/prepago/esim'">
                Prepago - {{permSv.hasPermFromV2(allPermissions.orange.permite_view_new_esim) ? 'Activación ': 'Alta '}}eSIM
            </span>
            <span class="navbar-text contratacion" *ngSwitchCase="'/orange/bonos-adicionales'">
                Bonos adicionales
            </span>
            <span class="navbar-text contratacion" *ngSwitchCase="'/orange/bonos-adicionales-listado'">
                Listado de operaciones
            </span>
            <span class="navbar-text contratacion" *ngSwitchCase="'/orange/prepago-listado'">
                Listado de operaciones
            </span>
        </div>

        <div class="my-2 my-lg-0">
            <span class="carrito" (click)="openShoppingCart()" *ngIf="(href === '/orange/bonos-adicionales' || href.startsWith('/orange/prepago/')) && !hiddeShoppingCart">
                <i-feather name="shopping-cart" class="icon-cart"></i-feather>
                <div class="item">
                    <!-- Total de carrito -->
                    <span class="badges badge-pill badge-light carrito-result">
                        <b>{{ (+(orangeAdditionalBonos$ | async)?.shopping_cart_total || (orangePrepaid$ | async)?.shopping_cart_total).toFixed(2) }}<span class="eu"> €</span></b>
                    </span>
                </div>
            </span>

            <a routerLink="" *ngIf="!href.includes('/orange/bonos-adicionales-order') && !href.includes('/orange/prepago-order')" class="btn btn-white btn-sm">
                Volver al inicio
            </a>
            <a (click)="goBack()" *ngIf="href.includes('/orange/bonos-adicionales-order')" class="btn btn-white btn-sm">
                Volver al listado
            </a>
            <a (click)="goBackPrepaid()" *ngIf="href.includes('/orange/prepago-order')" class="btn btn-white btn-sm">
                Volver al listado
            </a>
        </div>
    </div>
</nav>
