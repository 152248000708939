
<div class="container" *ngIf="showProgressBar">
    <div class="row">
        <div class="col-lg-8 offset-2">
            <section class="centered--carga">
                <h3 class="font-weight-bold" align="center">
                    Activando bono adicional
                </h3>
                <p class="text-center">
                    Manténgase a la espera, este proceso puede tardar varios segundos.
                </p>
                <br>

                <!-- Barra de carga -->
                <div class="barra--carga">
                    <div id="load-bar" class="move"></div>
                </div>
                <!-- ./ Barra de carga -->
            </section>
        </div>
    </div>
</div>

<ng-container *ngIf="!showProgressBar">
    <div class="container content">
        <div class="row">
            <div class="col-md-12">
                <h5 class="title-step" align="center">
                    <img src="./assets/img/credit-card.svg" class="img-fluid ico-title" width="20">
                    Confirmación del bono adicional
                </h5>
                <!-- Notificación -->
                <div class="alert" [ngClass]="{'alert-danger': showErrorOrder || (orange$ | async)?.orderInfo?.super_status === superStatus.error || (orange$ | async)?.orderInfo?.super_status === superStatus.cancelled, 'alert-success': (orange$ | async)?.orderInfo?.super_status === superStatus.active, 'alert-warning': (orange$ | async)?.orderInfo?.super_status === superStatus.in_process }" role="alert">
                    <h4 class="alert-heading mt-2">
                        <b *ngIf="!showErrorOrder">
                            {{ (orange$ | async)?.orderInfo?.status_title }}
                        </b>
                        <b *ngIf="showErrorOrder">
                            Fallo en la tramitación
                        </b>
                    </h4>
                    <p *ngIf="!showErrorOrder">
                        {{ (orange$ | async)?.orderInfo?.status_description }}
                    </p>
                    <p *ngIf="showErrorOrder">
                        {{ showErrorOrderMsg || 'No se ha podido realizar la tramitación. Por favor, inténtelo de nuevo más tarde.' }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <!--------- Resumen de la tramitación ------------->
    <!-------------------------------------------------->

    <div class="container firma mt-3 mb-5">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card mb-3">
                            <div class="card-header">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div>
                                        <h6 class="subtitulo-pack2">
                                            <b>Cliente</b>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body bg-white">
                                <div class="row mt-4">
                                    <div class="col-lg-9">
                                        <!-- Nombre cliente -->
                                        <h5 class="title-list ml-3">
                                            <a class="text-list">
                                                <b>{{ (orange$ | async)?.configuration_form_values?.customer_info?.first_name + ' ' + (orange$ | async)?.configuration_form_values?.customer_info?.last_name }}</b>
                                            </a>
                                            <br><br>
                                            <!-- Documento de identidad -->
                                            <span class="font-list">
                                                <img src="./assets/img/file.svg" class="img-fluid" width="13">
                                                {{ ((orange$ | async)?.configuration_form_values?.customer_info?.document_type_name && (orange$ | async)?.configuration_form_values?.customer_info?.document_type_name !== '') ? (orange$ | async)?.configuration_form_values?.customer_info?.document_type_name + ': ' : '' }}<b>{{ (orange$ | async)?.configuration_form_values?.customer_info?.document_number }}</b>
                                            </span>
                                            <br>
                                            <!-- Msisdn -->
                                            <span class="font-list">
                                                <img src="./assets/img/phone.svg" class="img-fluid" width="13">
                                                Msisdn: <b>{{ (orange$ | async)?.configuration_form_values?.msisdn }}</b>
                                            </span>
                                            <br>
                                            <!-- Fecha de solicitud: -->
                                            <ng-container *ngIf="(orange$ | async)?.orderInfo?.created_at && (orange$ | async)?.orderInfo?.created_at !== ''">
                                                <span class="font-list">
                                                    <img src="./assets/img/clock.svg" class="img-fluid" width="13">
                                                    Fecha de solicitud: <b>{{ (orange$ | async)?.orderInfo?.created_at | date: 'dd/MM/yyyy, HH:mm' }}</b>
                                                </span>
                                                <br>
                                            </ng-container>
                                            <!-- Fecha de activacion: -->
                                            <ng-container *ngIf="(orange$ | async)?.orderInfo?.activation_date && (orange$ | async)?.orderInfo?.activation_date !== ''">
                                                <span class="font-list">
                                                    <img src="./assets/img/clock.svg" class="img-fluid" width="13">
                                                    Fecha de activación: <b>{{ (orange$ | async)?.orderInfo?.activation_date | date: 'dd/MM/yyyy, HH:mm' }}</b>
                                                </span>
                                                <br>
                                            </ng-container>
                                        </h5>
                                    </div>
                                    <div align="right" class="col-lg-3">
                                        <div class="col-lg-12 mb-2">
                                            <span class="badge badge-pill badge-simyo font-list">
                                                {{ (orange$ | async)?.configuration_form_values?.rate?.name + ': ' + (+(orange$ | async)?.configuration_form_values?.rate?.price).toFixed(2) }} €
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div>
                                        <h6 class="subtitulo-pack2 ml-3">
                                            <img src="./assets/img/file-text-negro.svg" class="img-fluid ico-title mr-1" width="13">
                                            <b>Resumen de la tramitación</b>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        
                            <div class="bg-white">
                                <div class="row">
                                    <div class="col-md-12">
                                        <ul class="list-group list-group-flush">
                                            <li class="list-group-item cuerpo-pack-2">
                                                <!-- RESUMEN MOVIL PACK -->
                                                <h5 align="left" class="tar-name-7 mt-2 ml-3">
                                                    <b>Conceptos</b>
                                                </h5>
                                                
                                                <div class="resumen d-flex justify-content-between align-items-center">
                                                    <span class="sidebar-info4 simyo">
                                                        Bono adicional
                                                    </span>
                                                    <small class="text-muted"></small>
                                                </div>
                                                <div class="resumen d-flex justify-content-between align-items-center">
                                                    <!-- MSISDN -->
                                                    <span class="sidebar-info5">
                                                        <img src="./assets/img/phone.svg" class="img-fluid" width="13">
                                                        Msisdn: <b>{{ (orange$ | async)?.configuration_form_values?.msisdn }}</b>
                                                    </span>
                                                </div>
                                                <div class="resumen d-flex justify-content-between align-items-center">
                                                    <!-- tarifa -->
                                                    <span class="sidebar-info5">
                                                        <img src="./assets/img/package2.svg" class="img-fluid" width="13">
                                                        {{ (orange$ | async)?.configuration_form_values?.rate?.name }}
                                                    </span>
                                                    <small class="text-muted">
                                                        <!-- Precio tarifa -->
                                                        <span class="sidebar-info3">
                                                            <b>{{ (+(orange$ | async)?.configuration_form_values?.rate?.price).toFixed(2) }} €</b>
                                                        </span>
                                                    </small>
                                                </div>
                                                <br>
                                                <ng-container *ngIf="+(orange$ | async)?.configuration_form_values?.rate?.price > 0">
                                                    <div class="resumen d-flex justify-content-between align-items-center mt-2">
                                                        <span class="sidebar-info">
                                                            <b>Total</b>
                                                        </span>
                                                        <small class="text-muted trash-carrito">
                                                            <!-- Precio tarifa -->
                                                            <span class="sidebar-info2">
                                                                <b>{{ (+(orange$ | async)?.configuration_form_values?.rate?.price).toFixed(2) }} €</b>
                                                            </span>
                                                        </small>
                                                    </div>
                                                    <hr>
                                                    <!-- Importe Total -->
                                                    <div class="resumen-total total d-flex justify-content-between align-items-center">
                                                        <h5 class="total-color">
                                                            <b>Pago total</b>
                                                        </h5>
                                                        <h5 class="text-auto">
                                                            <b>{{ (+(orange$ | async)?.configuration_form_values?.rate?.price).toFixed(2) }} €</b>
                                                        </h5>
                                                    </div>
                                                    <div class="resumen-total total d-flex justify-content-between align-items-center">
                                                        <span class="total-color">
                                                            <b>IVA incluido</b>
                                                        </span>
                                                    </div>
                                                </ng-container>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--------- ACTIVAR SERVICIOS ------------>
    <!---------------------------------------->

    <div class="container firma">
        <b style="color: red;" *ngIf="formGroup.get('correctBalance').invalid">
            *Saldo insuficiente para el bono seleccionado
        </b>
        <br>
        <div class="row mt-2">
            <div class="col-md-12">
                <button (click)="create()" *ngIf="showCreateButton && !showErrorOrder" class="btn btn-simyo-o2 btn-block mb-5 pt-3" style="padding-bottom: 20px !important;" [disabled]="loadingBalance">
                    <b>{{ loadingBalance ? 'Verificando pedido' : 'Tramitar solicitud' }}  <span *ngIf="loadingBalance" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span></b>
                </button>
            </div>
        </div>
    </div>
</ng-container>