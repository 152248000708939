
<div class="jumbotron-form2 mt-3" [ngClass]="{ 'error' : errorForm }" style="min-height: 50px;">
    <div class="d-flex justify-content-between align-items-center">
        <div>
            <span class="label-ad-text2">Dirección de entrega</span>
            <br>
            <!-- Dirección -->
            <span class="label-ad-text">{{ confirmedAddress }}</span>
        </div>
        <small class="text-muted btn-muted">
            <button class="btn btn-simyo-a btn-sm" role="button" (click)="open(content)">
                {{ confirmedAddress && confirmedAddress !== '' ? 'Modificar' : 'Añadir'}}
            </button>
        </small>
    </div>
</div>

<ng-template #content let-modal>
    <div class="modal-header bg-simyo-grey">
        <h5 class="modal-title white" id="exampleModalLabel">Entrega</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true"><img src="./assets/img/times2.svg" class="img-fluid ico-title" width="20"></span>
        </button>
    </div>

    <div class="modal-body" id="bodyModal">
        <div class="container">
            <form [formGroup]="formGroup">
                <div class="row">
                    <div class="col-lg-12">
                        <h6 class="subtitulo-pack-form">Tipo de entrega</h6>
                        <div class="radio-btn-group">
                            <div class="radio">
                                <input id="dp-client" type="radio" name="delivery-price" [value]="3"
                                       (click)="changeDeliveryPrice($event.target.value)" [checked]="type === 3"/>
                                <label for="dp-client">
                                    Enviar a la dirección de contacto
                                    <!-- <b>{{ anyRegistration ? '9,00 €' : '3,00 €' }}</b> -->
                                </label>
                            </div>
                            <div class="radio">
                                <input id="dp-msg" type="radio" name="delivery-price" [value]="2"
                                       (click)="changeDeliveryPrice($event.target.value)" [checked]="type === 2"/>
                                <label for="dp-msg">Enviar a otra dirección
                                    <!-- <b>{{ anyRegistration ? '9,00 €' : '3,00 €' }}</b> -->
                                </label>
                            </div>
                        </div>

                        <div class="bb-line"></div>

                        <div class="form-row" *ngIf="type === 2">

                            <div class="col-lg-6 mt-1">
                                <mat-form-field>
                                    <mat-label>Nombre de la vía*</mat-label>
                                    <input matInput matGoogleMapsAutocomplete type="text" name="address" formControlName="address"
                                        [types]="['address']"
                                        [country]="country"
                                        (onAutocompleteSelected)="onAutocompleteSelected($event)">
                                </mat-form-field>
                            </div>

                            <div class="col-lg-1 mt-1">
                                <mat-form-field>
                                    <mat-label>Número*</mat-label>
                                    <input type="text" matInput formControlName="number">
                                </mat-form-field>
                            </div>

                            <div class="col-lg-2 mt-1">
                                <mat-form-field>
                                    <mat-label>Planta</mat-label>
                                    <input type="text" matInput formControlName="floor">
                                </mat-form-field>
                            </div>

                            <div class="col-lg-1 mt-1">
                                <mat-form-field>
                                    <mat-label>Puerta</mat-label>
                                    <input type="text" matInput formControlName="door">
                                </mat-form-field>
                            </div>

                            <div class="col-lg-2 mt-1">
                                <mat-form-field>
                                    <mat-label>Escalera</mat-label>
                                    <input type="text" matInput formControlName="stairway">
                                </mat-form-field>
                            </div>

                            <div class="col-lg-3 mt-1">
                                <mat-form-field>
                                    <mat-label>Código Postal*</mat-label>
                                    <input type="number" matInput formControlName="zip" (input)="onChangeZip($event.target.value)" pattern="\d+" min="0" max="99999">
                                </mat-form-field>
                            </div>

                            <div class="col-lg-5 mt-1">
                                <mat-form-field>
                                    <mat-label>Localidad*</mat-label>
                                    <input type="text" matInput formControlName="city">
                                </mat-form-field>
                            </div>

                            <div class="col-lg-4 mt-1">
                                <mat-form-field>
                                    <mat-label>Provincia</mat-label>
                                    <select matNativeControl formControlName="province">
                                        <option *ngFor="let province of provinces" [value]="province.code">
                                            {{province.code|titlecase}}
                                        </option>
                                    </select>
                                </mat-form-field>
                            </div>
                        </div>

                        <!-- Confirmación -->

                        <div class="jumbotron-norm mb-4">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="p-2 bd-highlight cong-tienda">
                                    <h6 class="pdv-nombre">
                                        <img src="./assets/img/credit-card.svg" class="img-fluid ico-title" width="17">
                                        Precio del servicio de entrega
                                    </h6>
                                </div>
                                <small class="text-muted">
                                    <h6 class="pdv-nombre verde mt-2">
                                        <!-- <b>{{ deliveryPrice == 0 ? 'GRATIS' : deliveryPrice + ",00€" }}</b> -->
                                        <b>GRATIS</b>
                                    </h6>
                                </small>
                            </div>
                        </div>

                        <div class="mb-3" align="center">
                            <br><br>
                            <button type="button" class="btn btn-simyo-a btn-sm" (click)="confirmDelivery()">
                                Confirmar entrega
                            </button>
                        </div>

                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>
