import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, Subject, merge, of } from 'rxjs';
import { delay, switchMap, tap, catchError, shareReplay, pluck, map, skipWhile } from 'rxjs/operators';
import { PointsService } from 'src/app/home/services/points.service';
import { tabHome } from 'src/app/shared/constantes';
import { snakeToCamel } from 'src/app/utils/convertKeysObject';

@Component({
    selector: 'app-home-points',
    templateUrl: './home-points.component.html',
    styleUrls: ['./home-points.component.scss'],
})
export class HomePointsComponent implements OnInit {
    @Input() content = 'points';
    @Input() pdv;
    @Output() onGoToRedeemPoints = new EventEmitter();
    @Output() onUpdatePoints = new EventEmitter();

    public points$: Observable<any>;
    public rewards$: Observable<any>;
    public reloadPoints$$: Subject<any> = new Subject();
    public reloadRewards$$: Subject<any> = new Subject();
    public statusPoints$: BehaviorSubject<string> = new BehaviorSubject(
        'initial'
    );
    public statusRewards$: BehaviorSubject<string> = new BehaviorSubject(
        'initial'
    );
    public availablePoints = null;
    public subscribePoints = null;
    constructor(
        private route: ActivatedRoute,
        private pointsService: PointsService
    ) {
        const route$ = this.route.queryParams.pipe(delay(0));
        const reload$ = route$.pipe(switchMap((data) => this.reloadPoints$$));

        this.points$ = merge(route$, reload$).pipe(
            tap(() => this.statusPoints$.next('loading')),
            switchMap(() =>
                this.pointsService.getPdvPoints().pipe(
                    tap(
                        () => this.statusPoints$.next('initial'),
                        catchError((err) => {
                            this.statusPoints$.next('error');
                            return of({});
                        })
                    )
                )
            ),
            pluck('msg'),
            map((data: any) => snakeToCamel(data)),
            map((data: any) => {
                for (let clave in data) {
                    if (data[clave] === null) {
                        data[clave] = 0;
                    }
                }
                this.availablePoints = data.pointsAvailable;
                return data;
            }),
            tap(() => this.reloadRewards$$.next()),
            tap((points) => this.onUpdatePoints.emit(points)),
            shareReplay()
        );

        this.rewards$ = this.reloadRewards$$.pipe(
            skipWhile(() => this.availablePoints === null),
            tap(() => this.statusRewards$.next('loading')),
            switchMap(() =>
                this.pointsService.getProducts().pipe(
                    tap(
                        () => this.statusRewards$.next('initial'),
                        catchError((err) => {
                            this.statusRewards$.next('error');
                            return of({});
                        })
                    )
                )
            ),
            pluck('msg'),
            pluck('products'),
            map((data: any) => snakeToCamel(data)),
            map((rewards) => {
                return rewards.map((reward) => {
                    let amountsAvailables = reward.amountsAvailables.map(
                        (amount) => {
                            let checkPoints =
                                this.availablePoints - amount.points;
                            return {
                                ...amount,
                                available: checkPoints >= 0,
                                tooltip: `Canjeo por ${
                                    amount.points
                                } puntos: te  ${
                                    checkPoints >= 0 ? 'sobran' : 'faltan'
                                }  ${this.getAbsoluteValue(checkPoints)}`,
                            };
                        }
                    );
                    return {
                        ...reward,
                        title: `Tarjeta regalo de ${reward.name}`,
                        amountsAvailables,
                        disableButtonRedeem:
                            this.availablePoints - reward.minPoints < 0,
                    };
                });
            }),
            shareReplay()
        );
    }

    ngOnInit(): void {
    }

    getAbsoluteValue(value) {
        return Math.abs(value);
    }

    changeContent(_content) {
        this.content = _content;
        if (_content == 'points') {
            setTimeout(() => {
                this.reloadPoints$$.next({
                    tab: {
                        textLabel: tabHome.PUNTOS
                    }
                });
            },100);
        }
    }

    navigateToCreateOrder(reward, amount = null) {
        if (amount != null && amount.available === false) return;
        if (reward.disableButtonRedeem) return;
        this.onGoToRedeemPoints.emit({
            availablePoints: this.availablePoints,
            reward,
            amount
        });
    }

    showView() {
        return (
            this.pdv.info.points_terms !== null &&
            JSON.parse(this.pdv.info.points_terms)?.conditions === true
        );
    }
}
