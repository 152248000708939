import { TerminalModel } from "./terminal-model";

export class Mobile {
    // News
    rate_id: string;
    contract_type: string;
    principal: boolean;
    new_iccid: string;
    rate?: any; // aux
    msisdn: string;
    old_iccid: string;
    old_contract_type: string;
    terminal_oid_id: number;
    service_provider_id: string;
    processing_date: string;
    //bonos: number[];
    portability?: any;
    new_msisdn?: any;
    price?: any;
    is_esim: any;
    front_main_line: boolean;
    customer_donor: any;
    is_main_line: boolean;
    terminal?: TerminalModel;
    discount?: any;
    total_without_discount?: number;
    total_line?: number;
    recharge?: number;
    change_owner?: boolean;
    portability_donor?: boolean;
}
