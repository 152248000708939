<section class="procesos">
    <div class="row" *ngIf="!orderListPdvError">
        <div class="col-xl-12">
            <div class="card--sidebar mt-2">
                <div class="card-body">
                    <div class="row">
                        <div class="row col-xl-6" [formGroup]="customFilterFormGroup">
                            <div class="col-xl-12">
                                <mat-form-field>
                                    <mat-label>
                                        <i-feather name="search" class="icon--fe" stroke-width="2.5"></i-feather>
                                        <b> Buscador</b> <span class="search--cliente"> (Nombre / DNI / Nº de teléfono)</span>
                                    </mat-label>
                                    <input matInput formControlName="custom_search" [attr.disabled]="loaded ? 'disabled' : null" (keyup.enter)="search()">
                                    <mat-icon matSuffix (click)="search()" class="cursor-pointer">arrow_right_alt</mat-icon>
                                </mat-form-field>
                            </div>
                            <div class="row" style="padding-left: 30px;">
                                <div style="align-self: center;">
                                    <mat-slide-toggle formControlName="statuses" class="mr-4" (change)="changeFilter()">
                                        Finalizadas
                                    </mat-slide-toggle>
                                </div>
                                <div class="col">
                                    <mat-slide-toggle formControlName="date" (change)="changeFilter()">
                                        Solicitudes mes actual
                                    </mat-slide-toggle>                                
                                    <mat-slide-toggle formControlName="activationDate" (change)="changeFilterActivation($event.checked); changeFilter()">
                                        Activaciones mes actual
                                    </mat-slide-toggle>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 row mx-0 text-center border-left justify-content-center" [formGroup]="filterBrandFormGroup">
                            <div class="col-xl-12 btn-group" style="align-items: center; margin-top: 10px; padding-bottom: 10px;">
                                <!-- <label style="margin-left: 6px;" *ngIf="permSv.hasPermFromV2(allPermissions.jazztel.permite_ventas) || permSv.hasPermFromV2(allPermissions.jazztel.tuvo_activaciones)">
                                    <input type="checkbox" [formControlName]="brands.jazztel" [value]="brands.jazztel"
                                        (change)="changeFilterBrand($event.target.value, $event.target.checked)" [attr.disabled]="loaded ? 'disabled' : null"/>
                                    <span class="btn-marca">
                                        <img src="./assets/img/jz-login-color.svg" class="img-fluid pl-1" width="120">
                                    </span>
                                </label> -->
                                <label style="margin-left: 6px;" *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_sell_bonos_aditional)">
                                    <input type="checkbox" [formControlName]="brands.orange" [value]="brands.orange"
                                        (change)="changeFilterBrand($event.target.value, $event.target.checked)" [attr.disabled]="loaded ? 'disabled' : null"/>
                                    <span class="btn-marca">
                                        <img src="./assets/img/or-login-color.svg" class="img-fluid pl-1" width="120">
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12" *ngIf="orderListPdv">
                    <div class="card--sidebar mt-2 mb-2">
                        <div class="card-body">
                            <table mat-table [dataSource]="dataSource" *ngIf="dataSource.length" multiTemplateDataRows>
                                <!-- Nombre y documento de identidad -->
                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef class="header-column"> Nombre </th>
                                    <td mat-cell *matCellDef="let element">
                                        <div class="link-black-factura font-weight-bold">
                                            {{element.name}}
                                        </div>
                                        <div style="font-size: 12px;">
                                            {{element.document_type && element.document_type !== '' ? element.document_type + ':' : ''}} {{element.document_number}}
                                        </div>
                                    </td>
                                </ng-container>

                                <!-- Documento de identidad -->
                                <ng-container matColumnDef="document_number">
                                    <th mat-header-cell *matHeaderCellDef class="header-column"> Nº de documento </th>
                                    <td mat-cell *matCellDef="let element"> {{element.document_number}} </td>
                                </ng-container>

                                <!-- Nº de contrato -->
                                <ng-container matColumnDef="contract_number">
                                    <th mat-header-cell *matHeaderCellDef class="header-column"> Nº de contrato </th>
                                    <td mat-cell *matCellDef="let element"> {{element.contract_number}} </td>
                                </ng-container>

                                <!-- Tipo de alta -->
                                <ng-container matColumnDef="type">
                                    <th mat-header-cell *matHeaderCellDef class="header-column"> Tipo de alta </th>
                                    <td mat-cell *matCellDef="let element" class="py-1">
                                        <ng-container *ngIf="element.lines && element.lines.length">
                                            <div *ngFor="let line of element.lines" class="content-sub-row">
                                                {{ typesLines[line.type] }} <span class="orange">{{ line.has_terminal ? '+ terminal' : '' }}</span>
                                                <ng-container *ngIf="line.bono && line.bono !== ''">
                                                    <br>
                                                    <span style="font-size: 12px;">{{ line.bono }}</span>
                                                </ng-container>
                                            </div>
                                        </ng-container>
                                    </td>
                                </ng-container>

                                <!-- Msisdn -->
                                <ng-container matColumnDef="msisdn">
                                    <th mat-header-cell *matHeaderCellDef class="header-column"> Msisdn </th>
                                    <td mat-cell *matCellDef="let element" class="py-1">
                                        <ng-container *ngIf="element.lines && element.lines.length">
                                            <div *ngFor="let line of element.lines" class="content-sub-row">
                                                {{ line.msisdn }}
                                            </div>
                                        </ng-container>
                                    </td>
                                </ng-container>

                                <!-- Estado -->
                                <ng-container matColumnDef="status">
                                    <th mat-header-cell *matHeaderCellDef class="header-column" style="text-align: center;"> Estado </th>
                                    <td mat-cell *matCellDef="let element" class="py-1">
                                        <ng-container *ngIf="element.lines && element.lines.length">
                                            <div align="center" *ngFor="let line of element.lines" class="content-sub-row align-self-center" style="position: relative;">
                                                <i-feather [name]="getStatusIcon(line)" [style]="'color:' + getColorIcon(line.status)" class="icon--fe status-icon" stroke-width="2.5"
                                                    [title]="line.status_description && line.status_description !== '' ? line.status_description : superStatusTranslate[line.status]">
                                                </i-feather>
                                            </div>
                                        </ng-container>
                                    </td>
                                </ng-container>

                                <!-- Fecha -->
                                <ng-container matColumnDef="date">
                                    <th mat-header-cell *matHeaderCellDef class="header-column" style="text-align: center;"> Fecha solicitud </th>
                                    <td mat-cell *matCellDef="let element" align="center"> {{convertDate(element.date)}} </td>
                                </ng-container>

                                <!-- Fecha de activacion -->
                                <ng-container matColumnDef="activation_date">
                                    <th mat-header-cell *matHeaderCellDef class="header-column" style="text-align: center;"> Fecha activación </th>
                                    <td mat-cell *matCellDef="let element">
                                        <ng-container *ngIf="element.lines && element.lines.length">
                                            <div align="center" *ngFor="let line of element.lines" class="content-sub-row">
                                                {{convertDate(line.activation_date)}}
                                            </div>
                                        </ng-container>
                                    </td>
                                </ng-container>

                                <!-- Marca -->
                                <ng-container matColumnDef="brand">
                                    <th mat-header-cell *matHeaderCellDef class="header-column"> Marca </th>
                                    <td mat-cell *matCellDef="let element">
                                        <img [src]="'./assets/img/' + getImgBrand(element.brand)" class="img-fluid" width="100" style="margin-left: -10px;">
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header-table"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="cursor-pointer" (click)="goDetailOrder(row)"></tr>
                            </table>
                            <div *ngIf="!dataSource.length && !loaded">
                                <h5 align="center" class="tar-name-preload">No se han encontrado operaciones para estos filtros</h5>
                            </div>
                            <div *ngIf="!automaticLoad && !loaded && body?.page < lastPage">
                                <button class="btn btn-black--outline btn-sm mb-1 mt-4 w-100" (click)="nextPage()">Cargar más</button>
                            </div>
                            <div *ngIf="loaded" class="col-xl-12">
                                <div class="my-3">
                                    <h5 align="center" class="tar-name-preload">Obteniendo operaciones...</h5>
                                    <div align="center">
                                        <img class="" src="./assets/img/spinner-home.svg" width="30" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!orderListPdv" class="col-xl-12">
                    <div class="card--sidebar mt-2">
                        <div class="my-5">
                            <h5 align="center" class="tar-name-preload">Obteniendo operaciones...</h5>
                            <div align="center">
                                <img class="" src="./assets/img/spinner-home.svg" width="30" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-5" *ngIf="orderListPdvError">
        <h5 align="center" class="tar-name-preload">Se ha producido un error, inténtalo de nuevo más tarde</h5>
    </div>
</section>
