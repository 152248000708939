import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError, map, pluck, tap } from 'rxjs/operators';
import * as moment from 'moment';
import { AppointmentResult, GroupedSlots, Slot, TimeSlot } from '../models/appointment';
import { of, throwError } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SimyoAppointmentService {
    private appointment : AppointmentResult;
    constructor(private http: HttpClient, private auth: AuthService) {
        this.appointment = {
            code: '',
            message: '',
            result: 'KO',
        }
    }

    private getHeaders(): HttpHeaders {
        return new HttpHeaders({
            Authorization: 'Bearer ' + this.auth.getToken(),
            'Content-Type': 'application/json',
        });
    }

    // Masters service
    getAppointmentSlots(post_code, order_id) {
        return this.http
            .get(
                `${environment.simyoEndPoint}order/appointment/slots/search?post_code=${post_code}&order_id=${order_id}`,
                { headers: this.getHeaders() }
            )
            .pipe(
                map((response: any) => {
                    //TODO - Cris cuando da error 901 debería de pasar por este if y me da que no lo hace por que, desde donde se llama a la función,
                    //TODO - Cris esta pasando por el tap en vez de por el catch
                    if(response?.msg?.code && response?.msg?.code !== 200) {
                        throwError(response?.msg?.error);
                    }
                    if (
                        response?.msg?.availableTimeSlot &&
                        response?.msg?.availableTimeSlot.length > 0
                    ) {
                        return this.groupByDay(
                            response?.msg?.availableTimeSlot
                        );
                    }
                    return [];
                })
            );
    }

    checker(gescal, order_id) {
        return this.http.post(
            environment.simyoEndPoint + 'order/appointment/checker',
            { gescal, order_id },
            { headers: this.getHeaders() }
        ).pipe(
            tap((response:any) => {
                if(response.msg && response.msg.result == 'OK') {
                    this.setAppointment(response.msg);
                }
            }),
            catchError(error => {
                this.resetAppointment();
                return of({msg: {
                    code: '',
                    message: '',
                    result: 'KO',
                }});
            }),
            pluck('msg')
        );
    }

    create(order_id, start_date_time, post_code,
        pdv_sfid, name, family_name, document, documentType,
        phone_number, observations, customer_email, customer_name, customer_surname) {
            let identification = documentType == 4 ? {organization_identification: [
                {
                    identification_id: document,
                },
            ]} : {individual_identification: [
                {
                    identification_id: document,
                },
            ]}
        let body = {
            // type: 'WorkOrderAppointment',
            // description: 'Instalación FTTH',
            valid_for: {
                start_date_time,
            },
            post_code,
            characteristic: [
                {
                    name: 'SFID',
                    value: pdv_sfid,
                }
            ],
            related_party: [
                {
                    referred_type: documentType == 4 ? 'Organization' : 'Individual',
                    name: customer_name,
                    family_name: customer_surname,
                    ...identification
                },
            ],
            contact_medium: [
                {
                    medium_type: "PhoneNumber",
                    phone_number
                },
                {
                    medium_type: "E-mail",
                    email_address: customer_email
                }
            ],
            notes: [
                {
                    text: `Nombre del contacto: ${name} ${family_name}`
                },
                {
                    text: `Observaciones: ${observations}`
                }
            ],
            order_id,
            mocked: false
        };
        return this.http.post(
            environment.simyoEndPoint + 'order/appointment',
            body,
            { headers: this.getHeaders() }
        ).pipe(
            map((response: any) => {
                if(response?.msg?.code && response?.msg?.code !== 200) {
                    if(response?.msg?.code == 8021) {
                        throw new Error('No hay citas disponibles en la fecha seleccionada. Prueba con otra fecha u horario, por favor.')
                    }
                    if(response?.msg?.code == 8022) {
                        throw new Error('Este cliente ya tiene una cita programada, por lo que no puede ser cancelada en este momento.')
                    }
                    throw new Error('Ha ocurrido un problema durante el proceso de reserva de su cita. Por favor, inténtelo nuevamente. Si el problema persiste, no dude en contactarnos para recibir asistencia.');
                }
                localStorage.setItem('appointment', JSON.stringify({...response.msg, order_id}));
                return response;
            })
        );
    }

    cancel(work_order_id, order_id) {
        let body = {
            work_order_id,
            status: 'Cancelled',
            order_id
        };
        return this.http.post(
            environment.simyoEndPoint + 'order/appointment/modify',
            body,
            { headers: this.getHeaders() }
        );
    }

    private groupByDay(timeSlots: TimeSlot[]): GroupedSlots[] {
        // Creamos un objeto para agrupar los slots por día
        const today = moment();
        const grouped = timeSlots.reduce(
            (acc: { [key: string]: Slot[] }, curr) => {
                // Extraemos las fechas y las horas
                const startDate = moment(curr.validFor.startDateTime);
                if(startDate < today) {
                    return acc;
                }

                const endDate = moment(curr.validFor.endDateTime);

                // Obtenemos solo la parte del día (YYYY-MM-DD)
                const day = startDate.format('YYYY-MM-DD');

                // Creamos el objeto slot con la hora concatenada
                const slot = {
                    title: this.getTitleSlot(startDate, endDate),
                    startDateTime: curr.validFor.startDateTime,
                    endDateTime: curr.validFor.endDateTime,
                };

                // Si no existe el día en el acumulador, lo creamos
                if (!acc[day]) {
                    acc[day] = [];
                }

                // Añadimos el slot al día correspondiente
                acc[day].push(slot);

                return acc;
            },
            this.initializeSlots()
        );

        // Convertimos el objeto agrupado en un array con el formato deseado
        return Object.keys(grouped).map((day) => ({
            day: moment(day).format('DD-MM-YYYY'),
            dayOfWeek: moment(day).format('dddd'),
            slots: grouped[day],
        }));
    }

    setAppointment(data) {
        this.appointment = data;
    }

    getAppointment() {
        return this.appointment;
    }

    resetAppointment() {
        this.appointment = {
            code: '',
            message: '',
            result: 'KO'
        }
    }

    initializeSlots() {
        const daysSlots = {};

        const today = moment();

        for (let i = 0; i < 15; i++) {
            const futureDate = today.clone().add(i, 'day');

            const formattedDate = futureDate.format('YYYY-MM-DD');

            daysSlots[formattedDate] = [];
        }

        return daysSlots;
    }

    getTitleSlot(startDate, endDate) {
        const startTime = startDate.format('HH:mm');
        const endTime = endDate.format('HH:mm');
        return `${startTime} - ${endTime}`;
    }
}
