<div>
    <div class="modal-header bg-simyo-grey">
        <h5 class="modal-title white" id="exampleModalLabel">Sugiérenos una dirección</h5>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">
                <img src="./assets/img/times2.svg" class="img-fluid ico-title" width="20">
            </span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-lg-12 mt-2">
                <div class="animated fadeIn">
                    <!----- Dirección de la normalización ------->
                    <div class="jumbotron-dir">
                        <div class="d-flex justify-content-between align-items-center">
                            <div>
                                <!----- Dirección ------->
                                <span class="dir-text">
                                    <!-- {{ formularioNormalizacion.get('tipoViaNormaliza').value }} --> {{ formularioNormalizacion.get('nombreViaNormaliza').value }}
                                    <span>
                                         Número {{ formularioNormalizacion.get('numeroNormaliza').value }}
                                    </span>
                                </span>
                                <br>
                                <!----- CP / Ciudad ------->
                                <span class="dir-text2">
                                    {{ formularioNormalizacion.get('codigoPostalNormaliza').value }} {{ formularioNormalizacion.get('localidadNormaliza').value }}
                                </span>
                                <br>
                                <!----- Provincia ------->
                                <span class="dir-text2">
                                    {{ formularioNormalizacion.get('provinciaNormaliza').value }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row mx-4" [formGroup]="newAddressFormGroup">
            <div class="col-lg-4">
                <mat-form-field>
                    <mat-label>Bis/duplicado</mat-label>
                    <select matNativeControl formControlName="bis">
                        <option value="">Selecciona</option>
                        <option *ngFor="let street_number_suffix of masterCoverage?.street_number_suffix" [value]="street_number_suffix.code">
                            {{street_number_suffix.value}}
                        </option>
                    </select>
                </mat-form-field>
            </div>
            <div class="col-lg-4">
                <mat-form-field>
                    <mat-label>Edificación</mat-label>
                    <select matNativeControl formControlName="bloqueFinca">
                        <option value="">Selecciona</option>
                        <option *ngFor="let sub_unit_type of masterCoverage?.sub_unit_type" [value]="sub_unit_type.code">
                            {{sub_unit_type.value}}
                        </option>
                    </select>
                </mat-form-field>
            </div>
            <div class="col-lg-4" *ngIf="newAddressFormGroup.get('bloqueFinca').value !== ''">
                <mat-form-field>
                    <mat-label>Identificador de edificación</mat-label>
                    <input matInput type="text" name="identificadorBloqueFinca" formControlName="identificadorBloqueFinca">
                </mat-form-field>
            </div>
            <div class="col-lg-4">
                <mat-form-field>
                    <mat-label>Finca/Portal</mat-label>
                    <select matNativeControl formControlName="portal">
                        <option value="">Selecciona</option>
                        <option *ngFor="let building_name of masterCoverage?.building_name" [value]="building_name.code">
                            {{building_name.value}}
                        </option>
                    </select>
                </mat-form-field>
            </div>
            <div class="col-lg-4" *ngIf="newAddressFormGroup.get('portal').value !== ''">
                <mat-form-field>
                    <mat-label>Identificador Finca/Portal</mat-label>
                    <input matInput type="text" name="letraPortal" formControlName="letraPortal">
                </mat-form-field>
            </div>
            <div class="col-lg-4">
                <mat-form-field>
                    <mat-label>Escalera</mat-label>
                    <select matNativeControl formControlName="escalera">
                        <option value="">Selecciona</option>
                        <option *ngFor="let stair of masterCoverage?.stair_case" [value]="stair.code">
                            {{stair.value}}
                        </option>
                    </select>
                </mat-form-field>
            </div>
            <div class="col-lg-4">
                <mat-form-field>
                    <mat-label>Planta</mat-label>
                    <input type="text" matInput formControlName="planta" [matAutocomplete]="autoFloorTypes" required>
                    <mat-autocomplete #autoFloorTypes="matAutocomplete" [displayWith]="displayFn">
                        <mat-option *ngFor="let floor of floorFilteredOptions | async" [value]="floor.code">
                            {{ floor.value }}
                        </mat-option>
                    </mat-autocomplete>
                    <!-- error -->
                    <mat-error>
                        Campo obligatorio.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-lg-4">
                <mat-form-field>
                    <mat-label>Tipo de puerta</mat-label>
                    <select matNativeControl formControlName="tipoPuerta">
                        <option value="">Selecciona</option>
                        <option *ngFor="let doorType of masterCoverage?.door_type" [value]="doorType.code">
                            {{doorType.value}}
                        </option>
                    </select>
                </mat-form-field>
            </div>
            <div class="col-lg-4">
                <mat-form-field>
                    <mat-label>Puerta</mat-label>
                    <input matInput type="text" name="puerta" formControlName="puerta">
                </mat-form-field>
            </div>
        </div>
        <mat-error *ngIf="errorNewAddress !== ''">
            <p class="alert alert-warning bg-simyo text-center">
                {{ errorNewAddress }}
            </p>            
        </mat-error>
        <div class="row mt-3">
            <div class="ng-star-inserted" class="col-12 col-md">
                <button [disabled]="isLoading" type="submit" class="btn btn-simyo mb-1" (click)="createAddress()">
                    <span *ngIf="isLoading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    {{ isLoading ? 'Comprobando cobertura' : 'Comprobar cobertura' }}
                </button>
            </div>
        </div>
    </div>
</div>