import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { allLocalStorage } from 'src/app/shared/constantes';
import { allPermissions } from 'src/app/shared/permissions';
import { AuthService } from 'src/app/shared/services/auth.service';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { environment } from 'src/environments/environment';
import { IrappelResponse } from '../models/Irappel';

export interface ITypeOperationForms {
    type: any,
    filtersBrands?: any,
}

export interface INavigateFromOperation {
    body: any, 
    data: any[],
    lastPage: any,
    forms: any;
}

@Injectable({
    providedIn: 'root'
})
export class HomeService {
    private viewBrandRates: BehaviorSubject<any>;
    private activationData: BehaviorSubject<any>;
    private activationDataError: BehaviorSubject<Boolean>;
    private typeOperationForms: BehaviorSubject<ITypeOperationForms>;
    private navigateFromOperation: BehaviorSubject<INavigateFromOperation>;
    private navigateFromOperationPosVenta: BehaviorSubject<INavigateFromOperation>;
    private rappelSimyo: BehaviorSubject<any>;
    private rappelSimyoError: BehaviorSubject<Boolean>;
    private allPermissions = allPermissions;
    private idSession: any =  null;
    private tabValue: BehaviorSubject<string>;

    constructor(
        private http: HttpClient,
        private auth: AuthService,
        private permSv: PermissionService
    ) {
        this.viewBrandRates = new BehaviorSubject<any>(null);
        this.activationData = new BehaviorSubject<any>(null);
        this.activationDataError = new BehaviorSubject<Boolean>(false);
        this.typeOperationForms = new BehaviorSubject<ITypeOperationForms>(null);
        this.navigateFromOperation = new BehaviorSubject<INavigateFromOperation>(null);
        this.navigateFromOperationPosVenta = new BehaviorSubject<INavigateFromOperation>(null);
        this.rappelSimyo = new BehaviorSubject<any>(null);
        this.rappelSimyoError = new BehaviorSubject<Boolean>(false);
        this.tabValue = new BehaviorSubject<string>('');
    }

    findIndexToMove(tabGroup, labelId): number {
        let indexToReturn = 0;
        tabGroup._allTabs.map((tab, index) => {
            if (tab.textLabel === labelId) {
                indexToReturn = index;
            }
        });
        return indexToReturn;
    }

    getHeaders() {
        const token = 'Bearer ' + this.auth.getToken();
        return new HttpHeaders({
            Authorization: token,
            'Content-Type': 'application/json'
        });
    }

    getMasterCoverage() {
        return this.http.get(environment.comunEndpoint + 'coverage/address/master', {headers: this.getHeaders()});        
    }
    
    createNewAddress(body) {
        return this.http.post(environment.comunEndpoint + 'coverage/address/new', body, {headers: this.getHeaders()});        
    }

    getActivationsByMonthFromService(body) {
        return this.http.post(environment.comunEndpoint + 'pdv/activations-by-month', {brands: body}, {headers: this.getHeaders()});
    }

    getOrderListPdvFromService(body) {
        return this.http.post(environment.comunEndpoint + 'pdv/order/list', body, {headers: this.getHeaders()});
    }

    getOrderListPdvPosVentaFromService(body) {
        return this.http.post(environment.comunEndpoint + 'pdv/after-sales/list', body, {headers: this.getHeaders()});
    }
    
    public getRappelDataFromService(): Observable<IrappelResponse> {
        return this.http.get<IrappelResponse>(environment.comunEndpoint + 'pdv/rappel', {headers: this.getHeaders()});
    }

    getInvoicesFromService() {
        return this.http.get(environment.comunEndpoint + 'pdv/autoinvoices', {headers: this.getHeaders()});
    }

    getCommisionsFromService() {
        return this.http.get(environment.comunEndpoint + 'pdv/commisions', {headers: this.getHeaders()});
    }

    sendIdSessionToService(from) {
        return this.http.post(environment.simyoEndPoint + 'gplaces/counter', {unique_session_id: from + '-' + this.getIdSesion()}, {headers: this.getHeaders()}).subscribe(data => {});
    }

    downloadReportFromService(body, pdv, brands, from, to) {
        const bodyRequest = {
            key: 'q8eDNLKRDjmOyDLrMAephmyVeZdPmhV4',
            report_informe: '081PDV',
            ver_orange: body.includes(brands.orange) ? 1 : 0,
            ver_simyo: body.includes(brands.simyo) ? 1 : 0,
            ver_amena: body.includes(brands.amena) ? 1 : 0,
            ver_repm: body.includes(brands.republica_movil) ? 1 : 0,
            buscar_report_id_pdv: pdv?.info?.idpdv,
            user_name: pdv?.info?.username,
            buscar_report_fecha_desde: from,
            buscar_report_fecha_hasta: to
        }
        return this.http.post(environment.digoEndPoint + 'generador_informes/report_auto_generate.php', bodyRequest);
    }

    getSimyoDocSendPendingFromService() {
        return this.http.get(environment.simyoEndPoint + 'documentation/send-pending', {headers: this.getHeaders()});
    }

    getUrlClicoFromService() {
        return this.http.get(environment.comunEndpoint + 'clico/get/url-access', {headers: this.getHeaders()});
    }

    getIdSesion() {
        let actualDate = new Date();
        this.idSession = localStorage.getItem(allLocalStorage.idSession);
        if (this.idSession) {
            if (new Date(+this.idSession + 3*60000) < actualDate) {
                this.idSession = actualDate.getTime();
                localStorage.setItem(allLocalStorage.idSession, this.idSession.toString());
            }
        }
        if (!this.idSession) {
            this.idSession = actualDate.getTime();
            localStorage.setItem(allLocalStorage.idSession, this.idSession.toString());
        }
        return this.idSession;
    }

    getBrandPermission(brands): any[] {
        const permissions = [];
        !this.permSv.hasPermFromV2(allPermissions.simyo.bloquea_ventas) ? permissions.push(brands.simyo) : '';
        this.permSv.hasPermFromV2(allPermissions.jazztel.permite_ventas) || this.permSv.hasPermFromV2(allPermissions.jazztel.tuvo_activaciones) ? permissions.push(brands.jazztel) : '';
        this.permSv.hasPermFromV2(allPermissions.orange.permite_ventas_prepago) || this.permSv.hasPermFromV2(allPermissions.orange.permite_ventas_pospago) || this.permSv.hasPermFromV2(allPermissions.orange.tuvo_activaciones_prepago) || this.permSv.hasPermFromV2(allPermissions.orange.tuvo_activaciones_pospago) ? permissions.push(brands.orange) : '';
        return permissions;
    }

    getBrandPermissionOperationsPosventa(brands): any[] {
        const permissions = [];
        // this.permSv.hasPermFromV2(allPermissions.jazztel.permite_ventas) || this.permSv.hasPermFromV2(allPermissions.jazztel.tuvo_activaciones) ? permissions.push(brands.jazztel) : '';
        this.permSv.hasPermFromV2(allPermissions.orange.permite_sell_bonos_aditional) ? permissions.push(brands.orange) : '';
        return permissions;
    }

    setViewBrandRates(data) {
        this.viewBrandRates.next(data);
    }

    getViewBrandRates() {
        return this.viewBrandRates.asObservable();
    }

    // Home sales
    setActivation(data) {
        this.activationData.next(data);
    }

    getActivation() {
        return this.activationData.asObservable();
    }
    
    setActivationError(data) {
        this.activationDataError.next(data);
    }

    getActivationError() {
        return this.activationDataError.asObservable();
    }

    // Home operations
    setTypeOperationForms(data: ITypeOperationForms) {
        this.typeOperationForms.next(data);
    }

    getTypeOperationForms() {
        return this.typeOperationForms.asObservable();
    }

    setNavigateFromOperation(data: INavigateFromOperation) {
        this.navigateFromOperation.next(data);
    }

    getNavigateFromOperation() {
        return this.navigateFromOperation.asObservable();
    }

    setNavigateFromOperationPosVenta(data: INavigateFromOperation) {
        this.navigateFromOperationPosVenta.next(data);
    }

    getNavigateFromOperationPosVenta() {
        return this.navigateFromOperationPosVenta.asObservable();
    }

    // Home commissions
    setRappelSimyo(data) {
        this.rappelSimyo.next(data);
    }

    getRappelSimyo() {
        return this.rappelSimyo.asObservable();
    }

    setRappelSimyoError(data) {
        this.rappelSimyoError.next(data);
    }

    getRappelSimyoError() {
        return this.rappelSimyoError.asObservable();
    }

    setGoToTab(data) {
        this.tabValue.next(data);
    }

    getGoToTab() {
        return this.tabValue.asObservable();
    }

    sendReport347(empresa) {
        return this.http.get(`${environment.digoEndPoint}comision_modelo_347_cif.itx?empresa=${empresa}`);
    }
}
