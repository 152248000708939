<section #procesos class="procesos mt-3">
    <div class="row">
        <!-- Activaciones -->
        <div class="col-xl-12" *ngIf="!activationsByMonthError">
            <div class="card--sidebar mb-3">
                <div class="card-footer--sidebar-kpi1">
                    <div class="row kpi-col" *ngIf="activationStatistics">
                        <div align="center" class="col-md-3 pl-4 mb-2" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_vender_broadband)">
                            <div class="cursor-pointer" (click)="viewOperation(typeFilterFormOperation.totalActivationsFibra)">
                                <span class="title-p3 mr-2">Fibra:</span>
                                <span class="kpi2">{{ activationStatistics.countActualMonthFibra }}</span>
                            </div>
                            <span title="Estimación a final de mes"
                                [ngClass]="{'kpi-per-positive2' : activationStatistics.estimatedFibra > 0, 'kpi-per-negative2': activationStatistics.estimatedFibra < 0, 'kpi-per-neutro2': activationStatistics.estimatedFibra === 0 }">
                                {{ getAbsoluteValue(activationStatistics.estimatedFibra) }}%
                            </span>
                            <span class="kpi-per-mes1 mr-1">- {{ monthNames[lastMonth] }}:</span>
                            <span class="kpi-a1">{{ activationStatistics.countPreviousMonthFibra }}</span>
                        </div>
                        <div align="center" class="pl-4 mb-2 border-left" [ngClass]="{'col-md-3': permSv.hasPermFromV2(allPermissions.simyo.permite_vender_broadband), 'col-md-4': !permSv.hasPermFromV2(allPermissions.simyo.permite_vender_broadband)}">
                            <div class="cursor-pointer" (click)="viewOperation(typeFilterFormOperation.totalActivationsPostpago)">
                                <span class="title-p3 mr-2">Pospago:</span>
                                <span class="kpi2">{{ activationStatistics.countActualMonthPospago }}</span>
                            </div>
                            <span title="Estimación a final de mes"
                                [ngClass]="{'kpi-per-positive2' : activationStatistics.estimatedPospago > 0, 'kpi-per-negative2': activationStatistics.estimatedPospago < 0, 'kpi-per-neutro2': activationStatistics.estimatedPospago === 0 }">
                                {{ getAbsoluteValue(activationStatistics.estimatedPospago) }}%
                            </span>
                            <span class="kpi-per-mes1 mr-1">- {{ monthNames[lastMonth] }}:</span>
                            <span class="kpi-a1">{{ activationStatistics.countPreviousMonthPospago }}</span>
                        </div>
                        <div align="center" class="pl-4 mb-2 border-left" [ngClass]="{'col-md-3': permSv.hasPermFromV2(allPermissions.simyo.permite_vender_broadband), 'col-md-4': !permSv.hasPermFromV2(allPermissions.simyo.permite_vender_broadband)}">
                            <div class="cursor-pointer" (click)="viewOperation(typeFilterFormOperation.totalActivationsPrepago)">
                                <span class="title-p3 mr-2">Prepago:</span>
                                <span class="kpi2">{{ activationStatistics.countActualMonthPrepago }}</span>
                            </div>
                            <span title="Estimación a final de mes"
                                [ngClass]="{'kpi-per-positive2' : activationStatistics.estimatedPrepago > 0, 'kpi-per-negative2': activationStatistics.estimatedPrepago < 0, 'kpi-per-neutro2': activationStatistics.estimatedPrepago === 0 }">
                                {{ getAbsoluteValue(activationStatistics.estimatedPrepago) }}%
                            </span>
                            <span class="kpi-per-mes1 mr-1">- {{ monthNames[lastMonth] }}:</span>
                            <span class="kpi-a1">{{ activationStatistics.countPreviousMonthPrepago }}</span>
                        </div>
                        <div align="center" class="pl-4 pt-1 mb-2 border-left" [ngClass]="{'col-md-3': permSv.hasPermFromV2(allPermissions.simyo.permite_vender_broadband), 'col-md-4': !permSv.hasPermFromV2(allPermissions.simyo.permite_vender_broadband)}">
                            <img src="./assets/img/saldo.svg" class="img-fluid avisos--sidebar--icon" width="16"> Total este mes:
                            <br>
                            <span class="orange ml-0 cursor-pointer" (click)="viewOperation(typeFilterFormOperation.totalActivationsMonth)">
                                <b>{{ activationStatistics.countActualMonthFibra + activationStatistics.countActualMonthPospago + activationStatistics.countActualMonthPrepago }}</b> activaciones
                            </span>
                        </div>
                    </div>
                    <div style="padding-bottom: 5px;" *ngIf="!activationStatistics">
                        <h5 align="center" class="tar-name-preload">
                            <img class="" src="./assets/img/spinner-home.svg" width="30" alt=""> Obteniendo activaciones...
                        </h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-12" *ngIf="activationsByMonthError">
            <div class="card--sidebar mb-3">
                <div class="card-footer--sidebar-kpi1">
                    <h5 align="center" class="tar-name-preload" style="padding-bottom: 5px;">Se ha producido un error, inténtalo de nuevo más tarde</h5>
                </div>
            </div>
        </div>

        <!-- MARCAS -->
        <div class="col-xl-7">
            <div class="accordion subpage_top_nav" id="accordionExample">
                <ul class="d-flex flex-column" style="list-style: none; margin-left: -40px;">
                    <li class="brand" *ngFor="let hmBrand of hmBrands; let i = index" [ngClass]="getBrandClass(hmBrand, i)">
                        <app-hm-brand [brand]="hmBrand"></app-hm-brand>
                    </li>
                </ul>
            </div>
            <ng-container
                *ngIf="(permSv.hasPermFromV2(allPermissions.others.comissions_permitir) || permSv.hasPermFromV2(allPermissions.others.comissions_pend_doc) || permSv.hasPermFromV2(allPermissions.others.comissions_pend_verificacion)) && pdv?.info?.tipo_vista !== PDV_MULTIMARCA; then recargasYpedidos">
            </ng-container>
        </div>
        <!-- MARCAS -->

        <!-- OTROS PROCESOS -->
        <div class="col-xl-5">
            <div class="banners_group">
                <div *ngIf="brandOpened === brands.simyo">
                    <!-- banner ads -->
                    <div class="banner--ads--sidebar-sm mb-3 fade-in">
                        <ngb-carousel class="container px-0" *ngIf="(today.isBefore(smChangeGif)) || today.isSameOrAfter(smGifFinish) || (!environment.production && pdv?.info?.username === 'demo_pdv' && (smChangeGif.isSameOrAfter(today) || today.isBefore(smGifFinish)))">
                            <ng-template ngbSlide>
                                <img [src]="getBannerSimyo()" class="img-fluid">
                            </ng-template>
                        </ngb-carousel>
                        <span *ngIf="!environment.production && pdv?.info?.username === 'demo_pdv' && (smChangeGif.isSameOrAfter(today) || today.isBefore(smGifFinish))">
                             A partir del día {{ smChangeGif.format('DD/MM/YYYY') }} {{ today.isBefore(smGifFinish) ? 'hasta el día ' + smGifFinish?.format('DD/MM/YYYY') : '' }} se muestra el banner de abajo
                        </span>
                        <ngb-carousel class="container px-0" *ngIf="(today.isSameOrAfter(smChangeGif) && today.isBefore(smGifFinish)) || (!environment.production && pdv?.info?.username === 'demo_pdv' && (smChangeGif.isSameOrAfter(today) || today.isBefore(smGifFinish)))">
                            <ng-template ngbSlide>
                                <img [src]="getBannerSimyo()" class="img-fluid">
                            </ng-template>
                        </ngb-carousel>
                    </div>
                </div>

                <div *ngIf="brandOpened === brands.orange">
                    <!-- banner ads -->
                    <div class="banner--ads--sidebar-or mb-3 fade-in">
                        <ngb-carousel [showNavigationIndicators]="pdv?.banners?.image_orange?.images.length > 1" class="container px-0" *ngIf="pdv?.banners?.image_orange && !pdv?.banners?.image_orange?.error">
                            <ng-template ngbSlide *ngFor="let banner of pdv?.banners?.image_orange?.images">
                                <img [src]="'data:image/gif;base64,' + banner" class="img-fluid">
                            </ng-template>
                        </ngb-carousel>
                        <!-- <img *ngIf="pdv?.banners?.image_orange && !pdv?.banners?.image_orange?.error" [src]="'data:image/gif;base64,' + pdv?.banners?.image_orange?.image" class="img-fluid"> -->
                        <img *ngIf="!pdv?.banners || pdv?.banners?.image_orange?.error" src="./assets/img/Banner_DIGO_Mundo_445x300_0421_V2.gif" class="img-fluid">
                    </div>
                </div>

                <div *ngIf="brandOpened === brands.jazztel">
                    <!-- banner ads -->
                    <div class="banner--ads--sidebar-am mb-3 fade-in">
                        <ngb-carousel class="container px-0">
                            <ng-template ngbSlide>
                                <img src="./assets/img/jazztel_banner_09-12-2024.jpg" class="img-fluid" alt="Jazztel">
                            </ng-template>
                        </ngb-carousel>
                    </div>
                </div>
            </div>

            <!-- Comisiones -->
            <ng-template *ngIf="(permSv.hasPermFromV2(allPermissions.others.comissions_permitir) || permSv.hasPermFromV2(allPermissions.others.comissions_pend_doc) || permSv.hasPermFromV2(allPermissions.others.comissions_pend_verificacion)) && pdv?.info?.tipo_vista !== PDV_MULTIMARCA && permSv.hasPermFromV2(allPermissions.others.access_night); then comisiones">
            </ng-template>

            <!-- Comisiones -->
            <ng-template *ngIf="permSv.hasPermFromV2(allPermissions.others.access_points) && permSv.hasPermFromV2(allPermissions.others.access_night); then points">
            </ng-template>

            <ng-template *ngIf="pdv?.info?.tipo_vista === PDV_MULTIMARCA && permSv.hasPermFromV2(allPermissions.others.terminales_libres); then terminalesLibres">
            </ng-template>

            <ng-template *ngIf="!(permSv.hasPermFromV2(allPermissions.others.comissions_permitir) || permSv.hasPermFromV2(allPermissions.others.comissions_pend_doc) || permSv.hasPermFromV2(allPermissions.others.comissions_pend_verificacion)) && pdv?.info?.tipo_vista !== PDV_MULTIMARCA; then recargasYpedidos">
            </ng-template>
        </div>
    </div>
</section>

<ng-template #recargasYpedidos id="orders_and_credit_phone">
    <div [class.form-row]="(permSv.hasPermFromV2(allPermissions.others.comissions_permitir) || permSv.hasPermFromV2(allPermissions.others.comissions_pend_doc) || permSv.hasPermFromV2(allPermissions.others.comissions_pend_verificacion))">
        <div [ngClass]="{'col-xl-12 p-0': !(permSv.hasPermFromV2(allPermissions.others.comissions_permitir) || permSv.hasPermFromV2(allPermissions.others.comissions_pend_doc) || permSv.hasPermFromV2(allPermissions.others.comissions_pend_verificacion)), 'col-xl-6': (permSv.hasPermFromV2(allPermissions.others.comissions_permitir) || permSv.hasPermFromV2(allPermissions.others.comissions_pend_doc) || permSv.hasPermFromV2(allPermissions.others.comissions_pend_verificacion)) }" *ngIf="pdv != null && ((permSv.hasPermFromV2(allPermissions.others.recharges_permite_saldo_promocional) && pdv.info_recargas.saldo_promo && pdv.info_recargas.saldo_promo > 0) || (!permSv.hasPermFromV2(allPermissions.others.recharges_bloquea_saldo_dexga)))">
            <div class="card--sidebar mb-3">
                <div class="card-header--sidebar">
                    Recarga de saldo
                </div>
                <div class="card-body--sidebar">
                    <div class="row">
                        <div class="col-12 col-md pt-1 pr-0">
                            <img src="./assets/img/saldo.svg" class="img-fluid avisos--sidebar--icon" width="16">
                            <span *ngIf="!permSv.hasPermFromV2(allPermissions.others.recharges_bloquea_saldo_dexga) && +pdv.info_recargas.usuario_dexga > 0">
                                Saldo: <span class="orange">{{ (+pdv.info_recargas.saldo_promo + +pdv.info_recargas.saldo_dexga).toFixed(2) }}</span> €
                            </span>
                            <span *ngIf="!permSv.hasPermFromV2(allPermissions.others.recharges_bloquea_saldo_dexga) && +pdv.info_recargas.usuario_dexga === 0">
                                Habilita el sistema de recargas
                            </span>
                        </div>
                        <div class="col-12 col-md-auto mobile--ver--sidebar align-self-center" *ngIf="!permSv.hasPermFromV2(allPermissions.others.recharges_bloquea_saldo_dexga) && +pdv.info_recargas.usuario_dexga > 0">
                            <a href="{{ baseUrl }}recargas_dexga_gestion.itx" class="btn btn-black-sidebar btn-sm">Mi cuenta</a>
                        </div>
                        <div class="col-12 col-md-auto mobile--ver--sidebar align-self-center" *ngIf="!permSv.hasPermFromV2(allPermissions.others.recharges_bloquea_saldo_dexga) && +pdv.info_recargas.usuario_dexga === 0">
                            <a href="{{ baseUrl }}recargas_dexga_sol_usuario.itx" class="btn btn-black-sidebar btn-sm">Solicitar</a>
                        </div>
                    </div>
                </div>
                <div class="card-footer--sidebar"
                    *ngIf="(permSv.hasPermFromV2(allPermissions.others.recharges_permite_saldo_promocional) && +pdv.info_recargas.saldo_promo > 0) || (!permSv.hasPermFromV2(allPermissions.others.recharges_bloquea_saldo_dexga) &&+ pdv.info_recargas.usuario_dexga > 0)">
                    <a href="{{ baseUrl }}recarga_saldo_origen.itx" class="btn btn-black--outline btn-sm btn-block"
                    *ngIf="permSv.hasPermFromV2(allPermissions.others.recharges_permite_saldo_promocional) && +pdv.info_recargas.saldo_promo > 0 && !permSv.hasPermFromV2(allPermissions.others.recharges_bloquea_saldo_dexga) && +pdv.info_recargas.usuario_dexga > 0">
                        Hacer una recarga
                    </a>
                    <a href="{{ baseUrl }}recarga_saldo.itx" class="btn btn-black--outline btn-sm btn-block"
                    *ngIf="permSv.hasPermFromV2(allPermissions.others.recharges_permite_saldo_promocional) && +pdv.info_recargas.saldo_promo > 0 && +pdv.info_recargas.usuario_dexga === 0">
                        Hacer una recarga
                    </a>
                    <a href="{{ baseUrl }}recargas_dexga_recarga.itx" class="btn btn-black--outline btn-sm btn-block"
                    *ngIf="!permSv.hasPermFromV2(allPermissions.others.recharges_bloquea_saldo_dexga) && +pdv.info_recargas.usuario_dexga > 0 && +pdv.info_recargas.saldo_promo === 0">
                        Hacer una recarga
                    </a>
                    <a href="{{ baseUrl }}bolsas_recarga_reparto.itx" class="btn btn-black--outline btn-sm btn-block"
                    *ngIf="!permSv.hasPermFromV2(allPermissions.others.recharges_bloquea_saldo_dexga) && pdv.info_recargas.usuario_dexga > 0 && pdv.info_recargas.bolsa_saldo === 1">
                        Gestión bolsa de saldo
                    </a>
                </div>
            </div>
        </div>

        <div [ngClass]="{'col-xl-12 p-0': !(permSv.hasPermFromV2(allPermissions.others.comissions_permitir) || permSv.hasPermFromV2(allPermissions.others.comissions_pend_doc) || permSv.hasPermFromV2(allPermissions.others.comissions_pend_verificacion)), 'col-xl-6': (permSv.hasPermFromV2(allPermissions.others.comissions_permitir) || permSv.hasPermFromV2(allPermissions.others.comissions_pend_doc) || permSv.hasPermFromV2(allPermissions.others.comissions_pend_verificacion)) }" *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_ventas_prepago) || permSv.hasPermFromV2(allPermissions.orange.permite_ventas_pospago)">
            <div class="card--sidebar mb-3">
                <div class="card-header--sidebar">
                    Pedidos
                </div>
                <div class="card-body--sidebar">
                    <div class="row">
                        <div class="col-12 col-md pt-1">
                            <img src="./assets/img/iconinvoice.svg" class="img-fluid avisos--sidebar--icon" width="16">
                            Albaranes
                        </div>
                        <div class="col-12 col-md-auto mobile--ver--sidebar">
                            <a href="{{ baseUrl }}app_albaran_pdv.itx" class="btn btn-black-sidebar btn-sm">Acceder</a>
                        </div>
                    </div>
                </div>

                <div class="card-footer--sidebar">
                    <a href="{{ baseUrl }}pedido_pdv.itx" class="btn btn-black--outline btn-sm btn-block">
                        <strong>Hacer un pedido</strong>
                    </a>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #comisiones id="commissions">
    <div class="card--sidebar mb-3">
        <div class="card-header--sidebar">
            Comisiones
        </div>
        <div class="card-body--sidebar">
            <div class="row" *ngIf="permSv.hasPermFromV2(allPermissions.others.comissions_permitir)">
                <div class="col-12 col-md pt-1">
                    <img src="./assets/img/euro.svg" class="img-fluid avisos--sidebar--icon" width="16">
                    Revisa tus comisiones
                </div>
                <div class="col-12 col-md-auto mobile--ver--sidebar">
                    <a (click)="viewCommissions()" class="btn btn-black-sidebar btn-sm">Consultar</a>
                </div>
            </div>

            <div class="row" *ngIf="permSv.hasPermFromV2(allPermissions.others.comissions_pend_doc)">
                <div class="col-12 col-md pt-1">
                    <img src="./assets/img/euro.svg" class="img-fluid avisos--sidebar--icon" width="16">
                    Únete al programa de comisiones
                </div>
                <div class="col-12 col-md-auto mobile--ver--sidebar">
                    <a href="{{ baseUrl }}comision_empresa.itx" class="btn btn-black-sidebar btn-sm">Gestionar</a>
                </div>
            </div>

            <div class="row" *ngIf="permSv.hasPermFromV2(allPermissions.others.comissions_pend_verificacion)">
                <div class="col-12 col-md pt-1">
                    <img src="./assets/img/euro.svg" class="img-fluid avisos--sidebar--icon" width="16">
                    Tu documentación está siendo validada
                </div>
            </div>
        </div>
        <ng-container *ngIf="permSv.hasPermFromV2(allPermissions.others.comissions_permitir) && (permSv.hasPermFromV2(allPermissions.jazztel.permite_ventas) || permSv.hasPermFromV2(allPermissions.simyo.permite_vender))">
            <div class="card-footer--sidebar" *ngIf="rappelSimyoData">
                <div *ngIf="rappelSimyoData.current">
                    <div class="row">
                        <div class="col-8">
                            <span>
                                <b>Categoría actual:</b>
                                <br>
                                <b class="orange">{{ rappelSimyoData.current.text }} </b><span class="badge badge-warning-orange">{{ rappelSimyoData.current.current_activations }} activaciones</span>
                            </span>
                            <br>
                        </div>
                        <div class="col-4 pr-0 pb-1" align="right" style="align-self: end;">
                            <img src="./assets/img/sm-login-color.svg" class="img-fluid logo--tarifa" width="80" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_vender)">
                            <img src="./assets/img/jz-login-color.svg" class="img-fluid logo--tarifa mb-1" width="80" *ngIf="permSv.hasPermFromV2(allPermissions.jazztel.permite_ventas)">
                        </div>
                    </div>
                    <div class="progress mt-4 pro-com">
                        <div class="progress-bar progress-bar progress-bar-striped progress-bar-animated" role="progressbar" [ngStyle]="{ 'width.%': getWidth() }" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <small class="text-muted" *ngIf="rappelSimyoData?.current?.next_level">
                        Te quedan <b>{{ rappelSimyoData.current.next_level.min_activations - rappelSimyoData.current.current_activations }}</b> activaciones para pasar a la categoría <span class="orange">{{ rappelSimyoData.current.next_level.text }}</span>
                    </small>
                    <ng-container *ngIf="today?.date() > dayToShowCategoryRappelPreviousMonth">
                        <br>
                        <small class="text-muted">
                            Categoría mes anterior <b class="orange">{{ translateCategoryRappel[pdv.info.clasificacion] || 'Básico' }}</b>
                        </small>
                    </ng-container>
                </div>
            </div>
            <div *ngIf="!rappelSimyoData && !rappelSimyoDataError" class="col-xl-12 card-footer--sidebar">
                <div class="mt-2">
                    <div class="my-5">
                        <h5 align="center" class="tar-name-preload">Obteniendo rappel de Simyo...</h5>
                        <div align="center">
                            <img class="" src="./assets/img/spinner-home.svg" width="30" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="rappelSimyoDataError">
                <h5 align="center" class="tar-name-preload">Se ha producido un error, inténtalo de nuevo más tarde</h5>
            </div>
        </ng-container>
    </div>
</ng-template>

<ng-template #points id="points">
    <div class="card--sidebar mb-3">
        <div class="card-header--sidebar">
            ¡Atrévete!
        </div>
        <div class="card-body--sidebar">
            <div class="row" *ngIf="pointsService.checkIsAcceptedPointsProgram(pdv.info.points_terms)">
                <div class="col-12 col-md pt-1" *ngIf="dataPoints">
                    <b class="title-points orange">{{dataPoints.pointsAvailable}} </b>
                    Puntos disponibles
                </div>
                <div class="col-12 col-md-auto mobile--ver--sidebar">
                    <a (click)="viewPoints()" class="btn btn-black-sidebar btn-sm">Ver puntos</a>
                </div>
            </div>

            <div class="row" *ngIf="pointsService.checkIsAcceptedPointsProgram(pdv.info.points_terms) === false">
                <div class="col-12 col-md pt-1">
                    <i-feather name="gift" class="icon--fe"></i-feather>
                    Únete al programa de puntos
                </div>
                <div class="col-12 col-md-auto mobile--ver--sidebar">
                    <a (click)="viewPoints()" class="btn btn-black-sidebar btn-sm">Gestionar</a>
                </div>
            </div>
        </div>
        <ng-container *ngIf="pointsService.checkIsAcceptedPointsProgram(pdv.info.points_terms)">
            <div class="card-footer--sidebar" *ngIf="dataPoints">
                <div class="progress mt-4 pro-com">
                    <div class="progress-bar progress-bar progress-bar-striped progress-bar-animated" role="progressbar" [ngStyle]="{ 'width.%': getPointsWidth() }" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <small class="text-muted">
                    Puntos mes actual <span class="kpi-points-home kpi" [ngClass]="{'kpi-per-positive2' : dataPoints.ratePointsWonLastWeek > 0, 'kpi-per-negative2': dataPoints.ratePointsWonLastWeek < 0, 'kpi-per-neutro2': dataPoints.ratePointsWonLastWeek === 0 }">
                        {{ getAbsoluteValue(dataPoints.ratePointsWonLastWeek) }}% </span><span>vs anterior</span>
                </small>
            </div>
            <div *ngIf="dataPointsError">
                <h5 align="center" class="tar-name-preload">Se ha producido un error, inténtalo de nuevo más tarde</h5>
            </div>
        </ng-container>
    </div>
</ng-template>

<ng-template #terminalesLibres id="free_terminals">
    <div class="card--sidebar mb-3" *ngIf="pdv?.info?.tipo_vista === PDV_MULTIMARCA && permSv.hasPermFromV2(allPermissions.others.terminales_libres)">
        <div class="card-header--sidebar">
            Terminales libres
        </div>

        <div>
            <div class="card-footer--sidebar row ml-0">
                <div class="col-12 col-md pt-1">
                    <img src="./assets/img/phone_home.svg" class="img-fluid avisos--sidebar--icon" width="16">
                    Venta de terminales
                </div>
                <div class="col-12 col-md-auto mobile--ver--sidebar">
                    <a href="{{ baseUrl }}tlib_activa.itx" class="btn btn-black-sidebar btn-sm">Acceder</a>
                </div>
            </div>
            <div class="card-footer--sidebar row ml-0">
                <div class="col-12 col-md pt-1">
                    <img src="./assets/img/phone_home.svg" class="img-fluid avisos--sidebar--icon" width="16">
                    Consulta tus ventas
                </div>
                <div class="col-12 col-md-auto mobile--ver--sidebar">
                    <a href="{{ baseUrl }}tlib_recogida_pdv.itx" class="btn btn-black-sidebar btn-sm">Acceder</a>
                </div>
            </div>
            <div class="card-footer--sidebar row ml-0">
                <div class="col-12 col-md pt-1">
                    <img src="./assets/img/phone_home.svg" class="img-fluid avisos--sidebar--icon" width="16">
                    Gestiona el stock
                </div>
                <div class="col-12 col-md-auto mobile--ver--sidebar">
                    <a href="{{ baseUrl }}tlib_gestion_stock.itx" class="btn btn-black-sidebar btn-sm">Acceder</a>
                </div>
            </div>
        </div>
    </div>
</ng-template>
