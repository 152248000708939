<div *ngIf="!order && !orderNotFound" class="mt-5 mb-5">
    <h5 align="center" class="tar-name-preload">Obteniendo orden...</h5>

    <div align="center">
        <img class="" src="./assets/img/spinner_rm.svg" width="30" alt="">
    </div>
</div>

<div *ngIf="loadingCobertura" class="mt-5 mb-5">
    <h5 align="center" class="tar-name-preload">Consultando cobertura...</h5>

    <div align="center">
        <img class="" src="./assets/img/spinner_rm.svg" width="30" alt="">
    </div>
</div>

<div *ngIf="order && !loadingCobertura">
    <div class="content mb-2">
        <div class="row">
            <div class="col-md-12">
                <h5 class="title-step mt-4" align="center">
                    Resumen de la activación
                </h5>
            </div>
        </div>
    </div>

    <div class="mt-3">
        <div class="row">
            <div class="col-md-12">
                <div class="jumbotron-norm-r mb-3">
                    <div class="row ml-1">
                        <div class="col-lg-2"><h5 align="left" class="tar-name-6 mt-2 mb-1">Nº de contrato</h5>
                            <h5 align="left" class="tar-name-7 mt-1">{{ order.result_id != null ? order.result_id.toUpperCase() : 'Pendiente de asignar' }}</h5>
                        </div>

                        <div class="col-lg-2">
                            <h5 align="left" class="tar-name-6 mt-2 mb-1">Fecha</h5>
                            <h5 align="left" class="tar-name-7 mt-1">{{ order.created_at | date: 'dd/MM/yyyy HH:mm' }}</h5>
                        </div>

                        <div class="col-lg-2">
                            <h5 align="left" class="tar-name-6 mt-2 mb-1">Estado</h5>
                            <h5 align="left" class="tar-name-7 mt-1 complete">{{ order.status_description }}</h5>
                        </div>

                        <div class="col-lg-2" *ngIf="order.error_description && order.error_description !== ''">
                            <h5 align="left" class="tar-name-6 mt-2 mb-1">Descripción</h5>
                            <h5 align="left" class="tar-name-7 mt-1 complete">{{ order.error_description }}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="mt-2">
        <div class="row">
            <div class="col-md-12">
                <div class="card mb-3">
                    <div class="card-header">
                        <div class="d-flex justify-content-between align-items-center">
                            <div>
                                <h6 class="subtitulo-pack2"><b>Cliente</b></h6>
                            </div>
                        </div>
                    </div>

                    <div class="card-body bg-white">
                        <div class="row mt-4">
                            <div class="col-lg-8">
                                <!-- Nombre cliente -->
                                <h5 class="title-list ml-3">
                                    <span class="text-list"
                                          *ngIf="(order.customer_document_type == null || order.customer_document_type.document_type_id !== 4) && order.customer_full_name != null && order.customer_full_name != ''">
                                        <b>{{ order.customer_full_name }}</b>
                                    <br><br>
                                    </span>
                                    <span class="text-list"
                                          *ngIf="order.customer_document_type != null && order.customer_document_type.document_type_id === 4">
                                        <b>{{ order.customer_company_name }}</b>
                                    <br><br>
                                    </span>
                                    <!-- Documento de identidad -->
                                    <span class="font-list" *ngIf="order.customer_document_type != null">
                                        <img src="./assets/img/file.svg" class="img-fluid" width="13">
                                        {{ order?.customer_document_type?.description}}: <b>{{ order.customer_document }}</b>
                                    <br>
                                    </span>
                                    <!-- Fecha de solicitud -->
                                    <span class="font-list">
                                        <img src="./assets/img/clock.svg" class="img-fluid" width="13">
                                        Fecha de solicitud: <b>{{ order.created_at | date: 'dd/MM/yyyy, HH:mm' }}</b>
                                    </span>
                                    <br>
                                    <!-- Tipo de alta -->
                                    <span class="font-list">
                                        <img src="./assets/img/package2.svg" class="img-fluid" width="13">
                                        Tipo de alta:
                                        <b *ngIf="order.type === 'broadband'">
                                            Solo Fibra
                                        </b>
                                        <b *ngIf="order.type === 'convergent'">
                                            {{ (order.broadband?.portability ? 'Portabilidad fibra' : 'Alta fibra') + ' + ' + (order.mobiles[0]?.portability ? 'Portabilidad móvil' : 'Alta móvil') + (order?.mobiles[0]?.rate?.name ? ' (' + order?.broadband?.rate?.name + '+' + order?.mobiles[0]?.rate?.name + ': ' + getConvergentPrice(order) + '€)' : '') }}
                                        </b>
                                        <b *ngIf="order.type !== 'convergent' && order.type !== 'broadband'">
                                            {{ 'Móvil' + (order?.mobiles[0]?.rate?.name ? ' (' + order?.mobiles[0]?.rate?.name + ' ' + getRatePrice(order?.mobiles[0]?.rate) + '€)' : '') }}
                                            <b class="info-secundaria" *ngIf="order.mobiles && order.mobiles.length > 1">
                                                + {{ order.mobiles.length - 1 }} línea(s) adicional(es)
                                            </b>
                                        </b>
                                    </span>
                                    <!-- Estado fibra -->
                                    <span class="font-list" *ngIf="order.broadband && order.broadband.status_description && order.broadband.status_description != ''">
                                        <br>
                                        <img src="./assets/img/phone.svg" class="img-fluid" width="13">
                                        Estado fibra: <b>{{ order.broadband.status_description }}</b>
                                    </span>
                                    <br>
                                    <span class="font-list" *ngIf="order.mobiles && order.mobiles.length > 0">
                                        <img src="./assets/img/phone.svg" class="img-fluid" width="13">
                                        <span *ngIf="order.mobiles[0].portability">
                                            Línea móvil: <b>{{ (order.mobiles[0].msisdn && order.mobiles[0].msisdn !== '' ? order.mobiles[0].msisdn : 'Pendiente de asignar') }}</b>
                                        </span>
                                        <span *ngIf="!order.mobiles[0].portability">
                                            Línea móvil: <b>{{ (order.mobiles[0].new_msisdn && order.mobiles[0].new_msisdn !== '' ? order.mobiles[0].new_msisdn : 'Pendiente de asignar') }}</b>
                                        </span>
                                    </span>
                                    <!-- Estado linea movil -->
                                    <span class="font-list"
                                          *ngIf="order.mobiles && order.mobiles.length > 0 && order.mobiles[0] && order.mobiles[0].status_description && order.mobiles[0].status !== -1">
                                        <br>
                                        <img src="./assets/img/phone.svg" class="img-fluid" width="13">
                                        Estado línea móvil: <b>{{ order.mobiles[0].status_description }}</b>
                                    </span>
                                </h5>
                            </div>

                            <div align="right" class="col-lg-4">
                                <div class="col-lg-12 mb-2">
                                    <span class="badge badge-pill badge-success font-list"
                                          *ngIf="(order.type === 'convergent' || order.type === 'broadband') && order.broadband">
                                        <span *ngIf="order.type === 'convergent'">
                                            {{ order.broadband.rate.name + '+' + order.mobiles[0]?.rate?.name + ': ' + priceWithIVA(+order.broadband.rate?.rate_amount + +order.mobiles[0]?.rate?.rate_amount - +order.broadband.rate?.discount).toFixed(0) }}
                                            €
                                        </span>
                                        <span *ngIf="order.type === 'broadband' && order.broadband.length > 0">
                                            {{ order.broadband.rate.name + ': ' + priceWithIVA(+order.broadband.rate?.rate_amount).toFixed(0) }} €
                                        </span>
                                    </span>
                                </div>
                                <div class="col-lg-12 mb-2" *ngFor="let mobile of order.mobiles; index as i">
                                    <span class="badge badge-pill badge-success font-list" *ngIf="mobile.rate && !(order.type === 'convergent' && i === 0)">
                                        {{ mobile.rate?.name + ': ' + priceWithIVA(mobile.rate?.rate_amount).toFixed(0) }} €
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--------- Resumen de la contratación ------------>
    <!------------------------------------------------->

    <div class="mt-2">
        <div class="row">
            <div class="col-md-12">
                <div class="accordion" id="accordionExample">
                    <div class="card">
                        <div class="card-header bg-rm">
                            <div class="d-flex justify-content-between align-items-center">
                                <div>
                                    <h6 class="subtitulo-pack2 ml-3">
                                        <b>Resumen de la tramitación</b>
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <ng-template  [ngIf]="(order.mobiles.length > 0) || (order.broadband && order.broadband.rate !== null) " [ngIfElse]="viewNotMobiles">
                            <div class="bg-white">
                                <div class="row">
                                    <div class="col-md-12">
                                        <ul class="list-group list-group-flush">
                                            <li class="list-group-item cuerpo-pack-2">
                                                <!-- CONVERGENTE -->
                                                <div *ngIf="order.type === 'convergent' && order.broadband">
                                                    <h5 align="left" class="tar-name-7 mt-2 ml-3">
                                                        <b>Fibra y móvil</b>
                                                    </h5>

                                                    <div class="resumen d-flex justify-content-between align-items-center">
                                                        <span class="sidebar-info4 verde"> Conceptos</span>
                                                        <small class="text-muted"></small>
                                                    </div>

                                                    <div class="resumen d-flex justify-content-between align-items-center">
                                                        <!-- tarifa -->
                                                        <span class="sidebar-info5"><img src="./assets/img/package2.svg" class="img-fluid" width="13">
                                                            {{ order.broadband.rate.name }}
                                                        </span>
                                                        <small class="text-muted">
                                                            <!-- Precio tarifa -->
                                                            <span class="sidebar-info3">
                                                                <b>{{ priceWithIVA(order.broadband.rate?.rate_amount).toFixed(0) }} €/mes</b>
                                                            </span>
                                                        </small>
                                                    </div>

                                                    <div class="resumen d-flex justify-content-between align-items-center pl-3"
                                                        *ngIf="order.broadband != null && order.broadband.status_description != null && order.broadband.status_description != ''">
                                                        <span class="sidebar-info5">
                                                            Estado fibra: <b>{{ order.broadband.status_description }}</b>
                                                        </span>
                                                    </div>

                                                    <div *ngIf="order.mobiles && order.mobiles.length && order.mobiles[0].principal">
                                                        <div class="resumen d-flex justify-content-between align-items-center">
                                                            <!-- tarifa -->
                                                            <span class="sidebar-info5"><img src="./assets/img/package2.svg" class="img-fluid" width="13">
                                                                {{ order.mobiles[0].rate.name }} ({{ order.mobiles[0].contract_type === 'postpaid' ? 'Pospago' : 'Prepago' }})
                                                                <br>
                                                                <span class="pl-3" *ngIf="order.mobiles[0].rate.label_data && order.mobiles[0].rate.label_data !== '' && order.mobiles[0].rate.resume_label_voice && order.mobiles[0].rate.resume_label_voice !== ''">
                                                                    {{ order.mobiles[0]?.rate.label_data }} + {{ order.mobiles[0]?.rate.resume_label_voice }}
                                                                </span>
                                                            </span>
                                                            <small class="text-muted">
                                                                <!-- Precio tarifa -->
                                                                <span class="sidebar-info3">
                                                                    <b>{{ priceWithIVA(order.mobiles[0].rate?.rate_amount).toFixed(0) }} €/mes</b>
                                                                </span>
                                                            </small>
                                                        </div>

                                                        <div class="resumen d-flexflex-column  justify-content-between align-items-start">
                                                            <span class="ml-3 d-block" *ngIf="!order.mobiles[0].portability && order.mobiles[0].new_msisdn != ''">
                                                                <small>Línea Móvil: <strong>{{ order.mobiles[0].new_msisdn }}</strong></small>
                                                            </span>
                                                            <span class="ml-3 d-block" *ngIf="order.mobiles[0].portability && order.mobiles[0].msisdn != ''">
                                                                <small>Número Portabilidad Móvil: <strong>{{ order.mobiles[0].msisdn }}</strong></small>
                                                            </span>
                                                            <span class="ml-3 d-block" *ngIf="order.mobiles[0].status_description && order.mobiles[0].status_description != ''">
                                                                <small>Estado línea móvil: <strong>{{ order.mobiles[0].status_description }}</strong></small>
                                                            </span>
                                                            <span class="ml-3 d-block" *ngIf="order.mobiles[0].new_iccid && order.mobiles[0].new_iccid != ''">
                                                                <small>ICCID: <strong>{{ order.mobiles[0].new_iccid }}</strong></small>
                                                            </span>
                                                            <span class="ml-3 d-block" *ngIf="order.mobiles[0].portability && order.mobiles[0].processing_date != '' && order.mobiles[0].processing_date != undefined ">
                                                                <small>Fecha de portabilidad: <strong>{{ order.mobiles[0].processing_date  | date: 'dd/MM/yyyy' : '+1000' }}</strong></small>
                                                            </span>
                                                        </div>

                                                        <div class="resumen d-flex justify-content-between align-items-center">
                                                            <!-- tarifa -->
                                                            <span class="sidebar-info5"><img src="./assets/img/package2.svg" class="img-fluid" width="13">
                                                                Descuento por fibra y móvil
                                                            </span>
                                                            <small class="text-muted">
                                                                <!-- Precio tarifa -->
                                                                <span class="sidebar-info3">
                                                                    <b>-{{ priceWithIVA(order.broadband.rate.discount).toFixed(0) }} €/mes</b>
                                                                </span>
                                                            </small>
                                                        </div>
                                                    </div>

                                                    <br>

                                                    <div class="resumen d-flex justify-content-between align-items-center mt-2">
                                                        <span class="sidebar-info"><b>Total</b></span>
                                                        <small class="text-muted trash-carrito">
                                                            <!-- Precio tarifa -->
                                                            <span class="sidebar-info2">
                                                                <b>{{ priceWithIVA(+order.broadband.rate?.rate_amount + +order.mobiles[0].rate?.rate_amount - +order.broadband.rate.discount).toFixed(0) }} €/mes</b>
                                                            </span>
                                                        </small>
                                                    </div>
                                                </div>

                                                <!-- SOLO FIBRA -->
                                                <div *ngIf="order.type === 'broadband' && order.broadband">
                                                    <h5 align="left" class="tar-name-7 mt-2 ml-3">
                                                        <b>Solo Fibra</b>
                                                    </h5>
                                                    <div class="resumen d-flex justify-content-between align-items-center">
                                                        <span class="sidebar-info4 verde"> Conceptos</span>
                                                        <small class="text-muted"></small>
                                                    </div>

                                                    <div class="resumen d-flex justify-content-between align-items-center">
                                                        <!-- tarifa -->
                                                        <span class="sidebar-info5"><img src="./assets/img/package2.svg" class="img-fluid" width="13">
                                                            {{ order.broadband.rate.name }}
                                                            </span>
                                                        <small class="text-muted">
                                                            <!-- Precio tarifa -->
                                                            <span class="sidebar-info3">
                                                                    <b>{{ priceWithIVA(order.broadband.rate?.rate_amount).toFixed(0) }} €/mes</b>
                                                                </span>
                                                        </small>
                                                    </div>

                                                    <div class="resumen d-flex justify-content-between align-items-center pl-3"
                                                        *ngIf="order.broadband != null && order.broadband.status_description != null && order.broadband.status_description != ''">
                                                        <span class="sidebar-info5">
                                                            Estado fibra: <b>{{ order.broadband.status_description }}</b>
                                                        </span>
                                                    </div>

                                                    <br>

                                                    <div class="resumen d-flex justify-content-between align-items-center mt-2">
                                                        <span class="sidebar-info"><b>Total</b></span>
                                                        <small class="text-muted trash-carrito">
                                                            <!-- Precio tarifa -->
                                                            <span class="sidebar-info2"><b>{{ priceWithIVA(order.broadband.rate?.rate_amount).toFixed(0) }} €/mes</b></span>
                                                        </small>
                                                    </div>
                                                </div>

                                                <!-- RESUMEN LÍNEA ADICIONAL -->

                                                <div *ngFor="let mobile of order.mobiles; index as i" [hidden]="order.type === 'convergent' && mobile.principal">
                                                    <hr *ngIf="order.type !== 'mobile' || i > 0">

                                                    <h5 align="left" class="tar-name-7 mt-2 ml-3">
                                                        <b>{{ i === 0 ? 'Línea principal' : 'Línea adicional' }}</b>
                                                    </h5>

                                                    <!-- CONCEPTOS -->
                                                    <div class="resumen d-flex justify-content-between align-items-center">
                                                        <span class="sidebar-info4 verde"> Conceptos</span>
                                                        <small class="text-muted">
                                                        </small>
                                                    </div>

                                                    <div class="resumen d-flex justify-content-between align-items-center">
                                                        <!-- tarifa -->
                                                        <span class="sidebar-info5">
                                                            <img src="./assets/img/package2.svg" class="img-fluid" width="13">
                                                            {{ mobile.rate.name }} ({{ mobile.contract_type === 'postpaid' ? 'Pospago' : 'Prepago' }})
                                                            <br>
                                                            <span class="pl-3" *ngIf="mobile.rate.label_data && mobile.rate.label_data !== '' && mobile.rate.resume_label_voice && mobile.rate.resume_label_voice !== ''">
                                                                {{ mobile.rate.label_data }} + {{ mobile.rate.resume_label_voice }}
                                                            </span>
                                                            </span>
                                                        <small class="text-muted">
                                                            <!-- Precio tarifa -->
                                                            <span class="sidebar-info3"><b>{{ getRatePrice(mobile.rate) }} €/mes</b></span>
                                                        </small>
                                                    </div>
                                                    <div class="resumen pt-0 d-flex flex-column align-items-start">
                                                        <span class="ml-3 d-block" *ngIf="!mobile.portability && mobile.new_msisdn">
                                                            <small>Línea Móvil: <strong>{{ mobile.new_msisdn }}</strong></small>
                                                        </span>

                                                        <span class="ml-3 d-block" *ngIf="mobile.portability && mobile.msisdn != ''">
                                                            <small>Número Portabilidad Móvil: <strong>{{ mobile.msisdn }}</strong></small>
                                                        </span>

                                                        <span class="ml-3 d-block" *ngIf="mobile.status_description && mobile.status_description != ''">
                                                            <small>Estado línea móvil: <strong>{{ mobile.status_description }}</strong></small>
                                                        </span>

                                                        <span class="ml-3 d-block" *ngIf="mobile.new_iccid && mobile.new_iccid !== ''">
                                                            <small>ICCID: <strong>{{ mobile.new_iccid }}</strong></small>
                                                        </span>

                                                        <span class="ml-3 d-block" *ngIf="mobile.portability && mobile.processing_date != '' && mobile.processing_date != undefined ">
                                                            <small>Fecha de portabilidad: <strong>{{ mobile.processing_date  | date: 'dd/MM/yyyy' : '+1000' }}</strong></small>
                                                        </span>
                                                    </div>

                                                    <br>

                                                    <div class="resumen d-flex justify-content-between align-items-center mt-2">
                                                        <span class="sidebar-info"><b>Total</b></span>
                                                        <small class="text-muted trash-carrito">
                                                            <!-- Precio tarifa -->
                                                            <span class="sidebar-info2"><b>{{ getRatePrice(mobile.rate) }} €/mes</b></span>
                                                        </small>
                                                    </div>
                                                </div>

                                                <hr>

                                                <!-- Importe Total -->
                                                <div class="resumen-total total d-flex justify-content-between align-items-center"
                                                    *ngIf="(order.broadband && order.mobiles.length > 1) || (!order.broadband && order.mobiles.length > 1)">
                                                    <h5 class="total-color"><b>total</b></h5>
                                                    <h5 class="text-auto"><b>{{ priceWithIVA(totalPrice).toFixed(0) }} €/mes</b></h5>
                                                </div>
                                                <div class="resumen-total total d-flex justify-content-between align-items-center">
                                                    <span class="total-color"><b>IVA incluido</b></span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template #viewNotMobiles>
                            <div class="bg-white">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="px-4 py-4">
                                            <b>Este pedido no contiene datos sobre los servicios a contratar.</b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- SECCIÓN DE DOCUMENTOS-->
    <div class="firma mt-4 mb-5" *ngIf="order.super_status === superStatus.in_process || order.super_status === superStatus.active">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-lg-6" *ngIf="order.sepa_asset_id || order.contract_asset_id">
                        <div class="card">
                            <div class="card-header">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div>
                                        <h6 class="subtitulo-pack2 ml-3">
                                            <b>Descarga de contratos</b>
                                        </h6>
                                    </div>
                                    <small class="text-muted">
                                        <div class="checkmark{{ allDownloaded() ? '' : '-disabled'}}"></div>
                                    </small>
                                </div>
                            </div>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item cuerpo-pack-2">
                                    <h5 align="left" class="tar-name-7 mt-2 ml-3">Hay <b>{{ order.sepa_asset_id && order.contract_asset_id ? '2 contratos' : '1 contrato ' }} para descargar</b></h5>
                                    <div class="d-flex" *ngIf="order.contract_asset_id">
                                        <h5 align="left" class="tar-name-8 mt-1 ml-3 cursor-pointer" (click)="descargar('contrato')">
                                            <img src="./assets/img/file.svg" class="img-fluid ico-title" width="13" > Contrato
                                            <span *ngIf="downloaded.contract" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                        </h5>
                                    </div>
                                    <span *ngIf="downloaded.contract_error" class="error-download-file">Contrato aún no disponible inténtalo de nuevo en unos segundos</span>

                                    <div class="d-flex" *ngIf="order.sepa_asset_id">
                                        <h5 align="left" class="tar-name-8 mt-1 ml-3 cursor-pointer" (click)="descargar('sepa')">
                                            <img src="./assets/img/file.svg" class="img-fluid ico-title" width="13" > SEPA
                                            <span *ngIf="downloaded.sepa" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                        </h5>
                                    </div>
                                    <span *ngIf="downloaded.sepa_error" class="error-download-file">SEPA aún no disponible inténtalo de nuevo en unos segundos</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="card">
                            <div class="card-header">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div>
                                        <h6 class="subtitulo-pack2 ml-3">
                                            <b>Subida de Documentación</b>
                                        </h6>
                                    </div>

                                    <small class="text-muted">
                                        <div class="checkmark{{ order.document_uploads.length < 2 ? '-disabled' : '' }}"></div>
                                    </small>
                                </div>
                            </div>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item cuerpo-pack-2">
                                    <h5 align="left" class="tar-name-7 mt-2 ml-3">Documentos subidos</h5>
                                    <div class="">
                                        <h5 *ngFor="let file of order.document_uploads" align="left" class="tar-name-8 mt-1 ml-3 verde cursor-pointer" (click)="descargar('other', file)">
                                            <img src="./assets/img/file.svg" class="img-fluid ico-title" width="13"> {{ file.file_name }}
                                            <span *ngIf="file.downloaded" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                        </h5>
                                        <!--<h5 align="left" class="tar-name-8 mt-1 ml-3 verde"><img src="./assets/img/file.svg" class="img-fluid ico-title" width="13"> Documento 3</h5>-->
                                    </div>

                                    <div align="center" class="mt-5 mt-2 mb-2">
                                        <a href="#!" (click)="showErrorUploadFile = false" data-toggle="modal" data-target="#documentacion" class="btn btn-green btn-sm btn-block" *ngIf="pdv?.permissions?.REPUBLICA_MOVIL !== 'TUVO_ACTIVACIONES'">Subir documentación</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- MODALES -->
    <div class="modal fade" id="contratos" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">

                <div class="modal-header">
                    <h5 class="modal-title" >Firma de contratos</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true"><img src="./assets/img/times.svg" class="img-fluid ico-title" width="20"></span>
                    </button>
                </div>

                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <h6 class="subtitulo-pack4">Listado de contratos</h6>
                            <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                                <div class="btn-group mr-2" role="group" aria-label="" (click)="descargar('contrato')">
                                    <div class="jumbotron-doc pending-doc">
                                        <h6 class="subtitulo-pack3"><b>Contrato</b></h6>
                                        <h6 class="subtitulo-pack3"><img src="./assets/img/clock.svg" class="img-fluid ico-title" width="10"> Descargar</h6>
                                    </div>
                                </div>

                                <div class="btn-group mr-2" role="group" aria-label="" (click)="descargar('sepa')">
                                    <div class="jumbotron-doc pending-doc">
                                        <h6 class="subtitulo-pack3"><b>Sepa</b></h6>
                                        <h6 class="subtitulo-pack3"><img src="./assets/img/clock.svg" class="img-fluid ico-title" width="10"> Descargar</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="bb-line"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="documentacion" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" style="display: none;" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">

                <div class="modal-header">
                    <h5 class="modal-title" >Documentación</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true"><img src="./assets/img/times.svg" class="img-fluid ico-title" width="20"></span>
                    </button>
                </div>

                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <!-- IBAN -->
                            <ng-container *ngFor="let file of order.document_uploads">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div>
                                        <h6>
                                            <span class="badge badge-pill badge-success">Subido</span>
                                        </h6>
                                    </div>
                                    <small class="text-muted">
                                        <h6 class="subtitulo-pack3">{{ file.file_name }}</h6>
                                    </small>
                                </div>
                                <div class="bb-line"></div>
                                <br>
                            </ng-container>

                            <div class="area mb-4">
                                <div id="dropZone"
                                    (drop)="dropFile($event)"
                                    (dragover)="allowDrop($event)"
                                    (click)="inputFile.click()">
                                    <img *ngIf="!uploadingFile"
                                        src="./assets/img/upload-cloud.svg"
                                        id="dropZoneImg"
                                        class="img-fluid mb-2"
                                        width="35">
                                    <img *ngIf="uploadingFile"
                                        src="./assets/img/spinner_rm.svg"
                                        class="img-fluid"
                                        width="30">
                                    <input type="file" name="file" id="fileBtn" (change)="fileChanged($event)" style="visibility: hidden; width:0; height:0" #inputFile>
                                    <br>
                                    <span style="font-size: medium;">Haz click sobre el icono para seleccionar un archivo o arrastralo al área de punteada</span>
                                </div>
                            </div>
                            <div *ngIf="showErrorUploadFile">
                                Error al subir documentación. Por favor, inténtelo de nuevo más tarde
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

</div>

<div class="container mt-3" *ngIf="orderNotFound">
    <div class="row">
        <div class="col-md-12">
            <h5 class="title-step mt-4" align="center">
                Lo sentimos, el pedido que está buscando no existe
            </h5>
            <div align="center">
                <button (click)="goBack()" class="btn btn-green btn-sm mt-2">
                    Volver al listado
                </button>
            </div>
        </div>
    </div>
</div>
