import {Component, Input, OnInit} from '@angular/core';
import {HomeBrand} from '../../../../models/home-brand';

@Component({
    selector: 'app-hm-brand',
    templateUrl: './hm-brand.component.html',
    styleUrls: ['./hm-brand.component.css']
})
export class HmBrandComponent implements OnInit {
    @Input() brand: HomeBrand;

    constructor() {

    }

    ngOnInit(): void {
    }

    getCardClass() {
        return {
            [`card--${this.brand.prefix}`]: true,
            [`card--${this.brand.prefix}--2`]: this.brand.isOpen
        };
    }

    toggleOpen() {
        this.brand.onToggle(!this.brand.isOpen ? this.brand.prefix : null);

        if (this.brand.canOpen) {
            this.brand.isOpen = !this.brand.isOpen;
        }
    }
}
