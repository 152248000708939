<div style="background-color: #FFD200;">
    <ngb-carousel *ngIf="(showSimyoAdditional() && showNotificationAdditional) || (pdv?.alerts?.notificar_cambio_mayorista && showNotificationMayorista)" 
        class="container px-0" [showNavigationArrows]="showNavigationArrows()">

        <!-- Notificacion Simyo adicionales -->
        <ng-template ngbSlide *ngIf="showSimyoAdditional() && showNotificationAdditional">
            <div class="alert mt-0 px-3 py-3 text-bold info-notify">
                <div class="row">
                    <div align="center" class="col-sm-2">
                        <img src="./assets/img/sm-login-color.svg" class="img-fluid">
                    </div>
                    <div class="col-sm-10">
                        Donde cabe uno de simyo, caben veinte ;)
                        <br>
                        A partir del 23 de Agosto:
                        <br>
                        <b>¡Líneas adicionales desde solo 4€!</b>
                    </div>
                </div>
                <span class="click--dismiss dismiss-button" (click)="showNotificationAdditional = false" align="right">
                    <i-feather name="x-circle" class="icon--dismiss ml-2"></i-feather>
                </span>
            </div>
        </ng-template>
        <!-- Notificacion Simyo adicionales -->

        <!-- Notificacion cambio de mayorista -->
        <ng-template ngbSlide *ngIf="pdv?.alerts?.notificar_cambio_mayorista && showNotificationMayorista">
            <div class="alert mt-0 px-3 py-3 text-bold info-notify">
                <div class="row">
                    <div align="center" class="col-sm-1" style="align-self: center; ">
                        <img src="./assets/img/megaphone.png" class="img-fluid" width="48">
                    </div>
                    <div class="col-sm-11" [innerHTML]="pdv?.alerts?.notificar_cambio_mayorista"></div>
                </div>
                <span class="click--dismiss dismiss-button" (click)="showNotificationMayorista = false" align="right">
                    <i-feather name="x-circle" class="icon--dismiss ml-2"></i-feather>
                </span>
            </div>
        </ng-template>
        <!-- Notificacion cambio de mayorista -->
    </ngb-carousel>
</div>
