import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { Observable, of, Subscription } from 'rxjs';
import { SimyoService } from '../../services/simyo.service';
import { SimyoNormalizadorService } from '../../services/simyo-normalizador.service';
import { SimyoUserDataService } from '../../services/simyo-user-data.service';
import { SimyoOrderService } from '../../services/simyo-order.service';
import { brands, maxDate, minDate, mobileContractType, orderType } from 'src/app/shared/constantes';
import { ibanValidator } from 'src/app/utils/validators/iban-validator';
import { cifValidator, dniValidator, getMaxLengthByDoc, nieValidator } from 'src/app/utils/validators/spanish-id-validator';
import { SimyoTerminalDeliveryComponent } from '../simyo-terminal-delivery/simyo-terminal-delivery.component';
import { onChangeZip, searchRoadType } from 'src/app/utils/normalizerFunctions';
import { HomeService } from 'src/app/home/services/home.service';
import { allPermissions } from 'src/app/shared/permissions';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from 'src/app/shared/components/alert-dialog/alert-dialog.component';
import { accountTypeSimyo, billCycleTypeSimyo, countrySimyo, creditCardTypeSimyo, documentTypeSimyo, genderSimyo, masterAllSimyo, provinceSimyo, trackTypeSimyo } from '../../models/masterAllSimyo';
import { RedirectDialogComponent } from 'src/app/shared/components/redirect-dialog/redirect-dialog.component';
import { blankSpaceValidator } from 'src/app/utils/validators/blank-space-validator';
import { TaxZone } from 'src/app/utils/validators/tax-zone-validator';
import { isValueDupWith, isAdult } from 'src/app/utils/validators/any-form-valid';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

@Component({
    selector: 'app-simyo-user-data',
    templateUrl: './simyo-user-data.component.html',
    styleUrls: ['./simyo-user-data.component.scss', '../../../../assets/css/simyo-theme.css']
})
export class SimyoUserDataComponent implements OnInit, OnDestroy {
    @Input() formGroup: FormGroup;
    @Input() stepper: MatStepper;
    @Input() typeOrder;
    @ViewChild('terminalDelivery') terminalDelivery: SimyoTerminalDeliveryComponent;

    private coverageData: any;
    public dataCustomer: any;
    public requireCif: boolean = false;
    public fullNameBanking = '';
    public country = environment.googleMapsCountry;
    public maxDate: Date = maxDate;
    public minDate: Date = minDate;
    public showBankingData: boolean = true;
    public showDelivery: boolean = true;
    public cities: [];
    public cityFilteredOptions: Observable<any[]>;
    public isCustomer: boolean = false;
    public thereIsMoreCreditcardData: boolean = false;
    public thereIsMoreBankData: boolean = false;
    public accountSelected: any;
    public maxLengthByDoc = 9;
    public allPermissions = allPermissions;
    public firstZipCode: string = '';
    public isPortabilityChange: boolean = false;

    // Master
    public documentTypes: documentTypeSimyo[] = [];
    public documentTypesDonor: documentTypeSimyo[] = [];
    public roadTypes: trackTypeSimyo[] = [];
    public provinces: provinceSimyo[] = [];
    public countries: countrySimyo[] = [];
    public sexTypes: genderSimyo[] = [];
    public accountTypes: accountTypeSimyo[] = [];
    public billCycleTypes: billCycleTypeSimyo[] = [];
    public creditCardTypes: creditCardTypeSimyo[] = [];

    private allSubscription: Subscription[] = [];

    constructor(
        private simyoService: SimyoService,
        private normalizadorService: SimyoNormalizadorService,
        private userDataService: SimyoUserDataService,
        private orderService: SimyoOrderService,
        private homeService: HomeService,
        private matdialog: MatDialog,
        public permSv: PermissionService
    ) { }

    ngOnInit(): void {
        this.formGroup.get('codigoPostal').valueChanges.subscribe(()=>{
            if(this.formGroup.get('codigoPostal')?.errors?.sameTax) {
                //Mostrar modal para cambiar el impuesto
                this.matdialog.open(RedirectDialogComponent, {
                    data: {title: 'CUIDADO',
                        message: 'El CP introducido cambia el tipo de impuesto aplicable',
                        optionStay: 'Modificar CP',
                        optionRedirect: 'Volver a inicio'}
                });
            }
        });
        this.formGroup.get('email')?.valueChanges.subscribe((data) => {
            let emailConfirmation = this.formGroup.get('email_confirm');
            emailConfirmation?.markAsUntouched();
            emailConfirmation?.setValue('');
            emailConfirmation?.disable();

            if (this.formGroup.get('email')?.valid) {
                emailConfirmation?.enable();
            }
        })

        this.formGroup.get('portability_origen_email')?.valueChanges.subscribe((data) => {
            if(this.formGroup.value.portability_origen_email == data) {
                return;
            }
            let emailConfirmation = this.formGroup.get('portability_origen_email_confirm');
            emailConfirmation?.markAsUntouched();
            emailConfirmation?.setValue('');
            emailConfirmation?.disable();

            if (this.formGroup.get('portability_origen_email')?.valid) {
                emailConfirmation?.enable();
            }
        })

        this.allSubscription.push(
            this.simyoService.getProvincesCommunFromService().pipe(
                map((data:any) => {
                    if(data && data.msg) {
                        this.simyoService.setProvincesCommun(data.msg);
                        return data.msg;
                    }
                    return null;
                }),
                tap((data: any) => {
                    if (data) {
                        this.provinces = data;
                        this.onChangeZip(this.formGroup.get('codigoPostalNormaliza').value);
                    }
                })
            ).subscribe()
        );

        this.allSubscription.push(
            this.simyoService.getRoadTypesCommun().pipe(
                switchMap((data:any) => {
                    if(data != null) {
                        return of(data);
                    }
                    return this.simyoService.getRoadTypeCommunFromService().pipe(
                        catchError(error => {
                            return of(null);
                        }),
                        map((data:any) => {
                            if(data && data.msg) {
                                this.simyoService.setRoadTypesCommun(data.msg);
                                return of(data.msg);
                            }
                            return of(null);
                        })
                    )
                }),
                tap((data: any) => {
                    if (data) {
                        this.roadTypes = data;
                    }
                })
            ).subscribe());

        this.allSubscription.push(this.simyoService.getMasterAll()
            .subscribe((data: masterAllSimyo) => {
                if (data) {
                    if (this.documentTypes && !this.documentTypes?.length) {
                        this.documentTypes = data.document_types;
                    }

                    if (this.documentTypesDonor && !this.documentTypesDonor?.length) {
                        this.documentTypesDonor = data.document_types_donor;
                    }

                    if (this.countries && !this.countries?.length) {
                        this.countries = data.countries.filter(country => !(this.formGroup.get('doctype').value === '1' && country.country_id === 192));
                    }
                    if (this.sexTypes && !this.sexTypes?.length) {
                        this.sexTypes = data.genders;
                    }
                    this.accountTypes = data.account_types;
                    this.billCycleTypes = data.bill_cycle_types;
                    this.creditCardTypes = data.credit_card_types;
                }
            })
        );

        this.allSubscription.push(this.normalizadorService.getResultadoCobertura()
            .subscribe((data: any) => {
                if (data && data.value.resultadoConsultaCobertura) {
                    this.coverageData = data;
                    this.setDataFromCoverage();
                }
            })
        );

        this.allSubscription.push(this.simyoService.getDataFromCustomer()
            .subscribe((data: any) => {
                if (data && data.customer) {
                    this.dataCustomer = data;
                    this.setDataFromCustomer(this.dataCustomer);
                    this.firstZipCode = this.formGroup.get('codigoPostal').value + '';
                    let zipValidators = [Validators.required, TaxZone(this.firstZipCode)];
                    this.formGroup.get('codigoPostal').setValidators(zipValidators);
                    this.formGroup.get('codigoPostal').updateValueAndValidity();

                }
            })
        );

        this.allSubscription.push(this.simyoService.getDataFromNotCustomer()
            .subscribe((data: any) => {
                if (data) {
                    this.setDataFromNotCustomer(data);
                    this.firstZipCode = this.formGroup.get('codigoPostal').value + '';
                    let zipValidators = [Validators.required, TaxZone(this.firstZipCode)];
                    this.formGroup.get('codigoPostal').setValidators(zipValidators);
                    this.formGroup.get('codigoPostal').updateValueAndValidity();
                }
            })
        );

        this.stepper.selectionChange
            .subscribe((data) => {
                this.formGroup.markAsUntouched();
                if ( this.terminalDelivery !== undefined) this.terminalDelivery.errorForm = false;
                if ((this.stepper.steps.length - 2) === data.selectedIndex) {
                    window.scrollTo({
                        top: 0,
                        left: 0 ,
                        behavior: 'smooth'
                    });
                }
            });

        this.allSubscription.push(this.orderService.getOrder()
            .subscribe((data: any) => {
                if (data) {
                    // Si algún mobile contiene la propiedad "customer_donor" la operacion contiene una portabilidad con cambio de titular y se muestra su formulario autorrellenando el telefono
                    let customerDonorIndex = data.mobiles.findIndex(obj => obj.hasOwnProperty('customer_donor'));
                    let oldIsPortabilityChange = this.isPortabilityChange;
                    this.isPortabilityChange = customerDonorIndex !== -1 ? true : false;
                    if(this.isPortabilityChange){
                        this.formGroup.get('portability_origen_phone').patchValue(data.mobiles[customerDonorIndex].msisdn);

                    };
                     //set validation portability change
                     if(oldIsPortabilityChange != this.isPortabilityChange) {
                         this.setValidationsPortabilityChangeData(this.isPortabilityChange);
                     }

                    // Mostrar método de pago
                    // Convergente o solo fibra
                    if (this.typeOrder === orderType.BROADBAND || this.typeOrder === orderType.CONVERGENT) {
                        this.showBankingData = true;
                        // Solo móvil
                    } else if (data.mobiles && data.mobiles.length) {
                        const found = data.mobiles.find(mobile => mobile.contract_type === mobileContractType.postpaid);
                        this.showBankingData = found ? true : false;
                    }
                    this.setValidationsBankAccount();

                    // Forma de pago
                    if (this.accountTypes && this.accountTypes.length) {
                        if (this.showBankingData) {
                            const found = this.accountTypes.find(x => x.account_type_id === 2);
                            this.formGroup.get('account_types').patchValue(found.account_type_id);
                        } else {
                            this.formGroup.get('account_types').patchValue('');
                        }
                    }

                    // Tipo de factura
                    if (this.billCycleTypes && this.billCycleTypes.length) {
                        if (this.showBankingData) {
                            const found = this.billCycleTypes.find(x => x.bill_cycle_type_id === 2);
                            this.formGroup.get('bill_cicle_types').patchValue(found.bill_cycle_type_id);
                        } else {
                            const found = this.accountTypes.find(x => x.account_type_id === 1);
                            this.formGroup.get('bill_cicle_types').patchValue(found.account_type_id);
                        }
                    }

                    // Mostrar método de entrega
                    if (data.mobiles && data.mobiles.length) {
                        const found = data.mobiles.find(mobile => mobile.terminal_oid_id);
                        this.showDelivery = found ? true : false;
                    } else {
                        this.showDelivery = false;
                    }

                    if (this.showDelivery) {
                        this.formGroup.get('is_custom_delivery').setValidators([Validators.required, blankSpaceValidator()]);
                    } else {
                        this.clearValidations('is_custom_delivery');
                        this.formGroup.get('is_custom_delivery').patchValue('');
                    }
                    this.formGroup.get('is_custom_delivery').updateValueAndValidity();
                }
            })
        );

        this.allSubscription.push(this.simyoService.getRatesMobile()
            .subscribe((data: any) => {
                if (data) {
                    this.userDataService.setUserData(this.formGroup.getRawValue());
                }
            })
        );

        if (this.formGroup.get('bank_account').value !== '') {
            this.fullNameBanking = this.getFullNameBanking();
        }

        if (this.formGroup.get('doctype').value != null && this.formGroup.get('doctype').value !== '') {
            this.changeTypeDocument(this.formGroup.get('doctype').value);
        }

        if (this.formGroup.get('codigoPostal').value != null && this.formGroup.get('codigoPostal').value !== '') {
            this.onChangeZip(this.formGroup.get('codigoPostal').value);
        }

        this.formGroup.get('provincia').disable();

    }

    private setValidationsBankAccount(forceIbanValidators = false) {
        // Validadores de la forma de pago
        if (this.showBankingData) {
            if (!this.thereIsMoreBankData || forceIbanValidators) {
                let ibanValidators = [Validators.required, blankSpaceValidator()];
                /*Aplicar el validador de iban si no es pasaporte (3)*/
                if(this.formGroup.get('doctype').value && +this.formGroup.get('doctype').value !== 3) {
                    ibanValidators =  [...ibanValidators, ibanValidator()];
                }
                this.formGroup.controls['new_bank_account'].setValidators(ibanValidators);
            }
            this.formGroup.get('bank_account').setValidators([Validators.required, blankSpaceValidator()]);
            this.formGroup.get('account_doctype').setValidators([Validators.required]);
            this.formGroup.get('account_doc').setValidators([Validators.required, Validators.pattern('^[a-zA-Z0-9]+$')]);
            this.formGroup.get('account_country').setValidators([Validators.required]);
            this.formGroup.get('account_firstName').setValidators([Validators.required, blankSpaceValidator()]);
            this.formGroup.get('account_surname1').setValidators([Validators.required, blankSpaceValidator()]);
            //agregar validaciones bankingData
        } else {
            this.clearValidations('new_bank_account');
            this.clearValidations('bank_account');
            this.clearValidations('account_doctype');
            this.clearValidations('account_doc');
            this.clearValidations('account_country');
            this.clearValidations('account_firstName');
            this.clearValidations('account_surname1');
            this.formGroup.get('new_bank_account').patchValue('');
            this.formGroup.get('bank_account').patchValue('');
            //eliminar validaciones bankingData
        }
        this.formGroup.get('new_bank_account').updateValueAndValidity();
        this.formGroup.get('bank_account').updateValueAndValidity();
        this.formGroup.get('account_doctype').updateValueAndValidity();
        this.formGroup.get('account_doc').updateValueAndValidity();
        this.formGroup.get('account_country').updateValueAndValidity();
        this.formGroup.get('account_firstName').updateValueAndValidity();
        this.formGroup.get('account_surname1').updateValueAndValidity();
    }

    validate(): boolean {
        this.setBankingData();
        this.formGroup.markAllAsTouched();
        this.formGroup.markAsTouched();
        this.formGroup.updateValueAndValidity();
        const result = [];
        if (!this.formGroup.get('tipoVia').value || this.formGroup.get('tipoVia').value === '') {
            searchRoadType(this.formGroup.get('nombreVia').value, this.roadTypes, 'value', this.formGroup.get('tipoVia'));
        }

        if (!this.formGroup.valid) {
            this.focusAndScrollFirstError();
            if (!this.formGroup.get('is_custom_delivery').valid) {
                this.terminalDelivery.errorForm = true;
            }
            return false;
        }
        if (this.terminalDelivery && this.terminalDelivery.errorForm) {
            this.terminalDelivery.errorForm = false;
        }
        return true;
    }

    public next() {
        if(!this.validationTaxZone(this.firstZipCode, (this.formGroup.get('codigoPostal').value + ''))) {
            this.matdialog.open(RedirectDialogComponent, {
                data: {title: 'CUIDADO',
                    message: 'El CP introducido cambia el tipo de impuesto aplicable',
                    optionStay: 'Modificar CP',
                    optionRedirect: 'Volver a inicio'}
            });
            return;
        }
        if (this.validate()) {
            this.stepper.next();
            return;
        }

    }

    public prev() {
        this.stepper.previous();
    }

    public changeTypeDocument(documentTypeId, formControlName?): void {
        const docValidators: ValidatorFn[] = [Validators.required, blankSpaceValidator(), Validators.pattern('^[a-zA-Z0-9]+$')];

        if (typeof documentTypeId === 'number') {
            documentTypeId = documentTypeId.toString();
        }

        switch (documentTypeId) {
            case '2':
                docValidators.push(dniValidator());
                break;
            case '1':
                if (this.formGroup.get('country').value === '192' && !formControlName) {
                    this.formGroup.get('country').patchValue(null);
                }
                docValidators.push(nieValidator());
                break;
            case '4':
                docValidators.push(cifValidator());
                break;
            default:
        }

        if(!formControlName){
            this.formGroup.get('doc').setValidators(docValidators);
            this.formGroup.get('doc').updateValueAndValidity();
            this.formGroup.get('company').updateValueAndValidity();
        } else if(formControlName == 'portability_origen_doc'){
            docValidators.push(isValueDupWith('doc'));
            this.formGroup.get(formControlName).setValidators(docValidators);
            this.formGroup.get(formControlName).updateValueAndValidity();

            this.formGroup.patchValue({
                portability_origen_nationality_id: documentTypeId == 2 ? 192 : null
            })
        }

    }

    public onChangeZip(value) {
        if (value.length > 5) {
            this.formGroup.get('codigoPostal').patchValue(value.slice(0, 5));
            }
        onChangeZip(value, this.provinces, 'postal_code', this.formGroup.get('provincia'));
    }


    public onAutocompleteSelected($event) {
        const addressComponents: any[] = $event.address_components;
        const postalCode = addressComponents.find(x => x.types.includes('postal_code'))?.long_name;
        if (!this.validationTaxZone(this.formGroup.get('codigoPostal').value + '', postalCode)) {
            this.matdialog.open(RedirectDialogComponent, {
                data: {title: 'CUIDADO',
                    message: 'El CP introducido cambia el tipo de impuesto aplicable',
                    optionStay: 'Modificar CP',
                    optionRedirect: 'Volver a inicio'}
            });
            this.formGroup.get('nombreVia').patchValue('');
        }
        else{
            if (addressComponents != null && addressComponents.length > 0) {
                this.formGroup.get('tipoVia').patchValue('');
                this.formGroup.get('nombreVia').patchValue('');
                this.formGroup.get('numero').patchValue('');
                this.formGroup.get('planta').patchValue('');
                this.formGroup.get('puerta').patchValue('');
                this.formGroup.get('escalera').patchValue('');
                this.formGroup.get('codigoPostal').patchValue('');
                this.formGroup.get('localidad').patchValue('');
                this.formGroup.get('provincia').patchValue('');

                for (const addressComponent of addressComponents) {
                    let field = '';
                    const types: string[] = addressComponent.types;
                    let value: string = addressComponent.long_name;
                    if (types.includes('route')) {
                        field = 'nombreVia';
                        value = searchRoadType(value, this.roadTypes, 'value', this.formGroup.get('tipoVia'));
                    } else if (types.includes('street_number')) {
                        field = 'numero';
                    } else if (types.includes('postal_code')) {
                        field = 'codigoPostal';
                        this.onChangeZip(value);
                    } else if (types.includes('locality')) {
                        field = 'localidad';
                    } else if (types.includes('administrative_area_level_2')) {
                        field = 'provincia';
                        value = value.toUpperCase();
                    }

                    if (field !== '') {
                        this.formGroup.get(field).patchValue(value);
                    }
                }
            }
        }
    }

    public isSameTaxZone(addressComponents) {
        return this.validationTaxZone(this.formGroup.get('codigoPostal').value + '', addressComponents.find(x => x.types.includes('postal_code'))?.long_name);
    }

    private setDataFromCoverage() {
        if (this.coverageData) {
            if (this.coverageData.value.localidadCobertura && (this.formGroup.get('localidad').value || this.formGroup.get('localidad').value === '')) {
                this.formGroup.get('localidad').patchValue(this.coverageData.value.localidadCobertura);
            }
            if (this.coverageData.value.nombreViaCobertura && (this.formGroup.get('nombreVia').value || this.formGroup.get('nombreVia').value === '')) {
                this.formGroup.get('nombreVia').patchValue(this.coverageData.value.tipoViaCobertura ?
                    this.getNameTypeStreetFromId(this.coverageData.value.tipoViaCobertura, this.roadTypes) + ' ' + this.coverageData.value.nombreViaCobertura :
                    this.coverageData.value.nombreViaCobertura);
            }
            if (this.coverageData.value.numeroCobertura && (this.formGroup.get('numero').value || this.formGroup.get('numero').value === '')) {
                this.formGroup.get('numero').patchValue(this.coverageData.value.numeroCobertura);
            }
            if (this.coverageData.value.provinciaCobertura && (this.formGroup.get('provincia').value || this.formGroup.get('provincia').value === '')) {
                this.formGroup.get('provincia').patchValue( this.coverageData.value.provinciaCobertura);
            }
            if (this.coverageData.value.tipoViaCobertura && (this.formGroup.get('tipoVia').value || this.formGroup.get('tipoVia').value === '')) {
                this.formGroup.get('tipoVia').patchValue(this.coverageData.value.tipoViaCobertura.toString().toLowerCase());
            } else {
                this.formGroup.get('tipoVia').patchValue('calle');
            }
            if (this.coverageData.value.plantaCobertura && (this.formGroup.get('planta').value || this.formGroup.get('planta').value === '')) {
                this.formGroup.get('planta').patchValue(this.coverageData.value.plantaCobertura);
            }
            if (this.coverageData.value.puertaCobertura && (this.formGroup.get('puerta').value || this.formGroup.get('puerta').value === '')) {
                this.formGroup.get('puerta').patchValue(this.coverageData.value.puertaCobertura);
            }
            if (this.coverageData.value.escaleraCobertura && (this.formGroup.get('escalera').value || this.formGroup.get('escalera').value === '')) {
                this.formGroup.get('escalera').patchValue(this.coverageData.value.escaleraCobertura);
            }
            if (this.coverageData.value.bloqueCobertura && (this.formGroup.get('bloque').value || this.formGroup.get('bloque').value === '')) {
                this.formGroup.get('bloque').patchValue(this.coverageData.value.bloqueCobertura);
            }
            if (this.coverageData.value.codigoPostalCobertura && (this.formGroup.get('codigoPostal').value || this.formGroup.get('codigoPostal').value === '')) {
                this.formGroup.get('codigoPostal').patchValue(this.coverageData.value.codigoPostalCobertura);
                this.onChangeZip(this.coverageData.value.codigoPostalCobertura);
            }
        }
    }

    private setDataFromCustomer(dataCustomer) {
        // Retramitaciones o seteamos datos del cliente
        // Datos de contacto
        if (dataCustomer?.customer) {
            if (dataCustomer.customer?.postal_address && +dataCustomer.customer.postal_address.post_code == dataCustomer.customer.zip_code) {
                if (dataCustomer.customer.postal_address.city && dataCustomer.customer.postal_address.city !== '') {
                    this.formGroup.get('localidad').patchValue(dataCustomer.customer.postal_address.city);
                }
                if (dataCustomer.customer.postal_address.street_name && dataCustomer.customer.postal_address.street_name !== '') {
                    this.formGroup.get('nombreVia').patchValue(dataCustomer.customer.postal_address.street_name);
                }
                if (dataCustomer.customer.postal_address.street_number && dataCustomer.customer.postal_address.street_number !== '') {
                    this.formGroup.get('numero').patchValue(dataCustomer.customer.postal_address.street_number);
                }
                if (dataCustomer.customer.postal_address.province && dataCustomer.customer.postal_address.province !== '') {

                    this.formGroup.get('provincia').patchValue(this.provinces.find(x => x.postal_code == dataCustomer.customer.postal_address.province)?.code);
                }
                if (dataCustomer.customer.postal_address.street_type && dataCustomer.customer.postal_address.street_type !== '') {
                    this.formGroup.get('tipoVia').patchValue(dataCustomer.customer.postal_address.street_type.toString());
                } else {
                    this.formGroup.get('tipoVia').patchValue('CL');
                }
                if (dataCustomer.customer.postal_address.floor_number && dataCustomer.customer.postal_address.floor_number !== '') {
                    this.formGroup.get('planta').patchValue(dataCustomer.customer.postal_address.floor_number);
                }
                if (dataCustomer.customer.postal_address.apartment_number && dataCustomer.customer.postal_address.apartment_number !== '') {
                    this.formGroup.get('puerta').patchValue(dataCustomer.customer.postal_address.apartment_number);
                }
                if (dataCustomer.customer.postal_address.staircase_number && dataCustomer.customer.postal_address.staircase_number !== '') {
                    this.formGroup.get('escalera').patchValue(dataCustomer.customer.postal_address.staircase_number);
                }
                if (dataCustomer.customer.postal_address.block && dataCustomer.customer.postal_address.block !== '') {
                    this.formGroup.get('bloque').patchValue(dataCustomer.customer.postal_address.block);
                }
                if (dataCustomer.customer.postal_address.post_code && dataCustomer.customer.postal_address.post_code !== '') {
                    this.formGroup.get('codigoPostal').patchValue(dataCustomer.customer.postal_address.post_code);
                    this.onChangeZip(dataCustomer.customer.postal_address.post_code);
                }
            }
            else {
                if (dataCustomer.customer?.zip_code) {
                    this.formGroup.get('codigoPostal').patchValue(dataCustomer.customer?.zip_code);

                    this.onChangeZip(dataCustomer.customer?.zip_code);
                    //no address
                    this.formGroup.get('tipoVia').patchValue('');
                    this.formGroup.get('nombreVia').patchValue('');
                    this.formGroup.get('numero').patchValue('');
                    this.formGroup.get('planta').patchValue('');
                    this.formGroup.get('puerta').patchValue('');
                    this.formGroup.get('escalera').patchValue('');
                    this.formGroup.get('bloque').patchValue('');
                    this.formGroup.get('localidad').patchValue('');
                }
            }

            // Datos personales
            if (dataCustomer.customer.document_type_id && dataCustomer.customer.document_type_id !== '') {
                this.formGroup.get('doctype').patchValue(dataCustomer.customer.document_type_id);
                this.isCif(dataCustomer.customer.document_type_id);
            }
            this.setValidationsBankAccount();
            this.formGroup.get('doctype').disable();

            if (dataCustomer.customer.document && dataCustomer.customer.document !== '') {
                this.formGroup.get('doc').patchValue(dataCustomer.customer.document);
            }
            this.formGroup.get('doc').disable();

            if (dataCustomer.customer.nationality_id && dataCustomer.customer.nationality_id !== '') {
                this.formGroup.get('country').patchValue(dataCustomer.customer.nationality_id);
            }
            this.formGroup.get('country').disable();

            if (dataCustomer.customer.name && dataCustomer.customer.name !== '') {
                this.formGroup.get('firstName').patchValue(dataCustomer.customer.name);
            }
            this.formGroup.get('firstName').disable();

            if (dataCustomer.customer.last_name && dataCustomer.customer.last_name !== '') {
                this.formGroup.get('surname1').patchValue(dataCustomer.customer.last_name);
            }
            this.formGroup.get('surname1').disable();

            if (dataCustomer.customer.second_last_name && dataCustomer.customer.second_last_name !== '') {
                this.formGroup.get('surname2').patchValue(dataCustomer.customer.second_last_name);
            }
            this.formGroup.get('surname2').disable();

            if (dataCustomer.customer.birth_date && dataCustomer.customer.birth_date !== '') {
                this.formGroup.get('birthday').patchValue(dataCustomer.customer.birth_date);
            }
            this.formGroup.get('birthday').disable();

            if (dataCustomer.customer.gender_id && dataCustomer.customer.gender_id !== '') {
                this.formGroup.get('sex').patchValue(dataCustomer.customer.gender_id);
            }
            this.formGroup.get('sex').disable();

            if (dataCustomer.customer.email && dataCustomer.customer.email !== '') {
                this.formGroup.get('email').patchValue(dataCustomer.customer.email);
            }
        }

        // Datos bancarios
        if (dataCustomer.accounts && dataCustomer.accounts.length) {
            dataCustomer.accounts.forEach((acc, i) => {
                const found = this.accountTypes.find((accountType: accountTypeSimyo) => accountType.account_type_id === acc.account_type_id);
                if (found) {
                    switch (found.account_type_id) {
                        // Tarjeta
                        case 1:
                            // TODO cuando tengamos ejemplo
                            this.thereIsMoreCreditcardData = true;
                            break;
                        // Cuenta bancaria
                        case 2:
                            this.thereIsMoreBankData = true;
                            this.setBankingData();
                            if (
                                acc.account_number && acc.account_number !== '' &&
                                this.formGroup.get('bank_account').value && this.formGroup.get('bank_account').value !== '' &&
                                acc.acct_code && acc.acct_code !== '' &&
                                this.formGroup.get('acct_code').value && this.formGroup.get('acct_code').value !== ''
                            ) {
                                if (acc.acct_code === this.formGroup.get('acct_code').value) {
                                    this.accountSelected = i;
                                    this.clearValidations('new_bank_account');
                                    this.formGroup.get('new_bank_account').patchValue('');
                                }
                            }
                            break;
                    }
                }
            });
        }
        if (this.formGroup.get('bank_account').value && this.formGroup.get('bank_account').value !== '' && !this.formGroup.get('acct_code').value) {
            this.accountSelected = '-1';
            this.formGroup.get('new_bank_account').patchValue(this.formGroup.get('bank_account').value);
        }
    }

    private setDataFromNotCustomer(dataNotCustomer) {
        // Datos personales
        if (dataNotCustomer.customer_doctype && dataNotCustomer.customer_doctype !== '') {
            this.formGroup.get('doctype').patchValue(dataNotCustomer.customer_doctype);
            this.isCif(dataNotCustomer.customer_doctype);
            if (+dataNotCustomer.customer_doctype === 1 && +this.formGroup.get('country').value === 192 ||
            +dataNotCustomer.customer_doctype === 3 && +this.formGroup.get('country').value === 192) {
                this.formGroup.get('country').patchValue(null);
            }
        }
        this.formGroup.get('doctype').disable();

        if (dataNotCustomer.customer_doc && dataNotCustomer.customer_doc !== '') {
            this.formGroup.get('doc').patchValue(dataNotCustomer.customer_doc);
        }
        this.formGroup.get('doc').disable();

        if (dataNotCustomer.customer_birthday && dataNotCustomer.customer_birthday !== '') {
            this.formGroup.get('birthday').patchValue(dataNotCustomer.customer_birthday);
            this.formGroup.get('birthday').disable();
        }

        if(dataNotCustomer?.addresses){
            var postal_addres = dataNotCustomer?.addresses.find(x => x.type === 'postal');
            this.setAddressForNotCustomer(postal_addres);
        }

        if ((dataNotCustomer?.zip_code != this.formGroup.get('codigoPostal').value) && dataNotCustomer.customer_doctype) {
            //no address
            this.formGroup.get('tipoVia').patchValue('');
            this.formGroup.get('nombreVia').patchValue('');
            this.formGroup.get('numero').patchValue('');
            this.formGroup.get('planta').patchValue('');
            this.formGroup.get('puerta').patchValue('');
            this.formGroup.get('escalera').patchValue('');
            this.formGroup.get('localidad').patchValue('');
        }
        if(dataNotCustomer?.zip_code && dataNotCustomer.customer_doctype){
            this.onChangeZip(dataNotCustomer?.zip_code);
            this.formGroup.get('codigoPostal').patchValue(dataNotCustomer?.zip_code);
        }
        if(dataNotCustomer?.country && dataNotCustomer.customer_doctype) {
            this.formGroup.get('country').patchValue(dataNotCustomer?.country);
            this.formGroup.get('country').disable();
        }
    }

    private setBankingData() {
        const userData = this.formGroup.getRawValue();
        if (userData.account_doctype == null || userData.account_doctype === '') {
            this.formGroup.get('account_doctype').patchValue(userData.doctype);
        }
        if (userData.account_doc == null || userData.account_doc === '') {
            this.formGroup.get('account_doc').patchValue(userData.doc);
        }
        if (userData.account_country == null || userData.account_country === '') {
            this.formGroup.get('account_country').patchValue(userData.country);
        }
        if (userData.account_firstName == null || userData.account_firstName.trim() === '') {
            this.formGroup.get('account_firstName').patchValue(userData.firstName);
        }
        if (userData.account_surname1 == null || userData.account_surname1.trim() === '') {
            this.formGroup.get('account_surname1').patchValue(userData.surname1);
        }
        if (userData.account_surname2 == null || userData.account_surname2 === '') {
            this.formGroup.get('account_surname2').patchValue(userData.surname2);
        }
        if (userData.account_company == null || userData.account_company === '') {
            this.formGroup.get('account_company').patchValue(userData.company);
        }
        this.fullNameBanking = this.getFullNameBanking();
    }

    public selectBankData(acc, i) {
        this.accountSelected = i;
        this.orderService.setCustomerAcctCode(acc.acct_code);
        this.formGroup.get('acct_code').patchValue(acc.acct_code);
        this.formGroup.get('bank_account').patchValue(acc.account_number);
        this.clearValidations('new_bank_account');
    }

    public selectNewBank() {
        this.accountSelected = '-1';
        this.orderService.setCustomerAcctCode(undefined);
        this.formGroup.get('acct_code').patchValue(null);
        this.formGroup.get('new_bank_account').markAsTouched();
        this.formGroup.get('bank_account').patchValue(this.formGroup.get('new_bank_account').value);
        this.setValidationsBankAccount(true);
    }

    public onIbanChange(event) {
        this.formGroup.get('bank_account').patchValue(event.target.value);
        this.setBankingData();
    }

    // Funcion para limpiar las validaciones de un campo del formulario
    private clearValidations(nameForm: string) {
        this.formGroup.get(nameForm).clearValidators();
        this.formGroup.get(nameForm).setValidators([]);
        this.formGroup.get(nameForm).updateValueAndValidity();
    }

    private getFullNameBanking(): string {
        return this.formGroup.get('account_firstName').value + ' ' + this.formGroup.get('account_surname1').value + ' ' + (this.formGroup.get('account_surname2').value && this.formGroup.get('account_surname2').value !== '' ? this.formGroup.get('account_surname2').value : '');
    }

    // Función para manejar los mensajes de error
    public errorHandling = (control: string, error: string) => {
        if (this.formGroup.get(control).invalid && (this.formGroup.get(control).dirty || this.formGroup.get(control).touched)) {
            return this.formGroup.controls[control].hasError(error);
        }
    }

    public setMaxLengthByDoc(type) {
        this.maxLengthByDoc = getMaxLengthByDoc(brands.simyo, type);
    }

    private isCif(doctype) {
        if (+doctype === 4) {
            this.requireCif = true;
            this.formGroup.get('birthday').patchValue(moment().subtract(50, 'years').toDate());
        } else {
            this.requireCif = false;
        }
    }

    private getNameTypeStreetFromId(id: any, roadTypes): string {
        if (id && !isNaN(Number(id)) && roadTypes && roadTypes.length) {
            const tipoVia = roadTypes.filter(item => {
                return id === item.track_type_id
            });

            if (tipoVia && tipoVia.length) {
                return tipoVia[0].value;
            }
        }
        return id;
    }

    public sendEvent(event) {
        setTimeout(() => {
            const text = event.target.value;
            if (text && text !== '') {
                this.homeService.sendIdSessionToService('SU');
            }
        }, 300)
    }

    public blockPaste(event: any) : void{
        event.preventDefault();
    }

    onlyPositiveDigits(event){
        if (event.key.length > 1) {
            return true;
        }
        if (+event.key >= 0 && +event.key <= 9 ) {
            return true;
        }

        return false;
    }

    setAddressForNotCustomer(address){
        if (address.city && address.city !== '') {
            this.formGroup.get('localidad').patchValue(address.city);
        }
        if (address.street_name && address.street_name !== '') {
            this.formGroup.get('nombreVia').patchValue(this.getNameTypeStreetFromId(address.street_type_id, this.roadTypes).toLowerCase() + ' ' + address.street_name);
        }
        if (address.street_number && address.street_number !== '') {
            this.formGroup.get('numero').patchValue(address.street_number);
        }
        if (address.province && address.province !== '') {
            this.formGroup.get('provincia').patchValue(address.province);
        }
        if (address.street_type && address.street_type !== '') {
            this.formGroup.get('tipoVia').patchValue(address?.street_type_id?.toString());
        } else {
            this.formGroup.get('tipoVia').patchValue('CL');
        }
        if (address.floor_number && address.floor_number !== '') {
            this.formGroup.get('planta').patchValue(address.floor_number);
        }
        if (address.apartment_number && address.apartment_number !== '') {
            this.formGroup.get('puerta').patchValue(address.apartment_number);
        }
        if (address.staircase_number && address.staircase_number !== '') {
            this.formGroup.get('escalera').patchValue(address.staircase_number);
        }
        if (address.block && address.block !== '') {
            this.formGroup.get('bloque').patchValue(address.block);
        }
        if (address.post_code && address.post_code !== '') {
            this.formGroup.get('codigoPostal').patchValue(address.post_code);
            this.onChangeZip(address.post_code);
        }
    }

    ngOnDestroy() {
        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map(subs => subs.unsubscribe());
        }
    }

    public checkErrorFieldForm(fieldName, errors:string[]) {
        return this.formGroup.get(fieldName)?.errors ? Object.keys(this.formGroup.get(fieldName)?.errors).findIndex((key) => errors.includes(key)) > -1 : false;
    }

    private focusAndScrollFirstError() {
        const formulario = document.getElementById('userDataForm') as HTMLFormElement;
        if (formulario) {
            const camposConErrores = formulario.querySelectorAll('mat-form-field.ng-invalid:not(.mat-form-field-disabled)');
            if (camposConErrores.length > 0) {
                const primerCampoConError = camposConErrores[0] as HTMLElement;
                primerCampoConError.focus();
                primerCampoConError.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }

    private validationTaxZone(oldZipCode, newZipCode ) {
        if (!oldZipCode || oldZipCode == '') {
            return true;
        }
        var zip_code = newZipCode.slice(0,2);
        var first_zip_code = oldZipCode.slice(0,2);

        if (first_zip_code === zip_code) {
            return true;
        }
        // si el primero es 35 o 38 y el actual es 35 o 38 se mantiene
        if ((first_zip_code === '35' || first_zip_code === '38') && (zip_code === '35' || zip_code === '38')) {
            return true;
        }

        // si alguno pertence a canarias se cambia el tipo de tasas
        if (first_zip_code === '35' || first_zip_code === '38' || zip_code === '35' || zip_code === '38') {
            return false;
        }
        // si alguno pertence a ceuta o melilla se cambia el tipo de tasas
        if (first_zip_code === '51' || first_zip_code === '52' || zip_code === '51' || zip_code === '52') {
            return false;
        }
        // si son distintos pero no pertenecen ni a canarias, ceuta o melilla se mantiene
        return true;
    }

    alphanumericOnly(event) {
        let pattern: RegExp = /^[a-zA-Z0-9]+$/;
        const inputChar = event.key;
        if (!pattern.test(inputChar)) {
            event.preventDefault();
        }
    }

    convertToUpperCase(controlName: string) {
        const control = this.formGroup.get(controlName);
        if (control) {
          control.setValue(control.value.toUpperCase());
        }
    }

    restrictInputToNumbers(event: any) {
        const input = event.target.value;
        event.target.value = input.replace(/\D/g, '');
    }

    private setValidationsPortabilityChangeData(showPortabilityChange) {
        let controlsWithValidators = {};
        if(showPortabilityChange) {
            controlsWithValidators = {
                portability_origen_name: [Validators.required, blankSpaceValidator()],
                portability_origen_lastname: [Validators.required, blankSpaceValidator()],
                portability_origen_lastname2: [],
                portability_origen_doc_type: [Validators.required],
                portability_origen_doc: [Validators.required, Validators.pattern('^[a-zA-Z0-9]+$'), isValueDupWith('doc')],
                portability_origen_birth_date: [Validators.required, isAdult()],
                portability_origen_email: [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$"), isValueDupWith('email')],
                portability_origen_email_confirm: [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")],
                portability_origen_nationality_id: [Validators.required],
                phone: [Validators.required, Validators.pattern('^[6-9][0-9]{0,8}$'), Validators.minLength(9), Validators.maxLength(9), isValueDupWith('portability_origen_phone')],
                email: [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$"), isValueDupWith('portability_origen_email')]
            };
            //this.formGroup.get('portability_origen_phone').patchValue(data.mobiles[customerDonorIndex].msisdn);
        } else {
            controlsWithValidators = {
                portability_origen_name: [],
                portability_origen_lastname: [],
                portability_origen_lastname2: [],
                portability_origen_doc_type: [],
                portability_origen_doc: [],
                portability_origen_birth_date: [],
                portability_origen_email: [],
                portability_origen_email_confirm: [],
                portability_origen_nationality_id: [],
                phone: [Validators.required, Validators.pattern('^[6-9][0-9]{0,8}$'), Validators.minLength(9), Validators.maxLength(9)],
                email: [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]
            };

            this.formGroup.patchValue({
                portability_origen_name: '',
                portability_origen_lastname: '',
                portability_origen_lastname2: '',
                portability_origen_doc_type: '',
                portability_origen_doc: '',
                portability_origen_birth_date: '',
                portability_origen_email: '',
                portability_origen_email_confirm: '',
                portability_origen_nationality_id: '',
            })
        }

        for (const controlName in controlsWithValidators) {
            const control = this.formGroup.get(controlName);
            control.setValidators(controlsWithValidators[controlName]);
            control.updateValueAndValidity({ onlySelf: true, emitEvent: false });
        }
    }

    convertToLowerCase(controlName: string) {
        const control = this.formGroup.get(controlName);
        if (control) {
          control.setValue(control.value.toLowerCase());
        }
    }
}
