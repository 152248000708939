<div class="modal-dialog" role="document">
    <div class="modal-content">
        <ng-container *ngIf="!loading">
            <div class="modal-header bg-simyo-grey">
                <h5 class="modal-title white" id="exampleModalLabel">Buscar Punto de Venta</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="this.closeModal()">
                    <span aria-hidden="true"><img src="./assets/img/times2.svg" class="img-fluid ico-title" width="20"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row" *ngIf="pdv === null">
                        <div class="col-lg-12" [formGroup]="formGroup">
                            <h6 class="font-weight-bold mt-2 text-simyo">
                                Indique el usuario Simyo asociado a la contratación
                            </h6>
                            <div class="form-group mb-0">
                                <mat-form-field>
                                    <mat-label>Usuario</mat-label>                                    
                                    <input matInput="" type="text" oninvalid="" formControlName="username">
                                    <!-- error -->
                                    <mat-error *ngIf="formGroup.get('username')?.errors?.required">
                                        Campo obligatorio.
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <p class="mb-0 text-simyo" *ngIf="error">No se ha encontrado el usuario</p>
                        </div>
                    </div>

                    <div class="row" *ngIf="pdv !== null">
                        <div class="col-lg-12" [formGroup]="formGroup">
                            <h6 class="font-weight-bold mt-2 text-simyo">
                                Punto de venta {{pdv.pdv_denominacion}}
                            </h6>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="modal-footer">
                <div class="container d-flex justify-content-end">
                    <button type="button" class="btn btn-simyo" (click)="searchPdV()" style="margin-right: auto;" *ngIf="pdv === null">Buscar</button>
                    <button type="button" class="btn btn-simyo mr-2" (click)="closeModal()" *ngIf="pdv !== null">Cancelar</button>
                    <button type="button" class="btn btn-simyo" (click)="createOrder()" *ngIf="pdv !== null">Activar servicios</button>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="loading">
            <div class="modal-body mt-2 mb-5">
                <h5 align="center" class="tar-name-preload">Buscando PdV...</h5>
                <div align="center">
                    <img class="" src="./assets/img/spinner-simyo.svg" width="30" alt="Cargando...">
                </div>
            </div>
        </ng-container>
    </div>    
</div>