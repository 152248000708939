import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SimyoService } from '../../services/simyo.service';
import { SimyoOrderService } from '../../services/simyo-order.service';
import { SimyoDraftService } from '../../services/simyo-draft.service';
import { SimyoRateService } from '../../services/simyo-rate.service';
import { documentationTypes, fiberIndirectTechnologies, mobileContractType, nameTaxType, orderType, superStatus, translateSignType, typeCard } from 'src/app/shared/constantes';
import { SimyoGlobals, descuento } from '../../classes/simyo-globals';
import { SimyoNormalizadorService } from '../../services/simyo-normalizador.service';
import { UserNotFoundComponent } from 'src/app/shared/components/user-not-found/user-not-found.component';
import { MatDialog } from '@angular/material/dialog';
import { SimyoModalReprocessComponent } from '../simyo-modal-reprocess/simyo-modal-reprocess.component';
import { AlertDialogComponent } from 'src/app/shared/components/alert-dialog/alert-dialog.component';
import { BehaviorSubject, Subscription, of } from 'rxjs';
import { HomeService, INavigateFromOperation } from 'src/app/home/services/home.service';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { allPermissions } from 'src/app/shared/permissions';
import { StringMap } from '@angular/compiler/src/compiler_facade_interface';
import { signatureDocuments } from '../../models/signatureDocuments';
import { documentTypeSimyo, masterAllResponseSimyo, masterAllSimyo, trackTypeSimyo } from '../../models/masterAllSimyo';
import { getUnique } from 'src/app/utils/getUniqueFromArray';
import * as moment from 'moment';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { catchError, map, take, tap } from 'rxjs/operators';

@Component({
    selector: 'app-simyo-order-detail',
    templateUrl: './simyo-order-detail.component.html',
    styleUrls: ['./simyo-order-detail.component.scss', '../../../../assets/css/simyo-theme.css']
})
export class SimyoOrderDetailComponent implements OnInit, OnDestroy {
    public order = null;
    public totalPriceInicial = 0;
    public totalMensualPrice = 0;
    public rateMaster: any;
    public customerData: any;
    public showDiscount: boolean = false;
    public descuento: number = descuento;
    public disabledButton: boolean = false;
    public orderNotFound: boolean = false;
    public superStatus: any = superStatus;
    public allPermissions = allPermissions;
    public loadingCobertura: boolean = false;
    public loaded: boolean = false;
    public dataOperation: any = null;
    public orderType = orderType;
    public fiberIndirectTechnologies = fiberIndirectTechnologies;
    public documentationMode: StringMap = documentationTypes;
    public dataOrderToSignature: signatureDocuments;
    public translateSignType = translateSignType;
    public tramitedAfter14March = '';


    // Master
    public documentTypes: documentTypeSimyo[];
    public roadTypes: trackTypeSimyo[];

    private allSubscription: Subscription[] = [];

    public signatureFormGroup: FormGroup;

    public isCollapsed: boolean[] = [];
    public email: string = "";

    public typeCard = typeCard;

    public statusDisableSend = ['loading', 'success'];
    public statusSendQR$: BehaviorSubject<[string]> = new BehaviorSubject(
        ['initial']
    );
    messageBlocked = 'Deben transcurrir al menos 5 minutos entre reenvios de QR';

    constructor(
        private router: Router,
        private modalService: NgbModal,
        private activatedRoute: ActivatedRoute,
        private simyoService: SimyoService,
        private orderService: SimyoOrderService,
        private draftService: SimyoDraftService,
        private rateService: SimyoRateService,
        private normalizadorService: SimyoNormalizadorService,
        private homeService: HomeService,
        public dialog: MatDialog,
        public simyoGlobals: SimyoGlobals,
        public permSv: PermissionService,
        private formBuilder: FormBuilder
    ) { }

    ngOnInit(): void {
        this.getOrderInfo()
        this.allSubscription.push(this.rateService.getRates()
            .subscribe((data: any) => {
                if (data != null) {
                    this.rateMaster = data;
                    this.getFtthRate(this.order?.broadband?.rate?.rate_id);
                }
            })
        );

        this.allSubscription.push(this.homeService.getNavigateFromOperation()
            .subscribe((data: any) => {
                this.dataOperation = data;
            })
        );

        this.allSubscription.push(this.simyoService.getMasterAll()
            .subscribe((data: masterAllSimyo) => {
                if (data) {
                    this.roadTypes = data.track_types;
                }
            })
        );

        this.allSubscription.push(this.homeService.getNavigateFromOperation()
            .subscribe((data: INavigateFromOperation) => {
                this.dataOperation = data;
            })
        );

        if (!this.documentTypes || !this.roadTypes) {
            this.simyoService.getMasterAllFromService()
                .subscribe((data: masterAllResponseSimyo) => {
                    if (data && data.msg) {
                        this.simyoService.setMasterAll(data.msg);
                        this.roadTypes = data.msg.track_types;
                        this.documentTypes = data.msg.document_types;
                    }
                });
        }

        this.signatureFormGroup = this.formBuilder.group({
            smsCheck: new FormControl(false),
            smsData: new FormControl(''),
            emailCheck: new FormControl(true),
            emailData: new FormControl(''),
            documentationUploadCheck: new FormControl(false, Validators.requiredTrue),
            documentationUploadType: new FormControl('digital')
        });

    }

    getRatesOfOrderFromService(){
        this.rateService.getRatesFromService(this.order?.tax_type_cp)
        .subscribe((data: any) => {
            this.rateService.setRates(data.msg);
        });
    }

    getOrderInfo(updateOrder?) {
        if (updateOrder) {
            this.loaded = false;
        }
        this.isCollapsed = [];
        this.orderService.getOrderFromService(this.activatedRoute.snapshot.paramMap.get('order_id'))
            .subscribe((data: any) => {
                if (data && data.msg && data.msg.length) {
                    this.orderNotFound = false;
                    this.order = data.msg[0];
                    if (updateOrder) {
                        this.loaded = true;
                    }
                    this.getRatesOfOrderFromService();
                    if (this.order?.tramited_at !== '') {
                        if (new Date(this.order.tramited_at) >= new Date('2023-03-14')) {
                            this.tramitedAfter14March = 'pospago';
                        }
                    }
                    let allPhonesToContact: any[] = [{phone: this.order.customer_phone_number, type: 'Contacto'}];
                    this.order.mobiles.map((mobile, index) => mobile.msisdn !== '' ? allPhonesToContact.push({phone: mobile.msisdn, type: index+1 + 'ª línea'}) : null);

                    this.order.mobiles.forEach((mobile, index) => {
                        if(mobile.is_esim == typeCard.sim || mobile.show_qr == false || mobile.super_status !== superStatus.active) return;
                        this.simyoService.queryEsimQR(this.order.customer_id, mobile.new_msisdn).pipe(
                            take(1),
                            map((data: any) => data.msg),
                            tap(data => {
                                if(data) {
                                    this.order.mobiles[index].pin = data.PIN;
                                    this.order.mobiles[index].qr_code = data.qr_code;
                                }
                            })
                        ).subscribe();
                    })
                    this.dataOrderToSignature = {
                        orderId: this.order.order_id,
                        email: this.order.customer_email,
                        phones: getUnique(allPhonesToContact, 'phone')
                    };

                    if (this.order.mobiles && this.order.mobiles.length) {
                        const foundTerminal = this.order.mobiles.find(movil => movil.terminal_oid)
                        if (foundTerminal) {
                            this.simyoService.terminalsAreValid(this.order);
                        }
                    }
                    if (this.order.customer_id && this.order.customer_id !== '') {
                        this.findCustomer();
                    } else {
                        this.loaded = true;
                    }
                    this.updateTotalPrice();

                    this.email = this.order.customer_email;
                };
            }, (error) => {
                if (error && error.error && error.error.error && error.error.error.errorCode !== null && error.error.error.errorCode === 462) {
                    this.dialog.open(UserNotFoundComponent, {
                        width: '350px',
                        disableClose: true,
                        data: {
                            marca: 'Simyo'
                          }
                    });
                } else {
                    this.orderNotFound = true;
                }
            });
    }

    priceMobileMonth(mobile): number {
        let price = 0;
        if (mobile.terminal_oid && mobile.terminal_oid.is_installment) {
            price += mobile.terminal_oid.terminal.installment && +mobile.terminal_oid.terminal.installment > 0 ? +mobile.terminal_oid.terminal.installment : 0;
        }
        return price + this.simyoGlobals.getMobilePrice(mobile);
    }

    updateTotalPrice() {
        this.totalMensualPrice = 0;
        this.totalPriceInicial = 0;

        if (this.order.broadband && this.order.broadband.rate) {
            this.totalMensualPrice += +this.order.broadband.rate.rate_info.contracted_price;
        }

        if (this.order.mobiles && this.order.mobiles.length) {
            this.order.mobiles.forEach((rate, index) => {
                if (this.order.broadband && index === 0) {
                    this.totalMensualPrice += +rate.rate.rate_info.contracted_price;
                } else {
                    // Precio tarifa movil
                    this.totalMensualPrice += +rate.rate.rate_info.contracted_price;
                }
                // Precio bonos
                // if (rate.bonos && rate.bonos.length) {
                //     rate.bonos.forEach(bono => {
                //         this.totalMensualPrice += +bono.rate.rate_info.price;
                //     });
                // }
                // Precio terminales
                if (rate.terminal_oid) {
                    if (rate.terminal_oid.is_installment) {
                        // Pago a plazos
                        this.totalPriceInicial += rate.terminal_oid.terminal.upfront && +rate.terminal_oid.terminal.upfront > 0 ? +rate.terminal_oid.terminal.upfront : 0;

                        this.totalMensualPrice += rate.terminal_oid.terminal.installment && +rate.terminal_oid.terminal.installment > 0 ? +rate.terminal_oid.terminal.installment : 0;
                    } else {
                        // Pago unico
                        this.totalPriceInicial += rate.terminal_oid.terminal.price && +rate.terminal_oid.terminal.price > 0 ? +rate.terminal_oid.terminal.price : 0;
                    }
                }
            });
        }

        if(this.order){
            this.showDiscount = this.simyoService.isAllowDiscount(this.order?.mobiles, this.order?.type, this.order?.broadband?.rate?.rate_info?.speed, this.customerData);
        }
        else {
            this.showDiscount = this.simyoService.isAllowDiscount(null);
        }
    }

    /*getIndexFirstPostpaidMobile(){
        return this.order.mobiles.findIndex((mobile) => (
            mobile.contract_type ?
                mobile.contract_type === 'postpaid' :
                mobile.type_pay === 'postpaid'
            ));
    }*/

    async continueDraftOrder() {
        const path = this.draftService.getPath(this.order.type);
        this.simyoService.setTypeOrder(this.order.type);
        const mobileForms = [];
        const forms = {
            fibra: null,
            movilPacks: null,
            cliente: null,
            normalizador: null,
            cobertura: null,
            haveCoverage: true
        };

        if (this.order.type !== orderType.MOBILE && this.order.broadband && this.order.addresses && this.order.addresses.length) {
            this.loadingCobertura = true;
            forms.haveCoverage = await this.simyoGlobals.coverageIsValid(this.order.broadband, this.order.addresses.find(x => x.type === "installation"), this.roadTypes);
            if (forms.haveCoverage) {
                if (this.simyoGlobals.ftthRateIsValid(this.order, this.rateMaster, this.simyoService.getMainLineWithoutSpeed(), this.simyoService.getShowFtthReduced(), this.simyoService.customerHasPackage())) {
                    forms.fibra = {
                        rate: {...this.draftService.getRate(this.order.broadband.rate), ...this.simyoGlobals.getftthRateMatrix(this.order, this.rateMaster)}
                    };
                } else {
                    forms.fibra = {
                        rate: null
                    };
                    this.rateService.setErrorRate(true);
                }
            } else {
                forms.fibra = {
                    rate: null
                };
            }
            forms.normalizador = this.draftService.getNormalizador(this.order.addresses.find(x => x.type === "installation"));
            forms.cobertura = this.draftService.getCobertura(this.order.addresses.find(x => x.type === "installation"), this.order.broadband);
        }

        if (this.order.mobiles && this.order.mobiles.length) {
            this.order.mobiles.forEach((item: any, index) => {
                this.setFtthMobilePrice(item.rate.rate_info);
                this.setOldPrice(item.rate.rate_info);
                if (!this.simyoGlobals.mobileRateIsValid(this.order, item, index, this.rateMaster, this.order.type, this.customerData)) {
                    item.rate = null;
                    // item.bonos = [];
                    if (forms.fibra && index === 0) {
                        forms.fibra.rate = null;
                    }
                    if (this.rateService.isAdditional(index, this.customerData, this.order.type, this.order)) {
                        this.rateService.setErrorRateAdditional(true);
                    } else {
                        this.rateService.setErrorRate(true);
                    }
                }
                if (index === 0 && this.order.type === orderType.CONVERGENT && !forms.haveCoverage) {
                    item.rate = null;
                    // item.bonos = [];
                    if (forms.fibra) {
                        forms.fibra.rate = null;
                    }
                }
                if (item.terminal_oid && !item.isValidTerminal) {
                    item.terminal_oid = null;
                }
                mobileForms.push(this.draftService.getMobilePack(item));
            });
            forms.movilPacks = mobileForms;
        }

        forms.cliente = this.draftService.getUserDataFromOrder(this.order);

        if (this.order.customer_id && this.order.customer_id !== '' && this.customerData) {
            this.simyoService.setDataFromCustomer(this.customerData);
            this.simyoService.setCustomerPromotion(this.customerData);
            this.normalizadorService.setClientIsCover(true);
            this.orderService.setCustomerId(this.order);
        } else {
            this.normalizadorService.setClientIsCover(false);
            this.simyoService.setDataFromNotCustomer(this.order);
            forms.cliente.new_bank_account = forms.cliente.bank_account;
        }

        this.orderService.setOrderId(this.order.order_id);
        this.simyoService.setTaxType(this.order.tax_type_cp);
        this.simyoService.setFormsData(forms);
        this.loadingCobertura = false;
        this.router.navigate([path]);
    }

    findCustomer() {
        if (this.order.customer_document && this.order.customer_document !== '' && this.order.customer_document_type_id && this.order.customer_document_type_id) {
            const body = {
                document_type_id: this.order.customer_document_type_id,
                document_number: this.order.customer_document,
                birth_date: this.order.customer_birth_date && this.order.customer_birth_date !== '' ? moment(this.order.customer_birth_date).format('YYYY-MM-DD') : undefined,
                zip_code: this.order.tax_type_cp

            }
            this.disabledButton = true;
            this.simyoService.getCustomerInfoFromService(body)
                .subscribe((response : any) => {
                    if (response && response.msg) {
                        this.customerData = response.msg;
                        this.customerData.customer.zip_code = this.order.tax_type_cp;
                        this.customerData.customer.tax_type_cp = this.order.tax_type;
                        this.simyoService.setCustomerPromotion(this.customerData);
                        this.updateTotalPrice();
                        this.isValidAcctCode();
                        this.orderService.set_existing_client(true);

                    }
                    if (response.error && response.error.errorCode && response.error.errorCode === 's631') {
                        this.orderService.set_existing_client(false);
                    }
                }, (error) => {
                    this.disabledButton = false;
                    this.loaded = true;
                }, () => {
                    this.disabledButton = false;
                    this.loaded = true;
                })
        } else {
            this.loaded = true;
        }
    }

    openModalNewProcess(onlyClient) {
        const modalRef = this.modalService.open(SimyoModalReprocessComponent, {size: 'lg', centered: true})
        modalRef.componentInstance.order = this.order;
        modalRef.componentInstance.rates = this.rateMaster;
        modalRef.componentInstance.customerData = this.customerData;
        modalRef.componentInstance.showOptionClient = onlyClient;
    }

    removeDraft() {
        if (this.permSv.hasPermFromV2(allPermissions.others.users_demo)) {
            this.dialog.open(AlertDialogComponent, {
                data: {message: 'Usuario demostración. No se puede eliminar un borrador'}
            });
        } else {
            if (this.order && this.order.order_id && this.order.order_id !== '') {
                this.simyoService.removeOrder(this.order.order_id)
                    .subscribe((response) => {
                        console.log('Order borrada');
                        this.router.navigate(['/simyo/listado']);
                    }, (error) => console.log('Error al borrar la order'));
            }
        }
    }

    public getDocumentLabel(id): string {
        if (this.documentTypes && this.documentTypes.length && id) {
            const found = this.documentTypes.find(x => x.document_type_id === +id)
            return found ? found.value : '';
        }
    }

    goBack() {
        this.dataOperation ? this.router.navigate(['/']) : this.router.navigate(['/simyo/listado']);
    }

    getRechargePrice(price) {
        return this.rateService.getRechargePrice(price);
    }

    moveTo(el) {
        document.getElementById(el).scrollIntoView({behavior: 'smooth'});
    }

    showUpdateOrder(order): boolean {
        if (order) {
            if (order.type === orderType.BROADBAND) {
                return order.super_status !== superStatus.draft && order.broadband && (order.broadband.super_status === superStatus.in_process || (order.broadband.super_status === superStatus.active && !order.broadband.activation_date));
            }
            if (order.type === orderType.CONVERGENT) {
                return order.super_status !== superStatus.draft && (order.broadband && (order.broadband.super_status === superStatus.in_process || (order.broadband.super_status === superStatus.active && !order.broadband.activation_date))) || (order.mobiles && order.mobiles.length && order.mobiles.find(line => (line.status === superStatus.in_process) || (line.status === superStatus.active && !line.activation_date)));
            }
            if (order.type === orderType.MOBILE) {
                return order.super_status !== superStatus.draft && order.mobiles && order.mobiles.length && order.mobiles.find(line => (line.super_status === superStatus.in_process) || (line.super_status === superStatus.active && !line.activation_date));
            }
            return
        }
        return false;
    }

    private isValidAcctCode() {
        if (this.order?.payer?.acct_code && this.customerData?.accounts && this.customerData?.accounts.length) {
            const found = this.customerData.accounts.find((account) => account.acct_code === this.order.payer.acct_code);
            if (!found) {
                this.order.iban = null;
                this.order.payer.acct_code = null;
            }
        }
    }

    public getContractNumber(): string {
        if (this.order.contract_id && this.order.contract_id !== '') {
            return this.order.contract_id.toUpperCase()
        }

        return this.order.is_contingency ? '-' : 'Pendiente de asignar';
    }

    getTaxType(tax_type){
        return nameTaxType[tax_type];
    }

    setFtthMobilePrice(rate_info){
        if (!rate_info) {
            return "";
        }

        if (this.order.tax_type === 'igic') {
            rate_info.ftth_100_price = rate_info?.ftth_100_price_igic;
            rate_info.ftth_300_price = rate_info?.ftth_300_price_igic;
            rate_info.ftth_500_price = rate_info?.ftth_500_price_igic;
            rate_info.ftth_600_price = rate_info?.ftth_600_price_igic;
            rate_info.ftth_1_price = rate_info?.ftth_1_price_igic;
        }
        if (this.order.tax_type === 'ipsi_8') {
            rate_info.ftth_100_price = rate_info?.ftth_100_price_ipsi_8;
            rate_info.ftth_300_price = rate_info?.ftth_300_price_ipsi_8;
            rate_info.ftth_500_price = rate_info?.ftth_500_price_ipsi_8;
            rate_info.ftth_600_price = rate_info?.ftth_600_price_ipsi_8;
            rate_info.ftth_1_price = rate_info?.ftth_1_price_ipsi_8;
        }
        if (this.order.tax_type === 'ipsi_10') {
            rate_info.ftth_100_price = rate_info?.ftth_100_price_ipsi_10;
            rate_info.ftth_300_price = rate_info?.ftth_300_price_ipsi_10;
            rate_info.ftth_500_price = rate_info?.ftth_500_price_ipsi_10;
            rate_info.ftth_600_price = rate_info?.ftth_600_price_ipsi_10;
            rate_info.ftth_1_price = rate_info?.ftth_1_price_ipsi_10;

        }
        if (this.order.tax_type === 'iva') {
            rate_info.ftth_100_price = rate_info?.ftth_100_price_iva;
            rate_info.ftth_300_price = rate_info?.ftth_300_price_iva;
            rate_info.ftth_500_price = rate_info?.ftth_500_price_iva;
            rate_info.ftth_600_price = rate_info?.ftth_600_price_iva;
            rate_info.ftth_1_price = rate_info?.ftth_1_price_iva;
        }
    }

    setOldPrice(rate_info){
        if (rate_info) {
            rate_info.old_price = this.getOldPrice(rate_info);
        }
    }

    getOldPrice(rate_info){
        if (!rate_info) {
            return "";
        }
        if (this.order.tax_type === 'igic') {
            return rate_info.old_price_igic;
        }
        if (this.order.tax_type === 'ipsi_8') {
            return rate_info.old_price_ipsi_8;
        }
        if (this.order.tax_type === 'ipsi_10') {
            return rate_info.old_price_ipsi_10;
        }
        if (this.order.tax_type === 'iva') {
            return rate_info.old_price_iva;
        }
        return "";
    }

    //Deprecated: Este metodo quedará eliminado ya que para calcular el descuento vendrá de back
    getFtthRate(rate_id: any){
        var index = this.rateMaster?.RATES?.FTTH_NEW.findIndex((ftth) => (
            ftth?.type_discount?.rate_id === rate_id || ftth?.type_normal?.rate_id === rate_id
            ));
        this.descuento = this.simyoGlobals.setFtthDiscount(this.rateMaster?.RATES?.FTTH_NEW[index]);
    }


    ngOnDestroy() {
        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map(subs => subs.unsubscribe());
        }
    }

    showMoreInfo() {
        return (this.order.broadband.status_description && this.order.broadband.status_description !== '')
        || this.order.broadband.substatus
        //|| this.order.broadband.virtual_installation
    }

    generateMoreInfo() {
        let moreInfo = '';
        if(this.order?.broadband?.substatus?.description && this.order?.broadband?.substatus?.description !== '') moreInfo += this.order?.broadband?.substatus?.description;
        /*if(this.order?.broadband?.virtual_installation && this.order?.broadband?.virtual_installation !== '') {
            if(moreInfo.length > 0) moreInfo += '; ';
            moreInfo += this.order?.broadband?.virtual_installation;
        }*/
        return moreInfo;
    }

    getTextInstallationType(broadband) {
        let message = broadband.iv_frontal_confirmation === true ? 'Solicitada Instalación Virtual.<br>' : '';
        if(broadband.virtual_installation !== null && broadband.virtual_installation !== undefined) {
            return `${message}Realizada Instalación ${broadband.virtual_installation}.`;
        }

        if((broadband.super_status === superStatus.active
            && (broadband.virtual_installation == null || broadband.virtual_installation == undefined))
            || (broadband.iv_frontal_confirmation == null || broadband.iv_frontal_confirmation == false)) {
            return `${message}Instalación física.`;
        }

        return message;
    }

    getTitleLine(mobile) {
        if(mobile === undefined) return '';
        const portability = mobile.msisdn && mobile.msisdn !== '';
        const portabilityChange = mobile.customer_donor != null;
        const portabilityChangeText = portabilityChange ? ' (Con cambio de titularidad)' : '';
        if (mobile.is_esim === typeCard.esim) {
            return portability  ? `Portabilidad eSIM de la línea móvil ${mobile.msisdn}${portabilityChangeText}` : 'Alta eSIM';
        }
        return portability  ? `Línea móvil a portar ${mobile.msisdn}${portabilityChangeText}` : 'Alta línea móvil';
    }

    public changeStateSendEmail(index) {
        this.order.mobiles[index].showSendEmail = !this.order.mobiles[index].showSendEmail;
        this.isCollapsed[index] = true;
    }

    public hideEsim(index) {
        if (this.isCollapsed[index] === undefined) {
            this.isCollapsed[index] = true;
        }

        return this.isCollapsed[index]
    }

    public changeStateInfoEsim(index) {
        this.isCollapsed[index] = !this.isCollapsed[index];
        this.order.mobiles[index].showSendEmail = false;
    }

    public sendEmail(index) {
        let statuses = this.statusSendQR$.getValue();
        let currentStatus = statuses[index];
        if(currentStatus === 'blocked') {
            return;
        }
        statuses[index] = 'loading';
        this.statusSendQR$.next(statuses);

        let data = {
            customer_id: this.order.customer_id,
            msisdn: this.order.mobiles[index].new_msisdn,
            order_mobile_id: this.order.mobiles[index].order_mobile_id
        }
        this.simyoService.sendEsimQR(data).pipe(
            take(1),
            catchError(err => {
                statuses[index] = 'error';
                if(err.error.error.errorCode === 403) {
                    statuses[index] = 'blocked';
                }

                this.statusSendQR$.next(statuses);
                return of({});
            }),
            tap((data: any) => {
                if(data.hasOwnProperty('msg')) {
                    statuses[index] = 'success';
                    this.statusSendQR$.next(statuses);
                }
                setTimeout(() => {
                    statuses[index] = 'initial';
                    this.statusSendQR$.next(statuses);
                }, 10000);
            })
        )
        .subscribe();
    }

    getTextButtonSendQR(status) {
        if(status === 'loading') return 'Enviando...';
        if(status === 'success') return 'Enviado';
        return 'Enviar';
    }
}
