<div class="container content" *ngIf="loaded">
    <p>FIBRA MÁS ALTA: {{maxBroadbandSpeed}}</p>
    <div class="row mb-4">
        <div class="col-lg-12 mb-2">
            <h5 class="title-step" align="center">
                <img src="./assets/img/linea-conf.svg" class="img-fluid ico-title transf-ico" width="20">
                {{ typeOrder === orderType.BROADBAND || showRateOnOffer ? 'Ofertas para el cliente' : 'Configuración de tarifa' }}
            </h5>
        </div>

        <div class="row justify-content-center col-lg-8 offset-md-2">
            <div class="alert alert-warning alert-list mb-3" role="alert" *ngIf="showErrorRate || showErrorAdditionalRate">
                Se ha eliminado la tarifa anterior porque no es compatible con la nueva contratación
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <img src="./assets/img/times2.svg" width="23" class="ml-2">
                </button>
            </div>
        </div>

        <!-- Solo fibra -->
        <ng-container *ngIf="showContent(orderType.BROADBAND)">
            <ng-template [ngIf]="fibraRates?.length" [ngIfElse]="unsupportedRates">
                <!-- Precio Tarifa -->
                <div class="col-12">
                    <div class="row justify-content-center">
                        <ng-container *ngFor="let fibra of fibraRates">
                            <div class="col-lg-3"  *ngIf="fibra.frontShowBroadband">
                                    <div class="card tar-simyo tarifas-card-simyo" style="height: auto !important;">
                                        <!-- Precio -->
                                        <div align="center" class="tar-simyo-price font-weight-bold mb-3">
                                            {{ (+fibra?.rate_info?.price)|toFixedDecimals }}<span class="decimals-1">€</span>
                                        </div>
                                        <!-- Servicios contratados -->
                                        <p align="center" class="tar-simyo-des font-weight-bold mb-4">
                                            {{ (fiberIndirectTechnologies.includes(order?.broadband?.access_type) ? 'Fibra IND ' : 'Fibra ') + fibra?.name }}
                                        </p>
                                        <!-- Contratar -->
                                        <a (click)="selectRate(fibra, false)" class="btn btn-simyo2 btn-sm mb-4">Continuar</a>
                                    </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="row justify-content-center col-lg-8 offset-md-2 mt-4" *ngIf="typeOrder == orderType.BROADBAND && !(isNebaTechnology && (!customerData || customerData?.num_subscriptions === 0))">
                    <a class="btn btn-black btn-blok mb-1 btn-pack w-100" (click)="switchToConvergent()" role="button">
                        ¡Convierte mi fibra en un pack con línea móvil!
                    </a>
                </div>
            </ng-template>
        </ng-container>

        <!-- Convergente y Solo movil -->
        <ng-container *ngIf="showContent(orderType.MOBILE) || showContent(orderType.CONVERGENT, true)">
            <ng-template [ngIf]="diffData?.length && diffVoice?.length" [ngIfElse]="unsupportedRates">
                <ng-container *ngIf="showRateOnOffer && ratesFormGroup.get('movilType').value !== mobileContractType.prepaid">
                    <div class="col-lg-12">
                        <div class="card bg-white configurador">
                            <div class="row mx-0">
                                <div class="col-12 d-flex align-items-center mb-4" [ngClass]="{'justify-content-between': typeOrder === orderType.CONVERGENT && index === 0 && showTypeContent == ''}">
                                    <h6 class="mb-2 font-weight-bold">
                                        Tipo de línea <span class="text-simyo">móvil</span>
                                    </h6>
                                    <div class="radio-btn-group ml-3" [formGroup]="ratesFormGroup">
                                        <div class="radio" *ngIf="showPostpaid && checkPermissions('postpaid')">
                                            <input id="postpaid" type="radio" name="movilType" value="postpaid" formControlName="movilType" (change)="changeMobileType($event.currentTarget.value)">
                                            <label for="postpaid" class="font-weight-bold radio-width">
                                                Pospago <img *ngIf="isAdditional" src="./assets/img/promo.svg" width="16" data-toggle="tooltip" data-placement="bottom" title="" class="ml-1" style="position: relative; top: -2px;">
                                            </label>
                                        </div>
                                        <div class="radio btn-movilType" *ngIf="showPrepaid && checkPermissions('prepaid')">
                                            <input id="prepaid" type="radio" name="movilType" value="prepaid" formControlName="movilType" (change)="changeMobileType($event.currentTarget.value)">
                                            <label for="prepaid" class="font-weight-bold radio-width">Prepago</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-12 mb-3" *ngIf="readyRates[mobileContractType.postpaid] && readyRates[mobileContractType.postpaid].length && ratesFormGroup.get('movilType').value && ratesFormGroup.get('movilType').value === mobileContractType.postpaid">
                            <div class="alert alert-success-orange mb-3" role="alert" *ngIf="ratesFormGroup.get('movilType').value === mobileContractType.postpaid">
                                <b>La línea principal siempre será la línea Móvil pospago con mayor cuota mensual</b>, esto puede hacer cambiar el precio de línea principal y adicional
                            </div>
                            <!-- Listas para llevar -->
                            <div class="alert alert-success-blue alert-list mb-3" role="alert">
                                Te mostramos a continuación <b>las mejores tarifas</b> para ti
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                    <img src="./assets/img/times2.svg" width="23" class="ml-2">
                                </button>
                            </div>
                            <br>

                            <div class="row mb-3">
                                <div class="col-lg hvr-float" *ngFor="let rate of readyRates[mobileContractType.postpaid]">
                                    <div class="card-tar card-white mb-5 mb-lg-0">
                                        <div class="card-tar-body-1">
                                            <!-- Precio -->
                                            <div align="center" class="text-muted font-weight-bold mb-0" style="text-decoration: line-through; min-height: 24px;">
                                                {{ rate.rate_info.old_price && rate.rate_info.old_price !== '' && !rate.show_additional_for_stand ? (rate?.rate_info?.old_price|toFixedDecimals) + '€' : ' ' }}
                                            </div>
                                            <div align="center" class="tar-simyo-price font-weight-bold mb-2">
                                                {{ rate?.rate_info?.real_price|toFixedDecimals }}<span class="decimals-1">€</span>
                                            </div>
                                            <!-- Servicios contratados -->
                                            <p align="center" class="tar-simyo-des font-weight-bold mb-4">
                                                {{ rate.name }}
                                            </p>
                                            <!-- Contratar -->
                                            <a (click)="selectRate(rate, true)" class="btn btn-simyo2 btn-block btn-sm mb-2">Continuar</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <hr>
                            <div class="row animated fadeIn" *ngIf="!isStand">
                                <div class="col-12 col-md">
                                    <!-- Text -->
                                    <p class="font-size-lg mb-3 mb-md-0 mt-1 pt-3">
                                        ¿No estás interesado en nuestra recomendación de tarifas? <b>Configura tu propia tarifa</b>
                                    </p>
                                </div>
                                <div class="col-12 col-md-auto">
                                    <!-- Button -->
                                    <a (click)="showRateConfigurator()" class="btn btn-simyo2 btn-sm mt-2">
                                        Abrir configurador
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!showRateOnOffer || ratesFormGroup.get('movilType').value === mobileContractType.prepaid">
                    <div class="mb-2"
                        [ngClass]="{'col-lg-9': ratesFormGroup.get('movilType').value && ratesFormGroup.get('movilType').value !== '', 'col-lg-12': !ratesFormGroup.get('movilType').value || ratesFormGroup.get('movilType').value === ''}"
                        *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_configurador_tarifas)">
                        <div class="card bg-white configurador">
                            <div class="alert alert-success-orange mb-4 mt-0 alert-banner" role="alert" *ngIf="isAdditional && ratesFormGroup.get('movilType').value == mobileContractType.postpaid">
                                <b>La línea principal siempre será la línea Móvil pospago con mayor cuota mensual</b>, esto puede hacer cambiar el precio de línea principal y adicional
                            </div>
                            <div class="row w-100 mx-0">
                                <div class="col-xl-12 p-0 d-flex align-items-center mb-4">
                                    <ng-container *ngIf="showContent(orderType.CONVERGENT, index === 0)">
                                        <h6 class="font-weight-bold col pl-0">
                                            Velocidad de <span class="text-simyo">fibra</span>
                                        </h6>

                                        <div class="radio-btn-group mr-2" [formGroup]="ratesFormGroup">
                                            <ng-container *ngFor="let fibra of fibraRates" >
                                                <div class="radio" *ngIf="fibra.frontShowBroadband">
                                                    <input id="{{fibra.rate_id}}" type="radio" name="fibraRate" value="{{fibra.rate_info?.speed}}" formControlName="fibraRate" (change)="isAvailableRate(fibra)">
                                                    <label for="{{fibra.rate_id}}" class="font-weight-bold">{{ fibra.name }}</label>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </ng-container>

                                    <h6 class="mb-2 font-weight-bold pr-0" [ngClass]="{'col': showContent(orderType.CONVERGENT, index === 0)}">
                                        Tipo de línea <span class="text-simyo">móvil</span>
                                    </h6>
                                    <div class="radio-btn-group ml-1" [formGroup]="ratesFormGroup">
                                        <div class="radio" *ngIf="showPostpaid && checkPermissions('postpaid')">
                                            <input id="postpaid" type="radio" name="movilType" value="postpaid" formControlName="movilType" (change)="changeMobileType($event.currentTarget.value)">
                                            <label for="postpaid" class="font-weight-bold radio-width">
                                                Pospago <img *ngIf="isAdditional" src="./assets/img/promo.svg" width="16" data-toggle="tooltip" data-placement="bottom" title="" class="ml-1" style="position: relative; top: -2px;">
                                            </label>
                                        </div>
                                        <div class="radio btn-movilType" *ngIf="showPrepaid && checkPermissions('prepaid')">
                                            <input id="prepaid" type="radio" name="movilType" value="prepaid" formControlName="movilType" (change)="changeMobileType($event.currentTarget.value)">
                                            <label for="prepaid" class="font-weight-bold radio-width">Prepago</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row  w-100 mx-0" *ngIf="ratesFormGroup.get('movilType').value && ratesFormGroup.get('movilType').value !== ''">
                                <ng-template [ngIf]="diffData.length > 1" [ngIfElse]="justOneData">
                                    <div class="col-xl-12 px-0">
                                        <h6 class="mb-2 font-weight-bold">
                                            Elige <span class="text-simyo">datos</span> de internet móvil
                                        </h6>

                                        <!-- Datos moviles -->
                                        <div class="slider-container" [formGroup]="ratesFormGroup">
                                            <input *ngIf="ratesFormGroup.get('movilType').value  === 'prepaid'" type="range" min="0" [max]="diffData.length - 1" class="sliders" formControlName="dataRate" (change)="changeData($event.currentTarget.value)">
                                            <input *ngIf="ratesFormGroup.get('movilType').value  === 'postpaid'" type="range" min="0" [max]="diffData.length - 1" class="sliders" formControlName="dataRate" (change)="changeData($event.currentTarget.value)">
                                        </div>

                                        <div class="label-container">
                                            <div class="label-slider1 disabled" *ngFor="let data of diffData" [ngClass]="{'disabled' : data.disabled }">
                                                {{ data.label }} <img *ngIf="data.isOffer && ratesFormGroup.get('movilType').value === mobileContractType.postpaid" src="./assets/img/promo.svg" width="16" data-toggle="tooltip" data-placement="bottom" title="" class="ml-1" style="position: relative; top: -2px;">
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template [ngIf]="diffVoice.length > 1" [ngIfElse]="justOneVoice">
                                    <div class="col-xl-12 px-0">
                                        <h6 class="mb-2 font-weight-bold">
                                            Elige <span class="text-simyo">minutos</span> de llamadas
                                        </h6>

                                        <!-- Min llamadas-->
                                        <div class="slider-container" [formGroup]="ratesFormGroup">
                                            <input type="range" min="0" [max]="diffVoice.length - 1" class="sliders" formControlName="voiceRate" (change)="changeVoice($event.currentTarget.value)">
                                        </div>

                                        <div class="label-container">
                                            <div class="label-slider disabled" *ngFor="let voice of diffVoice" [ngClass]="{'disabled' : voice.disabled }">
                                                {{ voice.label.length > 8 ? voice.label.substr(0, 6) : voice.label }} <img *ngIf="voice.isOffer && ratesFormGroup.get('movilType').value === mobileContractType.postpaid" src="./assets/img/promo.svg" width="16" data-toggle="tooltip" data-placement="bottom" title="" class="ml-1" style="position: relative; top: -2px;">
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>

                                <!--<div class="col-xl-12" *ngIf="bonosRates && bonosRates.length">
                                    <!-<h6 style="margin-top: -18px;" class="mb-4 font-weight-bold">
                                        Completa tu tarifa con:
                                        <a class="text-simyo" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                                            + añadir bonos
                                        </a>
                                    </h6>->
                                    <div class="collapse" id="collapseExample">
                                        <div class="row mb-2" [formGroup]="ratesFormGroup">
                                            <ng-container *ngFor="let bono of bonosRates; let i = index">
                                                <div class="col-xl-3" *ngIf="bono.name === 'Chat'">
                                                    <span class="tar-add">{{ bono.name }}</span>
                                                    <img src="./assets/img/whatsapp.svg" class="icon-info2 ml-1">
                                                    <img src="./assets/img/telegram.svg" class="icon-info ml-1">
                                                    <div class="complete--tarifa--1">
                                                        <label class="switch">
                                                            <input type="checkbox" value="{{i}}" class="warning" [formControl]="ratesFormGroup?.controls['bonosRate']?.controls[i]" (change)="addBonoToCustomRate(bono, $event.currentTarget.checked)">
                                                            <span class="slider round"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4" *ngIf="bono.name === '100 MIN a simyos'">
                                                    <span class="tar-add">{{ bono.name }}</span>
                                                    <div class="complete--tarifa--1">
                                                        <label class="switch">
                                                            <input type="checkbox" value="{{i}}" class="warning" [formControl]="ratesFormGroup?.controls['bonosRate']?.controls[i]" (change)="addBonoToCustomRate(bono, $event.currentTarget.checked)">
                                                            <span class="slider round"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-xl-5" *ngIf="bono.name === 'Redes Sociales'">
                                                    <span class="tar-add">{{ bono.name }}</span>
                                                    <img src="./assets/img/facebook.svg" class="icon-info ml-1">
                                                    <img src="./assets/img/instagram.svg" class="icon-info ml-1">
                                                    <img src="./assets/img/twitter.svg" class="icon-info ml-1">
                                                    <img src="./assets/img/snapchat.svg" class="icon-info ml-1">
                                                    <div class="complete--tarifa--1">
                                                        <label class="switch">
                                                            <input type="checkbox" value="{{i}}" class="warning" [formControl]="ratesFormGroup?.controls['bonosRate']?.controls[i]" (change)="addBonoToCustomRate(bono, $event.currentTarget.checked)">
                                                            <span class="slider round"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>-->
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 mb-2" *ngIf="!permSv.hasPermFromV2(allPermissions.simyo.permite_configurador_tarifas)">
                        <div class="card bg-white configurador">
                            <div class="row">
                                <div class="col-xl-4">
                                    <h6 class="mb-2 font-weight-bold">
                                        Tipo de línea <span class="text-simyo">móvil</span>
                                    </h6>
                                </div>
                                <div class="col-xl-8">
                                    <div class="radio-btn-group mb-4" [formGroup]="ratesFormGroup">
                                        <div class="radio" *ngIf="showPostpaid && checkPermissions('postpaid')">
                                            <input id="postpaid" type="radio" name="movilType" value="postpaid" formControlName="movilType">
                                            <label for="postpaid" class="font-weight-bold radio-width">Pospago</label>
                                        </div>
                                        <div class="radio btn-movilType" *ngIf="showPrepaid && checkPermissions('prepaid')">
                                            <input id="prepaid" type="radio" name="movilType" value="prepaid" formControlName="movilType">
                                            <label for="prepaid" class="font-weight-bold radio-width">Prepago</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Precio Tarifa -->
                    <div class="col-lg-3" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_configurador_tarifas) && ratesFormGroup.get('movilType').value && ratesFormGroup.get('movilType').value !== ''">
                        <div class="card tar-simyo tarifas-card-simyo">
                            <!-- Precio -->
                            <div align="center" class="text-muted font-weight-bold mb-0" style="text-decoration: line-through; min-height: 24px;"
                            *ngIf="showContent(orderType.MOBILE) && ratesFormGroup.get('movilType').value === mobileContractType.postpaid &&  customRate?.rates?.movil?.is_additional == true">
                                {{ calculateOldPrice(customRate)}}

                            </div>
                            <div align="center" class="tar-simyo-price font-weight-bold mb-3">
                                {{ (calculatePrice(customRate))|toFixedDecimals }}<span class="decimals-1">€</span>
                            </div>
                            <!-- Servicios contratados Linea 1-->
                            <p align="center" class="tar-simyo-des--configurador font-weight-bold mb-4" *ngIf="customRate.textFibra !== '' && showContent(orderType.CONVERGENT, true)">
                                {{ (fiberIndirectTechnologies.includes(order?.broadband?.access_type) ? 'Fibra IND ' : 'Fibra ') + customRate.textFibra }}
                            </p>
                            <!-- Servicios contratados Linea 21-->
                            <p align="center" class="tar-simyo-des--configurador font-weight-bold mb-4" *ngIf="customRate.textType !== '' || customRate.textRate !== ''">
                                {{ customRate.textType }} <br> {{ customRate.textRate }}
                            </p>
                            <!-- Contratar -->
                            <a (click)="selectRate(customRate, false)" class="btn btn-simyo2 btn-sm mb-4">Continuar</a>
                        </div>
                    </div>
                    <br>
                    <div class="col-xl-12 mb-3"
                    *ngIf="showContent(orderType.MOBILE)
                            && ratesFormGroup.get('movilType').value
                            && ratesFormGroup.get('movilType').value !== ''
                            && readyRates[ratesFormGroup.get('movilType').value]
                            && readyRates[ratesFormGroup.get('movilType').value].length">
                        <hr class="hr-text mb-4" data-content="Tarifas listas para llevar">
                        <div class="row">
                            <ng-container *ngFor="let rate of readyRates[ratesFormGroup.get('movilType').value]">
                                <div class="col-lg hvr-float" *ngIf="isShowReadyToGo(ratesFormGroup.get('movilType').value, rate)">
                                    <div class="card-tar card-white mb-5 mb-lg-0">
                                        <div class="card-simyo-header header-ready-rates">
                                            <p align="center" class="tar-simyo-tar">
                                                {{ rate?.rate_info?.caption}}
                                            </p>
                                        </div>
                                        <div class="card-tar-body">
                                            <!-- Precio anterior -->
                                            <div align="center" class="text-muted font-weight-bold mb-0" style="text-decoration: line-through; min-height: 24px;"
                                            *ngIf="rate.rate_info.old_price && rate.rate_info.old_price !== '' && !rate.show_additional_for_stand">
                                                {{ (rate?.rate_info?.old_price|toFixedDecimals) + '€'}}
                                            </div>
                                            <!-- Precio -->
                                            <div align="center" class="tar-simyo-price2 font-weight-bold mb-2">
                                                {{ (rate?.rate_info?.real_price)|toFixedDecimals }}<span class="decimals-1">€</span>
                                            </div>
                                            <!-- Servicios contratados -->
                                            <p align="center" class="tar-simyo-des font-weight-bold mb-4">
                                                {{ customRate.textType }} <br> {{ rate.name }}
                                            </p>
                                            <!-- Contratar -->
                                            <a (click)="selectRate(rate, true)" class="btn btn-simyo2 btn-block btn-sm mb-2">Continuar</a>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </ng-template>
        </ng-container>
    </div>

</div>

<div *ngIf="!loaded" class="mt-2 mb-5">
    <h5 align="center" class="tar-name-preload">Obteniendo tarifas...</h5>

    <div align="center">
        <img class="" src="./assets/img/spinner-simyo.svg" width="30" alt="">
    </div>
</div>

<ng-template #unsupportedRates>
    <div class="row justify-content-center col-lg-8 offset-md-2">
        <div class="alert alert-warning alert-list mb-3" role="alert">
            No hay tarifas disponibles. Inténtelo de nuevo más tarde
        </div>
    </div>
</ng-template>

<ng-template #justOne>
    <div class="col-xl-12 label-container">
        <h6 class="mb-2 font-weight-bold w-100">
            Datos de internet móvil: <span class="text-simyo">{{ diffData[0].label }}</span>
        </h6>
    </div>

    <div class="col-xl-12 label-container" style="margin-top: -20px;">
        <h6 class="mb-2 font-weight-bold">
            Minutos de llamadas: <span class="text-simyo">{{ diffVoice[0].label }}</span>
        </h6>
    </div>
</ng-template>

<ng-template #justOneData>
    <div class="col-xl-12 label-container">
        <h6 class="mb-2 font-weight-bold w-100">
            <span class="text-simyo">Datos</span> de internet móvil <strong class="just-one-data float-right">{{ diffData[0].label }}</strong>
        </h6>
    </div>
</ng-template>

<ng-template #justOneVoice>
    <div class="col-xl-12 label-container" style="margin-top: -20px;">
        <h6 class="mb-2 font-weight-bold">
            Minutos de llamadas: <span class="text-simyo">{{ diffVoice[0].label }}</span>
        </h6>
    </div>
</ng-template>

<ng-template #content let-modal>
    <div class="modal-header" style="background-color: #fff;">
        <b class="col text-simyo pr-0 mt-2" style="font-size: 18px;" align="center">IMPORTANTE</b>
    </div>

    <div class="modal-body pt-0" id="bodyModal">
        <div class="m-3 row">
            <span class="col-12" align="center">
                Avisa al cliente de que para que se activen los bonos tiene que recargar
                <span class="text-simyo">{{ (+rechargePrice)|toFixedDecimals }}€ </span>
                <b>DESPUÉS DE ACTIVAR LA SIM</b>
            </span>
            <span class="col-12 mt-4" align="center">
                Si puedes hazle la recarga ahora mismo y si no que tenga el móvil conectado en WIFI sin datos móviles hasta que recargue
            </span>
        </div>
        <a (click)="modal.dismiss('Cross click')" class="btn btn-simyo-o2 btn-block mb-1" role="button">
            Entendido
        </a>
    </div>
</ng-template>

<ng-template #rememberPrepaid let-modal>
    <div class="modal-header" style="background-color: #fff;">
        <b class="col text-simyo pr-0 mt-2" style="font-size: 18px;" align="center">IMPORTANTE</b>
    </div>

    <div class="modal-body pt-0" id="bodyModal">
        <div class="m-3 row">
            <span class="col-12" align="center">
                Recuerda que si eliges pospago podrás disfrutar de un mejor precio en fibra y en líneas adicionales
            </span>
        </div>
        <a (click)="modal.dismiss('Cross click')" class="btn btn-simyo-o2 btn-block mb-1" role="button">
            Entendido
        </a>
    </div>
</ng-template>

<ng-template #notAllowedPrepaid let-modal>
    <div class="modal-header" style="background-color: #fff;">
        <b class="col text-simyo pr-0 mt-2" style="font-size: 18px;" align="center">IMPORTANTE</b>
    </div>

    <div class="modal-body pt-0" id="bodyModal">
        <div class="m-3 row">
            <span class="col-12" align="center">
                Esta tarifa no es compatible con la fibra seleccionada.
            </span>
        </div>
        <a (click)="modal.dismiss('Cross click')" class="btn btn-simyo-o2 btn-block mb-1" role="button">
            Entendido
        </a>
    </div>
</ng-template>
