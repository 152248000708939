<p>Simyo Address (Libreria cargada desde js)</p>

<myaddress-component
 showMap="false"
 origin="MYADDRESS"
 environment="PRE"
 coverage="false"
 hiddenVertical="false"
 (addrselected)="addressSelected($event)"
 (addrcreated)="addressCreated($event)"
 (sendInitialized)="sendInitialized($event)">
 </myaddress-component>
