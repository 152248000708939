<div class="modal-dialog" role="document">
    <div class="modal-content">
        <ng-container *ngIf="!loading">
            <div class="modal-header bg-simyo-grey">
                <h5 class="modal-title white" id="exampleModalLabel">Aviso importante!</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="this.closeModal()">
                    <span aria-hidden="true"><img src="./assets/img/times2.svg" class="img-fluid ico-title" width="20"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <h6 class="font-weight-bold mt-2">
                                La tramitación no se ha completado, ¿quieres guardarla para poder retomarla?
                            </h6>
                        </div>
                    </div>
                    <div class="row" *ngIf="needResetDocumentation()">
                        <div class="col-lg-12">
                            <h6 class="mt-2">
                                Esta acción cancelará el proceso de firma y el cliente tendrá que firmar el contrato cuando retomes la tramitación
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn" [ngClass]="{ 'btn-grey-outline2' : !classClickedNo, 'btn-grey-outline2-cliked' : classClickedNo }" data-dismiss="modal" (click)="onRemoveDraft(); classClickedNo = true">NO</button>
                <button type="button" class="btn" [ngClass]="{ 'btn-black-outline' : !classClickedYes, 'btn-grey-outline2-cliked' : classClickedYes }" (click)="onSaveDraft(); classClickedYes = true">SI</button>
            </div>
        </ng-container>
        <ng-container *ngIf="loading">
            <div class="modal-body mt-2 mb-5">
                <h5 align="center" class="tar-name-preload">{{ classClickedNo ? 'Eliminando borrador...' : 'Guardando borrador...' }}</h5>
                <div align="center">
                    <img class="" src="./assets/img/spinner-simyo.svg" width="30" alt="Cargando...">
                </div>
            </div>
        </ng-container>
    </div>    
</div>