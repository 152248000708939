import {StringMap} from '@angular/compiler/src/compiler_facade_interface';
import {Component, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {orangeWelcomePackCodes} from 'src/app/shared/constantes';
import {AppState} from '../../redux';
import {showShoppingCart} from '../../redux/orange.actions';
import {IOrangeAdditionalBonosState, IOrangePrepaidState} from '../../redux/orange.state';
import {OrangeRatesWP} from '../../models/masterAllResponse';
import {OrangeShoppingCartService} from '../../services/orange-shopping-cart.service';
import { eSimConstants } from '../../models/eSimConstants';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { allPermissions } from 'src/app/shared/permissions';

@Component({
    selector: 'app-orange-shopping-cart',
    templateUrl: './orange-shopping-cart.component.html',
    styleUrls: ['./orange-shopping-cart.component.css', '../../../../assets/css/orange-theme.css']
})
export class OrangeShoppingCartComponent implements OnInit {

    public orangeAdditionalBonos$: Observable<IOrangeAdditionalBonosState>;
    public orangePrepaid$: Observable<IOrangePrepaidState>;
    public orangeWelcomePackCodes: StringMap = orangeWelcomePackCodes;
    public allPermissions = allPermissions;

    constructor(
        private orangeStore: Store<AppState>,
        private shoppingCartService: OrangeShoppingCartService,
        public permSv: PermissionService
    ) {
        this.orangeAdditionalBonos$ = orangeStore.pipe(select('orangeAdditionalBonos'));
        this.orangePrepaid$ = orangeStore.pipe(select('orangePrepaid'));
    }

    ngOnInit(): void {
    }

    public closeShoppingCart() {
        this.orangeStore.dispatch(showShoppingCart({show: false}));
    }

    public normalizedMsisdns(msisdns) {
        return msisdns ? msisdns.filter(msisdn => msisdn !== '' && msisdn.length === 9 && (msisdn[0] === '6' || msisdn[0] === '7')) : [];
    }

    public getTotalPrice(msisdns, rateSelected): number {
        const msisdnsNormalized = this.normalizedMsisdns(msisdns);
        if (msisdnsNormalized && msisdnsNormalized.length && rateSelected) {
            return msisdnsNormalized.length * +rateSelected.price;
        }
        return 0;
    }

    public getAltaType(rate: OrangeRatesWP, typeOperation: string): string {
        if (rate.is_esim) {
            //agregar si es porta o alta
            if (typeOperation === eSimConstants.portabilityTypeOperation) {
                return 'Línea móvil a portar ';
            }
            return 'Alta eSIM prepago ';
        }

        if (rate.is_portability) {
            return 'Línea móvil a portar ';
        }

        if (rate.is_terminal) {
            return 'Alta SIM + Terminal ';
        }

        if (rate.is_prepaid && !rate.is_esim) {
            return 'Alta linea móvil ';
        }
    }

    public getTitleAltaType(rate: OrangeRatesWP, typeOperation: string): string {
        if (rate.is_esim) {
            //agregar si es porta o alta
            if (typeOperation === eSimConstants.portabilityTypeOperation) {
                return 'Portabilidad eSIM ';
            }
            return 'eSIM ';
        }

        if (rate.is_portability) {
            return 'Portabilidad ';
        }

        if (rate.is_terminal) {
            return 'Alta SIM + Terminal ';
        }

        if (rate.is_prepaid && !rate.is_esim) {
            return 'Alta ';
        }

        return '';
    }

    public goToEdit(index: number) {
        this.shoppingCartService.getStepper().selectedIndex = 0;
        setTimeout(() => this.shoppingCartService.focusMsisdn.emit(index), 100);
    }

    public goToEditEsim() {
        this.shoppingCartService.getStepper().selectedIndex = 0;
    }

    public removeMsisdn(index: number) {
        this.shoppingCartService.removeMsisdn.emit(index);
    }

    getArrayMultiLine(lines) {
        return Array(lines).fill(0);
    }

    public getTotalPriceEsim(lines, rateSelected): number {
        if (rateSelected) {
            return lines * +rateSelected.price;
        }
        return 0;
    }

    removeLine() {
        this.shoppingCartService.removeLine.emit();
    }

    isEsimRegistration(data): boolean {
        return data.welcome_pack_selected?.is_esim === true 
        && data.configuration_form_values.type_operation === eSimConstants.registrationTypeOperation;
    }
}
