import {AfterViewInit, Component, HostListener, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HmRateService} from '../../services/hm-rate.service';
import {MatTabGroup} from '@angular/material/tabs';
import {environment} from '../../../../environments/environment';
import * as moment from 'moment/moment';
import {NgbCarouselConfig, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {HmModalSimyoContratosComponent} from '../hm-modal-simyo-contratos/hm-modal-simyo-contratos.component';
import {monthNames, PDV_MULTIMARCA, tabHome, translateCategoryRappel, typeFilterFormOperation} from 'src/app/shared/constantes';
import {Subscription} from 'rxjs';
import {HomeService} from '../../services/home.service';
import {HmModalJazztelContratosComponent} from '../hm-modal-jazztel-contratos/hm-modal-jazztel-contratos.component';
import {allPermissions} from 'src/app/shared/permissions';
import {PermissionService} from 'src/app/shared/services/permission.service';
import {Ipdv} from 'src/app/shared/models/pdvResponse';
import {dayToShowCategoryRappelPreviousMonth, IrappelMaster} from '../../models/Irappel';
import {SimyoTemporaryClosureComponent} from '../../../simyo/components/simyo-temporary-closure/simyo-temporary-closure.component';
import {Router} from '@angular/router';
import {PointsService} from '../../services/points.service';
import {HomeBrand, HomeBrandData} from '../../models/home-brand';

@Component({
    selector: 'app-hm-hiring',
    templateUrl: './hm-hiring.component.html',
    styleUrls: ['./hm-hiring.component.css', '../../../../assets/css/home-theme.css'],
    providers: [NgbCarouselConfig]
})
export class HmHiringComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() tabGroup: MatTabGroup;
    @Input() pdv: Ipdv;
    @Input() dataPoints;
    @ViewChild('procesos') procesos;

    private innerWidth: any;
    public brands = {
        simyo: 'sm',
        orange: 'or',
        jazztel: 'jz'
    };
    public brandsPerm = {
        simyo: 'simyo',
        orange: 'orange',
        jazztel: 'jazztel'
    };
    public baseUrl: string;
    public brandOpened: string;
    public titleColumn = 'col-12';
    public buttonColumn = 'col-12';
    public showMessages = true;
    public today = moment();
    public lastMonth = moment().subtract(1, 'months').month();
    public orChange = moment('2022-04-24');
    public smChange = moment('2021-08-23');
    public smChangeGif = moment('2024-02-05');
    public smGifFinish = moment('2024-02-05');
    public environment = environment;
    public activationStatistics: any;
    public activationsByMonthError: boolean = false;
    public rappelSimyoData: any;
    public rappelSimyoDataError: boolean = false;
    public monthNames = monthNames;
    public typeFilterFormOperation = typeFilterFormOperation;
    public allPermissions = allPermissions;
    public PDV_MULTIMARCA = PDV_MULTIMARCA;
    public numberOfRecords: number = 0;
    public translateCategoryRappel = translateCategoryRappel;
    public dayToShowCategoryRappelPreviousMonth = dayToShowCategoryRappelPreviousMonth;
    private allSubscription: Subscription[] = [];
    public dataPointsError = false;

    public hmBrands: HomeBrand[] = [];

    constructor(
        private rateService: HmRateService,
        private modalService: NgbModal,
        private homeService: HomeService,
        public permSv: PermissionService,
        public config: NgbCarouselConfig,
        private router: Router,
        public pointsService: PointsService
    ) {
        this.innerWidth = window.innerWidth;
        this.baseUrl = environment.digoEndPoint;

        config.interval = 4770;
        config.pauseOnHover = false;
        config.showNavigationArrows = false;
        config.showNavigationIndicators = false;
    }

    ngOnInit(): void {
        this.allSubscription.push(this.homeService.getActivation()
            .subscribe((data: any) => {
                if (data != null) {
                    this.activationStatistics = data;
                }
            })
        );

        this.allSubscription.push(this.homeService.getActivationError()
            .subscribe((data: any) => {
                if (data != null) {
                    this.activationsByMonthError = data;
                }
            })
        );

        this.allSubscription.push(this.homeService.getRappelSimyo()
            .subscribe((data: any) => {
                if (data != null) {
                    this.rappelSimyoData = data;
                }
            })
        );

        this.allSubscription.push(this.homeService.getRappelSimyoError()
            .subscribe((data: any) => {
                if (data != null) {
                    this.rappelSimyoDataError = data;
                }
            })
        );

        this.setHmBrands();
    }

    ngAfterViewInit() {
        if (this.homeService.getBrandPermission(this.brandsPerm).length === 1 && !(document.getElementById('orders_and_credit_phone') || document.getElementById('commissions') || document.getElementById('free_terminals'))) {
            this.changeBrand(this.brands[this.homeService.getBrandPermission(this.brandsPerm)[0]]);
            document.getElementById(this.brands[this.homeService.getBrandPermission(this.brandsPerm)[0]]).classList.add('show');
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerWidth = window.innerWidth;
    }

    getBrandClass(hmBrand: HomeBrand, index: number) {
        return {
            'brand-first': this.brandOpened === hmBrand.prefix,
            [`order-${index + 1}`]: this.brandOpened !== hmBrand.prefix
        };
    }

    setHmBrands() {
        this.setHmBrandSimyo();
        this.setHmBrandJazztel();
        this.setHmBrandOrange();
    }

    setHmBrandSimyo() {
        if (!this.permSv.hasPermFromV2(allPermissions.simyo.permite_vender) || !this.permSv.hasPermFromV2(allPermissions.others.access_night)) {
            return;
        }

        const data: HomeBrandData[] = [];

        if (!this.permSv.hasPermFromV2(allPermissions.simyo.nbss_block)) {
            if (this.permSv.hasPermFromV2(allPermissions.simyo.permite_ventas_viejo_frontal)) {
                const dataOldFront: HomeBrandData = {
                    bodyTitle: 'Contratación',
                    bodyPrincipalLinks: [],
                    bodySecondaryLinks: [],
                    footerLinks: [],
                };

                dataOldFront.bodyPrincipalLinks.push({
                    label: 'Móvil (contrato)',
                    route: `${this.baseUrl}smy_activa.itx`
                });

                dataOldFront.bodyPrincipalLinks.push({
                    label: 'Móvil (prepago)',
                    route: `${this.baseUrl}smy_activa.itx?mode=prepaid`
                });

                data.push(dataOldFront);
            }

            if (this.permSv.hasPermFromV2(allPermissions.simyo.permite_ventas_nuevo_frontal)) {
                const dataNewFront: HomeBrandData = {
                    bodyTitle: 'Contratación',
                    bodyPrincipalLinks: [],
                    bodySecondaryLinks: [],
                    footerLinks: [],
                };

                if (this.permSv.hasPermFromV2(allPermissions.simyo.permite_vender_broadband)) {
                    dataNewFront.bodyPrincipalLinks.push({
                        label: 'Fibra y móvil',
                        route: '/simyo/convergente'
                    });
                }

                dataNewFront.bodyPrincipalLinks.push({
                    label: 'Móvil',
                    route: '/simyo/solo-movil'
                });

                if (this.permSv.hasPermFromV2(allPermissions.simyo.permite_vender_broadband)) {
                    dataNewFront.bodyPrincipalLinks.push({
                        label: 'Fibra',
                        route: '/simyo/solo-fibra'
                    });
                }

                if (this.permSv.hasPermFromV2(allPermissions.simyo.permite_duplicado_sim)) {
                    dataNewFront.bodyPrincipalLinks.push({
                        label: 'Duplicados',
                        onClick: () => this.checkSimyoTemporaryClosure('/simyo/duplicados')
                    });
                }

                dataNewFront.bodySecondaryLinks.push({
                    label: 'Consultar operaciones',
                    route: '/simyo/listado',
                    imagePath: './assets/img/iconfinder.svg'
                });

                if (this.permSv.hasPermFromV2(allPermissions.simyo.permite_duplicado_sim)) {
                    dataNewFront.bodySecondaryLinks.push({
                        label: 'Consultar duplicados',
                        route: '/simyo/listado-duplicados',
                        imagePath: './assets/img/iconfinder.svg'
                    });
                }

                dataNewFront.footerLinks.push({
                    label: 'Contratos',
                    onClick: () => this.openModalSimyoContratos()
                });

                data.push(dataNewFront);
            }
        }

        this.hmBrands.push({
            name: 'Simyo',
            description: '<span>En Simyo líneas adicionales</span>',
            prefix: this.brands.simyo,
            logoPath: './assets/img/sm-login-c.svg',
            data: data,
            canOpen: !this.permSv.hasPermFromV2(allPermissions.simyo.nbss_block),
            onToggle: (prefix) => {
                if (this.brandOpened !== prefix && !this.permSv.hasPermFromV2(allPermissions.simyo.nbss_block)) {
                    this.changeBrand(prefix);
                } else {
                    this.openSimyoTemporaryClosure();
                }
            }
        });
    }

    setHmBrandJazztel() {
        if (!this.permSv.hasPermFromV2(allPermissions.jazztel.permite_ventas)) {
            return;
        }

        const data: HomeBrandData = {
            bodyTitle: 'Contratación',
            bodyPrincipalLinks: [],
            bodySecondaryLinks: [],
            footerLinks: [],
        };

        data.bodyPrincipalLinks.push({
            label: `Acceder a contrataciones ${this.pdv?.info?.usuariopangea && this.pdv?.info?.usuariopangea !== '' ? '(' + this.pdv?.info?.usuariopangea + ')' : ''}`,
            route: 'https://pangea.orange.es',
            isExternal: true,
            isBlank: true
        });

        data.bodySecondaryLinks.push({
            label: 'Consultar operaciones',
            route: `${this.baseUrl}jazztel_list_pdv.itx`,
            imagePath: './assets/img/iconfinder.svg',
            isExternal: true
        });

        data.footerLinks.push({
            label: 'Contratos',
            onClick: () => this.openModalJazztelContratos()
        });

        this.hmBrands.push({
            name: 'Jazztel',
            description: '<span>Mejoramos las tarifas sin subir el precio</span>',
            prefix: this.brands.jazztel,
            logoPath: './assets/img/jz-login-c.svg',
            data: [data],
            canOpen: true,
            onToggle: (prefix) => this.changeBrand(prefix)
        });
    }

    setHmBrandOrange() {
        if (!this.permSv.hasPermFromV2(allPermissions.orange.permite_ventas_prepago) && !this.permSv.hasPermFromV2(allPermissions.orange.permite_ventas_pospago)) {
            return;
        }

        const dataPrepaid: HomeBrandData = {
            bodyTitle: 'Móvil prepago',
            bodyPrincipalLinks: [],
            bodySecondaryLinks: [],
            footerLinks: [],
        };

        const dataPostpaid: HomeBrandData = {
            bodyTitle: 'Móvil Pospago',
            bodyPrincipalLinks: [],
            bodySecondaryLinks: [],
            footerLinks: [],
        };

        //Botones para el nuevo flujo, en caso tenga el permiso
        if (this.permSv.hasPermFromV2(allPermissions.orange.nuevo_flujo_prepago_orange)) {
            if (!this.permSv.hasPermFromV2(allPermissions.orange.permite_sell_only_esim)) {
                dataPrepaid.bodyPrincipalLinks.push({
                    label: 'Alta',
                    route: '/orange/prepago/alta'
                });
            }

            if (this.permSv.hasPermFromV2(allPermissions.orange.permite_portabilidad)) {
                dataPrepaid.bodyPrincipalLinks.push({
                    label: 'Portabilidad',
                    route: '/orange/prepago/portabilidad',
                });
            }

            if (this.permSv.hasPermFromV2(allPermissions.orange.permite_renovacion_anticipada_bonos)) {
                dataPrepaid.bodyPrincipalLinks.push({
                    label: 'Renovación bonos',
                    route: `${this.baseUrl}rebo_activa.itx?marca=orange`,
                    isExternal: true
                });
            }

            if (this.permSv.hasPermFromV2(allPermissions.orange.permite_sell_bonos_aditional)) {
                dataPrepaid.bodyPrincipalLinks.push({
                    label: 'Bonos adicionales',
                    route: '/orange/bonos-adicionales'
                });
            }

            if (this.permSv.hasPermFromV2(allPermissions.orange.permite_sell_esim)) {
                dataPrepaid.bodyPrincipalLinks.push({
                    label: `${this.permSv.hasPermFromV2(allPermissions.orange.permite_view_new_esim) ? 'Activación' : 'Alta'} eSIM`,
                    route: '/orange/prepago/esim'
                });
            }

            dataPrepaid.bodySecondaryLinks.push({
                label: 'Consulta operaciones prepago',
                route: '/orange/prepago-listado',
                imagePath: './assets/img/iconfinder.svg'
            });

            if (this.permSv.hasPermFromV2(allPermissions.orange.permite_renovacion_anticipada_bonos)) {
                dataPrepaid.bodySecondaryLinks.push({
                    label: 'Consulta renovaciones bonos',
                    route: `${this.baseUrl}rebo_recogida_pdv.itx`,
                    imagePath: './assets/img/iconfinder.svg',
                    isExternal: true
                });
            }

            if (this.permSv.hasPermFromV2(allPermissions.orange.permite_sell_bonos_aditional)) {
                dataPrepaid.bodySecondaryLinks.push({
                    label: 'Consulta bonos adicionales',
                    route: '/orange/bonos-adicionales-listado',
                    imagePath: './assets/img/iconfinder.svg'
                });
            }

            if (this.permSv.hasPermFromV2(allPermissions.orange.permite_ventas_prepago)) {
                if (this.permSv.hasPermFromV2(allPermissions.orange.permite_ventas_pospago) && this.permSv.hasPermFromV2(allPermissions.orange.permite_ventas_prepago)) {
                    dataPrepaid.footerLinks.push({
                        label: 'Ver tarifas',
                        onClick: () => this.viewRates(this.brands.orange),
                    });
                }

                if (this.permSv.hasPermFromV2(allPermissions.orange.permite_ventas_prepago)) {
                    dataPrepaid.footerLinks.push({
                        label: 'Tarifas internacionales',
                        route: 'https://www.simmundo.es/',
                        isExternal: true,
                        isBlank: true
                    });

                    dataPrepaid.footerLinks.push({
                        label: 'Códigos prepago Orange',
                        route: './assets/pdf/Orange - Codigos prepago.pdf',
                        isExternal: true,
                        isBlank: true
                    });
                }
            }

            if (this.permSv.hasPermFromV2(allPermissions.orange.permite_ventas_pospago)) {
                dataPostpaid.bodySecondaryLinks.push({
                    label: 'Consulta operaciones Mi Fijo',
                    route: `${this.baseUrl}pos_recogida_pdv.itx`,
                    imagePath: './assets/img/iconfinder.svg',
                    isExternal: true
                });

                dataPostpaid.bodySecondaryLinks.push({
                    label: 'Anular portab. Mi Fijo',
                    route: `${this.baseUrl}cancelar_mi_fijo.itx`,
                    imagePath: './assets/img/iconcancel.svg',
                    isExternal: true
                });
            }
        } else { //Botones en caso NO tenga el permiso para nuevo flujo
            if (!this.permSv.hasPermFromV2(allPermissions.orange.permite_sell_only_esim)) {
                dataPrepaid.bodyPrincipalLinks.push({
                    label: 'Alta',
                    route: `${this.baseUrl}activa_pdv.itx`,
                    isExternal: true
                });
            }

            if (this.permSv.hasPermFromV2(allPermissions.orange.permite_portabilidad)) {
                dataPrepaid.bodyPrincipalLinks.push({
                    label: 'Portabilidad',
                    route: `${this.baseUrl}activa_pdv_portabilidad.itx`,
                    isExternal: true
                });
            }

            if (this.permSv.hasPermFromV2(allPermissions.orange.permite_venta_terminal_sim)) {
                dataPrepaid.bodyPrincipalLinks.push({
                    label: 'Venta terminal + SIM',
                    route: `${this.baseUrl}activa_pdv_terminal_sim.itx`,
                    isExternal: true
                });
            }

            if (this.permSv.hasPermFromV2(allPermissions.orange.permite_renovacion_anticipada_bonos)) {
                dataPrepaid.bodyPrincipalLinks.push({
                    label: 'Renovación bonos',
                    route: `${this.baseUrl}rebo_activa.itx?marca=orange`,
                    isExternal: true
                });
            }

            if (this.permSv.hasPermFromV2(allPermissions.orange.permite_sell_bonos_aditional)) {
                dataPrepaid.bodyPrincipalLinks.push({
                    label: 'Bonos adicionales',
                    route: '/orange/bonos-adicionales'
                });
            }

            if (this.permSv.hasPermFromV2(allPermissions.orange.permite_sell_esim)) {
                dataPrepaid.bodyPrincipalLinks.push({
                    label: `${this.permSv.hasPermFromV2(allPermissions.orange.permite_view_new_esim) ? 'Activación' : 'Alta'} eSIM`,
                    route: '/orange/prepago/esim'
                });
            }

            dataPrepaid.bodySecondaryLinks.push({
                label: 'Consulta operaciones prepago',
                route: `${this.baseUrl}recogida_pdv.itx`,
                imagePath: './assets/img/iconfinder.svg',
                isExternal: true
            });

            if (this.permSv.hasPermFromV2(allPermissions.orange.permite_renovacion_anticipada_bonos)) {
                dataPrepaid.bodySecondaryLinks.push({
                    label: 'Consulta renovaciones bonos',
                    route: `${this.baseUrl}rebo_recogida_pdv.itx`,
                    imagePath: './assets/img/iconfinder.svg',
                    isExternal: true
                });
            }

            if (this.permSv.hasPermFromV2(allPermissions.orange.permite_sell_bonos_aditional)) {
                dataPrepaid.bodySecondaryLinks.push({
                    label: 'Consulta bonos adicionales',
                    route: '/orange/bonos-adicionales-listado',
                    imagePath: './assets/img/iconfinder.svg'
                });
            }
        }


        this.hmBrands.push({
            name: 'Orange',
            description: '<span>Rompe las reglas con las tarifas Mundo</span>',
            prefix: this.brands.orange,
            logoPath: './assets/img/or-login-c.svg',
            data: [dataPrepaid, dataPostpaid],
            canOpen: true,
            onToggle: (prefix) => this.changeBrand(prefix)
        });
    }

    changeBrand(brand?: string) {
        this.brandOpened = brand;
        this.showMessages = brand == null;
        this.titleColumn = brand == null ? 'col-12' : 'col';
        this.buttonColumn = brand == null ? 'col-12' : 'col-auto';

        if (brand != null) {
            this.hmBrands.forEach(hb => hb.isOpen = false);
        }

        if (brand != null && this.innerWidth <= 920) {
            setTimeout(() => window.scrollTo(0, 250));
        }
    }

    viewRates(brand) {
        this.tabGroup.selectedIndex = this.homeService.findIndexToMove(this.tabGroup, tabHome.TARIFAS);
        this.rateService.setFilterRatesByBrand(brand);
    }

    viewOperation(type) {
        this.tabGroup.selectedIndex = this.homeService.findIndexToMove(this.tabGroup, tabHome.OPERACIONES);
        this.homeService.setTypeOperationForms({type: type});
    }

    viewCommissions() {
        this.tabGroup.selectedIndex = this.homeService.findIndexToMove(this.tabGroup, tabHome.COMISIONES);
    }

    openModalSimyoContratos() {
        this.modalService.open(HmModalSimyoContratosComponent, {size: 'lg', centered: true});
    }

    openModalJazztelContratos() {
        this.modalService.open(HmModalJazztelContratosComponent, {size: 'lg', centered: true});
    }

    getAbsoluteValue(value) {
        return Math.abs(value);
    }

    public getWidth(): number {
        if (this.rappelSimyoData) {
            let maxValueProgressBar: number = 0;
            // La barra de progreso representa el número de activaciones con respecto al mínimo de activaciones del nivel más alto
            maxValueProgressBar = Math.max.apply(Math, this.rappelSimyoData.master.map((level: IrappelMaster) => {
                return level.min_activations;
            })) || 20;
            return (this.rappelSimyoData.current.current_activations * 100) / maxValueProgressBar;
        }
        return 0;
    }

    public checkSimyoTemporaryClosure(path: string) {
        if (this.permSv.hasPermFromV2(this.allPermissions.simyo.nbss_block)) {
            this.openSimyoTemporaryClosure();
            return;
        }

        this.router.navigate([path]);
    }

    public openSimyoTemporaryClosure() {
        this.modalService.open(SimyoTemporaryClosureComponent, {size: 'lg', centered: true, backdrop: 'static'});
    }

    ngOnDestroy() {
        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map(subs => subs.unsubscribe());
        }
    }

    viewPoints() {
        this.tabGroup.selectedIndex = this.homeService.findIndexToMove(this.tabGroup, tabHome.PUNTOS);
    }

    getPointsWidth() {
        if (!this.dataPoints) {
            return 0;
        }
        return Number(this.dataPoints.pointsWonThisMonth) * 100 / Number(this.dataPoints.pointsWonLastMonth);
    }

    getBannerSimyo() {
        if (this.permSv.getPermissionSimyoRates()?.SIMYO_TARIFAS_MOSTRAR_OFERTAS == 'MOSTRAR') {
            return './assets/img/banner_digo_navidad2024_v2.gif';
        }
        return './assets/img/banner_digo_navidad2024_v3.gif';
    }

}
