import { HttpErrorResponse } from '@angular/common/http';
import { StringMap } from '@angular/compiler/src/compiler_facade_interface';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription, of } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { CreatePrepaidResponse } from 'src/app/orange/models/createPrepaid';
import {MasterAllOrange, OrangeDocumentType} from 'src/app/orange/models/masterAllResponse';
import { AppState } from 'src/app/orange/redux';
import { setProvinceByPostalCode, setOrderDraftId} from 'src/app/orange/redux/orange.actions';
import { IOrangePrepaidState } from 'src/app/orange/redux/orange.state';
import { OrangeOrdersService } from 'src/app/orange/services/orange-orders.service';
import { OrangePrepaidHiringService } from 'src/app/orange/services/orange-prepaid-hiring.service';
import { brands, maxDate, minDate, orangePrepaidTypes, orangeWelcomePackCodes } from 'src/app/shared/constantes';
import { Ipdv } from 'src/app/shared/models/pdvResponse';
import { blankSpaceValidator } from 'src/app/utils/validators/blank-space-validator';
import { cifValidator, dniValidator, getMaxLengthByDoc, nieValidator } from 'src/app/utils/validators/spanish-id-validator';

@Component({
    selector: 'app-orange-prepaid-user-data',
    templateUrl: './orange-prepaid-user-data.component.html',
    styleUrls: ['./orange-prepaid-user-data.component.scss', '../../../../../assets/css/orange-theme.css']
})
export class OrangePrepaidUserDataComponent implements OnInit {

    @Input() userDataFormGroup: FormGroup;
    @Input() agreementsFormGroup: FormGroup;
    @Input() stepper: MatStepper;
    @Input() pdv: Ipdv;
    @Input() configurationFormGroup: FormGroup;
    @Input() type: string;
    @Input() previousIndex: number;
 
    @ViewChild('modalOrderPending') modalOrderPending: any;
    private validationEmailRequest: Subscription;
    public maxLengthByDoc: number = 9;
    public maxDate: Date = maxDate;
    public minDate: Date = minDate;
    public orange$: Observable<IOrangePrepaidState>;
    public orangeWelcomePackCodes: StringMap = orangeWelcomePackCodes;
    public master: MasterAllOrange;
    public documentTypeName: string = '';
    public onlySpain = false;
    public mailInfo = '';
    public errorEmailValidation: string = '';
    public validEmailLoading: boolean = false;
    public isCreateLoading: boolean = false;
    public showErrorOrderMsg: string = 'Error, no pudimos crear el pedido';
    public showErrorOrder: boolean = false;

    public allowedAllCountries = false;
    public countries = [];

    public loadCheckDocument = false;
    public draftOrderId = null;
    public validateDocument = true;
    public orangePrepaidTypes = orangePrepaidTypes;
    constructor(
        private orangeStore: Store<AppState>,
        private orangePrepaidHiringService: OrangePrepaidHiringService,
        private orangeOrderService: OrangeOrdersService,
        private modalService: NgbModal
    ) {
        this.orange$ = orangeStore.pipe(select('orangePrepaid'));
    }

    ngOnInit(): void {
        //this.userDataFormGroup.get('email').markAsTouched();
        this.orange$.subscribe(d => {
            this.master = d.master;
            this.countries = d.master?.countries?.filter(country => {
                return (this.onlySpain && country.country_id === 64)
               || (!this.onlySpain && country.country_id !== 64) 
               || this.allowedAllCountries
            })
        });
        this.userDataFormGroup.get('email')?.valueChanges.subscribe(() => {
            let emailConfirmation = this.userDataFormGroup.get('email_confirm');
            emailConfirmation?.markAsUntouched();
            emailConfirmation?.setValue('');
            emailConfirmation?.disable();
            
            if (this.userDataFormGroup.get('email')?.valid) {
                emailConfirmation?.enable();
                return;
            }
        })

        this.userDataFormGroup.get('document_number').valueChanges.subscribe(() => {
            //crear variable para volver a comprobar el documento
            this.validateDocument = true;
        });
        
        this.mailInfo = this.type === orangePrepaidTypes.esim ? 'Obligatorio para realizar el envío del resumen de contrato' : 'El mail del cliente es obligatorio para realizar el envío del resumen de contrato'
    }

    public changeTypeDocument(documentType: OrangeDocumentType): void {
        const docValidators: ValidatorFn[] = [Validators.required,  Validators.pattern('^[a-zA-Z0-9]+$')];

        if (documentType && documentType.name !== null) {
            this.documentTypeName = documentType.name;
        }

        this.onlySpain = false;
        this.allowedAllCountries = false;
        this.userDataFormGroup.get('second_surname').clearValidators();
        this.userDataFormGroup.get('second_surname').updateValueAndValidity();

        switch (this.documentTypeName) {
            case 'NIF':
            case 'DNI':
            case 'DNI/NIF':
                this.setNationalityToSpain()
                docValidators.push(dniValidator());

                /*this.userDataFormGroup.get('second_surname').setValidators([Validators.required]);
                this.userDataFormGroup.get('second_surname').updateValueAndValidity();*/
                break;
            case 'NIE':
                this.userDataFormGroup.get('nacionality').patchValue(null);
                docValidators.push(nieValidator());
                break;
            case 'CIF':
                this.setNationalityToSpain()
                docValidators.push(cifValidator());
                break;
            case 'Pasaporte':
            case 'PASAPORTE':
                this.allowedAllCountries = true;
                this.userDataFormGroup.get('nacionality').patchValue(null);
                break;
            default:
                this.userDataFormGroup.get('nacionality').patchValue(null);
        }

        this.userDataFormGroup.get('document_number').setValidators(docValidators);
        this.userDataFormGroup.get('document_number').updateValueAndValidity();
        //this.onChanges();
    }

    private setNationalityToSpain() {
        this.onlySpain = true;
        this.userDataFormGroup.get('nacionality').patchValue(this.master.countries.find(country => country.country_id === 64));
    }

    public setMaxLengthByDoc(documentType: OrangeDocumentType) {
        if (documentType && documentType.document_type_id !== null) {
            this.maxLengthByDoc = getMaxLengthByDoc(brands.orange, documentType.document_type_id);
        }
    }

    public next() {
        if (this.validate()) {
            this.checkDocumentPending();
        }

        this.focusAndScrollFirstError()
    }

    public onChangeZip(value) {
        if (value && +value > 99999) {
            value = value.substring(0,5);
            this.userDataFormGroup.get('postal_code').patchValue(value);
            //this.onChanges();
        }
        this.orangeStore.dispatch(setProvinceByPostalCode({postalCode: +value}));
    }

    public onChanges() {
        //Si esta todo correcto, scroll para que se vea el nuevo botón de abajo
        if (this.userDataFormGroup.valid && this.agreementsFormGroup.valid) {
            setTimeout(() => {
                window.scrollTo({
                    top: document.body.scrollHeight,
                    left: 0 ,
                    behavior: 'smooth'
                });
            }, 1000);
        }
    }
    public isValidEmail(): void {
        if (this.userDataFormGroup.get('email').valid) {
            this.errorEmailValidation = '';
            this.validEmailLoading = true;
            if (this.validationEmailRequest) {
                this.validationEmailRequest.unsubscribe();
            }
            this.validationEmailRequest = this.orangePrepaidHiringService.emailPrepaidValid(this.userDataFormGroup.get('email').value)
                .subscribe((data: any) => {
                    if (data) {
                        if (data.msg === 'ok') {
                            this.userDataFormGroup.get('email').setErrors(null);
                        }
                        if (data.msg === 'ko') {
                            this.userDataFormGroup.get('email').markAsTouched();
                            this.userDataFormGroup.get('email').setErrors({ invalid: true });
                            this.errorEmailValidation = data.error;
                        }
                    } else {
                        this.errorEmailValidation = 'Ha ocurrido un error al validar el email. Inténtelo de nuevo más tarde';
                    }
                    this.validEmailLoading = false;
                }, (error: HttpErrorResponse) => {
                    this.errorEmailValidation = 'Ha ocurrido un error al validar el email. Inténtelo de nuevo más tarde';
                    this.validEmailLoading = false;
                });
        }
    }

    private validate() {
        this.userDataFormGroup.markAllAsTouched();
        this.userDataFormGroup.updateValueAndValidity();
        if (!this.userDataFormGroup.valid) {
            const elements: any = document.getElementsByClassName('mat-input-element ng-invalid');
            if (elements.length > 0) {
                elements[0].focus();
            }
            return false;
        }
        return true;
    }

    private createDraftOrder() {
        if (this.draftOrderId === null && this.type === orangePrepaidTypes.esim && this.configurationFormGroup.valid) {
            this.isCreateLoading = true;
            let userData = {
                ...this.userDataFormGroup.value,
                province: this.userDataFormGroup.get('province').value
            };
            return this.orangeOrderService.createDraftOrderEsim(
                userData,
                this.configurationFormGroup.value,
                this.agreementsFormGroup.value,
                this.type,
                '',
                false,
                this.pdv?.sfids?.orange)
                .subscribe(r => this.onCreateSuccess(r), e => this.onCreateError(e), () => this.onCreateComplete());
                
        }
        this.stepper.selectedIndex = 2;
        this.previousIndex = 2;
    }

    private onCreateSuccess(response: CreatePrepaidResponse) {
        if (response?.msg) {
            if (response?.msg?.order_id && response?.msg?.order_id !== '') {
                this.draftOrderId = response.msg.order_id;
                this.orangeStore.dispatch(setOrderDraftId({orderDraftId: response.msg.order_id}));
                this.stepper.selectedIndex = 2;
                this.previousIndex = 2;
                return;
            }
        }

        if (response?.error?.error_code.toString().includes('E')) {
            this.showErrorOrderMsg = response.error.msg + ' (' + response?.error?.error_code.toString() + ')';
        }

        this.showErrorOrder = true;
    }

    private onCreateError(error: HttpErrorResponse) {
        this.showErrorOrder = true;
    }

    private onCreateComplete() {
        this.isCreateLoading = false;
    }

    public checkErrorFieldForm(fieldName, errors:string[]) {
        let hasError = this.userDataFormGroup.get(fieldName)?.errors ? Object.keys(this.userDataFormGroup.get(fieldName)?.errors).findIndex((key) => errors.includes(key)) > -1 : false;
        return hasError;
        //return hasError && this.userDataFormGroup.get(fieldName).touched;
    }
    private focusAndScrollFirstError() {
        const formulario = document.getElementById('userDataForm') as HTMLFormElement;
        if (formulario) {
            const camposConErrores = formulario.querySelectorAll(':invalid');
            
            if (camposConErrores.length > 0) {
                const primerCampoConError = camposConErrores[0] as HTMLElement;
            
                primerCampoConError.focus();
                primerCampoConError.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }

    alphanumericOnly(event) {
        let pattern: RegExp = /^[a-zA-Z0-9]+$/;
        const inputChar = event.key;
        if (!pattern.test(inputChar)) {
            event.preventDefault();
        }
    }

    
    checkDocumentPending() {
        if(this.loadCheckDocument || this.validateDocument === false){
            this.createDraftOrder(); 
            return;
        } 
        this.loadCheckDocument = true;
        let currentDocument = this.userDataFormGroup.get('document_number').value;        
        this.orangePrepaidHiringService.checkPending(currentDocument, this.type === orangePrepaidTypes.esim).pipe(
            take(1),
            catchError(err => {
                return of({msg:'error'})
            }),
            tap(result => {
                if(result.msg === 'ok' && result.has_pending_orders) {
                    let modalRef = this.modalService.open(this.modalOrderPending, {size: 'lg', centered: true, backdrop: 'static', keyboard : false});
                    modalRef.result.then((result) => {
                        if(result === false) {
                            this.userDataFormGroup.reset();
                            return;
                        }
                        this.createDraftOrder();
                    });
                    return;
                }
                this.createDraftOrder();                
            }),
            tap(() => {
                this.loadCheckDocument = false;
                this.validateDocument = false;
            })
        ).subscribe();
    }
}
