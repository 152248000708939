<form [formGroup]="data.formGroup">
    <div class="modal-header bg-simyo-grey">
        <h5 class="modal-title white" id="exampleModalLabel">Editar contacto</h5>

        <button type="button" class="close" (click)="save()">
            <span aria-hidden="true">
                <img src="./assets/img/times2.svg" class="img-fluid ico-title" width="20">
            </span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col-lg-12">

                <h6 class="subtitulo-pack-form">Datos personales </h6>
                <!-- <button class="btn btn-sm btn-green" (click)="copyUserData()">Usar datos del cliente</button> -->
                <div class="form-row">
                    <div class="col-lg-3 mt-1">
                        <mat-form-field>
                            <mat-label>Tipo documento</mat-label>
                            <select matNativeControl required formControlName="account_doctype"
                                    (change)="changeTypeDocument($event.target.value); setMaxLengthByDoc($event.target.value)">
                                <option *ngFor="let documentType of documentTypes" [value]="documentType.document_type_id">
                                    {{ documentType.value }}
                                </option>
                            </select>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-3 mt-1">
                        <mat-form-field>
                            <mat-label>Nº de documento</mat-label>
                            <input matInput="" type="text" oninvalid="" formControlName="account_doc" [maxLength]="maxLengthByDoc" (keypress)="alphanumericOnly($event)">
                            <!-- error -->
                            <mat-error *ngIf="data.formGroup.get('account_doc')?.errors?.required">
                                Campo obligatorio.
                            </mat-error>
                            <mat-error *ngIf="(data.formGroup.get('account_doc')?.errors?.doc || data.formGroup.get('account_doc')?.errors?.doc) && !data.formGroup.get('account_doc')?.errors?.required">
                                Número de documento erróneo
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-3 mt-1">
                        <mat-form-field>
                            <mat-label>Nacionalidad</mat-label>
                            <select matNativeControl required formControlName="account_country">
                                <ng-container *ngFor="let country of countries;">
                                    <option [value]="country.country_id" *ngIf="!(data.formGroup.get('account_doctype').value === '1' && country.country_id === 192)">
                                        {{ country.value }}
                                    </option>
                                </ng-container>
                            </select>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-3 mt-1">
                        <mat-form-field>
                            <mat-label>Nombre</mat-label>
                            <input matInput="" type="text" formControlName="account_firstName">
                        </mat-form-field>
                    </div>

                    <div class="col-lg-3 mt-1">
                        <mat-form-field>
                            <mat-label>Apellido1</mat-label>
                            <input matInput="" type="text" formControlName="account_surname1">
                        </mat-form-field>
                    </div>

                    <div class="col-lg-3 mt-1">
                        <mat-form-field>
                            <mat-label>Apellido2</mat-label>
                            <input matInput="" type="text" formControlName="account_surname2">
                        </mat-form-field>
                    </div>

                    <!--<div class="col-lg-3 mt-1">
                        <mat-form-field>
                            <mat-label>Fecha de nacimiento</mat-label>
                            <input matInput [matDatepicker]="picker" required formControlName="birthday"
                                   formControlName="account_birthday"
                                   [value]="formGroup.get('account_birthday').value">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-3 mt-1">
                        <mat-form-field>
                            <mat-label>Sexo</mat-label>
                            <select matNativeControl formControlName="account_sex" [value]="formGroup.get('account_sex').value">
                                <option>-</option>
                                <option value="M">Masculino</option>
                                <option value="F">Femenino</option>
                            </select>
                        </mat-form-field>
                    </div>-->


                    <!-- Este campo aparece si se elige CIF -->

                    <div class="col-lg-6 mt-1" *ngIf="requireCif">
                        <mat-form-field>
                            <mat-label>Razón social</mat-label>
                            <input matInput="" type="text" oninvalid="" formControlName="account_company">
                        </mat-form-field>
                    </div>
                </div>


                <!--<h6 class="subtitulo-pack-form">Datos de contacto</h6>

                <div class="form-row">
                    <div class="col-lg-3 mt-1">
                        <mat-form-field>
                            <mat-label>Teléfono de contacto</mat-label>
                            <input matInput="" type="text" oninvalid="" formControlName="account_phone"
                                   [value]="formGroup.get('account_phone').value">
                        </mat-form-field>
                    </div>

                    <div class="col-lg-3 mt-1">
                        <mat-form-field>
                            <mat-label>Correo electrónico</mat-label>
                            <input type="text" matInput="" oninvalid="" formControlName="account_email"
                                   [value]="formGroup.get('account_email').value">
                        </mat-form-field>
                    </div>
                </div>-->

                <h6 class="subtitulo-pack-form">Datos bancarios</h6>

                <div class="form-row">
                    <div class="col-lg-6 mt-1">
                        <mat-form-field *ngIf="data.formGroup.get('account_doctype').value !== '3'">
                            <mat-label>IBAN* (ESXX XXXX XXXX XX XXXXXXXXXX)</mat-label>
                            <input matInput="" type="text" oninvalid="" formControlName="bank_account" mask="AA00 0000 0000 00 0000000000">
                        </mat-form-field>
                        <mat-form-field *ngIf="data.formGroup.get('account_doctype').value === '3'">
                            <mat-label>IBAN*</mat-label>
                            <input matInput="" type="text" oninvalid="" formControlName="bank_account" maxlength="32">
                        </mat-form-field>
                    </div>
                </div>

                <div align="center">
                    <br><br>
                    <a class="btn btn-simyo-o2 btn-sm mb-3" role="button" (click)="save()">Guardar</a>
                </div>

            </div>
        </div>
    </div>
</form>
