import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DigoService } from 'src/app/services/digo.service';
import { Ipdv } from 'src/app/shared/models/pdvResponse';
import { PermissionService } from 'src/app/shared/services/permission.service';

@Injectable({
  providedIn: 'root'
})
export class OrangePrepaidHiringGuard implements CanActivate {
    constructor(
        private permSv: PermissionService,
        private router: Router,
        private digoService: DigoService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        return this.digoService.getPDV()
            .pipe(
                switchMap((response: Ipdv) =>{
                    /*if (!this.permSv.hasPermFromV2(allPermissions.orange.permite_sell_esim)) {
                        this.router.navigate(['/']);
                        return of(false);
                    }*/
                    return of(true);
                })
            );
    }

}
