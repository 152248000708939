<div *ngIf="!loaded && !orderNotFound" class="mt-5 mb-5">
    <h5 align="center" class="tar-name-preload">Obteniendo orden...</h5>

    <div align="center">
        <img class="" src="./assets/img/spinner-simyo.svg" width="30" alt="">
    </div>
</div>

<div *ngIf="loadingCobertura" class="mt-5 mb-5">
    <h5 align="center" class="tar-name-preload">Consultando cobertura...</h5>

    <div align="center">
        <img class="" src="./assets/img/spinner-simyo.svg" width="30" alt="">
    </div>
</div>

<div class="container content mb-2" *ngIf="loaded && !loadingCobertura">
    <!-- Titulo -->
    <div class="row">
        <div class="col-md-12">
            <h5 class="title-step mt-4" align="center">
                Resumen de la activación
            </h5>
        </div>
    </div>

    <!-- Descripcion rapida -->
    <div class="row mt-3 mb-2">
        <div class="col-md-12">
            <div class="jumbotron-norm-r mb-3">
                <div class="row ml-1">
                    <div class="col-lg-2">
                        <h5 align="left" class="tar-name-6 mt-2 mb-1">Nº de contrato</h5>
                        <h5 align="left" class="tar-name-7 mt-1">{{ getContractNumber() }}</h5>
                    </div>
                    <!-- Caducidad -->
                    <div class="col-lg-2">
                        <h5 align="left" class="tar-name-6 mt-2 mb-1">Fecha</h5>
                        <h5 align="left" class="tar-name-7 mt-1">{{ order.tramited_at && order.tramited_at !== '' ? (order.tramited_at | date: 'dd/MM/yyyy, HH:mm') : (order.created_at | date: 'dd/MM/yyyy, HH:mm') }}</h5>
                    </div>
                    <!-- Estado -->
                    <div class="col-lg-2">
                        <h5 align="left" class="tar-name-6 mt-2 mb-1">Estado</h5>
                        <h5 align="left" class="tar-name-7 mt-1 complete">
                            {{ order.status_description }}
                            <i-feather *ngIf="showUpdateOrder(order)" (click)="getOrderInfo(true)" name="refresh-cw" class="icon--fe m-1 cursor-pointer" style="width: 15px !important; height: auto; color: #000;" title="Pulse para actualizar el pedido"></i-feather>
                        </h5>
                    </div>
                    <!-- Descripcion -->
                    <div class="col-lg-2" *ngIf="order.error_description && order.error_description !== ''">
                        <h5 align="left" class="tar-name-6 mt-2 mb-1">Descripción</h5>
                        <h5 align="left" class="tar-name-7 mt-1">{{ order.error_description }}</h5>
                    </div>
                    <!-- Documentación -->
                    <div class="col" *ngIf="order.doc_status && order.doc_status !== ''">
                        <h5 align="left" class="tar-name-6 mt-2 mb-1">Documentación {{ order?.sign_type && order?.sign_type !== 'not_required' && order?.sign_type !== 'old' ? '(' + translateSignType[order.sign_type] + ')' : '' }}</h5>
                        <h5 align="left" class="tar-name-7 mt-1">{{ order.doc_status }}{{ order.doc_annotation && order.doc_annotation !== '' ? ': ' + order.doc_annotation : '' }}</h5>
                    </div>
                    <!-- Incidencias -->
                    <div class="col-auto mr-3" align="right" *ngIf="order.doc_warning && order.doc_warning !== ''" style="align-self: center;" title="Ver incidencias" (click)="moveTo('incidencias')">
                        <img src="./assets/img/info.svg" class="img-fluid ico-title icon-white cursor-pointer" width="20">
                    </div>
                    <!-- Fecha cita -->
                    <div class="col" *ngIf="order && order.broadband && order.broadband.appointment_date && order.broadband.appointment_date !== ''">
                        <h5 align="left" class="tar-name-6 mt-2 mb-1">Fecha cita</h5>
                        <h5 align="left" class="tar-name-7 mt-1">{{ formatAppointmentDate(order.broadband.appointment_date) }}</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Cliente -->
    <app-simyo-summary-client
    [customer]="order.customer"
    [typeOrder]="typeOrder"
    [broadband]="order.broadband"
    [mobiles]="order.mobiles"
    [date]="tramitedDate"
    [rateFtth]="order.broadband?.rate"
    [contract_id]="order.contract_id"
    [is_contingency]="order.is_contingency"
    [isDetail]="true"></app-simyo-summary-client>

    <!-- Resumen tramitacion-->
    <app-simyo-summary-processing
    [typeOrder]="typeOrder"
    [broadband]="order.broadband"
    [mobiles]="order.mobiles"
    [rateFtth]="order.broadband?.rate"
    [customerData]="customerData"
    [ratesMobiles]="order.mobiles"
    [super_status]="order.super_status"
    [orderTotalInitial]="order.total_initial"
    [orderTotalMonthly]="order.total_monthly"
    [date]="tramitedDate"
    [isDetail]="true"></app-simyo-summary-processing>

    <!-- Incidencias -->
    <div class="row mb-2" *ngIf="order.doc_warning && order.doc_warning !== ''" id="incidencias">
        <div class="col-md-12">
            <div class="accordion">
                <div class="card mb-3">
                    <div class="card-header bg-rm">
                        <div class="d-flex justify-content-between align-items-center">
                            <div>
                                <h6 class="subtitulo-pack2 ml-3">
                                    <b>Incidencias</b>
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div class="bg-white">
                        <div class="row">
                            <div class="col-md-12">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item cuerpo-pack-2">
                                        <h5 class="tar-name-7 mt-2 ml-3">{{ order.doc_warning }}</h5>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Firma Nueva -->
    <div class="mb-2" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_new_signature) && order?.super_status !== superStatus.draft">
        <app-simyo-documentation-signature [dataOrderToSignature]="dataOrderToSignature" [documentationMode]="documentationMode.viewDocumentation" [fullOrder]="order" [signatureFormGroup]="signatureFormGroup"></app-simyo-documentation-signature>
    </div>

    <!-- Botones -->
    <div align="center" class="my-4" *ngIf="!this.permSv.hasPermFromV2(allPermissions.simyo.tuvo_activaciones)">
        <button (click)="continueDraftOrder()" class="btn btn-simyo-o2 btn-sm w-100" *ngIf="order.super_status === superStatus.draft && !permSv.hasPermFromV2(allPermissions.simyo.nbss_block)" [disabled]="disabledButton">
            Continuar la tramitación donde la había dejado
        </button>
        <button (click)="openModalNewProcess(true)" class="btn btn-simyo-o2 btn-sm w-100" *ngIf="order.super_status !== superStatus.draft && !permSv.hasPermFromV2(allPermissions.simyo.nbss_block)" [disabled]="disabledButton">
            Nueva tramitación copiando los datos de este cliente
        </button>
        <button (click)="openModalNewProcess(false)" class="btn btn-simyo-o2 btn-sm w-100 mt-2" *ngIf="(order.super_status === superStatus.cancelled || order.can_retry) && !permSv.hasPermFromV2(allPermissions.simyo.nbss_block)" [disabled]="disabledButton">
            Retramitación con los productos cancelados
        </button>
        <button (click)="removeDraft()" class="btn btn-simyo-o2 btn-sm w-100 mt-2" *ngIf="order.super_status === superStatus.draft" [disabled]="disabledButton">
            Eliminar borrador
        </button>
    </div>
    <br><br>
</div>

<div class="container mt-3" *ngIf="orderNotFound">
    <div class="row">
        <div class="col-md-12">
            <h5 class="title-step mt-4" align="center">
                Lo sentimos, el pedido que está buscando no existe
            </h5>
            <div align="center">
                <button (click)="goBack()" class="btn btn-simyo-o2 btn-sm mt-2">
                    Volver al listado
                </button>
            </div>
        </div>
    </div>
</div>

<ng-template #infoBroadband>
    <div class="d-flex flex-column">
        <span>{{ order?.broadband?.substatus?.description }}</span>
    </div>
</ng-template>
