import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AuthService} from 'src/app/shared/services/auth.service';
import {environment} from 'src/environments/environment';
import {CreatePrepaidRequest, CreatePrepaidResponse} from '../models/createPrepaid';
import {PrepaidOrderListRequest, PrepaidOrderListResponse, PrepaidOrderResponse} from '../models/prepaidInfoResponse';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class OrangePrepaidHiringService {

    constructor(
        private http: HttpClient,
        private auth: AuthService
    ) { }

    getOrderPrepaid(id: string, use_legacy = true, notCheckStatus?: boolean, recharge = 0) {
        if (use_legacy) {
            return this.http.get<PrepaidOrderResponse>(`${environment.digoEndPoint}api-micros/pdv/orange/prepaid/detail.itx?order_id= ${id}&recharge=${recharge}`, {headers: this.getHeaders()});
        }

        return this.http.get<PrepaidOrderResponse>(environment.orangeEndPoint + 'esim/' + id + (notCheckStatus ? '?check_status=0' : ''), {headers: this.getHeaders()});
    }

    checkIfMsisdnIsValid(msisdn: string, user) {
        let digoUser = environment.production === false ? `&digo_user=${user}` : '';
        return this.http.get(environment.digoEndPoint + 'api-micros/isValidMsisdnPrepagoOrange.itx?msisdn=' + msisdn + digoUser);
    }

    geLineQrCode(url: string) {
        return this.http.get(url, {headers: this.getHeaders()});
    }

    listPrepaid(body: PrepaidOrderListRequest) {
        // return this.http.post<PrepaidOrderListResponse>(environment.orangeEndPoint + 'esim/list', body, {headers: this.getHeaders()});
        return this.http.post<PrepaidOrderListResponse>(environment.digoEndPoint + 'api-micros/pdv/orange/prepaid/list.itx', body, {headers: this.getHeaders()});
    }

    createEsimPreOrder(body: CreatePrepaidRequest){
        //Endpoint precontrato
        return this.http.post<CreatePrepaidResponse>(environment.orangeEndPoint + 'precontract/generate', body, {headers: this.getHeaders()});
    }

    createEsimOrder(body: CreatePrepaidRequest) {
        //Tramitación solo el hash
        return this.http.post<CreatePrepaidResponse>(environment.orangeEndPoint + 'esim/create', body, {headers: this.getHeaders()});
    }

    createPrepaidPreOrder(body: CreatePrepaidRequest) {
        //Endpoint precontrato
        return this.http.post<CreatePrepaidResponse>(environment.orangeEndPoint + 'precontract/generate', body, {headers: this.getHeaders()});
    }

    createPrepaidOrder(body: CreatePrepaidRequest) {
        return this.http.post<CreatePrepaidResponse>(environment.digoEndPoint + 'api-micros/pdv/orange/prepaid/create.itx', body, {headers: this.getHeaders()});
    }

    downloadPre(hash: string) {
        //Endpoint descarga precontrato
        return this.http.post(environment.orangeEndPoint + 'precontract/download', {hash: hash}, {headers: this.getHeadersPdf(), responseType: 'blob'});
    }

    sendEmailEsim(id: string, email_to:string, msisdn: string) {
        const data = {
            email_to,
            msisdn
        }
        return this.http.post<CreatePrepaidResponse>(environment.orangeEndPoint + 'esim/' + id + '/email/resend', data, {headers: this.getHeaders()});
    }

    cancelOrderPrepaid(id: string) {
        return this.http.get<PrepaidOrderResponse>(environment.digoEndPoint + 'api-micros/pdv/orange/prepaid/cancel.itx?order_id=' + id, {headers: this.getHeaders()});
    }

    rechargeOrderPrepaid(id: string) {
        return this.http.post<PrepaidOrderResponse>(environment.digoEndPoint + 'api-micros/pdv/orange/prepaid/launch_recharge.itx', {order_id: id}, {headers: this.getHeaders()});
    }

    emailPrepaidValid(email: string): Observable<any> {
        return this.http.get<any>(environment.orangeEndPoint + `/esim/check/email?email_check=${email}`, {headers: this.getHeaders()});
    }

    getHeaders() {
        const token = 'Bearer ' + this.auth.getToken();

        return new HttpHeaders({
            Authorization: token,
            'Content-Type': 'application/json'
        });
    }

    sendErrorKYC(orderId: string, error: any): Observable<any> {
        return this.http.post<any>(environment.orangeEndPoint + `esim/${orderId}/kyc-error`, {payload: JSON.stringify(error)}, {headers: this.getHeaders()});
    }

    private getHeadersPdf(): HttpHeaders {
        return new HttpHeaders({
            Authorization: 'Bearer ' + this.auth.getToken(),
            Accept: 'application/pdf'
        });
    }

    checkPending(document, is_esim = false) {
        return this.http.post<any>(`${environment.orangeEndPoint}customer/check-pending`, {document, is_esim}, {headers: this.getHeaders()});
    }
}
