<h6 class="subtitulo-pack-form" [ngClass]="{ 'error' : errorForm }">
    {{ 'Terminal ' + (index + 1) + 'ª línea móvil' }}
</h6>


<div class="jumbotron-form2" [ngClass]="{ 'error' : errorForm }">
    <div class="d-flex justify-content-between align-items-center">
        <div>
            <span class="label-ad-text2">Modelo</span>
            <br>
            <!-- Modelo -->
            <span class="label-ad-text">
                <span *ngIf="!terminal|| !paymentType">-</span>
                <span *ngIf="terminal && paymentType">{{ terminal?.brand }} {{ terminal?.model }} </span>
                <span *ngIf="terminal && paymentType && paymentType === 'unico'" class="price">{{ terminal?.price }}€</span>
                <span *ngIf="terminal && paymentType && paymentType === 'VAP'" class="price"> {{ +terminal?.upfront === 0 ? terminal?.installment + ' € x ' + terminal?.dues + 'meses' : terminal?.upfront + ' € + (' + terminal?.installment + ' € x ' + terminal?.dues + 'meses)' }}</span>
            </span>
        </div>
        <small class="text-muted btn-muted">
            <a href="javascript:void(0)" (click)="removeSelectedTerminal()" *ngIf="terminal && paymentType">
                <img src="./assets/img/trash.svg" class="img-fluid ico-title-trash" width="17">
            </a>
            <button class="btn btn-simyo-a btn-sm" type="button" role="button" (click)="open(content)">Añadir</button>
        </small>
    </div>
</div>
<mat-error *ngIf="showAlertTypePay">
    Debe seleccionar tipo de pago antes de seleccionar terminal
</mat-error>
<mat-error *ngIf="changeTerminal">
    Hemos eliminado el terminal seleccionado al ser incompatible con el tipo de operación
</mat-error>

<ng-template #content let-modal>
    <div class="modal-header bg-simyo-grey">
        <h5 class="modal-title white" id="exampleModalLabel">Selección de terminal</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true"><img src="./assets/img/times2.svg" class="img-fluid ico-title" width="20"></span>
        </button>
    </div>
    <div class="modal-body modal-body-terminal" *ngIf="loaded">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h6 class="subtitulo-pack-form">Filtrado:</h6>
                    <div class="form-row">
                        <div class="col-lg-4 mt-1" *ngIf="showFilterTypePay">
                            <div class="form-group">
                                <select id="tipo-pago" (change)="selectFilterPaymentType($event.target.value)">
                                    <option value="-1">-</option>
                                    <option *ngFor="let tipo of paymentTypes" value="{{ tipo.id }}">
                                        {{ tipo.name }}
                                    </option>
                                </select>
                                <label for="tipo-pago" class="control-label">Tipo de pago*</label><i class="bar"></i>
                            </div>
                        </div>
                        <div class="mt-1 col-lg-4">
                            <div class="form-group">
                                <select id="marca" (change)="selectFilterBrand($event.target.value)">
                                    <option value="">-</option>
                                    <option *ngFor="let marca of brands | orderBy" value="{{ marca }}">{{ marca }}</option>
                                </select>
                                <label for="marca" class="control-label">Marca*</label><i class="bar"></i>
                            </div>
                        </div>
                        <div class="mb-0 col-lg-4">
                            <div class="form-group">
                                <input id="modelo" type="text" oninvalid="" (input)="inputModel($event.target.value)" required>
                                <label for="modelo" class="control-label">Modelo*</label><i class="bar"></i>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-5 mb-5">
                        <div class="col-xl-4 col-md-6" *ngFor="let terminal of terminalsFiltered">
                            <div class="mb-5 mb-sm-3">
                                <div class="row" [ngClass]="{ 'showMoreInfo' : terminal.showMoreInfo }">
                                    <div class="col-lg-5 col-md-12 col-5">
                                        <img src="{{ terminal.image_url }}" class="img-fluid" alt="...">
                                    </div>
                                    <div class="col-lg-7 col-md-12 col-7">
                                        <div class="">
                                            <h5 class="card-title smartphone-title mt-3">{{ terminal.brand }} {{ terminal.model }} {{ terminal.color }}</h5>
                                            <button class="btn btn-simyo-a btn-sm mb-2" (click)="terminal.showMoreInfo = true">Más info</button>
                                        </div>
                                        <ng-container *ngIf="terminal.state == terminalStatus.NOT_AVAILABLE">
                                            <p class="card-text smartphone-title-2">
                                                <span class="font-weight-bold">Temporalmente sin stock</span>
                                                <br><br>
                                            </p>
                                        </ng-container>                                        
                                        <ng-container *ngIf="terminal.state == terminalStatus.AVAILABLE_SOON">
                                            <p class="card-text smartphone-title-2">
                                                <span class="font-weight-bold">Disponible próximamente</span>
                                                <br><br>
                                            </p>
                                        </ng-container>
                                        <ng-container *ngIf="terminal.state == terminalStatus.AVAILABLE">
                                            <p class="card-text smartphone-title-2" *ngIf="terminal.is_installment">
                                                <span class="font-weight-bold">{{ terminal.installment }} €/mes</span> en {{ terminal.dues }} meses
                                                <br>
                                                Pago inicial: <span class="font-weight-bold">{{ terminal.upfront }}€</span>
                                                <br><br>
                                                <button class="btn btn-simyo-a btn-sm" (click)="selectTerminalAndPayment(terminal, 'VAP')">Elegir pago a plazos</button>
                                            </p>
                                            <p class="card-text smartphone-title-2" *ngIf="terminal.price > 0">
                                                Pago único: <span class="font-weight-bold">{{ +terminal.price }}€</span>
                                                <br><br>
                                                <button class="btn btn-simyo-a btn-sm" (click)="selectTerminalAndPayment(terminal, 'unico')">Elegir pago único</button>
                                            </p>
                                        </ng-container>
                                    </div>
                                </div>
                                <div *ngIf="terminal.showMoreInfo">
                                    <div class="row">
                                        <h5 class="card-title smartphone-title mt-3 ml-4">{{ terminal.brand }} {{ terminal.model }} {{ terminal.color }}</h5>
                                        <button type="button" class="close ml-auto mr-3" style="z-index: 999999;" aria-label="Close" (click)="terminal.showMoreInfo = false">
                                            <span aria-hidden="true"><img src="./assets/img/times.svg" class="img-fluid ico-title" width="20"></span>
                                        </button>
                                    </div>
                                    <div [innerHtml]="terminal.feature" class="terminal-feature mb-2"></div>
                                </div>
                                
                            </div>
                        </div>
                        <div class="col-lg-12 text-center" *ngIf="terminalsFiltered.length === 0">
                            No hay terminales.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!loaded" class="mt-2 mb-5">
        <h5 align="center" class="tar-name-preload">Obteniendo terminales...</h5>
    
        <div align="center">
            <img class="" src="./assets/img/spinner-simyo.svg" width="30" alt="">
        </div>
    </div>
</ng-template>