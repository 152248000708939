import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatHorizontalStepper } from '@angular/material/stepper';
import { SimyoService } from '../../services/simyo.service';
import { SimyoMovilPackService } from '../../services/simyo-movil-pack.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { fiberIndirectTechnologies, orderType, stateIccid, typeCard } from 'src/app/shared/constantes';
import { iccidValidator } from 'src/app/utils/validators/iccid-validator';
import { DigoService } from 'src/app/services/digo.service';
import { SimyoTerminalComponent } from '../simyo-terminal/simyo-terminal.component';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { SimyoRateService } from '../../services/simyo-rate.service';
import { SimyoOrderService } from '../../services/simyo-order.service';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { allPermissions } from 'src/app/shared/permissions';
import { masterAllSimyo, serviceProviderSimyo } from '../../models/masterAllSimyo';
import { blankSpaceValidator } from 'src/app/utils/validators/blank-space-validator';
import { NotMustMatch } from 'src/app/utils/validators/not-much-match-validator';
import { tap } from 'rxjs/operators';
import { isAdult, isValueDupWith } from 'src/app/utils/validators/any-form-valid';

@Component({
    selector: 'app-simyo-movil-pack',
    templateUrl: './simyo-movil-pack.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./simyo-movil-pack.component.css', '../../../../assets/css/simyo-theme.css']
})
export class SimyoMovilPackComponent implements OnInit, OnDestroy {
    @Input() index: number;
    @Input() formGroup: FormGroup;
    @Input() clienteFormGroup: FormGroup;
    @Input() customerFormGroup: FormGroup;
    @Input() stepper: MatHorizontalStepper;
    @Output() requestAddMobileLine = new EventEmitter();
    @Output() requestRemovePostpaidsLines = new EventEmitter();
    @Output() requestResetDocument = new EventEmitter()
    @ViewChild('terminalCmp') terminalCmp: SimyoTerminalComponent;
    @ViewChild('esimNotAvailable') esimNotAvailable: NgbModalRef;

    public stepperIndex: number;
    public typeOrder: string;
    public rate: any;
    public typeOperation = '';
    public portabilityTypeClient: string;
    public rateFtth: any = null;
    public allowAddAdditionalLines = true;
    // public priceBonos: number = 0;
    public iccidLoading: boolean = false;
    public showErrorTypeOperation: boolean = false;
    public environment = environment;
    public today = moment();
    public showTerminals = moment('2021-02-24');
    public isAdditional: boolean = false;
    public customerInfo: any;
    public order: any;
    public allPermissions = allPermissions;
    public fiberIndirectTechnologies = fiberIndirectTechnologies;

    // Master
    public serviceProviders: serviceProviderSimyo[] = [];

    private allSubscription: Subscription[] = [];
    public currentStepper = null;
    public showTypeContent = '';
    public orderType = orderType;

    public typeCard = typeCard;
    initialValue: any;

    public isCustomerNotCif = false;

    constructor(
        private simyoService: SimyoService,
        private movilPackService: SimyoMovilPackService,
        private modalService: NgbModal,
        private digoService: DigoService,
        private rateService: SimyoRateService,
        private orderService: SimyoOrderService,
        public permSv: PermissionService
    ) { }

    ngOnInit(): void {
        this.allSubscription.push(this.simyoService.getTypeOrder()
            .subscribe((data: any) => {
                if (data != null) {
                    this.typeOrder = data;
                    this.stepperIndex = this.index + (this.typeOrder === orderType.CONVERGENT ? 1 : 0);
                    this.isAdditional = this.rateService.isAdditional(this.index, this.customerInfo, this.typeOrder, this.order);
                    if(this.currentStepper == null) {
                        this.currentStepper = this.stepper.selectedIndex;
                    }
                }
            })
        );

        this.allSubscription.push(this.simyoService.getMasterAll()
            .subscribe((data: masterAllSimyo) => {
                if (data) {
                    this.serviceProviders = data.service_providers;
                }
            })
        );

        this.allSubscription.push(this.simyoService.getRateFtth()
            .subscribe((data: any) => {
                this.rateFtth = data;
            })
        );

        this.allSubscription.push(this.stepper.selectionChange
            .subscribe((data) => {
                this.currentStepper = data.selectedIndex;
                this.stepperIndex = this.index + (this.typeOrder === orderType.CONVERGENT ? 1 : 0);
                if (this.stepperIndex === data.previouslySelectedIndex) {
                    if (this.validate()) {
                        this.simyoService.setMobilePacks(this.index, this.formGroup.getRawValue());
                    }
                }
                setTimeout(() => {
                    if(this.currentStepper == this.stepperIndex && this.formGroup.valid) {
                        window.scrollTo({
                            top: 0,
                            left: 0 ,
                            behavior: 'smooth'
                        });
                    }
                }, 0);

                if(this.checkIfFirstPortaChange() == false && this.formGroup.get('type_operation').value == 'portability_change') {
                    this.formGroup.patchValue({
                        type_operation: 'registration'
                    });
                    this.changeTypeOperation('registration');
                }
            })
        );

        this.allSubscription.push(this.movilPackService.getAllowAddAdditionalLines()
            .subscribe((data: any) => {
                if (data != null) {
                    this.allowAddAdditionalLines = data;
                }
            })
        );

        this.allSubscription.push(this.simyoService.getCustomerPromotion()
            .subscribe((data) => {
                this.customerInfo = data;
                this.isAdditional = this.rateService.isAdditional(this.index, this.customerInfo, this.typeOrder, this.order);
            })
        );

        this.allSubscription.push(this.orderService.getOrder()
            .subscribe((order) => {
                this.order = order;
                this.isAdditional = this.rateService.isAdditional(this.index, this.customerInfo, this.typeOrder, this.order);
            })
        );

        if (this.formGroup.get('type_operation').value != null && this.formGroup.get('type_operation').value !== '') {
            this.changeTypeOperation(this.formGroup.get('type_operation').value, true);
        }

        if (this.formGroup.get('portability_type_client').value != null && this.formGroup.get('portability_type_client').value !== '') {
            this.changePortabilityTypeClient(this.formGroup.get('portability_type_client').value);
        }

        if (this.formGroup.get('iccid').value != null && this.formGroup.get('iccid').value !== '') {
            this.isValidIccid('iccid', true);
        }

        this.allSubscription.push(this.formGroup.statusChanges
            .subscribe(form => {
                if ((this.formGroup.get('rate').value !== null && this.formGroup.get('rate').value !== '' &&  this.formGroup.valid)) {
                    let itemsForms = this.simyoService.getMobilePacksObject();
                    if (itemsForms && itemsForms.length) {
                        itemsForms.splice(this.index, 1, this.formGroup.getRawValue());
                    }
                    if (itemsForms && itemsForms.length > 1) {
                        this.evaluateICCID(itemsForms);
                        this.evaluateMSISDN(itemsForms);
                    }
                }

                if(this.formGroup.valid) {
                    // Actualizar tarifas si el formulario es valido para visualizar el carrito
                    this.simyoService.setMobilePacks(this.index, this.formGroup.getRawValue());
                }

            }));

        this.formGroup
            .valueChanges // subscribe to all changes
            .pipe(
                tap(data => {
                    if(this.initialValue == undefined) {
                        this.initialValue = data;
                    }
                    let changes = this.getChanges(data, this.initialValue)
                    if(changes && Object.keys(changes).length > 0) {
                        this.initialValue = data;
                    }
                    if(changes && Object.keys(changes).length == 0) {
                        setTimeout(() => {
                            if(this.currentStepper == this.stepperIndex && this.formGroup.valid) {
                                window.scrollTo({
                                    top: document.body.scrollHeight,
                                    left: 0 ,
                                    behavior: 'smooth'
                                });
                            }
                        }, 500);
                    }


                })
            ).subscribe();

        this.allSubscription.push(this.simyoService.getRatesMobile()
            .subscribe((data: any) => {
                if (data && typeof data[this.index] !== 'undefined') {
                    this.rate = data[this.index];
                    // this.priceBonos = 0;
                    // if (this.rate && this.rate.bonos && this.rate.bonos.length) {
                    //     this.rate.bonos.map(x => this.priceBonos += +x.rate_info.real_price)
                    // }
                    this.formGroup.get('type_pay').patchValue(this.rate.type_pay);
                    this.formGroup.get('rate').patchValue(this.rate);
                    this.isValidTerminal();
                    this.simyoService.setMobilePacks(this.index, this.formGroup.getRawValue());
                } else {
                    this.rate = null;
                }
            })
        );

        this.customerIsNotCif();
    }

    getChanges(currentValues: any, initialValues: any) {
        const changes = {};
        for (const key in currentValues) {
          if (currentValues.hasOwnProperty(key)) {
            if((key in initialValues) == false) {
                changes[key] = {
                    previous: null,
                    current: currentValues[key]
                };
            }
            else if (currentValues[key] !== initialValues[key]) {
              changes[key] = {
                previous: initialValues[key],
                current: currentValues[key]
              };
            }
          }
        }
        return changes;
      }

    changeTypeOperation(value, firstChange = false) {
        this.typeOperation = value;
        this.showErrorTypeOperation = false;

        this.formGroup.patchValue({
            type_operation: value
        })

        if (this.typeOperation === 'portability' || this.typeOperation === 'portability_change') {
            this.formGroup.get('portability_phone').setValidators([Validators.required,Validators.pattern('[0-9 ]*'),Validators.minLength(9),Validators.maxLength(9)]);
            this.formGroup.get('portability_type_client').setValidators([Validators.required]);
            this.formGroup.get('portability_iccid').setValidators([Validators.required, iccidValidator(), blankSpaceValidator()]);
            this.formGroup.get('portability_operator').setValidators([Validators.required, blankSpaceValidator()]);
            this.changePortabilityTypeClient(this.formGroup.get('portability_type_client').value);
        } else {
            this.formGroup.get('portability_phone').patchValue('');
            this.formGroup.get('portability_type_client').patchValue('');
            this.formGroup.get('portability_iccid').patchValue('');
            this.formGroup.get('portability_operator').patchValue('');
            this.formGroup.get('portability_date').patchValue('');

            this.formGroup.get('portability_phone').clearValidators();
            this.formGroup.get('portability_type_client').clearValidators();
            this.formGroup.get('portability_iccid').clearValidators();
            this.formGroup.get('portability_operator').clearValidators();
            this.formGroup.get('portability_type_client').updateValueAndValidity();
        }

        this.formGroup.get('portability_phone').updateValueAndValidity();
        this.formGroup.get('portability_operator').updateValueAndValidity();
        this.formGroup.get('portability_iccid').updateValueAndValidity();

        this.isValidTerminal();

        if(firstChange == false) {
            this.requestResetDocument.emit(true);
        }
    }

    changePortabilityTypeClient(value) {
        this.portabilityTypeClient = value;
        this.formGroup.get('portability_iccid').clearValidators();

        if (value === 'postpaid') {
            this.formGroup.get('portability_iccid').patchValue('');
            this.formGroup.get('portability_iccid').setValidators(iccidValidator());
        } else {
            this.formGroup.get('portability_iccid').setValidators([Validators.required, iccidValidator()]);
        }

        this.formGroup.get('portability_iccid').updateValueAndValidity();
    }

    validate(): boolean {
        this.formGroup.markAllAsTouched();
        this.formGroup.updateValueAndValidity();

        if (this.formGroup.valid && !this.iccidLoading) {
            this.showErrorTypeOperation = false;
            return true;
        } else {
            const groupElements = document.querySelectorAll('.mat-horizontal-stepper-content');
            if(groupElements.length > 0) {
                groupElements.forEach(obj => {
                    if(obj.getAttribute('aria-expanded')) {
                        const elements: any = obj.getElementsByClassName('mat-input-element ng-invalid');
                        if (elements.length > 0) {
                            elements[0].focus();
                        }
                    }
                });

            }
            if (this.formGroup.get('type_operation').value === '') {
                this.showErrorTypeOperation = true;
            }

            return false;
        }
    }

    isValidTerminal() {
        let terminal = this.formGroup.get('terminal').value;
        let operacion = this.formGroup.get('type_operation').value === 'registration' ? false : true;
        let tipoPago = this.formGroup.get('terminal_payment_type').value === 'VAP' ? true : false;

        if (terminal && terminal.terminal_oids && terminal.terminal_oids.length) {
            const found = terminal.terminal_oids.find(x => x.pay_type === this.formGroup.get('type_pay').value && x.portability === operacion && x.is_installment === tipoPago);
            if (!found) {
                this.terminalCmp.terminal = null;
                this.terminalCmp.paymentType = null;
                this.formGroup.get('terminal').patchValue('');
                this.formGroup.get('terminal_payment_type').patchValue('');
                this.simyoService.removeTerminal(this.index);
                this.terminalCmp.changeTerminal = true;
            }
        }
    }

    addMobileLine() {
        if (this.validate()) {
            if (this.anyLinePending()) {
                this.stepper.next();
            } else {
                this.requestAddMobileLine.emit();
                this.simyoService.setMobilePacks(this.index, this.formGroup.getRawValue());
                this.stepper.next();
            }
        }
    }

    anyLinePending(): boolean {
        return (this.stepper.steps.length - this.stepper.selectedIndex) > 3;
    }

    changeRate() {
        this.showTypeContent = orderType.MOBILE;
        this.simyoService.setIsChanging(true);
        this.rate = null;
        this.formGroup.get('rate').patchValue('');
        this.isAdditional = this.rateService.isAdditional(this.index, this.customerInfo, this.typeOrder, this.order);
        if (this.isAdditional) {
            this.rateService.setShowRateOnOffer({index: this.index, status: false});
        }
    }

    evaluateICCID(itemsForms: any[]) {
        const typeForms = itemsForms.filter(form => (form.portability_iccid !== '' && form.portability_type_client === 'prepaid' && (form.type_operation === 'portability' || form.type_operation === 'portability_change')) || (form.iccid !== ''));
        if (typeForms && typeForms.length > 1) {
            let iccids:string[] = [];
            typeForms.map((form) => {
                if (form.iccid !== '') {
                    iccids.push(form.iccid);
                }
                if (form.portability_iccid !== '') {
                    iccids.push(form.portability_iccid);
                }
            });
            let unique = [...new Set(iccids)];
            if (this.formGroup.get('is_esim').value === typeCard.sim && unique.length < iccids.length) {
                // Buscamos cuales de los iccids es el repetido el campo que tenga ese valor es el que falla
                let conflictValue = [...new Set(iccids.filter((value, index, self) => self.indexOf(value) !== index))]
                if (this.formGroup.get('iccid').value !== '' && this.formGroup.get('iccid').value === conflictValue[0]) {
                    this.formGroup.controls['iccid'].setErrors({notmustMatch: true});
                }
                if (this.formGroup.get('portability_iccid').value !== '' && this.formGroup.get('portability_iccid').value === conflictValue[0]) {
                    this.formGroup.controls['portability_iccid'].setErrors({notmustMatch: true});
                }
            } else {
                if (this.formGroup.controls['iccid'].errors) {
                    this.formGroup.controls['iccid'].setErrors(null);
                }
                if (this.formGroup.controls['portability_iccid'].errors) {
                    this.formGroup.controls['portability_iccid'].setErrors(null);
                }
            }
        }
    }

    evaluateMSISDN(itemsForms: any[]) {
        const typeForms = itemsForms.filter(form => form.portability_phone !== '');
        if (typeForms && typeForms.length > 1) {
            let msisdn:string[] = [];
            typeForms.map((form) => {
                if (form.portability_phone !== '') {
                    msisdn.push(form.portability_phone);
                }
            });
            let unique = [...new Set(msisdn)];
            if (unique.length < msisdn.length) {
                // Buscamos cuales de los msisdn es el repetido el campo que tenga ese valor es el que falla
                let conflictValue = [...new Set(msisdn.filter((value, index, self) => self.indexOf(value) !== index))]
                if (this.formGroup.get('portability_phone').value !== '' && this.formGroup.get('portability_phone').value === conflictValue[0]) {
                    this.formGroup.controls['portability_phone'].setErrors({notmustMatch: true});
                }
            } else {
                if (this.formGroup.controls['portability_phone'].errors) {
                    this.formGroup.controls['portability_phone'].setErrors(null);
                }
            }
        }
    }

    next() {
        if (this.validate()) {
            this.simyoService.setMobilePacks(this.index, this.formGroup.getRawValue());
            this.stepper.next();
        }
    }

    prev() {
        this.stepper.previous();
    }

    openModalICCID(content) {
        this.modalService.open(content, {size: 'md', centered: true});
    }

    isValidIccid(formName, clear?) {
        if (this.formGroup.get(formName).valid) {
            this.iccidLoading = formName === 'iccid' ? true : false;
            this.formGroup.get(formName).disable();
            this.simyoService.iccidAvailable(this.formGroup.get(formName).value)
                .subscribe((data: any) => {
                    this.iccidLoading = false;
                    this.formGroup.get(formName).enable();
                    if (data && data.msg !== null) {
                        if (data.msg === stateIccid.used) {
                            this.formGroup.controls[formName].setErrors({ used: true });
                            if (clear) {
                                this.clearIccid(formName);
                            }
                        }
                        if (data.msg === stateIccid.invalid) {
                            this.formGroup.controls[formName].setErrors({ invalid: true });
                            if (clear) {
                                this.clearIccid(formName);
                            }
                        }
                        if (data.msg === stateIccid.valid) {
                            this.formGroup.controls[formName].setErrors(null);
                        }
                    }
                }, () => {
                    this.iccidLoading = false;
                    this.formGroup.get(formName).enable();
                    if (clear) {
                        this.clearIccid(formName);
                    }
                });
        }
    }

    clearIccid(formName) {
        this.formGroup.controls[formName].setErrors(null);
        this.formGroup.get(formName).patchValue('');
    }

    doRemoveAllAdditionalPostpaids(index){
        if(this.typeOrder === orderType.MOBILE || this.typeOrder === orderType.CONVERGENT && index > 0){
            this.requestRemovePostpaidsLines.emit(index);
        }
    }

    ngOnDestroy() {
        this.formGroup.reset();
        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map(subs => subs.unsubscribe());
        }
    }

    public numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;

        return !(charCode > 31 && (charCode < 48 || charCode > 57));
    }


    /* ---- ESIM ----*/
    changeTypeCard (event) {
        let value = event.target.value;
        if (value === 'esim' && this.permSv.hasPermFromV2(allPermissions.simyo.disponible_sell_esim)) {
            this.formGroup.get('iccid').patchValue(null);
            this.formGroup.get('iccid').clearValidators();
        } else {
            this.formGroup.get('iccid').patchValue('');
            this.formGroup.get('iccid').setValidators([Validators.required, iccidValidator(), Validators.minLength(19)]);
        }

        this.formGroup.get('iccid').updateValueAndValidity();
    }

    getPriceBySpeed(speed) {
        return this.rate?.rate_info ? this.rate?.rate_info[`ftth_${speed}_price`] : '0';
    }

    showMessage() {
        if(this.permSv.hasPermFromV2(allPermissions.simyo.disponible_sell_esim) == false) {
            event.preventDefault();
            if(this.esimNotAvailable) {
                this.modalService.open(this.esimNotAvailable, {size: 'md', centered: true, backdrop: 'static', keyboard: false});
            }
        }
    }

    checkIfFirstPortaChange() {
        let indexFound = this.order.mobiles.findIndex(mobile => mobile.change_owner == true);
        if(indexFound > -1 && indexFound != this.index) {
            return false;
        }
        return true;
    }

    customerIsNotCif() {
        this.isCustomerNotCif = this.customerFormGroup.value.customer_doctype != '4' && this.clienteFormGroup.value.account_doctype != '4';
        if(this.formGroup.get('type_operation').value === 'portability_change' && !this.isCustomerNotCif) {
            this.changeTypeOperation('portability')
        }
    }
}
