import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {Observable, Subscription} from 'rxjs';
import {DigoService} from 'src/app/services/digo.service';
import {Ipdv} from 'src/app/shared/models/pdvResponse';
import {MasterAllResponse} from '../../models/masterAllResponse';
import {AppState} from '../../redux';
import {resetState, setConfiFormDataPrepaid, setErrorMaster, setLoadingMaster, setMasterAll, setPrepaidOrderInfo, setTotal, setUserFormDataPrepaid} from '../../redux/orange.actions';
import {IOrangePrepaidState} from '../../redux/orange.state';
import {OrangeService} from '../../services/orange.service';
import {ActivatedRoute, Router} from '@angular/router';
import {OrangeShoppingCartService} from '../../services/orange-shopping-cart.service';
import {MatStepper} from '@angular/material/stepper';
import { orangePrepaidTypes } from 'src/app/shared/constantes';
import {isAdult} from '../../../utils/validators/any-form-valid';
import { HttpErrorResponse } from '@angular/common/http';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { eSimConstants } from '../../models/eSimConstants';
import { blankSpaceValidator } from 'src/app/utils/validators/blank-space-validator';
import { MustMatch } from 'src/app/utils/validators/email-confirm-validator';
import { OrangePrepaidUserDataComponent } from './orange-prepaid-user-data/orange-prepaid-user-data.component';

@Component({
    selector: 'app-orange-prepaid-hiring',
    templateUrl: './orange-prepaid-hiring.component.html',
    styleUrls: ['./orange-prepaid-hiring.component.css', '../../../../assets/css/orange-theme.css']
})
export class OrangePrepaidHiringComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('stepper') stepper: MatStepper;
    @ViewChild('tabUserData') tabUserData: OrangePrepaidUserDataComponent;
    

    public pdv: Ipdv;
    private allSubscription: Subscription[] = [];
    public orange$: Observable<IOrangePrepaidState>;
    public configurationFormGroup: FormGroup;
    public userDataFormGroup: FormGroup;
    public agreementsFormGroup: FormGroup;
    public isEditable: boolean = true;
    public type: string;
    public mobile: boolean = false;
    public previousIndexSelected = 0;

    constructor(
        private digoService: DigoService,
        private formBuilder: FormBuilder,
        private orangeStore: Store<AppState>,
        private orangeMasterService: OrangeService,
        private activatedRoute: ActivatedRoute,
        private shoppingCartService: OrangeShoppingCartService,
        private router: Router,
        private elementRef: ElementRef
    ) {
        this.orange$ = orangeStore.pipe(select('orangePrepaid'));
    }

    ngOnInit(): void {
        this.isAlive();
        
        switch (this.router.url) {
            case '/orange/prepago/alta':
                this.type = orangePrepaidTypes.alta;
                break;
            case '/orange/prepago/portabilidad':
                this.type = orangePrepaidTypes.portabilidad;
                break;
            case '/orange/prepago/esim':
                this.type = orangePrepaidTypes.esim;
                break;
            default:
                break;
        }

        this.configurationFormGroup = this.formBuilder.group({
            rate: [null, Validators.required],
            welcome_pack: [null, Validators.required],
            msisdns: new FormArray([]),
            balance: [0],
            terminal_imei: [null],
            portability_operator: [null],
            portability_type_client: [null],
            portability_old_iccid: [null],
            portability_new_iccid: [null],
            type_operation: [''],
            esim_number_lines: [null]
        });

        let emailConfirmValidators = [];
        let formValidators = [];
        if(this.type === orangePrepaidTypes.esim) {
            emailConfirmValidators = [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9._-]+\\.[a-zA-Z]{2,4}$')];
            formValidators = [MustMatch('email', 'email_confirm')]
        }
        this.userDataFormGroup = this.formBuilder.group({
            // Personal info
            document_type: ['', Validators.required],
            document_number: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9]+$')]],
            nacionality: ['', Validators.required],
            birth_date: ['', [Validators.required, isAdult()]],
            name: ['', [Validators.required, blankSpaceValidator()]],
            first_surname: ['', [Validators.required, blankSpaceValidator()]],
            second_surname: [''],
            sex: ['', Validators.required],
            // Contact info
            postal_code: ['', Validators.required],
            province: [{value: '', disabled: true}, [Validators.required]],
            email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9._-]+\\.[a-zA-Z]{2,4}$')]],
            email_confirm: [{value:'', disabled:true}, emailConfirmValidators]
        }, {
            validators: formValidators
        });

        this.agreementsFormGroup = this.formBuilder.group({
            check1: [false],
            check2: [false],
            check3: [false],
            check4: [false],
            check5: [false],
        });

        this.configurationFormGroup.valueChanges
            .subscribe((configurationFormValue) => {
                if (configurationFormValue.rate != null) {
                    const isEsimAndRegistration = configurationFormValue.welcome_pack.is_esim && configurationFormValue.type_operation === eSimConstants.registrationTypeOperation;
                    const lines = isEsimAndRegistration ? configurationFormValue.esim_number_lines : configurationFormValue.msisdns.filter(item => item !== '').length;
                    const ratePrice = +configurationFormValue.rate.price;                    
                    this.orangeStore.dispatch(setTotal({total: ratePrice * lines}));
                }

                this.orangeStore.dispatch(setConfiFormDataPrepaid({form: configurationFormValue}));
            });

        this.userDataFormGroup.valueChanges
            .subscribe((userDataFormValue) => {
                this.orangeStore.dispatch(setUserFormDataPrepaid({form: userDataFormValue}));
            });

        this.allSubscription.push(this.digoService.getPDV()
            .subscribe((data: Ipdv) => {
                if (data) {
                    this.pdv = data;
                }
            })
        );

        this.orangeStore.dispatch(setLoadingMaster({loading: true}));
        this.orangeMasterService.getMasterAllFromService(this.type !== orangePrepaidTypes.esim)
            .subscribe((data: MasterAllResponse) => {
                if (data?.msg) {
                    const response = data.msg;

                    response.rates = response.rates.filter((rate) => {
                        switch (this.type) {
                            case orangePrepaidTypes.esim:
                                return rate.is_esim;
                            case orangePrepaidTypes.portabilidad:
                                return rate.is_portability;
                            case 'terminal':
                                return rate.is_terminal;
                            case orangePrepaidTypes.alta:
                                return !rate.is_esim && !rate.is_portability && !rate.is_terminal;
                            default:
                                return !rate.is_esim && !rate.is_portability && !rate.is_terminal;
                        }
                    });

                    this.orangeStore.dispatch(setMasterAll({master: response}));
                    this.orangeStore.dispatch(setErrorMaster({error: false}));

                    var ua = navigator.userAgent; //Si está en un dispositivo movil de iOs
                    if(/webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)){
                        this.mobile = true;
                        this.userDataFormGroup.get('document_type').patchValue(data.msg.document_types.find(document => document.document_type_id === 2));
                        this.userDataFormGroup.get('sex').patchValue(data.msg.genders.find(gender => gender.gender_id === 1));
                    }

                    this.userDataFormGroup.get('nacionality').patchValue(data.msg.countries.find(country => country.country_id === 64));
                }
            }, (error) => {
                this.orangeStore.dispatch(setErrorMaster({error: true}));
                this.orangeStore.dispatch(setLoadingMaster({loading: false}));
            }, () => {
                this.orangeStore.dispatch(setLoadingMaster({loading: false}));
            });
    }

    private isAlive(): void {
        this.digoService.keepAlive()
            .subscribe({
                next: (resp: any) => {
                    if (!resp || resp !== 200) {
                        this.digoService.goLogin();
                    }
                },
                error: (error: HttpErrorResponse) => {
                    this.digoService.goLogin();
                }
            });
    }

    public onStepChange(stepChange: StepperSelectionEvent) {
        //this.isAlive();
        if ( stepChange.selectedStep.label === 'Cliente') {
            this.goTop(true);
        }
        if (stepChange.previouslySelectedIndex >= stepChange.selectedIndex) {
            stepChange.previouslySelectedStep.interacted = false;
        }
    }

    public stateEditable(event) {
        this.isEditable = event;
    }

    public goTop(go: boolean){
        if (go && this.mobile) {
            window.scroll({
                top: 0,
                left: 0
            });
        }
    }

    ngAfterViewInit() {
        this.shoppingCartService.setStepper(this.stepper);
        this.elementRef.nativeElement.querySelectorAll('mat-step-header').forEach(item => {
            item.addEventListener('click', event => {
                let currentIndex = event.currentTarget.ariaPosInSet - 1;
                if(currentIndex > this.previousIndexSelected) {
                    this.stepper.selectedIndex = this.previousIndexSelected; 
                }

                if(currentIndex === 1) {
                    this.validatePrepaidConfigurationData();
                }

                if(currentIndex === 2) {
                    this.validateUserData();
                }

             });
          });
    }

    ngOnDestroy() {
        this.orangeStore.dispatch(resetState());
        this.orangeStore.dispatch(setTotal({total: 0}));
        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map(subs => subs.unsubscribe());
        }
    }

    validateUserData() {
        if(this.userDataFormGroup.valid) {
            this.tabUserData.next();
        }        
    }

    validatePrepaidConfigurationData() {
        if(this.configurationFormGroup.valid) {
            this.previousIndexSelected = 1;
            this.stepper.selectedIndex = this.previousIndexSelected;            
        }
    }
}
