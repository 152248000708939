import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-simyo-my-address',
  templateUrl: './simyo-my-address.component.html',
  styleUrls: ['./simyo-my-address.component.css']
})
export class SimyoMyAddressComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  addressSelected(event) {
    console.log('addressSelected', event);
  }

  addressCreated(event) {
    console.log('addressCreated', event);
  }

  sendInitialized(event) {
    console.log('sendInitialized', event);
  }

}
