<div class="modal-header">
    <h5 class="modal-title font-weight-bold ml-3 mt-3" id="exampleModalLabel">
        Soporte
    </h5>

    <button type="button" class="close close-m2" mat-dialog-close="">
        <img src="./assets/img/x-black.svg" width="18" class="close-aviso--1">
    </button>
</div>

<div class="modal-body">
    <div class="container">
        <div class="row mb-2" *ngIf="pdv?.info?.tipo_vista !== PDV_MULTIMARCA">
            <div class="col-12 card-notify--min-width">
                <div class="card--notify mb-2">
                    <div class="card-body--notify">
                        <div class="row my-4">
                            <div class="col-6 col-sm d-flex align-items-center">
                                <h5 class="font-weight-bold mb-0 align-left">
                                    <span class="soporte--title">Números de atención al punto de venta</span>
                                </h5>
                            </div>

                            <div class="col-6 col-sm d-flex align-items-center">
                                <h5 class="font-weight-bold mb-0">
                                    2267 / 665652797 / 900808184
                                </h5>
                            </div>
                        </div>
                    </div>

                    <!-- Simyo -->
                    <div class="card-brand mb-2" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_vender)">
                        <div class="card-body--notify">
                            <div class="row mb-0">
                                <div class="col-12 col-sm d-flex align-items-center">
                                    <img src="./assets/img/sm-login-color.svg" class="img-fluid marca--rm soporte--logo"
                                         width="120">
                                </div>
                                <div class="col-12 col-sm-6 d-flex align-items-center">
                                    <!--contacto-->
                                    <p class="font-weight-bold soporte--n">
                                        <i-feather name="phone-call" class="icon-contact"></i-feather> <span>Marque 1</span><br>
                                        <i-feather name="mail" class="icon-contact"></i-feather> <span>soportepdv@soydesimyo.es</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Jazztel -->
                    <div class="card-brand mb-2" *ngIf="permSv.hasPermFromV2(allPermissions.jazztel.permite_ventas)">
                        <div class="card-body--notify">
                            <div class="row mb-0">
                                <div class="col-12 col-sm d-flex align-items-center">
                                    <img src="./assets/img/jz-login-color.svg" class="img-fluid marca--rm soporte--logo"
                                         width="120">
                                </div>
                                <div class="col-12 col-sm-6 d-flex align-items-center">
                                    <!--contacto-->
                                    <p class="font-weight-bold soporte--n">
                                        <i-feather name="phone-call" class="icon-contact"></i-feather> <span>Marque 2</span><br>
                                        <i-feather name="mail" class="icon-contact"></i-feather> <span>jazztel.distribucion@jazztel.com</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-brand mb-2" *ngIf="permSv.hasPermFromV2(allPermissions.jazztel.permite_ventas) || permSv.hasPermFromV2(allPermissions.simyo.permite_vender)">
                        <div class="card-body--notify">
                            <div class="row mb-0">
                                <div class="col-6 col-sm">
                                    <h5 class="font-weight-bold mb-0 align-left">
                                        <span class="soporte--title">Citación de instalación de fibra</span>
                                    </h5>
                                    <div class="d-flex img-align-left">
                                        <img src="./assets/img/jz-login-color.svg" class="img-fluid"
                                         width="80" *ngIf="permSv.hasPermFromV2(allPermissions.jazztel.permite_ventas)">
                                         <img src="./assets/img/sm-login-color.svg" class="img-fluid img-brand"
                                         width="80" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_vender)">
                                    </div>
                                    <!--<p class="font-weight-bold mb-0 align-left">
                                        <span class="soporte--title">Citación de instalación de fibra</span>
                                    </p>-->
                                </div>

                                <div class="col-6 col-sm d-flex align-items-center">
                                    <p class="font-weight-bold soporte--n">
                                        <i-feather name="phone-call" class="icon-contact"></i-feather> <span>Marque 3</span>
                                        </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Orange -->
                    <div class="card-brand mb-2" *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_ventas_prepago) || permSv.hasPermFromV2(allPermissions.orange.permite_ventas_pospago)">
                        <div class="card-body--notify">
                            <div class="row mb-0">
                                <div class="col-12 col-sm d-flex align-items-center">
                                    <img src="./assets/img/or-login-color.svg" class="img-fluid marca--rm soporte--logo"
                                         width="120">
                                </div>
                                <div class="col-12 col-sm-6 d-flex align-items-center">
                                    <!--contacto-->
                                    <p class="font-weight-bold soporte--n">
                                        <i-feather name="phone-call" class="icon-contact"></i-feather> <span>Marque 4</span>
                                        <!--{{ orangeNumber }} <br
                                        *ngIf="gpvName && gpvName !== '' && gpvName.length > 7">{{ gpvName && gpvName !== '' ? '(' + gpvName + ')' : '(GPV)' }}
                                        <br>-->
                                        <!--<a href=""><img src="./assets/img/mail.svg" width="18" class="mail-m"></a>-->
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- RM -->
            <!--<div class="col card-notify&#45;&#45;min-width" *ngIf="permSv.hasPermFromV2(allPermissions.rep_movil.permite_ventas)">
                <div class="card&#45;&#45;notify mb-2">
                    <div class="card-body&#45;&#45;notify">
                        <div class="row mb-0">
                            <div class="col-12 col-sm d-flex align-items-center">
                                <img src="./assets/img/rm-login-color.svg" class="img-fluid marca&#45;&#45;rm  soporte&#45;&#45;logo"
                                     width="120">
                            </div>
                            <div class="col-12 col-sm-auto d-flex align-items-center">
                                &lt;!&ndash;contacto&ndash;&gt;
                                <p class="font-weight-bold soporte&#45;&#45;n" align="right">
                                    900 900 537<br>
                                    &lt;!&ndash;<a href=""><img src="./assets/img/mail.svg" width="18" class="mail-m"></a>&ndash;&gt;
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>-->

            <!-- GPV -->
            <div class="col card-notify--min-width" *ngIf="pdv.gpv">
                <div class="card--notify mb-2">
                    <div class="card-body--notify">
                        <div class="row mb-0">
                            <div class="col-12 col-sm d-flex align-items-center">
                                <h5 class="font-weight-bold mb-0 align-left">Tu GPV:</h5>
                            </div>
                            <div class="col-12 col-sm-6 d-flex align-items-center">
                                <!--contacto-->
                                <p class="font-weight-bold soporte--n">
                                    <i-feather name="phone-call" class="icon-contact"></i-feather> <span>{{ pdv.gpv.phone }}</span><br>
                                    <i-feather name="mail" class="icon-contact"></i-feather> <span>{{ pdv.gpv.email }}</span>
                                    <!--{{ orangeNumber }} <br
                                    *ngIf="gpvName && gpvName !== '' && gpvName.length > 7">{{ gpvName && gpvName !== '' ? '(' + gpvName + ')' : '(GPV)' }}
                                    <br>-->
                                    <!--<a href=""><img src="./assets/img/mail.svg" width="18" class="mail-m"></a>-->
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mb-2" *ngIf="pdv?.info?.tipo_vista === PDV_MULTIMARCA">
            <!-- Canal multimarca -->
            <div class="col card-notify--min-width">
                <div class="card--notify mb-2">
                    <div class="card-body--notify">
                        <div class="col-12 mb-0">
                            <div class="col-12 col-sm d-flex align-items-center"
                                 style="min-height: 40px; font-size: 1.5rem;">
                                Canal multimarca
                            </div>
                            <div class="col-12 col-sm-auto d-flex align-items-center">
                                <!--contacto-->
                                <p class="font-weight-bold soporte--n">
                                    Atención al PdV: 651 651 651<br>
                                    Atención al cliente: 2239<br>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Simyo -->
            <div class="col card-notify--min-width" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_vender)">
                <div class="card--notify mb-2">
                    <div class="card-body--notify">
                        <div class="col-12 mb-0">
                            <img src="./assets/img/sm-login-color.svg" class="img-fluid" width="120">
                            <div class="col-12 col-sm-auto d-flex align-items-center">
                                <!--contacto-->
                                <p class="font-weight-bold soporte--n">
                                    Atención al PdV: <span align="right">918 380 513</span><br>
                                    Atención al cliente: 121<br>
                                    <!--<a href=""><img src="./assets/img/mail.svg" width="18" class="mail-m"></a>-->
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
