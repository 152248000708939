import { Component, OnInit, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { SimyoRateService } from '../../services/simyo-rate.service';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { mobileContractType, orderType, rateAvailableIn } from 'src/app/shared/constantes';
import { SimyoService } from '../../services/simyo.service';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SimyoGlobals } from '../../classes/simyo-globals';
import { Subscription } from 'rxjs';
import { Ipdv } from 'src/app/shared/models/pdvResponse';
import { DigoService } from 'src/app/services/digo.service';
import { blankSpaceValidator } from 'src/app/utils/validators/blank-space-validator';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { allPermissions } from 'src/app/shared/permissions';

@Component({
    selector: 'app-simyo-rates-list',
    templateUrl: './simyo-rates-list.component.html',
    styleUrls: ['./simyo-rates-list.component.css', '../../../../assets/css/simyo-theme.css']
})
export class SimyoRatesListComponent implements OnInit {

    @ViewChild('content') content: NgbModalRef;

    public loaded: boolean = false;
    public allRates: any;
    //public bonosRates: any[] = [];
    public readyRates: any[] = [];
    public ratesNotAvailableMobile: any[] = [];
    public ratesNotAvailableFibra: any[] = [];
    public msgToShow: string = '';
    public allFibraRates: any[] = [];
    public fibraRates: any[] = [];
    public movilRates: any[] = [];
    public diffVoice: any[] = [];
    public diffData: any[] = [];
    public ratesListFormGroup: FormGroup;
    public taxesFormGroup: FormGroup;
    public currentVoiceIndex = -1;
    public rechargePrice: any;
    public showPostpaid: boolean = false;
    public showPrepaid: boolean = false;
    public showErrorMovilType: boolean = false;
    public customRate: any = {
        price: 0,
        textFibra: '',
        textType: '',
        textRate: '',
        buttonTitle: '',
        typeOrder: '',
        rates: {
            fibra: null,
            movil: null,
            //bonos: []
        }
    }
    taxes = [{name: 'IVA', value: 'iva'}, {name: 'IGIC', value: 'igic'}, {name: 'IPSI 8%', value: 'ipsi_8'}, {name: 'IPSI 10%', value: 'ipsi_10'}];
    selectedTax: string = 'iva';

    constructor(
        private simyoService: SimyoService,
        private rateService: SimyoRateService,
        private formBuilder: FormBuilder,
        private router: Router,
        private modalService: NgbModal,
        private digoService: DigoService,
        public simyoGlobals: SimyoGlobals,
        public permSv: PermissionService
    ) {
        this.ratesListFormGroup = this.formBuilder.group({
            isCustomer: ['0'],
            fibraRate: ['', Validators.required],
            movilType: ['postpaid'],
            dataRate: ['', Validators.required],
            voiceRate: ['', Validators.required],
            //bonosRate: new FormArray([])
        });
        this.taxesFormGroup = this.formBuilder.group({
            tax: ['iva', Validators.required]
        });
    }

    ngOnInit(): void {
        this.rateService.getRatesFromService(this.zipCodeSelectedTax())
            .subscribe((data: any) => {
                if (data) {
                    this.allRates = data.msg;
                    this.getDiffRates(this.allRates, rateAvailableIn.broadband_300);
                    this.getDiffMovilType(this.allRates, rateAvailableIn.broadband_300);
                    this.showCorrectFibraRates();
                    this.initForm();
                    this.loaded = true;
                }
            });



        this.ratesListFormGroup.valueChanges
            .subscribe((data: any) => {
                if (data) {
                    this.changeMovilType(data.movilType);
                    this.showErrorMovilType = false;
                    this.evaluateCustomRate(data);
                    this.evaluateActiveOptions(data);
                    this.showCorrectFibraRates();
                }
            });
    }

    getDiffMovilType(rates, type) {
        if (rates.RATES && rates.RATES.MOBILE) {
            const thereIsPostpaid = rates.RATES.MOBILE.filter(rate => rate.rate_info.available_in.includes(type)).map(rate => rate && rate['rate_ids']).filter(rate_ids => rate_ids.postpaid !== '');
            const thereIsPrepaid = rates.RATES.MOBILE.filter(rate => rate.rate_info.available_in.includes(type)).map(rate => rate && rate['rate_ids']).filter(rate_ids => rate_ids.prepaid !== '');
            if (thereIsPostpaid.length){
                this.movilRates = rates.RATES.MOBILE.filter(rate => rate.rate_info.available_in.includes(type) && rate.rate_ids.postpaid !== '')
            }
            if (thereIsPrepaid.length) {
                this.movilRates = rates.RATES.MOBILE.filter(rate => rate.rate_info.available_in.includes(type) && rate.rate_ids.prepaid !== '')
            }
            this.showPostpaid = thereIsPostpaid && thereIsPostpaid.length ? true : false;
            this.showPrepaid = thereIsPrepaid && thereIsPrepaid.length ? true : false;
            this.initMovilType();
        }
    }

    changeMovilType(type){
        if (type == 'postpaid'){
            this.movilRates = this.allRates.RATES.MOBILE.filter(rate => rate.rate_ids.postpaid !== '')
        }
        if (type == 'prepaid') {
            this.movilRates = this.allRates.RATES.MOBILE.filter(rate => rate.rate_ids.prepaid !== '')
        }
    }

    getDiffRates(rates, type) {
        // FIBRA
        if (rates.RATES && rates.RATES.FTTH_NEW) {
            this.allFibraRates = rates.RATES.FTTH_NEW;
        }

        // MOVILES
        if (rates.RATES && rates.RATES.MOBILE) {
            const auxdiffData = [...new Set(rates.RATES.MOBILE
                .filter(rate =>
                    rate.rate_info.available_in.includes(type) &&
                    rate.rate_ids[this.ratesListFormGroup.get('movilType').value] !== ''
                )
                .map(rate => rate && rate['data'])
                .filter(rate => rate))];

            this.diffData = auxdiffData
                .map( item => {
                    return {
                        label: item,
                        disabled: false
                    }
                });
            const auxDiffVoice = [...new Set(rates.RATES.MOBILE
                .filter(rate =>
                    rate.rate_info.available_in.includes(type) &&
                    rate.rate_ids[this.ratesListFormGroup.get('movilType').value] !== ''
                )
                .map(rate => rate && rate['voice'])
                .filter(rate => rate))];

            // Ponemos el ultimo elemento del array al principio
            // auxDiffVoice.splice(0, 0, auxDiffVoice.splice(auxDiffVoice.length - 1, 1)[0]);
            this.diffVoice = auxDiffVoice
                .map((item: any) => {
                    return {
                        label: item,
                        disabled: false
                    }
                });

            this.movilRates = rates.RATES.MOBILE;
        }

        // BONOS
        // if (rates.BONOS && rates.BONOS.length) {
        //     this.bonosRates = rates.BONOS;
        //     this.bonosRates.map(x => (this.ratesListFormGroup.get('bonosRate') as FormArray).push(new FormControl(false)));
        // }

        // READY TO GO
        if (rates.READY_TO_GO && rates.READY_TO_GO.length) {
            this.readyRates = rates.READY_TO_GO.sort((a, b) => {
                if(+a.rate_info.real_price < +b.rate_info.real_price){
                    return -1;
                }else if(+a.rate_info.real_price > +b.rate_info.real_price){
                    return 1;
                }else{
                    return 0;
                }
            });
        }
    }

    showCorrectFibraRates() {
        // Mostar tarifas para clientes
        // if (this.ratesListFormGroup.get('isCustomer').value === '1' || (this.ratesListFormGroup.get('movilType').value !== '0' && this.ratesListFormGroup.get('fibraRate').value !== '0')) {
        //     this.fibraRates = this.allFibraRates.filter(x => {
        //         return x.rate_info.already_client === true;
        //     });
        // } else { // Mostar tarifas para no clientes
        //     this.fibraRates = this.allFibraRates.filter(x => {
        //         return x.rate_info.already_client === false;
        //     });
        // }
        this.fibraRates = this.allFibraRates;

        // Esto ya no debería pasar
        const fibraId = this.ratesListFormGroup.get('fibraRate').value;
        if (fibraId && fibraId !== '') {
            const found = this.fibraRates.find(rate => rate.rate_id === +fibraId);
            if (!found) {
                // Ha pasado de cliente a no cliente o al reves
                // Seleccionamos la tarifa de la misma velocidad
                const oldRate = this.allFibraRates.find(rate => rate.rate_id === +fibraId);
                if (oldRate) {
                    let speed = oldRate.rate_info.speed;
                    const newRateId = this.fibraRates.find(rate => rate.rate_info.speed === speed);
                    if (newRateId) {
                        this.ratesListFormGroup.get('fibraRate').patchValue(newRateId.rate_id.toString());
                        this.evaluateCustomRate(this.ratesListFormGroup.getRawValue());
                    }
                }
            }
        }
    }

    initForm() {
        this.initFibraRate();
        this.initMobileRate();
        this.evaluateCustomRate(this.ratesListFormGroup.getRawValue());
        this.evaluateActiveOptions(this.ratesListFormGroup.getRawValue());
    }

    initMobileRate() {
        // Tarifa por defecto
        if (this.movilRates && this.movilRates.length) {
            // Buscamos una por defecto
            let type = rateAvailableIn.mobile;
            if (this.ratesListFormGroup.get('fibraRate').value !== '0') {
                type = this.simyoGlobals.getAvailableIn(this.customRate.rates.fibra.rate_info.speed);
            } else {
                type = rateAvailableIn.mobile;
            }
            const compatibleRateDefault = this.movilRates
                .filter(rate =>
                    rate.rate_info.available_in.includes(type) &&
                    rate.rate_ids[this.ratesListFormGroup.get('movilType').value] !== ''
                );

            const rateDefault = compatibleRateDefault.find(x => x.default_selected);

            if (rateDefault) {
                this.setMobileRate(rateDefault);
            } else {
                // Seleccionamos una de las listas para llevar
                if (compatibleRateDefault && compatibleRateDefault.length) {
                    this.setMobileRate(compatibleRateDefault[compatibleRateDefault.length - 1]);
                }
            }
        }
    }

    initFibraRate() {
        if (this.fibraRates && this.fibraRates.length) {
            const rateDefault = this.fibraRates.find(x => x.default_selected);
            if (rateDefault) {
                this.ratesListFormGroup.get('fibraRate').patchValue(rateDefault.rate_id.toString());
            } else {
                this.ratesListFormGroup.get('fibraRate').patchValue(this.fibraRates[0].rate_id.toString());
            }
        }
    }

    initMovilType() {
        if (this.ratesListFormGroup.get('movilType').value !== '0') {
            if (!this.showPostpaid && this.showPrepaid) {
                this.ratesListFormGroup.get('movilType').patchValue('prepaid');
            }

            if (this.showPostpaid && !this.showPrepaid) {
                this.ratesListFormGroup.get('movilType').patchValue('postpaid');
            }

            if (this.showPostpaid && this.showPrepaid) {
                this.ratesListFormGroup.get('movilType').patchValue('postpaid');
            }

            if (!this.showPostpaid && !this.showPrepaid) {
                this.ratesListFormGroup.get('movilType').patchValue('0');
            }
        }
    }

    // Funcion que se llama al cambiar los minutos de las llamadas
    changeVoice(newData) {
        if (newData && newData !== '') {
            if (!this.diffVoice[newData].disabled) {
                this.currentVoiceIndex = newData;
                this.evaluateActiveOptions(this.ratesListFormGroup.getRawValue());
                this.setNewRate(this.diffData, this.ratesListFormGroup.get('dataRate').value, 'dataRate');
            } else {
                this.ratesListFormGroup.get('voiceRate').patchValue(this.currentVoiceIndex);
            }
        }
    }

    // Funcion que se llama al cambiar los datos del movil
    changeData(newData) {
        if (newData && newData !== '') {
            this.evaluateActiveOptions(this.ratesListFormGroup.getRawValue());
            this.setNewRate(this.diffVoice, this.ratesListFormGroup.get('voiceRate').value, 'voiceRate');
        }
    }

    setNewRate(data, actualValue, formName) {
        if (data[+actualValue].disabled) {
            const found = data.findIndex(item => !item.disabled);
            if (found !== -1) {
                this.ratesListFormGroup.get(formName).patchValue(found);
            }
        }
    }

    // Funcion que se llama para gestionar la informacion que se le pasa de la tarifa personalizada
    evaluateCustomRate(data) {
        this.customRate.price = 0;
        this.customRate.textRate = '';
        this.customRate.textFibra = '';
        this.customRate.textType = '';
        this.customRate.typeOrder = '';
        this.customRate.buttonTitle = 'Configure su tarifa';
        let auxText = [];
        // Fibra
        if (data.fibraRate && data.fibraRate !== '') {
            const found = this.fibraRates.find(rate => rate.rate_id === +data.fibraRate);
            if (found) {
                this.customRate.price += +found.type_normal.price.replace(/,/g, '.');
                this.customRate.textFibra = found.rate_info.speed + (found.rate_info.speed === '1' ? ' Gb' : ' Mb');
                this.customRate.rates.fibra = found;
            } else {
                this.customRate.rates.fibra = null;
            }
        }
        // Tarifa movil
        // NO quitar !== null que hace validaciones con el 0

        if (data.dataRate !== null && data.dataRate !== '' && data.voiceRate !== null && data.voiceRate !== '' && data.movilType !== '0' && this.diffVoice[+data.voiceRate] && this.diffData[+data.dataRate]) {
            const found = this.movilRates.filter(rate => rate.voice === this.diffVoice[+data.voiceRate].label && rate.data === this.diffData[+data.dataRate].label);
            if (found && found.length) {
                let auxFound: any;
                if (found.length > 1) {
                    if (this.customRate.rates.fibra) {
                        auxFound = found.find(rate => rate.rate_info.available_in.includes(this.simyoGlobals.getAvailableIn(this.customRate.rates.fibra.rate_info.speed)));
                    } else {
                        auxFound = found.find(rate => rate.rate_info.available_in.includes(rateAvailableIn.mobile));
                    }
                } else {
                    auxFound = found[0];
                }

                let priceconvergente = '';
                if (this.customRate.rates.fibra) {
                    priceconvergente = this.simyoGlobals.getFtthPrice(this.customRate.rates.fibra.rate_info.speed);
                    if (auxFound.rate_info[priceconvergente] && auxFound.rate_info[priceconvergente] !== '') {
                        this.customRate.price = 0;
                        this.customRate.price +=+auxFound.rate_info[priceconvergente];
                    }
                } else {
                    this.customRate.price += +auxFound.rate_info.real_price.replace(/,/g, '.');
                }

                // this.customRate.price += +auxFound.rate_info.price.replace(/,/g, '.');
                auxText.push(auxFound.data, auxFound.voice);
                this.customRate.rates.movil = auxFound;
            }

        }
        // Bonos
        // if (this.customRate.rates.bonos && this.customRate.rates.bonos.length && data.movilType !== '0') {
        //     this.customRate.rates.bonos.forEach(bono => {
        //         auxText.push(bono.rate_info.description);
        //         this.customRate.price += +bono.rate_info.price;
        //     });
        // }

        // Tipo de línea móvil
        if (data.movilType && data.movilType !== '' && data.movilType !== '0') {
            this.customRate.textType = data.movilType === 'prepaid' ? 'Prepago' : 'Pospago';
        }

        if (auxText && auxText.length) {
            this.customRate.textRate = auxText.join(' + ');
        }

        // Tipo de flujo
        // Solo fibra
        if (data.movilType === '0' && data.fibraRate !== '0') {
            this.customRate.buttonTitle = 'Continuar solo fibra';
            this.customRate.typeOrder = orderType.BROADBAND;
        }
        // Solo movil
        if (data.movilType !== '0' && data.fibraRate === '0') {
            this.customRate.buttonTitle = 'Continuar móvil';
            this.customRate.typeOrder = orderType.MOBILE;
        }
        // Convergente
        if (data.movilType !== '0' && data.fibraRate !== '0') {
            this.customRate.buttonTitle = 'Continuar fibra y móvil';
            this.customRate.typeOrder = orderType.CONVERGENT;
        }
    }

    evaluateActiveOptions(data) {
        let type = rateAvailableIn.mobile;

        if (this.customRate.rates.fibra) {
            type = this.simyoGlobals.getAvailableIn(this.customRate.rates.fibra.rate_info.speed);
        }

        if (data.dataRate !== null && data.dataRate !== '' && this.diffData[+data.dataRate]) {
            // Obtenemos la tarifa de voz disponible para ese data
            const aux = this.movilRates
                .filter(rate =>
                    rate.rate_info.available_in.includes(type) &&
                    rate.rate_ids[this.ratesListFormGroup.get('movilType').value] !== '' &&
                    rate.data === this.diffData[+data.dataRate].label
                );

            if (aux && aux.length === 0) {
                this.isAvailableRate();
                return;
            }

            // Mostramos como deshabilitado si no lo encontramos
            this.diffVoice = this.movilRates
                .filter(rate =>
                    rate.rate_info.available_in.includes(type) &&
                    rate.rate_ids[this.ratesListFormGroup.get('movilType').value] !== ''
                )
                .map(item => {
                    return {
                        label: item.voice,
                        disabled: aux.find(rate => rate.voice === item.voice) ? false : true
                    };
                })
                .reduce(this.filterByValue, [])
                .sort(this.compare);

            if (this.currentVoiceIndex === -1 || !this.diffVoice[this.currentVoiceIndex] || this.diffVoice[this.currentVoiceIndex].disabled) {
                this.currentVoiceIndex = this.diffVoice.findIndex(x => x.disabled === false);
                this.ratesListFormGroup.get('voiceRate').patchValue(this.currentVoiceIndex);
            }
        }

        if (data.voiceRate !== null && data.voiceRate !== '') {
            // Obtenemos la tarifa de voz disponible para ese data
            // Mostramos como deshabilitado si no lo encontramos
            this.diffData = this.movilRates
                .filter(rate =>
                    rate.rate_info.available_in.includes(type) &&
                    rate.rate_ids[this.ratesListFormGroup.get('movilType').value] !== ''
                )
                .map(item => {
                    return {
                        label: item.data,
                        disabled: false
                    };
                })
                .reduce(this.filterByValue, [])
                .sort(this.compare);
        }
    }

    setMobileRate(rateMobile) {
        this.currentVoiceIndex = this.diffVoice.findIndex(x => x.label === rateMobile.voice);
        this.ratesListFormGroup.get('dataRate').patchValue(this.diffData.findIndex(x => x.label === rateMobile.data));
        this.ratesListFormGroup.get('voiceRate').patchValue(this.diffVoice.findIndex(x => x.label === rateMobile.voice));
    }

    // addBonoToCustomRate(rate, state) {
    //     if (state) {
    //         // Añadimos el bono si no está añadido
    //         const found = this.customRate.rates.bonos.find(x => rate.name === x.name);
    //         if (!found) {
    //             this.customRate.rates.bonos.push(rate);
    //         }
    //     } else {
    //         // Eliminamos ese bono de la tarifa
    //         if (this.customRate.rates.bonos && this.customRate.rates.bonos.length) {
    //             const found = this.customRate.rates.bonos.findIndex(x => x.rate_ids.postpaid === rate.rate_ids.postpaid);
    //             if (found !== -1) {
    //                 this.customRate.rates.bonos.splice(found, 1);
    //             }
    //         }
    //     }
    //     if (this.customRate.rates.bonos && this.customRate.rates.bonos.length) {
    //         this.customRate.rates.bonos.map(bono => {
    //             this.customRate.textRate += ' + ' + bono.rate_info.description;
    //             this.customRate.price += +bono.rate_info.price;
    //         });
    //     }
    //     this.evaluateCustomRate(this.ratesListFormGroup.getRawValue());
    // }

    selectRate(rate, isReadyToBuy) {
        if (isReadyToBuy) {
            if (this.ratesListFormGroup.get('movilType').value === '0') {
                this.showErrorMovilType = true;
                return;
            }
            rate.type_pay = this.ratesListFormGroup.get('movilType').value;
            //this.simyoService.setRatesMobile(0, {...rate, bonos: []});
            this.simyoService.setRatesMobile(0, {...rate});
            this.router.navigate(['simyo/solo-movil']);
        } else {
            if (this.customRate.typeOrder === orderType.BROADBAND) {
                rate.rates.fibra.rate_id = rate.rates.fibra.type_normal.rate_id;
                rate.rates.fibra.rate_info.price = rate.rates.fibra.type_normal.price;
                rate.rates.fibra.rate_info.real_price = rate.rates.fibra.type_normal.price;

                this.simyoService.setRateFtth(rate.rates.fibra);
                this.router.navigate(['simyo/solo-fibra']);
            }

            if (this.customRate.typeOrder === orderType.CONVERGENT) {
                if (this.ratesListFormGroup.get('movilType').value === 'postpaid') {
                    rate.rates.fibra.rate_id = rate.rates.fibra.type_discount.rate_id;
                    rate.rates.fibra.rate_info.price = rate.rates.fibra.type_discount.price;
                    rate.rates.fibra.rate_info.real_price = rate.rates.fibra.type_discount.price;

                }else{
                    rate.rates.fibra.rate_id = rate.rates.fibra.type_normal.rate_id;
                    rate.rates.fibra.rate_info.price = rate.rates.fibra.type_normal.price;
                    rate.rates.fibra.rate_info.real_price = rate.rates.fibra.type_normal.price;

                }
                this.simyoService.setRateFtth(rate.rates.fibra);
                rate.rates.movil.type_pay = this.ratesListFormGroup.get('movilType').value;
                // this.simyoService.addFirstRatesMobile({...rate.rates.movil, bonos: rate.rates.bonos});
                this.simyoService.addFirstRatesMobile({movil: rate.rates.movil});
                this.router.navigate(['simyo/convergente']);
            }

            if (this.customRate.typeOrder === orderType.MOBILE) {
                rate.rates.movil.type_pay = this.ratesListFormGroup.get('movilType').value;
                //this.simyoService.setRatesMobile(0, {movil: rate.rates.movil, bonos: rate.rates.bonos});
                this.simyoService.setRatesMobile(0, {...rate.rates.movil});
                this.router.navigate(['simyo/solo-movil']);
            }
        }

        if (isReadyToBuy && this.ratesListFormGroup.get('movilType').value === mobileContractType.prepaid) {
            this.rechargePrice = this.rateService.getRechargePrice(+rate.rate_info.real_price);
            if (this.rechargePrice > 0) {
                this.modalService.open(this.content, {size: 'md', centered: true, backdrop: 'static', keyboard : false});
            }
        } else if (this.customRate.typeOrder === orderType.CONVERGENT && this.ratesListFormGroup.get('movilType').value === mobileContractType.prepaid && !isReadyToBuy) {
            this.rechargePrice = this.rateService.getRechargePrice(+rate.rates.movil.rate_info.real_price);// + this.simyoGlobals.pricesBonos(rate.rates.bonos));
            if (this.rechargePrice > 0) {
                this.modalService.open(this.content, {size: 'md', centered: true, backdrop: 'static', keyboard : false});
            }
        } else if (this.customRate.typeOrder === orderType.MOBILE && this.ratesListFormGroup.get('movilType').value === mobileContractType.prepaid) {
            this.rechargePrice = this.rateService.getRechargePrice((+rate.rates.movil.rate_info.real_price));// + this.simyoGlobals.pricesBonos(rate.rates.bonos)));
            if (this.rechargePrice > 0) {
                this.modalService.open(this.content, {size: 'md', centered: true, backdrop: 'static', keyboard : false});
            }
        }
    }

    filterByValue(accumulator, current) {
        const x = accumulator.find(item => item.label === current.label);

        if (!x) {
            return accumulator.concat([current]);
        } else {
            return accumulator;
        }
    }

    compare(a, b) {
        let labelA = parseInt(a.label.replace('MIN', '').replace('MB', '').replace('GB', ''), 10);
        let labelB = parseInt(b.label.replace('MIN', '').replace('MB', '').replace('GB', ''), 10);
        labelA = isNaN(labelA) ? 1 : labelA;
        labelB = isNaN(labelB) ? 1 : labelB;

        if (a.label.includes('ILIMIT')) {
            labelA = labelA * 1000000;
        } else if (a.label.includes('GB')) {
            labelA = labelA * 100;
        }

        if (b.label.includes('ILIMIT')) {
            labelB = labelB * 1000000;
        } else if (b.label.includes('GB')) {
            labelB = labelB * 100;
        }

        if (labelA < labelB) {
            return -1;
        }

        if (labelA > labelB) {
            return 1;
        }

        return 0;
    }

    isAvailableRate() {
        if (this.ratesListFormGroup.get('fibraRate').value !== '0') {
            const found = this.fibraRates.find(x => x.rate_id === +this.ratesListFormGroup.get('fibraRate').value);
            if (found) {
                this.getDiffRates(this.allRates, this.simyoGlobals.getAvailableIn(found.rate_info.speed));
                this.getDiffMovilType(this.allRates, this.simyoGlobals.getAvailableIn(found.rate_info.speed));
            }
        } else {
            this.getDiffRates(this.allRates, rateAvailableIn.mobile);
            this.getDiffMovilType(this.allRates, rateAvailableIn.mobile);
        }
        this.initMobileRate();
    }

    public changeTaxType(taxType): void {
        this.selectedTax = taxType;
        this.ngOnInit();
    }

    zipCodeSelectedTax(){
        if (this.selectedTax == 'iva') {
            return null;
        }
        if (this.selectedTax == 'igic') {
            return '35000';
        }
        if (this.selectedTax == 'ipsi_8') {
            return '52000';
        }
        if (this.selectedTax == 'ipsi_10') {
            return '51000';
        }
    }

    checkPermissions(type) {
        if(this.permSv.hasPermFromV2(allPermissions.simyo.permite_sell_prepaid_postpaid)) {
            return true;
        }
        if(type === 'postpaid' && this.permSv.hasPermFromV2(allPermissions.simyo.permite_sell_postpaid)) {
            if(this.ratesListFormGroup.get('movilType').value !== type) {
                this.ratesListFormGroup.patchValue({'movilType': type})
            }
            return true;
        }

        if(type === 'prepaid' && this.permSv.hasPermFromV2(allPermissions.simyo.permite_sell_prepaid)) {
            if(this.ratesListFormGroup.get('movilType').value !== type) {
                this.ratesListFormGroup.patchValue({'movilType': type})
            }
            return true;
        }

        return false;
    }
}
