import { Component, OnInit, Output, EventEmitter, OnDestroy, ViewChild, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SimyoService } from '../../services/simyo.service';
import { orderType, documentationTypes, technologyTypes, ComponentState, ComponentSteps, modalityIV } from 'src/app/shared/constantes';
import { SimyoOrderService } from '../../services/simyo-order.service';
import { SimyoConfirmationService } from '../../services/simyo-confirmation.service';
import { Order } from '../../classes/order';
import { SimyoGlobals, descuento } from '../../classes/simyo-globals';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SimyoShoppingCartService } from '../../services/simyo-shopping-cart.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from '../../../shared/components/alert-dialog/alert-dialog.component';
import { of, Subscription, throwError } from 'rxjs';
import { SimyoRateService } from '../../services/simyo-rate.service';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { StringMap } from '@angular/compiler/src/compiler_facade_interface';
import { IstateDocumentationEE, signatureDocuments } from '../../models/signatureDocuments';
import { MatStepper } from '@angular/material/stepper';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { getUnique } from 'src/app/utils/getUniqueFromArray';
import { Ipdv } from 'src/app/shared/models/pdvResponse';
import {downloadFile} from 'src/app/utils/downloadFile';
import { HttpErrorResponse } from '@angular/common/http';
import { SimyoDocumentationService } from '../../services/simyo-documentation.service';
import { allPermissions } from 'src/app/shared/permissions';
import { DigoService } from 'src/app/services/digo.service';
import { SearchPdvComponent } from '../../modals/search-pdv/search-pdv.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SimyoNormalizadorService } from '../../services/simyo-normalizador.service';
import { ConfirmComponent } from '../../modals/confirm/confirm.component';
import { catchError, map, pluck, take, tap } from 'rxjs/operators';
import { responseReuseIVAccessSimyo } from '../../models/installationVirtual';
import { SimyoAppointmentService } from '../../services/simyo-appointment.service';
import { AppointmentResult } from '../../models/appointment';


@Component({
    selector: 'app-simyo-confirmation',
    templateUrl: './simyo-confirmation.component.html',
    styleUrls: ['./simyo-confirmation.component.css', '../../../../assets/css/simyo-theme.css']
})
export class SimyoConfirmationComponent implements OnInit, OnChanges, OnDestroy {
    @Input() movilPackForms: FormGroup;
    @Input() clienteFormGroup: FormGroup;
    @Input() fibraFormGroup: FormGroup;
    @Input() pdv: Ipdv;
    @Input() stepper: MatStepper;
    @Input() orderCreateIsFinished: boolean;
    @Input() signatureFormGroup: FormGroup;
    @Output() onActivationRequest = new EventEmitter<boolean>();
    @Output() onActivationLoader = new EventEmitter<any>();
    @ViewChild('infoPreviaActivar') infoPreviaActivar: NgbModalRef;

    public order: Order;
    public dataOrderToSignature: signatureDocuments;
    public typeOrder: string;
    public rateFtth: any = null;
    public ratesMobiles = [];
    public isLoading = false;
    public activationError: any;
    public date = new Date();
    public startGetStatusTime = 0;
    public documentationMode: StringMap = documentationTypes;
    public showMsgValidateDocumentation: boolean = false;
    private enableActivateButton: boolean = false;
    private allSubscription: Subscription[] = [];
    public customerData: any;
    public precontract: boolean = false;
    public hash: string = "";
    public loadingPrecontract: boolean = false;
    public loadingDownload: boolean = false;
    public errorDownload: boolean = false;
    public sendEmailTries: number = 3;
    public downloaded: boolean = false;
    public sended: boolean = false;
    public modalShowed: boolean = false;
    public stepperAqui: boolean = false;
    public instalationVirtualFormGroup: FormGroup;
    public dataIV;

    public componentState = ComponentState;
    public componentsStatus = {
        precontract: ComponentState.NotLoad,
        documentation: ComponentState.NotLoad,
        virtualInstallation: ComponentState.NotLoad,
        iuaValidation: ComponentState.NotLoad,
        scheduleAppointment: ComponentState.NotLoad,
        btnOrderFinish: ComponentState.NotLoad
    }
    validateDocumentation: boolean;

    constructor(
        private simyoService: SimyoService,
        private orderService: SimyoOrderService,
        private documentService: SimyoDocumentationService,
        private confirmationService: SimyoConfirmationService,
        private modalService: NgbModal,
        private rateService: SimyoRateService,
        public matDialog: MatDialog,
        private shoppingCart: SimyoShoppingCartService,
        public simyoGlobals: SimyoGlobals,
        public permSv: PermissionService,
        private formBuilder: FormBuilder,
        public digoService: DigoService,
        public appointmentService: SimyoAppointmentService
    ) {
        this.instalationVirtualFormGroup = this.formBuilder.group({
            instalationVirtualCheck: [null, Validators.required]
        });
    }

    ngOnInit(): void {
        this.fibraFormGroup.get('client_iua').valueChanges.subscribe((value)=>{
            this.order.broadband.client_iua = this.fibraFormGroup.get('client_iua').valid ? this.fibraFormGroup.get('client_iua').value : null;
        });

        this.clienteFormGroup.get('email').valueChanges.subscribe(()=>{this.resetPrecontract()});
        this.fibraFormGroup.get('rate').valueChanges.subscribe(()=>{this.resetPrecontract()});
        this.movilPackForms.valueChanges.subscribe((configurationFormValue) => {
            if (this.simyoService.getChangeMobilePack() === true) {
                this.simyoService.setChangeMobilePack(false);
                this.resetPrecontract();
            }
            else{
                this.simyoService.setChangeMobilePack(true);
            }

        });

        this.allSubscription.push(this.simyoService.getTypeOrder()
            .subscribe((data: any) => {
                this.typeOrder = data;
            })
        );

        this.allSubscription.push(this.simyoService.getRateFtth()
            .subscribe((data: any) => {
                this.rateFtth = data;
            })
        );

        this.allSubscription.push(this.simyoService.getRatesMobile()
            .subscribe((data: any) => {
                if (data != null) {
                    this.ratesMobiles = data;
                }
            })
        );

        this.allSubscription.push(this.orderService.getOrder()
            .subscribe((data: any) => {
                this.order = data;
                //this.componentsStatus.scheduleAppointment = this.order.broadband.access_type === technologyTypes.dir && this.permSv.hasPermFromV2(allPermissions.simyo.permite_cita_previa) ? ComponentState.NotLoad : ComponentState.Hidden;
            })
        );

        this.allSubscription.push(this.simyoService.getCustomerPromotion()
            .subscribe((data) => {
                this.customerData = data;
            })
        );

        this.stepper.selectionChange
            .subscribe((res: StepperSelectionEvent) => {
                if (this.stepper.steps.last === res.selectedStep) {
                    this.stepperAqui = true;
                    let allPhonesToContact: any[] = [{phone: this.order.customer.phone_number, type: 'Contacto'}];
                    this.order.mobiles.map((mobile, index) => mobile.msisdn !== '' ? allPhonesToContact.push({phone: mobile.msisdn, type: index+1 + 'ª línea'}) : null);
                    this.dataOrderToSignature = {
                        orderId: this.order.order_id,
                        email: this.order.customer.email,
                        phones: getUnique(allPhonesToContact, 'phone'),
                        orderInProcess: this.order,
                        isLastStep: true
                    }
                    if (!this.modalShowed && !this.sended && !this.downloaded && this.sendEmailTries === 0) {
                        this.openModal('No es posible enviar el correo al cliente. Imprime ahora el documento y entrégaselo al cliente. Recuerda que es obligatorio que el cliente disponga de este documento.');
                    }
                    this.scrollToBottom(700);
                }
                else{
                    this.stepperAqui = false;
                }
                if (this.dataOrderToSignature && this.stepper.steps.last !== res.selectedStep) this.dataOrderToSignature.isLastStep = false;
            });

        this.initializeComponentsStatus();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.orderCreateIsFinished?.currentValue) {
            this.componentsStatus.precontract = changes?.orderCreateIsFinished?.currentValue ? ComponentState.View : ComponentState.NotLoad;
            this.showMsgValidateDocumentation = false;
            if(this.stepperAqui) {
                this.scrollToBottom(0);
            }
        }
    }

    activateService(precontract: boolean) {
        if(this.digoService.isOpSimyoUser() && precontract === false) {
            //mostrar modal de username y enviar la firma de documentos vacia
            const options: NgbModalOptions = {
                backdrop: 'static',
                size: 'lg',
                centered: true,
                windowClass: 'modalAlertChange'
            };

            let modalRef = this.modalService.open(SearchPdvComponent, options);
            modalRef.componentInstance.onCreateOrder.subscribe((pdv) => {
                this.orderService.setSupplantAccount(pdv.id_pdv, pdv.id_usuario_principal, pdv.username_principal);
                this.activate(precontract);
            });
            return;
        }
        this.showMsgValidateDocumentation = false;
        if (this.permSv.hasPermFromV2(allPermissions.others.users_demo)) {
            this.openModal('Usuario demostración. No se puede finalizar el alta');
            return;
        }
        if (this.isDisabled() && precontract === false) {
            if (!this.enableActivateButton && this.permSv.hasPermFromV2(allPermissions.simyo.permite_new_signature)) {
                this.showMsgValidateDocumentation = true;
            }
            return;
        }

        if(this.simyoService.getCanVirtualInstallation() && !precontract) {
            let instalationVirtualCheck = this.instalationVirtualFormGroup.value.instalationVirtualCheck;
            if(instalationVirtualCheck) {
                const options: NgbModalOptions = {
                    backdrop: 'static',
                    size: 'lg',
                    centered: true,
                    windowClass: 'modalAlertChange'
                };

                let modalWarningRef = this.modalService.open(ConfirmComponent, options);
                modalWarningRef.componentInstance.text = `Vas a realizar la activación con instalación virtual, más rápida y sin necesidad de visita de un técnico. Ten en cuenta que esto supondrá <b>la baja del fijo ${this.dataIV.phoneNumber}</b> que tu cliente tiene en <b>${this.dataIV.brand}</b>.`;
                modalWarningRef.componentInstance.title = '¡Advertencia!';
                modalWarningRef.componentInstance.onConfirm.pipe(take(1)).subscribe((result) => {
                    this.simyoService.postConfirmVirtualInstallation(result).pipe(take(1)).subscribe();
                    this.orderService.setBroadbandIV(result === 'SI');
                    this.activate(precontract);
                });
            } else {
                this.simyoService.postConfirmVirtualInstallation('NO').pipe(take(1)).subscribe();
                this.orderService.setBroadbandIV(false);
                this.activate(precontract);
            }
            return;
        }
        this.activate(precontract);
    }

    retryCreate(precontract: boolean) {
        if (this.permSv.hasPermFromV2(allPermissions.others.users_demo)) {
            this.openModal('Usuario demostración. No se puede finalizar el alta');
            return;
        }
        if (this.isDisabled() && !precontract) {
            if (!this.enableActivateButton && this.permSv.hasPermFromV2(allPermissions.simyo.permite_new_signature)) {
                this.showMsgValidateDocumentation = true;
            }
            return;
        }
        this.activate(precontract);
        this.scrollToBottom(300);
    }

    activate(precontract: boolean) {

        if (precontract) {
            this.documentService.createPreOrder(this.order.order_id, this.order.only_send_email)
            .subscribe((response: any) => {
                if (response?.error?.errorCode === 's107') {
                    this.sendEmailTries--;
                    this.sended = false;
                    this.previousComponent(ComponentSteps.precontract);
                    this.hash = response?.error?.errorCode;
                    if (this.sendEmailTries > 0) {
                        this.order.only_send_email = true;
                        this.activate(true);
                    }else{// se ha intentado ya 2 veces y no se ha enviado el mail
                        if (!this.downloaded && !this.errorDownload && !this.modalShowed && this.stepperAqui) { //Si no ha intentado descargar aun
                            this.openModal('No es posible enviar el correo al cliente. Imprime ahora el documento y entrégaselo al cliente. Recuerda que es obligatorio que el cliente disponga de este documento.');
                        }
                    }
                }else{
                    this.sendEmailTries = 3;
                    this.hash = response.hash;
                    this.sended = true;
                    this.order.only_send_email = false;
                    this.nextComponent(ComponentSteps.precontract);
                    this.scrollToBottom(500);
                }
                this.loadingPrecontract = false;
            },
            error => {

                this.hash = '';
                this.order.only_send_email = true;
                this.sended = false;
                this.loadingPrecontract = false;
                this.previousComponent(ComponentSteps.precontract);
            });
            return;
        }

        let initialTime = new Date().getTime();
        this.activationError = null;
        this.onActivationRequest.emit(false);
        this.confirmationService.setHttpError(false);
        this.startGetStatusTime = initialTime;
        this.isLoading = true;
        this.orderService.createOrder(false)
        .subscribe((response: any) => {
            this.orderService.setSkipKYC(false)
        }, error => {
            this.isLoading = false;
            this.activationError = error;
            this.onActivationRequest.emit(true);
            this.confirmationService.setHttpError(true);
        });


    }

    public preloadingComplete(order) {
        this.onActivationLoader.emit(order);
        this.confirmationService.setFinished(true);
    }

    goToStep(index) {
        this.shoppingCart.getStepper().selectedIndex = index;
    }

    removeExtraLine(index) {
        this.orderService.orderShowRemoveOrderDialog(index);
    }

    public showActivateButtonEvent(stateDocumentation: IstateDocumentationEE): void {
        this.enableActivateButton = stateDocumentation.formIsValid;
        if(stateDocumentation.allUploadDocAreValid && this.enableActivateButton) {
            if(this.showIuaValidation() && this.componentsStatus.virtualInstallation == this.componentState.Hidden) {
                this.nextComponent(ComponentSteps.iuaValidation, ComponentState.View);
            }
            this.nextComponent(ComponentSteps.documentation);
            this.validateDocumentation = true;
        } else if (this.validateDocumentation) {
            this.validateDocumentation = false;
            this.previousComponent(ComponentSteps.documentation);
        }
        if (stateDocumentation.allUploadDocAreValid) this.showMsgValidateDocumentation = false;
    }

    public isDisabled(): boolean {
        return !this.orderCreateIsFinished || (this.digoService.isOpSimyoUser() === false && !this.enableActivateButton && this.permSv.hasPermFromV2(allPermissions.simyo.permite_new_signature));
    }

    public precontractSended(): void {
        this.reuseIVAccess();
        setTimeout(() => {
            this.checkAppointment();
          }, 4000);

        this.precontract = true;
        this.loadingPrecontract = true;
        this.errorDownload = false;
        this.activateService(true);
    }

    public download(){
        if (this.sendEmailTries > 0 && !this.sended && !this.modalShowed) {
            this.openModal('Imprime ahora el documento y entregárselo al cliente. Recuerda que es obligatorio que el cliente disponga de este documento.');
        }
        this.loadingDownload = true;
        this.documentService.downloadPre(this.order.order_id)
            .subscribe(data => {
                if (data) {
                    var reader = new FileReader();
                    let self = this;
                    reader.onloadend = function() {
                        try {
                            const file = JSON.parse(reader.result as string);
                            if (file && file.error) {
                                self.errorDownload = true;
                            }
                        } catch (e) {
                            downloadFile(data, 'Resumen_de_contrato.pdf');
                        }
                    };
                    reader.readAsText(data);
                    this.downloaded = true;
                    this.nextComponent(ComponentSteps.precontract);
                }
                this.loadingDownload = false;
            }, (error: HttpErrorResponse) => {
                this.loadingDownload = false;
                this.errorDownload = true;
                this.downloaded = false;
                this.previousComponent(ComponentSteps.precontract);
            }, () => {
            });
    }

    public resetPrecontract(){
        this.precontract = false;
        this.sendEmailTries = 3;
        this.order.only_send_email = false;
        this.downloaded = false;
        this.sended = false;
        this.modalShowed = false;
        //Reset error
        this.activationError = null;
        this.initializeComponentsStatus();
    }

    public openModal(msg: string){
        this.modalShowed = true;
        this.matDialog.open(AlertDialogComponent, {
            data: {message: msg}
        });
    }

    ngOnDestroy() {
        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map(subs => subs.unsubscribe());
        }
    }

    scrollToBottom(time) {
        if(this.stepperAqui) {
            setTimeout(() => window.scrollTo({ top: document.body.scrollHeight, left: 0 , behavior: 'smooth' }), time);
        }
    }

    public errorHandling = (control: string, error: string) => {
        if (this.instalationVirtualFormGroup.get(control).invalid && (this.instalationVirtualFormGroup.get(control).dirty || this.instalationVirtualFormGroup.get(control).touched)) {
            return this.instalationVirtualFormGroup.controls[control].hasError(error);
        }
    };

    public confirmIV(value) {
        this.instalationVirtualFormGroup.patchValue({
            instalationVirtualCheck: value
        });
        if(value) {
            this.componentsStatus.scheduleAppointment = this.componentState.Hidden;
            this.componentsStatus.iuaValidation = this.componentState.Hidden;
        } else {
            if(this.showIuaValidation()) {
                this.nextComponent(ComponentSteps.iuaValidation, ComponentState.View);
            }
        }
        this.nextComponent(ComponentSteps.virtualInstallation);
    }

    nextComponent(currentComponent, status = ComponentState.Finished) {
        this.componentsStatus[currentComponent] = status;
        let nextKey = this.getNextKey(this.componentsStatus, currentComponent);
        if(nextKey == null) {
            return;
        }
        if(this.componentsStatus[nextKey] == ComponentState.Finished || this.componentsStatus[nextKey] == ComponentState.Hidden ) {
            this.nextComponent(nextKey, this.componentsStatus[nextKey]);
            return;
        }
        this.componentsStatus[nextKey] = ComponentState.View;
        this.scrollToBottom(500);
    }

    previousComponent(currentComponent, status = ComponentState.View) {
        this.componentsStatus[currentComponent] = status;
        let nextKey = this.getNextKey(this.componentsStatus, currentComponent);
        if(nextKey == null) {
            return;
        }
        if(this.componentsStatus[nextKey] == ComponentState.Hidden ) {
            this.previousComponent(nextKey, this.componentsStatus[nextKey]);
            return;
        }
        this.previousComponent(nextKey, ComponentState.NotLoad);
        this.scrollToBottom(500);
    }

    getNextKey(obj: any, currentKey: string): string | null {
        const keys = Object.keys(obj); // Obtén las claves del objeto en un array
        const currentIndex = keys.indexOf(currentKey); // Encuentra la posición de la clave actual

        if (currentIndex === -1 || currentIndex === keys.length - 1) {
          // Si la clave no existe o es la última clave
          return null;
        }

        return keys[currentIndex + 1]; // Devuelve la siguiente clave
    }

    initializeComponentsStatus() {
        this.componentsStatus =  {
            precontract: this.orderCreateIsFinished ? ComponentState.View : ComponentState.NotLoad,
            documentation: this.digoService.isOpSimyoUser() === false && this.permSv.hasPermFromV2(allPermissions.simyo.permite_new_signature) ? ComponentState.NotLoad : ComponentState.Hidden,
            iuaValidation: ComponentState.Hidden,
            virtualInstallation: this.typeOrder !== orderType.MOBILE ? ComponentState.NotLoad : ComponentState.Hidden,
            scheduleAppointment:  this.typeOrder !== orderType.MOBILE && this.permSv.hasPermFromV2(allPermissions.simyo.permite_cita_previa) ? ComponentState.NotLoad : ComponentState.Hidden,
            btnOrderFinish: ComponentState.NotLoad
        }
    }



    reuseIVAccess() {
        if(this.typeOrder === orderType.MOBILE) {
            this.componentsStatus.virtualInstallation = ComponentState.Hidden;
            return;
        }
        let permissionIV = this.order.broadband.access_type == technologyTypes.dir ? allPermissions.simyo.permite_iv_dir : allPermissions.simyo.permite_iv_vula;
        if(this.permSv.hasPermFromV2(permissionIV) == false) {
            this.componentsStatus.virtualInstallation = ComponentState.Hidden;
            return;
        }

        this.simyoService.setVirtualInstallationData({
            modality: modalityIV[this.rateFtth.rate_info.speed]
        })
        //update virtual installation
        this.simyoService.postVirtualInstallationData().pipe(
            catchError(error => of({msg: {message: 'ko'}})),
            /*map(() => {
                return {
                    "msg": {
                        "message": "ok",
                        "description": "Operation Successful",
                        "registerId": null,
                        "phoneNumber": "00000000"
                    }
                }
            }),*/
            pluck('msg'),
            map((response:responseReuseIVAccessSimyo) => {
                if(response.message === 'ok') {

                    return {
                        ...response,
                        brand: response.brand?.toLowerCase().replace(/\b\w/g, s => s.toUpperCase()) || ''
                    };
                }
                return response;
            }),
            tap((response:responseReuseIVAccessSimyo) => {
                if(response.message !== 'ok') {
                    this.simyoService.setCanVirtualInstallation(false);
                    this.componentsStatus.virtualInstallation = ComponentState.Hidden;
                    return;
                }
                this.simyoService.setCanVirtualInstallation(true);
                this.componentsStatus.virtualInstallation = ComponentState.NotLoad;
                this.simyoService.setResponseVirtualInstallationData(response);
                this.dataIV = response;
            }),

        )
        .subscribe()
    }

    changeAppointment($event) {
        if($event == true) {
            this.nextComponent(ComponentSteps.scheduleAppointment);
        } else {
            this.previousComponent(ComponentSteps.scheduleAppointment);
        }
    }

    checkAppointment() {
        if(this.order.broadband?.access_type != technologyTypes.dir || this.permSv.hasPermFromV2(allPermissions.simyo.permite_cita_previa) == false) {
            this.componentsStatus.scheduleAppointment = ComponentState.Hidden;
            return;
        }

        this.appointmentService.checker(this.order.broadband.gescal, this.order.order_id).pipe(
            take(1),
            tap((response: AppointmentResult) => {
                this.componentsStatus.scheduleAppointment = response.result == 'OK' && ComponentState.NotLoad ? ComponentState.NotLoad : ComponentState.Hidden;
            })
        ).subscribe();
    }

    showIuaValidation() {
        return false;
        return [technologyTypes.ind_vula].includes(this.order.broadband.access_type);
    }
}

